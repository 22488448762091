/** Styles to view **/
import { StyleSheet, ImageStyle, Platform, TextStyle, ViewStyle } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

let imageNotFoundContainer: ViewStyle | TextStyle | ImageStyle;
if (Platform.OS === 'web') {
  imageNotFoundContainer = {
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: '25%',
    marginLeft: '5%',
    marginRight: '5%',
  };
} else {
  imageNotFoundContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100vh',
    minHeight: '100vh',
  },
  containerHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: colors.primary,
    paddingTop: 20,
    paddingRigth: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    marginBottom: 20,
  },
  title: {
    color: colors.white,
    fontSize: 15,
  },
  titleHistory: {
    color: colors.white,
    fontSize: 14,
    textAlign: 'left',
  },
  viewRequestOrderItemsAmount: {
    paddingLeft: 20,
    width: '33%',
  },
  quotaAvailableValue: {
    paddingRigth: 20,
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 15,
    textAlign: 'right',
  },
  containerInfo: {
    marginRight: 7,
    marginLeft: 7,
    paddingTop: 5,
    paddingBottom: 10,
    backgroundColor: colors.white,
    flexDirection: 'row',
  },
  containerDateType: {
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  containeTitle: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerData: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerOverdueDebt: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  label: {
    color: colors.black,
    fontSize: 15,
    ontWeight: 'bold',
  },
  value: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 14,
  },
  containerDebt: {
    marginTop: 30,
    marginRight: 20,
    marginLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  labelDebt: {
    color: colors.gray,
    fontSize: 14,
  },
  valueDebt: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 24,
  },
  searchBarContainer: {
    backgroundColor: colors.white,
    borderWidth: 0,
    borderTopWidth: 0,
    borderTopColor: colors.white,
    borderBottomColor: colors.white,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  inputContainerStyle: {
    backgroundColor: colors.searchBarBackground,
  },
  searchBarInputStyle: {
    backgroundColor: colors.searchBarBackground,
    color: colors.black,
  },
  buttonTextBottom: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 14,
  },
  containerDate: {
    width: '55%',
    right: '3%',
  },
  TextInputDate: {
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
    borderRadius: 2,
    width: '80%',
    height: '20%',
    textAlign: 'left',
    marginLeft: 40,
    paddingBottom: 9,
    paddingLeft: 5,
    paddingTop: 7,
    marginTop: 15,
    bottom: 10,
  },
  TextInputType: {
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
    borderRadius: 2,
    width: '45%',
    height: '100%',
    paddingBottom: 10,
    textAlign: 'left',
    color: colors.black,
    marginRight: 35,
    paddingRight: 5,
    left: '3%',
  },
  containerButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    display: 'flex',
    flex: 1,
    width: '100%',
    maxHeight: '100%',
  },
  TextTotaLimit: {
    borderRadius: 3,
    width: '50%',
    height: '100%',
    padding: 15,
    marginLeft: 5,
    marginRight: 2,
    textAlign: 'center',
    marginTop: -10,
    backgroundColor: '#64BBD0',
    color: colors.white,
    fontWeight: 'bold',
  },
  TextTotaLavailable: {
    borderRadius: 3,
    width: '50%',
    height: '100%',
    padding: 15,
    marginRight: 5,
    marginLeft: 2,
    marginTop: -10,
    textAlign: 'center',
    color: colors.white,
    fontWeight: 'bold',
  },
  colorRedTotaLavailable: {
    backgroundColor: '#F76268',
  },
  colorTotaLavailable: {
    backgroundColor: '#2ECC71',
  },
  daysPastDue: {
    borderBottomStartRadius: 25,
    borderBottomEndRadius: 25,
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    width: '100%',
    height: '100%',
    padding: 13,
    marginLeft: 5,
    marginRight: 2,
    textAlign: 'center',
    marginTop: 2,
    backgroundColor: 'white',
    fontWeight: 'bold',
    fontSize: 15,
  },
  colorRed: {
    color: colors.danger,
  },
  colorblue: {
    color: colors.blueRSales,
  },
  containerDaysPastDue: {
    paddingTop: 5,
    paddingBottom: 10,
    flexDirection: 'row',
    backgroundColor: colors.softGray,
  },
  imageNoFoundContainer: imageNotFoundContainer,
  imageNoFound: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    width: 128,
    height: 128,
  },
  imageNoFoundDescriptionContainer: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescription: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: colors.grayPlaceholder,
  },
  Allcontainer: {
    height: '40vh',
    minHeight: 400,
  },
  containerItems: {
    height: '60vh',
  },
  headerSide: {
    position: 'relative',
  },
});

/** Export component styles **/
export default styles;
