/** Import React **/
import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Components **/
import Button from '../button';
import Loader from '../loader';

/** Import constants **/
import styles from './style';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { IconMoon } from './../icon/IconMoon';
import icons from './../../constants/icons/icons';
import colors from './../../constants/styles/colors';

/** Const to define size in icons **/
const ICON_SIZE = 24;

interface Props {
  onRef: (ref: any) => void;
  title: string;
  text_products: string;
  subtitle: string;
  action: () => void;
  actionText: string;
  auth?: Auth;
}

interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  callBackClose?: () => void | null;
}

/**
 * Create Modal with password
 *
 * @param {*} props
 * @returns
 */
class ModalConfirmItem extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   *Creates an instance of ModalConfirmItem.
   * @param {*} props
   * @memberof ModalConfirmItem
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
    };
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalConfirmItem
   */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalConfirmItem
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  /**
   * Open alert
   *
   * @memberof ModalConfirmItem
   */
  openModalConfirmItem = () => {
    this.setState({
      isVisible: true,
    });
  };

  /**
   * Close alert
   *
   * @memberof ModalConfirmItem
   */
  closeModalConfirmItem = () => {
    this.setState({
      isVisible: false,
    });
  };

  /**
   * Function to log out
   *
   * @memberof ModalConfirmItem
   */
  _callAction = () => {
    this.setState({ callBackClose: this.props.action }, () => this.closeModalConfirmItem());
  };

  /**
   * Render ModalConfirmItem in modal
   *
   * @returns
   * @memberof ModalConfirmItem
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        avoidKeyboard={true}
        style={styles.modal}
        onModalHide={() => this.state.callBackClose && this.state.callBackClose()}
      >
        <SafeAreaProvider>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <View style={styles.modalBackground}>
            <TouchableOpacity style={styles.button}>
              <IconMoon
                onPress={this.closeModalConfirmItem}
                style={styles.icon}
                name={icons.icCloseWhite}
                size={ICON_SIZE}
                color={colors.white}
              />
            </TouchableOpacity>
            <View style={styles.modalView}>
              {this.props.title != '' ? <Text style={styles.modalTitle}>{this.props.title}</Text> : null}
              {this.props.text_products != '' ? (
                <Text style={styles.modalTextProduc}>{this.props.text_products}</Text>
              ) : null}
              <Text style={styles.modalSubTitle}>{this.props.subtitle}</Text>
              <View style={styles.containerButtons}>
                <Button
                  onPress={this._callAction}
                  buttonClass={styles.buttonConfirm}
                  buttonLabelText={this.props.actionText}
                  buttonLabelTextClass={styles.buttonTextConfirm}
                />
              </View>
            </View>
          </View>
        </SafeAreaProvider>
      </Modal>
    );
  }
}

ModalConfirmItem.propTypes = {
  auth: AuthPropTypes,
};

ModalConfirmItem.defaultProps = {};

/** Export component ModalConfirmItem **/
export default connect((state) => ({ ...state }))(ModalConfirmItem);
