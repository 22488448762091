/** Import modules **/
import React, { Component } from 'react';
import { View, Text, Platform, FlatList, Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import SearchBar **/
import { SearchBar } from 'react-native-elements';

/** Import Api **/
import { apiGet } from '../../api';
import { urlFavoritesList } from '../../api/urls';

/** Import components **/
import ButtonBranchOffice from '../../components/buttonBranchOffice';
import HeaderSideDrawer from '../../components/headerSideDrawer';
import LoaderWithoutModal from '../../components/loaderWithoutModal';
import ProductsFlatList from '../../components/productsFlatList';

/** Import Constants **/
import assets from '../../constants/assets/assets';
import colors from '../../constants/styles/colors';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, FilterData, ProductItem, Products, ShoppingCart, ShoppingCartItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes, ProductsPropTypes, ShoppingCartPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setFavorites } from '../../slices/favorites/favoritesSlice';

/** Import Utils **/
import parseProductData from '../../utils/parseProductData';
import showAlert from '../../utils/showAlert';
import { updateMinimumAmountShoppingCartItem } from '../../slices/shoppingCart/shoppingCartSlice';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Global variables **/
const WAIT_INTERVAL = 800;

/** Import Images **/
import notFoundImage from '../../web_assets/images/not_found.png';
import ShoppingCartButton from '../../components/shoppingCartButton';

let NOT_FOUND = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NOT_FOUND = notFoundImage;
} else if (Platform.OS === 'ios') {
  NOT_FOUND = { uri: `${assets.notFound}` };
} else {
  NOT_FOUND = { uri: `@mipmap/${assets.notFound}` };
}

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  favorites: Products;
  shoppingCart: ShoppingCart;
}

interface State {
  handleLoadMore: boolean;
  group: string;
  family: string;
  line: string;
  type_search: string;
  total_amount?: number;
  loading: boolean;
  data: Array<ProductItem>;
  filter_data: FilterData;
}

/**
 * Class to export FavoritesScreen
 *
 * @class FavoritesScreen
 * @extends {Component<Props, State>}
 */
class FavoritesScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public timer!: any;

  /**
   * Creates an instance of FavoritesScreen.
   * @param {*} props
   * @memberof FavoritesScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      handleLoadMore: true,
      group: '%',
      family: '%',
      line: '%',
      type_search: 'LIKENAMEANDCODE',
      total_amount: undefined,
      loading: false,
      data: [],
      filter_data: {
        filter_value: undefined,
        page: {
          has_next: true,
          next_page_number: 0,
        },
      },
    };
  }

  /**
   * Functin to get FavoritesScreen when moduled is opened
   *
   * @memberof FavoritesScreen
   */
  componentDidMount() {
    this.timer = null;
    this._favorites(this.props.auth);
  }

  /**
   * Function to set false mounted
   *
   * @memberof FavoritesScreen
   */
  componentWillUnmount() {
    this.timer = null;
  }

  /**
   * Function to receive props
   *
   * @param {*} prevProps
   * @memberof FavoritesScreen
   */
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id &&
      prevProps.auth &&
      prevProps.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id !== prevProps.auth.current_rshop.rshop_id
    ) {
      this._updateState();
    }

    if (this.props.favorites !== prevProps.favorites) {
      this._updateFavorites();
    }
  }

  /**
   * Function to update state from component did mount
   *
   * @memberof FavoritesScreen
   */
  _updateState = () => {
    this.setState(
      {
        handleLoadMore: true,
        group: '%',
        family: '%',
        line: '%',
        type_search: 'LIKENAMEANDCODE',
        total_amount: undefined,
        loading: false,
        data: [],
        filter_data: {
          filter_value: undefined,
          page: {
            has_next: true,
            next_page_number: 0,
          },
        },
      },
      () => this._favorites(this.props.auth),
    );
  };

  /**
   * Functionto update state from component did update
   *
   * @memberof FavoritesScreen
   */
  _updateFavorites = () => {
    this.setState({ data: this.props.favorites.data });
  };

  /**
   * Function to get rshop_id
   *
   * @memberof FavoritesScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Function to call service and get products
   *
   * @memberof FavoritesScreen
   */
  _favorites = (user: Auth) => {
    if (
      this.state.loading ||
      (user.csrftoken && user.csrftoken.length === 0) ||
      !user.current_rshop ||
      (user.current_rshop && !user.current_rshop.rshop_id)
    ) {
      return;
    }
    this.setState({ loading: true }, async () => {
      const urlFavoritesListReplaced = urlFavoritesList.replace(/\[rshop_id\]/g, String(this._getRshop_id()));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
        filter_value: this.state.filter_data?.filter_value,
        group: this.state.group,
        family: this.state.family,
        line: this.state.line,
        type_search: this.state.type_search,
        page: this.state.filter_data?.page.next_page_number,
        total_amount: this.state.total_amount,
      };
      try {
        const response = await apiGet(urlFavoritesListReplaced, data)();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const favorites = this.parsedProducts(response.favorites);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const filter_value = response.filter_value;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const page = response.page;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.data = [...this.state.data, ...favorites];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.filter_value = this.state.filter_data?.filter_value;

          store.dispatch(setFavorites(response));

          this.setState({
            total_amount: response && favorites && favorites.length > 0 ? favorites[0].total_amount : 0,
            data: favorites,
            filter_data: {
              filter_value: filter_value,
              page: page,
            },
            loading: false,
          });
        } else {
          this.setState({ loading: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (008).';
        }
        this.setState({ loading: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Function to merge ProductItems with the ShoppingCartItems
   */
  parsedProducts = (products: any) => {
    const mergedProducts: Array<any> = [];
    let key: any;
    for (key in products) {
      const item = products[key];
      const shoppingCartItem = this.getShoppingCartItem(item);
      if (shoppingCartItem) {
        mergedProducts.push(parseProductData(shoppingCartItem, undefined));
      } else {
        mergedProducts.push(parseProductData(undefined, item));
      }
    }
    return mergedProducts;
  };

  getShoppingCartItem = (productItem: ProductItem): ShoppingCartItem | undefined => {
    if (this.props.shoppingCart?.data && this.props.shoppingCart.data.length > 0) {
      let i: any;
      for (i in this.props.shoppingCart.data) {
        const shoppingFactor = parseFloat(this.props.shoppingCart.data[i].factor);
        const factorFound = productItem.factors.filter(
          (factor) => parseFloat(String(factor.factor)) === shoppingFactor,
        );
        if (this.props.shoppingCart.data[i].product_code === productItem.code && factorFound.length > 0) {
          store.dispatch(
            updateMinimumAmountShoppingCartItem({
              ...this.props.shoppingCart.data[i],
              minimum_amount: factorFound[0].minimum_amount || 0,
            }),
          );
          return this.props.shoppingCart.data[i];
        }
      }
    }
    return undefined;
  };

  /**
   * Get current items amount on shopping cart
   *
   * @memberof ShoppingCartScreen
   */
  _getCurrentShoppingCartItemsAmount = () => {
    return this.props.shoppingCart.data ? this.props.shoppingCart.data.length : 0;
  };

  /**
   * Function to clean state and then load favorites
   *
   * @memberof FavoritesScreen
   */
  _cleanStateToLoadProducts = () => {
    this.setState(
      {
        total_amount: undefined,
        data: [],
      },
      () => {
        this._favorites(this.props.auth);
      },
    );
  };

  /**
   * Handle event to update filter_value
   *
   * @param {*} term
   * @memberof FavoritesScreen
   */
  _searchUpdated(term: string) {
    clearTimeout(this.timer);
    this.setState(
      {
        filter_data: {
          filter_value: term,
          page: {
            has_next: false,
            next_page_number: 0,
          },
        },
      },
      () => {
        this.timer = setTimeout(this._cleanStateToLoadProducts, WAIT_INTERVAL);
      },
    );
  }

  /**
   * Return an id to each view
   *
   * @memberof FavoritesScreen
   */
  _keyExtractor = (item: ProductItem) => {
    return `${item.code}`;
  };

  /**
   * Function to open detail
   *
   * @memberof FavoritesScreen
   */
  _onPressItem = (item: ProductItem) => {
    let itemSelected;
    if (this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0) {
      let index = -1,
        i: any;
      for (i in this.props.shoppingCart.data) {
        const shoppingFactor = parseFloat(this.props.shoppingCart.data[i].factor);
        const factorFound = item.factors.filter((factor) => parseFloat(String(factor.factor)) === shoppingFactor);
        if (this.props.shoppingCart.data[i].product_code === item.code && factorFound.length > 0) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        itemSelected = parseProductData(this.props.shoppingCart.data[index], item);
      } else {
        itemSelected = item;
      }
    } else {
      itemSelected = item;
    }

    this.props.navigation.navigate(routes.productsDetail, {
      backRoute: routes.favorites,
      item: { ...itemSelected, is_favorite: 'Y' },
      originalItem: { ...item, is_favorite: 'Y' },
    });
  };

  /**
   * Function to open product image gallery
   *
   * @memberof FavoriteScreen
   */
  _onPressImage = (item: ProductItem) => {
    let itemSelected;
    if (this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0) {
      let index = -1,
        i: any;
      for (i in this.props.shoppingCart.data) {
        const shoppingFactor = parseFloat(this.props.shoppingCart.data[i].factor);
        const factorFound = item.factors.filter((factor) => parseFloat(String(factor.factor)) === shoppingFactor);
        if (this.props.shoppingCart.data[i].product_code === item.code && factorFound.length > 0) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        itemSelected = parseProductData(this.props.shoppingCart.data[index], item);
      } else {
        itemSelected = item;
      }
    } else {
      itemSelected = item;
    }

    this.props.navigation.navigate(routes.productImageGallery, {
      backRoute: routes.favorites,
      item: { ...itemSelected, is_favorite: 'Y' },
      originalItem: { ...item, is_favorite: 'Y' },
    });
  };

  /**
   * Render item from ProductsFlatList view
   *
   * @memberof FavoritesScreen
   */
  _renderItem = ({ item, index }: { item: ProductItem; index: number }) => (
    <ProductsFlatList
      backgroundColor={colors.stripedColors[index % colors.stripedColors.length]}
      item={item}
      onPressItem={(itemPressed: any) => this._onPressItem(itemPressed)}
      onPressImage={(itemPressed: any) => {
        this._onPressImage(itemPressed);
      }}
      fromView="Favorites"
    />
  );

  /**
   * Render emtpy component
   *
   * @memberof FavoritesScreen
   */
  _renderEmpty = () => {
    return (this.state.data && this.state.data.length > 0) || this.state.loading ? null : (
      <View style={styles.imageNoFoundContainer}>
        <Image source={NOT_FOUND} style={styles.imageNoFound} />
        <View style={styles.imageNoFoundDescriptionContainer}>
          <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
        </View>
      </View>
    );
  };

  /**
   * Render loader in footer
   *
   * @memberof FavoritesScreen
   */
  _renderFooter = () => {
    if (this.state.loading) {
      return <LoaderWithoutModal />;
    }
  };

  /**
   * Function to handle scroll bottom
   *
   * @memberof FavoritesScreen
   */
  _handleLoadMore = () => {
    if (this.state.loading) {
      return;
    }
    if (this.state.filter_data?.page.has_next) {
      this._favorites(this.props.auth);
    } else {
      this.setState({
        data:
          this.props.favorites && this.props.favorites.data && this.props.favorites.data.length > 0
            ? this.props.favorites.data
            : [],
      });
    }
  };

  _rightComponent = () => <ShoppingCartButton navigation={this.props.navigation} backRoute={routes.favorites} />;

  _onPressTotal = () => {
    this.props.navigation.navigate(routes.checkout, {
      backRoute: routes.favorites,
    });
  };

  /**
   * Render home view
   *
   * @returns
   * @memberof FavoritesScreen
   */
  render() {
    return (
      <>
        <HeaderSideDrawer
          navigation={this.props.navigation}
          auth={this.props.auth}
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
          rightComponent={this._rightComponent}
        />
        <SafeAreaView style={styles.container}>
          <ButtonBranchOffice rshop={this.props.auth?.current_rshop} />
          <SearchBar
            containerStyle={styles.searchBarContainer}
            inputContainerStyle={styles.inputContainerStyle}
            inputStyle={styles.searchBarInputStyle}
            onChangeText={(term) => this._searchUpdated(term)}
            placeholder={strings.searchPlaceholder}
            placeholderTextColor={colors.grayPlaceholder}
            lightTheme
            round
            value={this.state.filter_data?.filter_value}
          />
          <FlatList
            style={styles.flexOne}
            data={this.state.data}
            extraData={this.state}
            keyExtractor={this._keyExtractor}
            renderItem={this._renderItem}
            ListHeaderComponent={this._renderEmpty()}
            ListFooterComponent={this._renderFooter()}
            onEndReached={this._handleLoadMore}
            onEndReachedThreshold={0.4}
            bounces={false}
          />
          <View style={styles.total}>
            <TouchableOpacity
              style={[
                styles.totalContainer,
                this._getCurrentShoppingCartItemsAmount() > 0
                  ? styles.totalContainerEnabled
                  : styles.totalContainerDisabled,
              ]}
              disabled={!(this._getCurrentShoppingCartItemsAmount() > 0)}
              onPress={this._onPressTotal}
            >
              <View style={styles.rowDirection}>
                <View style={styles.viewRequestOrderItemsAmount}>
                  <Text style={styles.textRequestOrderItemsAmount}>{this._getCurrentShoppingCartItemsAmount()}</Text>
                </View>
                <Text style={styles.textRequestOrder}>{strings.total}</Text>
                <Text style={styles.textTotalOrder}>{accounting.formatMoney(this.props.shoppingCart.total_neto)}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </>
    );
  }
}

FavoritesScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
  products: ProductsPropTypes,
  shoppingCart: ShoppingCartPropTypes,
};

FavoritesScreen.defaultProps = {};

/** Export component FavoritesScreen **/
export default connect((state) => ({ ...state }))(FavoritesScreen);
