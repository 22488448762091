let _interval: any;
/**
 * Function to handle delay
 *
 * @param {number} time
 * @param {() => void} callBack
 */
const debounce = (time: number, callBack: () => void) => {
  if (_interval) {
    clearInterval(_interval);
  }
  _interval = setInterval(() => {
    callBack();
    clearInterval(_interval);
  }, time);
};

export default debounce;
