/** Import Constants **/
import colors from './../../constants/styles/colors';

/** Create an object style **/
const drawerStyles = {
  container: {
    backgroundColor: colors.primary,
    width: 250,
  },
};

/** Export component style **/
export default drawerStyles;
