/** Import React **/
import React from 'react';
import { BackHandler, Alert } from 'react-native';

/** Import Constants **/
import { useFocusEffect } from '@react-navigation/native';

/** Import Constants **/
import stringsConstants from '../../constants/strings/strings';

interface Props {
  exitApp?: boolean;
  avoidBack?: boolean;
}

/**
 * Function to create a button rounded
 *
 * @param {Props}
 * @returns
 */
const BackHandlerCustom = ({ exitApp, avoidBack }: Props) => {
  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (exitApp) {
          Alert.alert(
            stringsConstants.signOutTitle,
            stringsConstants.signOutSubtitle,
            [
              {
                text: stringsConstants.cancel,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onPress: () => {},
                style: 'cancel',
              },
              { text: stringsConstants.signOutConfirm, onPress: () => BackHandler.exitApp() },
            ],
            { cancelable: false },
          );
          return true;
        } else if (avoidBack) {
          return true;
        } else {
          return false;
        }
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, ['avoidBack', 'exitApp']),
  );

  return null;
};

/** Export component BackHandlerCustom **/
export default BackHandlerCustom;
