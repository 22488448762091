/** Styles to view **/
import { StyleSheet, Dimensions } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

const { width } = Dimensions.get('window');

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  profileImage: {
    width: 150,
    height: 150,
    borderWidth: 2,
    borderColor: colors.black,
    borderRadius: 150,
  },
  containerData: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  title: {
    color: colors.gray,
    fontWeight: 'bold',
    fontSize: 16,
  },
  containerInfo: {
    marginRight: 20,
    marginLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomColor: colors.gray,
  },
  label: {
    color: colors.gray,
    fontSize: 14,
  },
  value: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 16,
    height: 44,
    width: width - 120,
    borderRadius: 5,
    borderColor: colors.black,
    paddingLeft: 8,
  },
  containerAccount: {
    marginTop: 40,
    marginRight: 20,
    marginLeft: 20,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderWidth: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    marginBottom: 20,
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonText: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 16,
  },
  buttonsEdit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#306BFB',
    borderRadius: 5,
    width: 90,
    height: 35,
    marginRight: 5,
    marginTop: -50,
  },
  containerEditButtons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    display: 'flex',
    flex: 1,
    width: '70%',
    paddingRight: 10,
  },
  textEdit: {
    color: colors.white,
    fontWeight: 'bold',
    marginLeft: 13,
  },
  iconMoon: {
    marginLeft: 8,
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cancel: {
    width: '50%',
    paddingTop: 15,
    height: 70,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'row',
  },
  save: {
    width: '50%',
    paddingTop: 15,
    height: 70,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'row',
  },
  containerCancel: {
    marginTop: 10,
    marginBottom: 10,
    width: '45%',
    height: 55,
    backgroundColor: '#F56167',
    borderRadius: 5,
  },
  containerSave: {
    marginTop: 10,
    marginBottom: 10,
    width: '45%',
    height: 55,
    backgroundColor: '#2ECC71',
    borderRadius: 5,
  },
  rowDirection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  buttonTextCancel: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 20,
  },
});

/** Export component styles **/
export default styles;
