/** Styles to view **/
import { StyleSheet, StatusBar, Dimensions } from 'react-native';

/** Import Constants **/
import colors from './../constants/styles/colors';

const { height } = Dimensions.get('window');

const profileContainerHeight = 110;
const containerButtonsTopHeight = 40;
const textVersionHeight = 30;
const signoutButtonHeight = 48;
const menuOptionsContainerHeight =
  height - profileContainerHeight - containerButtonsTopHeight - textVersionHeight - signoutButtonHeight;

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    //flex: 1,
    height: height,
  },
  /********** Profile *****************/
  profileContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 35 + (StatusBar && StatusBar.currentHeight ? StatusBar.currentHeight : 0),
    marginRight: 10,
    paddingBottom: 25,
    marginLeft: 10,
    height: profileContainerHeight,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginLeft: 30,
  },
  profileImage: {
    width: 50,
    height: 50,
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 3,
    marginLeft: 15,
  },
  profileUserName: {
    textAlign: 'center',
    marginTop: 5,
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 16,
  },
  profileEmail: {
    textAlign: 'center',
    marginTop: 5,
    color: colors.white,
    fontSize: 12,
  },
  /************ Container Buttons *************/
  distributorsAndBranchesContainer: {
    height: containerButtonsTopHeight,
  },
  containerButtonsTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
  },
  containerButtonTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  branchOfficesButton100: {
    backgroundColor: colors.white,
    width: '100%',
    height: 30,
    borderRadius: 20,
  },
  buttonsTopTextClass100: {
    color: colors.primary,
    fontSize: 12,
    fontWeight: 'bold',
  },
  companiesButton: {
    backgroundColor: colors.greenFlat,
    marginLeft: 5,
    marginRight: 5,
    height: 20,
    borderRadius: 20,
  },
  buttonsTopTextClass: {
    color: colors.white,
    fontSize: 10,
    fontWeight: 'bold',
  },
  branchOfficesButton: {
    backgroundColor: colors.branchOffices,
    marginLeft: 5,
    marginRight: 5,
    height: 20,
    borderRadius: 20,
  },
  /********** Menu Options ********************/
  menuOptionsContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
    height: menuOptionsContainerHeight,
    display: 'flex',
  },
  sidebarButton: {
    marginBottom: 5,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
  },
  sidebarLabelButtonTextClass: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  /************** Version ******************/
  textVersion: {
    color: colors.buttonSideDrawer,
    textAlign: 'center',
    paddingTop: 5,
    height: textVersionHeight,
  },
  /************ Singout ***********************/
  signoutButton: {
    borderTopWidth: 1,
    borderColor: colors.white,
    borderRadius: 0,
    height: signoutButtonHeight,
  },
  signoutButtonTextClass: {
    color: colors.white,
    fontSize: 12,
    fontWeight: 'bold',
  },
});

/** Export component style **/
export default styles;
