/** Styles to view **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    borderRadius: 9,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerFocused: {
    backgroundColor: colors.blueRSales,
    opacity: 0.1,
    borderRadius: 9,
  },
  containerIcon: {
    position: 'absolute',
  },
  badgeContainer: {
    position: 'relative',
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.greenFlat,
    width: 20,
    height: 20,
    borderRadius: 20,
    textAlign: 'center',
    position: 'absolute',
    top: -8,
    right: -8,
    zIndex: 99,
    opacity: 0.8,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    marginLeft: 10,
  },
  badgeText: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 12,
  },
});

/** Export component style **/
export default styles;
