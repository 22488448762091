/** Styles to view **/
import { ImageStyle, Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

// const { height } = Dimensions.get('window');
// const TOTAL_BUTTON_MARGIN_TOP = height - 45 - 52 - 120 - 150 - 151 - 100 - 120;

let imageNotFoundContainer: ViewStyle | TextStyle | ImageStyle;
if (Platform.OS === 'web') {
  imageNotFoundContainer = {
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: '25%',
    marginLeft: '5%',
    marginRight: '5%',
  };
} else {
  imageNotFoundContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxHeight: '100vh',
  },
  containerDescription: {
    height: 27,
    flexDirection: 'row',
  },
  titleDescription: {
    color: colors.primary,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 'bold',
  },
  titleCartSection: {
    backgroundColor: colors.primary,
    height: 27,
    flexDirection: 'row',
  },
  titleSection: {
    color: colors.white,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 'bold',
  },
  centerElements: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendOrderButton: {
    backgroundColor: colors.primary,
  },
  sendOrderLabelButtonTextClass: {
    color: colors.white,
  },
  imageNoFoundContainer: imageNotFoundContainer,
  imageNoFound: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    width: 128,
    height: 128,
  },
  imageNoFoundDescriptionContainer: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescription: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: colors.grayPlaceholder,
  },
  total: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.white,
    width: '100%',
    height: 70,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    left: '2.5%',
    width: '95%',
    height: 55,
  },
  totalContainerEnabled: {
    backgroundColor: colors.primary,
  },
  totalContainerDisabled: {
    backgroundColor: colors.grayPlaceholder,
  },
  containerInput: {
    flex: 4,
    marginBottom: 20,
  },
  containerObservations: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },
  flexRow: {
    flexDirection: 'row',
  },
  maxObservations: {
    color: colors.primary,
    marginBottom: 5,
  },
  textArea: {
    height: 85,
    borderColor: colors.primary,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderRadius: 5,
    textAlignVertical: 'top',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  totalOrderContainer: {
    backgroundColor: colors.primary,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowDirection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  viewRequestOrderItemsAmount: {
    paddingLeft: 20,
    width: '30%',
  },
  textRequestOrderItemsAmount: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.white,
    width: 40,
    height: 30,
    textAlign: 'center',
    paddingTop: 5,
    borderRadius: 10,
    backgroundColor: 'rgba(89, 88, 88, 68)',
  },
  textRequestOrder: {
    width: '30%',
    fontSize: 17,
    fontWeight: 'bold',
    color: colors.white,
    paddingLeft: 20,
    textAlign: 'center',
  },
  textTotalOrder: {
    width: '40%',
    fontSize: 17,
    textAlign: 'right',
    color: colors.white,
    paddingRight: 20,
  },
  containerWrapper: {
    flex: 1,
    flexDirection: 'column',
    height: '39vh',
    maxHeight: '57vh',
    minHeight: '30vh',
    overflow: 'scroll',
  },
  containerPreviewDeliveryDay: {
    backgroundColor: colors.white,
    height: 80,
    marginTop: 10,
    marginBottom: 30,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
  },
  containerPreviewDeliveryDayText: {
    fontSize: 17,
    textAlign: 'center',
    fontWeight: '700',
    color: colors.primary,
  },
  containerPreviewDeliveryDayContent: {
    backgroundColor: colors.primary,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    justifyContent: 'space-between',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
  },
  containerPreviewDeliveryDayContentText: {
    fontWeight: '700',
    color: colors.white,
    fontSize: 17,
  },
  containerCheckout: {
    backgroundColor: colors.white,
    height: 80,
    marginTop: 10,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
  },
  containerCheckoutIcon: {
    flex: 1,
  },
  containerCheckoutContent: {
    flex: 2,
    height: 80,
    paddingTop: 25,
    paddingLeft: 30,
  },
  containerCheckoutContentText1: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  containerCheckoutContentText2: {
    fontSize: 15,
  },
  containerCheckoutButton: {
    flex: 1,
    height: 80,
    paddingTop: 25,
  },
  containerCheckoutButtonIcon: {
    fontSize: 30,
  },
  containerTitleAndObservations: {
    backgroundColor: colors.white,
    height: 150,
    marginBottom: 30,
  },
  titleTotalCartSection: {
    backgroundColor: colors.primary,
    flexDirection: 'row',
  },
  titleTotalSection: {
    color: colors.primary,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 'bold',
  },
  containerTotal: {
    backgroundColor: colors.white,
  },
  containerAllTotals: {
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  containerTotals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerTotalsLeft: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  totalsLabel: {
    color: colors.gray,
    fontSize: 12,
  },
  containerTotalsRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  totalsValue: {
    color: colors.black,
    fontSize: 16,
  },
  containerSelectCheckout: {
    backgroundColor: colors.white,
    height: 90,
    marginTop: 10,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
  },
  containerLabelCheckout: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: 10,
  },
  selectButtonText: {
    fontSize: 16,
    padding: 6,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 20,
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: 10,
    height: 'auto',
    maxHeight: '60%',
    maxWidth: '70%',
    margin: 42,
    border: '1px solid black',
    paddingBottom: 15,
    left: '14%',
    top: '5%',
  },
  optionButton: {
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    padding: 10,
  },
  optionText: {
    fontSize: 14,
    marginTop: 10,
    marginLeft: 8,
    marginRight: 12,
    paddingBottom: 8,
  },

  searchInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingTop: 10,
    margin: 10,
    borderRadius: 5,
  },
  itemContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
});

/** Export component style **/
export default styles;
