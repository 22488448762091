/** Import React **/
import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { Platform, Dimensions } from 'react-native';

/** Import Redux **/
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

/** Import NetInfo **/
import NetInfo from '@react-native-community/netinfo';

/** Import Navigation **/
import { NavigationContainer } from '@react-navigation/native';
import AppNavigator from './navigators/AppNavigator';
import { navigationRef } from './navigators/RootNavigation';

/** Import SplashScreen **/
import SplashScreen from 'react-native-splash-screen';

/** Import SplashScreen **/
import { linking } from './constants/routes/routes';

import { SafeAreaProvider } from 'react-native-safe-area-context';

import { Text } from 'react-native';

import strings from './constants/strings/strings';

// Before rendering any navigation stack
import { enableScreens } from 'react-native-screens';
enableScreens();

/** Import Expo Font **/
import * as Font from 'expo-font';
/************************ Import Fonts ****************************/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IcoMoon from './web_assets/fonts/IcoMoon.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AntDesign from './web_assets/fonts/AntDesign.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Entypo from './web_assets/fonts/Entypo.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EvilIcons from './web_assets/fonts/EvilIcons.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Feather from './web_assets/fonts/Feather.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FontAwesome from './web_assets/fonts/FontAwesome.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FontAwesome5_Brands from './web_assets/fonts/FontAwesome5_Brands.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FontAwesome5_Regular from './web_assets/fonts/FontAwesome5_Regular.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FontAwesome5_Solid from './web_assets/fonts/FontAwesome5_Solid.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Foundation from './web_assets/fonts/Foundation.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Ionicons from './web_assets/fonts/Ionicons.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MaterialIcons from './web_assets/fonts/MaterialIcons.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MaterialCommunityIcons from './web_assets/fonts/MaterialCommunityIcons.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SimpleLineIcons from './web_assets/fonts/SimpleLineIcons.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Octicons from './web_assets/fonts/Octicons.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Zocial from './web_assets/fonts/Zocial.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratBold from './web_assets/fonts/Montserrat-Bold.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratBoldItalic from './web_assets/fonts/Montserrat-BoldItalic.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratItalic from './web_assets/fonts/Montserrat-Italic.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratLight from './web_assets/fonts/Montserrat-Light.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratLightItalic from './web_assets/fonts/Montserrat-LightItalic.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratMedium from './web_assets/fonts/Montserrat-Medium.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratMediumItalic from './web_assets/fonts/Montserrat-MediumItalic.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MontserratRegular from './web_assets/fonts/Montserrat-Regular.ttf';
// ************************************************************************************

const { height } = Dimensions.get('window');

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const App = () => {
  if (Platform.OS !== 'web') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => SplashScreen.hide(), []);
  } else {
    let prepareResources;
    // eslint-disable-next-line prefer-const
    prepareResources = async () => {
      await initNetInfo();
      await cacheAssets();
    };
    prepareResources();

    try {
      // Fix error in Chrome 90 with the main div heigh
      const document: any = window.document ?? {};
      if (document.querySelector) {
        document.addEventListener('DOMContentLoaded', function () {
          const element = document.querySelector('#root > div:first-child');
          element.style.height = `${height}px`;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <NavigationContainer linking={linking} fallback={<Text>{strings.loadingUrl}</Text>} ref={navigationRef}>
            <AppNavigator />
          </NavigationContainer>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
};

async function cacheAssets() {
  const fontAssets = cacheFonts([
    {
      IcoMoon: { uri: IcoMoon },
      AntDesign: { uri: AntDesign },
      Entypo: { uri: Entypo },
      EvilIcons: { uri: EvilIcons },
      Feather: { uri: Feather },
      FontAwesome: { uri: FontAwesome },
      FontAwesome5_Brands: { uri: FontAwesome5_Brands },
      FontAwesome5_Regular: { uri: FontAwesome5_Regular },
      FontAwesome5_Solid: { uri: FontAwesome5_Solid },
      Foundation: { uri: Foundation },
      Ionicons: { uri: Ionicons },
      MaterialIcons: { uri: MaterialIcons },
      MaterialCommunityIcons: { uri: MaterialCommunityIcons },
      SimpleLineIcons: { uri: SimpleLineIcons },
      Octicons: { uri: Octicons },
      Zocial: { uri: Zocial },
      MontserratBold: { uri: MontserratBold },
      MontserratBoldItalic: { uri: MontserratBoldItalic },
      MontserratItalic: { uri: MontserratItalic },
      MontserratLight: { uri: MontserratLight },
      MontserratLightItalic: { uri: MontserratLightItalic },
      MontserratMedium: { uri: MontserratMedium },
      MontserratMediumItalic: { uri: MontserratMediumItalic },
      MontserratRegular: { uri: MontserratRegular },
    },
  ]);

  await Promise.all([...fontAssets]);
}

function cacheFonts(fonts: any[]) {
  return fonts.map((font) => Font.loadAsync(font));
}

async function initNetInfo() {
  return new Promise((r) => NetInfo.fetch().then(r));
}

export default App;
