/** Import Interfaces **/
import { ShoppingCartItem } from './../interfaces';

/** Import types and utils **/
import totalizeValues from './totalizeValues';

/** Function to return total neto and subtotal of item **/
export default (shoppingCart: Array<ShoppingCartItem>) => {
  if (shoppingCart.length === 0) {
    return {
      totalNeto: 0,
      totalPriceWithoutTaxes: 0,
      totalRealTaxes: 0,
      totalTaxes: 0,
      totalChargeValue: 0,
    };
  } else if (shoppingCart.length === 1) {
    const totals = totalizeValues(shoppingCart[0]);
    return {
      totalNeto: totals.totalNeto,
      totalPriceWithoutTaxes: totals.priceWithoutTaxes,
      totalRealTaxes: totals.totalRealTaxes,
      totalTaxes: totals.taxes,
      totalChargeValue: totals.chargeValue,
    };
  } else {
    const data = {
      totalNeto: 0,
      totalPriceWithoutTaxes: 0,
      totalRealTaxes: 0,
      totalTaxes: 0,
      totalChargeValue: 0,
    };
    shoppingCart.forEach((item) => {
      const totals = totalizeValues(item);
      data.totalNeto += totals.totalNeto;
      data.totalPriceWithoutTaxes += totals.priceWithoutTaxes;
      data.totalRealTaxes += totals.totalRealTaxes;
      data.totalTaxes += totals.taxes;
      data.totalChargeValue += totals.chargeValue;
    });
    return data;
  }
};
