/** Import Interfaces **/
import { SnackBarData } from '../../interfaces';

/** Import Constants **/
import colors from '../../constants/styles/colors';

const INITIAL_STATE: SnackBarData = {
  visible: false,
  visibleCustom: false,
  textMessage: '',
  actionHandler: undefined,
  actionText: 'Cerrar',
  position: 'top',
  top: 0,
  autoHidingTime: 2000,
  backgroundColor: colors.danger,
  accentColor: colors.white,
  messageColor: colors.white,
  containerStyle: { marginLeft: 15, marginRight: 15, borderRadius: 10 },
  theme: 'danger',
};

export { INITIAL_STATE };
