/** Import React **/
import React from 'react';
import { View, ActivityIndicator } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';
import styles from './style';

interface Props {
  size: 'small' | 'large';
}

/**
 * Function to show loader
 *
 * @class AuthLoadingScreen
 * @extends {Component<Props, State>}
 */
const AuthLoadingScreen = ({ size }: Props) => {
  /**
   * Render any loading content
   *
   * @returns
   * @memberof AuthLoadingScreen
   */
  return (
    <View style={[styles.container]}>
      <ActivityIndicator size={size} color={colors.primary} />
    </View>
  );
};

/** Export component AuthLoadingScreen **/
export default AuthLoadingScreen;
