/** Styles to Button **/
import { StyleSheet } from 'react-native';

/** Create an object style **/
const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderWidth: 0,
    padding: 10,
    borderRadius: 10,
  },
  buttonText: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  opacity4: {
    opacity: 0.4,
  },
});

/** Export component styles **/
export default styles;
