/** Styles to view **/
import { Dimensions, StyleSheet } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

const { height } = Dimensions.get('window');
const CONTAINER_HEIGHT = height;

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
  },
  containerScrollView: {
    // flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white,
    height: CONTAINER_HEIGHT,
  },
  containerImage: {
    height: 200,
    // height: '50%',
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },
  image: {
    maxWidth: '50%',
    height: '100%',
    width: '100%',
  },
  containerData: {
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    flexDirection: 'row',
    marginTop: 10,
    marginRight: 10,
    marginBottom: 30,
    marginLeft: 10,
    backgroundColor: colors.white,
  },
  containerItemData: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: colors.white,
  },
  productDescription: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  productCode: {
    fontSize: 14,
    fontStyle: 'italic',
    color: colors.grayTextDescription,
  },
  containerFavorite: {
    //flex: 1,
    backgroundColor: colors.white,
    fontWeight: 'bold',
    left: '40%',
  },
  containerDropdownPrice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    marginRight: 15,
    marginBottom: 15,
    marginLeft: 15,
    backgroundColor: colors.white,
  },
  containerDropdown: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flex: 1,
  },
  containerPrice: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1,
  },
  containerBorderPrice: {
    borderWidth: 1,
    borderColor: colors.gray,
    borderRadius: 10,
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    paddingLeft: 10,
  },
  newPrice: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 19,
  },
  titleCartSection: {
    backgroundColor: colors.primary,
    flexDirection: 'row',
    marginTop: '12%',
  },
  titleSection: {
    color: colors.white,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    fontWeight: 'bold',
  },
  containerAllTotals: {
    marginTop: 3,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray,
    backgroundColor: colors.white,
  },
  containerTotals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  containerTotalsLeft: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddinTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    backgroundColor: colors.white,
  },
  totalsLabel: {
    color: colors.gray,
    fontSize: 12,
  },
  labelObservation: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  containerTotalsRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddinTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    backgroundColor: colors.white,
  },
  totalsValue: {
    color: colors.black,
    fontSize: 16,
    backgroundColor: colors.white,
  },
  containerTotalNeto: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 50,
    paddingRight: 50,
    marginBottom: 100,
    backgroundColor: colors.white,
  },
  containerBorder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    borderColor: colors.black,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 1,
  },
  containerValues: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  totalNetoLabel: {
    color: colors.gray,
    fontWeight: 'bold',
    fontSize: 14,
  },
  totalNetoValue: {
    color: colors.primary,
    fontWeight: 'bold',
    fontSize: 19,
  },
  containerBottom: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    paddingTop: 0,
    paddingRight: 15,
    paddingBottom: 0,
    marginBottom: 20,
    paddingLeft: 0,
    maxHeight: 60,
    width: '100%',
    marginLeft: 15,
  },
  containerButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    display: 'flex',
    flex: 1,
  },
  buttonsDec: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderRadius: 5,
    width: 35,
    height: 40,
  },
  textInputQuantity: {
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
    borderRadius: 5,
    width: 40,
    marginRight: 15,
    marginLeft: 15,
    paddinTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    textAlign: 'center',
  },
  buttonsInc: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.greenFlat,
    borderRadius: 5,
    width: 35,
    height: 40,
  },
  containerButtonAdd: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 8,
  },
  buttonAdd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    borderRadius: 5,
    height: 40,
    paddingLeft: 10,
    paddingRight: 10,
  },
  buttonAddText: {
    marginLeft: 10,
    color: colors.white,
    fontSize: 18,
  },
  iconTrash: {
    width: 48,
    height: 45,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 5,
  },
  containerDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15,
    marginLeft: 15,
    width: '92%',
  },
  textInputDescription: {
    height: 85,
    width: '100%',
    borderColor: colors.primary,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderRadius: 5,
    textAlignVertical: 'center',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 15,
  },
  containerObservation: {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    textAlignVertical: 'center',
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    backgroundColor: colors.white,
    marginTop: 10,
  },
});

/** Export component styles **/
export default styles;
