/** Import Redux Toolkit **/
import { createSlice } from '@reduxjs/toolkit';

/** Import Initial State **/
import { INITIAL_STATE } from './initialState';

const shoppingHistorySlice = createSlice({
  name: 'shoppingHistory',
  initialState: INITIAL_STATE,
  reducers: {
    setShoppingHistory(state, action) {
      return {
        ...state,
        page: action.payload.page,
        shopping_history: action.payload.data,
        order_state: action.payload.order_state,
      };
    },
    setShoppingHistoryDetail(state, action) {
      return {
        ...state,
        detail: action.payload.data,
        currentOrder: action.payload.currentOrder,
      };
    },
    cleanShoppingHistory() {
      return INITIAL_STATE;
    },
  },
});

export const { setShoppingHistory, setShoppingHistoryDetail, cleanShoppingHistory } = shoppingHistorySlice.actions;

export default shoppingHistorySlice.reducer;
