/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Productos',
  detailLabel: 'Detalle',
  searchPlaceholder: 'Buscar...',
  total: 'PEDIR ',
  errorLoadProducts: 'Carga de Productos',
  errorToogleFavorite: 'Favorito',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
};

/** Export strings **/
export default strings;
