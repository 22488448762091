/** Import React **/
import React, { Component } from 'react';
import { ImageBackground, StatusBar, Platform, Text, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { apiGet, apiPost } from '../../api';
import { urlLoginWhatsapp, urlShoppingcartList, urlHome, urlOpenWhatsapp } from '../../api/urls';

/** Import components **/
import Loader from '../../components/loader';
import Logo from '../../components/logo';
import Overlay from '../../components/overlay';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from './../../constants/styles/colors';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Slices **/
import { cleanAndLogout, store } from '../../store';
import { setAuth, setRShop, setHome } from '../../slices/auth/authSlice';
import { setShoppingCart } from '../../slices/shoppingCart/shoppingCartSlice';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

/** Import Universal Cookie **/
import Cookies from 'universal-cookie';

/** Import Images **/
import backgroundLogo from '../../web_assets/images/background_login4.jpg';
let BACKGROUND_LOGIN = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  BACKGROUND_LOGIN = backgroundLogo;
} else if (Platform.OS === 'ios') {
  BACKGROUND_LOGIN = { uri: `${assets.backgroundLogin}` };
} else {
  BACKGROUND_LOGIN = { uri: `@mipmap/${assets.backgroundLogin}` };
}

/**
 * Create Cookies
 */
const cookies = new Cookies();

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  route: any;
}

interface State {
  userId: number;
  sessionCode: string;
  routeName: string;
  primaryColor: string;
  logoImage: string;
  hasError: boolean;
}

/**
 * Class to export LoginScreenWhatsapp
 *
 * @class LoginScreenWhatsapp
 * @extends {Component<Props, State>}
 */
class LoginScreenWhatsapp extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;
  public ModalPassword!: any;

  public ModalResetPassword!: any;

  /**
   * Creates an instance of LoginScreenWhatsapp.
   * @param {*} props
   * @memberof LoginScreenWhatsapp
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      userId: this.props.route.params.user_id,
      sessionCode: this.props.route.params.session_code,
      routeName: this.props.route.params.route_name,
      primaryColor: this.props.route.params.primary_color,
      logoImage: this.props.route.params.logo_image,
      hasError: false,
    };
  }

  /**
   * Validate user and app state
   *
   * @memberof LoginScreenWhatsapp
   */
  componentDidMount() {
    cleanAndLogout();
    setTimeout(this._login, 500);
  }

  /**
   * Function to handle next view
   *
   * @memberof LoginScreenWhatsapp
   */
  _handleNextRoute = async () => {
    const auth = this.props.auth;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const arrayCompanies = auth && auth.array_companies ? auth.array_companies : [];

    let selectedCompanyId = '';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectedCompanyId = auth.selected_company_id;

    let selectedClientId = '';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectedClientId = auth.selected_client_id;

    let selectedCompanyIndex = -1;
    for (let i = 0; i < arrayCompanies.length; i++) {
      if (arrayCompanies[i].company_code === selectedCompanyId) {
        selectedCompanyIndex = i;
      }
    }

    // colors.setPrimaryColor(arrayCompanies[selectedCompanyIndex].ecommerce_primary_color);

    const branchOffices =
      arrayCompanies[selectedCompanyIndex] && arrayCompanies[selectedCompanyIndex].branch_offices
        ? arrayCompanies[selectedCompanyIndex].branch_offices
        : {};
    const branch_offices = [];

    let selectedClientIndex = -1;
    let i = 0;
    for (const branch_office in branchOffices) {
      if (branchOffices[branch_office].client_id === selectedClientId) {
        selectedClientIndex = i;
      }
      i++;
      branch_offices.push({
        ecommerce_image: arrayCompanies[selectedCompanyIndex].ecommerce_image,
        company_name: arrayCompanies[selectedCompanyIndex].company_name,
        company_code: arrayCompanies[selectedCompanyIndex].company_code,
        ecommerce_sac_contact: arrayCompanies[selectedCompanyIndex].ecommerce_sac_contact,
        client_id: branchOffices[branch_office].client_id,
        client_name: branchOffices[branch_office].client_name,
        rshop_id: branchOffices[branch_office].rshop_id,
        min_order_value: branchOffices[branch_office].min_order_value,
        client_address: branchOffices[branch_office].client_address,
        zone_settings: branchOffices[branch_office].zone_settings,
        apply_taxes: arrayCompanies[selectedCompanyIndex].apply_taxes,
        has_rsales_operation: arrayCompanies[selectedCompanyIndex].has_rsales_operation === 'Y',
        apply_charge_values_to: arrayCompanies[selectedCompanyIndex].apply_charge_values_to,
        ecommerce_orders: arrayCompanies[selectedCompanyIndex].ecommerce_orders,
        ecommerce_wallet: arrayCompanies[selectedCompanyIndex].ecommerce_wallet,
        ecommerce_contact_supplier: arrayCompanies[selectedCompanyIndex].ecommerce_contact_supplier,
        ecommerce_contact_seller: arrayCompanies[selectedCompanyIndex].ecommerce_contact_seller,
        has_activate_logistics_zone: arrayCompanies[selectedCompanyIndex].has_activate_logistics_zone,
        zone_default: arrayCompanies[selectedCompanyIndex].zone_default,
        ecommerce_deliveries: arrayCompanies[selectedCompanyIndex].ecommerce_deliveries,
        disabled_sales: branchOffices[branch_office].disabled_sales,
        ecommerce_cellar_select: arrayCompanies[selectedCompanyIndex].ecommerce_cellar_select,
      });
    }

    const rShop = {
      company: branch_offices[selectedClientIndex],
      branch_offices: branch_offices,
    };

    const rshop_id = rShop.company && rShop.company.rshop_id ? rShop.company.rshop_id : '';
    const urlShoppingcartListReplaced = urlShoppingcartList.replace(/\[rshop_id\]/g, String(rshop_id));
    const urlHomeReplaced = urlHome.replace(/\[rshop_id\]/g, String(rshop_id));
    const data = {
      csrfmiddlewaretoken: this.props.auth?.csrftoken,
    };
    try {
      const responseShoppinCart = await apiGet(urlShoppingcartListReplaced, data)();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (responseShoppinCart.success) {
        const responseHome = await apiGet(urlHomeReplaced, data)();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (responseHome.success) {
          store.dispatch(setRShop(rShop));
          store.dispatch(setShoppingCart(responseShoppinCart));
          store.dispatch(setHome(responseHome));
        } else {
          this.Loader.closeLoader(() => showAlert(responseHome, 'danger'));
        }
      } else {
        this.Loader.closeLoader(() => showAlert(responseShoppinCart, 'danger'));
      }
    } catch (error) {
      if (error.message) {
        error.message += ' (002).';
      }
      this.Loader.closeLoader(() => showAlert(error, 'danger'));
    }
  };

  /**
   * Handle login and call action
   *
   * @memberof LoginScreenWhatsapp
   */
  _login = () => {
    // this.props.route.params.user_id
    // this.props.route.params.session_code
    this.Loader.openLoader(async () => {
      const data = {
        user_id: this.state.userId,
        session_code: this.state.sessionCode,
        app_route: this.state.routeName,
      };
      try {
        const response = await apiPost(urlLoginWhatsapp, data)();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.email = response.user.user_email;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.is_from_wa = true;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.next_route_name = this.state.routeName;
          store.dispatch(setAuth(response));
          cookies.set('sessionid', response.sessionid, { path: '/' });
          cookies.set('csrftoken', response.csrftoken, { path: '/' });
          this._handleNextRoute();
        } else {
          this.setState({ hasError: true });
          this.Loader.closeLoader(() =>
            showAlert(response, 'danger', () => {
              console.log('Login form errors');
            }),
          );
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (001).';
        }
        this.setState({ hasError: true });
        this.Loader.closeLoader(() => showAlert(error, 'danger'));
      }
    });
  };

  _backToWhatsapp = () => {
    switch (this.state.routeName) {
      case routes.products:
        window.open(urlOpenWhatsapp + '&text=1', '_blank');
        break;
      case routes.shoppingHistory:
        window.open(urlOpenWhatsapp + '&text=2', '_blank');
        break;
      default:
        window.open(urlOpenWhatsapp, '_blank');
    }
  };

  /**
   * Render sign in view
   *
   * @returns
   * @memberof LoginScreenWhatsapp
   */
  render() {
    return (
      <>
        <Loader onRef={(ref) => (this.Loader = ref)} />
        <StatusBar backgroundColor={colors.statusBarBackgroundColor} translucent={true} barStyle="light-content" />
        <ImageBackground source={BACKGROUND_LOGIN} style={styles.loginImageBackground}>
          <Overlay />
          <Logo logoImage={this.state.logoImage} />
          {!this.state.hasError ? (
            <View style={styles.containerDescription}>
              <Text style={styles.descriptionText}>{strings.description}</Text>
            </View>
          ) : null}
          {this.state.hasError ? (
            <View style={styles.containerError}>
              <Text style={styles.errorText}>{strings.signinErrorWa}</Text>
              <TouchableOpacity style={styles.backToWhatsappContainer} onPress={this._backToWhatsapp}>
                <View style={styles.rowDirection}>
                  <Text style={styles.textBackToWhatsapp}>{strings.backToWhatsapp}</Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : null}
        </ImageBackground>
      </>
    );
  }
}

LoginScreenWhatsapp.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

LoginScreenWhatsapp.defaultProps = {};

/** Export component LoginScreenWhatsapp **/
export default connect((state) => ({ ...state }))(LoginScreenWhatsapp);
