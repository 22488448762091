/** Define object to static labels in view **/
const strings = {
  searchPlaceholder: 'Buscar...',
  headerTitleShop: 'Mis Proveedores',
  msgErrorShoppingCar: 'Carro de Compras',
  msgErrorHome: 'Home',
  nit: 'Nit/Código: ',
  address: 'Dir: ',
};

/** Export strings **/
export default strings;
