/** Define object to static labels in app **/
const strings = {
  padding: 'padding',
  continue: 'Continuar',
  close: 'Cerrar',
  cancel: 'Cancelar',
  request: 'Solicitar',
  confirm: 'Confirmar',
  themeAlertDefault: 'default',
  themeAlertInverse: 'inverse',
  themeAlertSuccess: 'success',
  themeAlertInfo: 'info',
  themeAlertDanger: 'danger',
  themeAlertWarning: 'warning',
  menssageError: 'Ha ocurrido un error inesperado.',
  internalError1111: 1111, // Requiere Autenticación
  internalError1112: 1112,
  internalError4003: 4003, // Expiró Contraseña
  internalError4444: 4444, // Proveedor o Comercio Inactivo
  internalError500: 500,
  branchOffice: 'Comercio',
  ready: 'LISTO',
  info: 'INFORMACIÓN',
  nit: 'Nit/Código:',
  address: 'Dirección:',
  provider: 'Proveedor:',
  requestNewPassword: 'Actualizar contraseña',
  updatePassword: 'Actualizar contraseña',
  typeNewPassword: 'Mínimo 6 caracteres, una minúscula, una mayúscula y un caracter especial (!@$&)',
  newPassword: 'Nueva contraseña',
  confirmPassword: 'Confirmar contraseña',
  required: 'Es un campo requerido',
  requiredPlus: 'Son campos requeridos',
  passwordMatched: 'Las claves deben ser iguales',
  password: 'Contraseñas',
  companiesButton: 'MIS PROVEEDORES',
  branchOfficesButton: 'MIS COMERCIOS',
  homeButton: 'PEDIR',
  profileButton: 'MI CUENTA',
  historialButton: 'HISTORIAL DE COMPRAS',
  portfolioButton: 'MI CARTERA',
  callClientButton: 'LLAMAR A MI PROVEEDOR',
  callSellerButton: 'LLAMAR VENDEDOR',
  writeSellerButton: 'ESCRIBIR AL VENDEDOR',
  logoutButton: 'CERRAR SESIÓN',
  callLinking: 'Llamada',
  callLinkingError: 'Esta solicitud no pudo ser procesada',
  whatsappLinking: 'Whatsapp',
  whatsappLinkingError: 'Esta solicitud no pudo ser procesada',
  signOutTitle: 'Cerrar Sesión',
  signOutSubtitle: '¿Realmente desea cerrar sesión?',
  signOutConfirm: 'Si, cerrar',
  done: 'Hecho',
  yourEmail: 'Tu número móvil...',
  msgNetWorkError: 'Network Error',
  msgConnectInternet: 'Por favor compruebe que el dispositivo se encuentre conectado a internet.',
  loadingUrl: 'Cargando...',
  helloSellerPrefix: 'Buen día',
  versionPrefix: 'VERSIÓN',
  tituloCartera: 'Código de descuento',
  infoCartera:
    'En caso de inquietudes referentes a los saldos, aplicaciones de cartera comunicarse con el área de cartera de la compañía.',
  from: 'Desde:',
  until: 'Hasta:',
  restore: 'Restablecer',
  documentNumber: 'N° de Factura',
  dateContainer: 'Fecha',
  saldo: 'Saldo',
  expires: 'Vence',
  currentDebt: 'Deuda Corriente',
  expiredDays: 'Días vencido',
  deliveryHistoryButton: 'HISTORIAL DE ENTREGAS',
  accept: 'ACEPTAR',
};

/** Export strings **/
export default strings;
