/** Define object to static labels in view **/
const strings = {
  mainLabel: 'ESTADO DE CARTERA',
  availableQuotaLabel: 'CUPO DISPONIBLE',
  quotaLabel: 'CUPO TOTAL',
  currentDebtLabel: 'DEUDA CORRIENTE',
  overdueDebt: 'DEUDA VENCIDA',
  totalDebt: 'DEUDA TOTAL',
  searchPlaceholder: 'Buscar...',
  labelType: 'Tipo',
  labelDate: 'Fecha',
  tituloCartera: 'Código de descuento',
  totaLimit: 'Cupo Total',
  totaLavailable: 'Cupo Disponible',
  expiredDays: 'Días de mora: ',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
  placeholderSelect: 'Todos',
  orderSources: {
    defeated: 'Vencidos',
    active: 'Vigente',
  },
};

/** Export strings **/
export default strings;
