/** Styles to Button **/
import { ImageStyle, Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

let itemProductContainer: ViewStyle | TextStyle | ImageStyle;
if (Platform.OS === 'web') {
  itemProductContainer = {
    marginLeft: 2,
    marginRight: 8,
    paddingTop: 10,
    paddingBottom: 10,
  };
} else {
  itemProductContainer = {
    marginLeft: 2,
    marginRight: 2,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    margin: 2,
    padding: 5,
  },
  itemAddedContainer: {
    zIndex: 10000,
    backgroundColor: colors.white,
    position: 'absolute',
    right: 4,
    borderRadius: 100,
  },
  itemAddedIcon: {
    borderRadius: 150,
    borderWidth: 2,
    borderColor: colors.white,
  },
  itemProductImageContainer: {
    marginRight: 5,
    marginLeft: 5,
    borderWidth: 0,
    width: '20%',
  },
  image: {
    width: 60,
    height: 50,
    marginLeft: 7,
    marginRight: 10,
  },
  itemProductDetailContainer: {
    flexDirection: 'column',
    width: '80%',
    marginLeft: 2,
    borderWidth: 0,
  },
  itemProductDescription: {
    fontWeight: 'bold',
  },
  itemProductFactor: {
    fontStyle: 'italic',
    color: colors.grayPlaceholder,
  },
  itemProductPriceContainer: {
    alignSelf: 'flex-end',
  },
  itemProductQuantity: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.greenFlat,
  },
  itemProductPrice: {
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.black,
  },
  itemProductButtonsContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    borderWidth: 0,
    borderColor: 'green',
    alignSelf: 'flex-end',
    width: '40%',
  },
  itemProductFavoriteButton: { paddingTop: 6 },
  shoppingItemContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    borderWidth: 0,
    borderColor: 'green',
    alignSelf: 'flex-end',
    width: '40%',
  },
  itemProductCartButton: {
    width: 50,
    height: 42,
    borderRadius: 5,
    justifyContent: 'flex-end',
    paddingLeft: 10,
  },
  successButon: {
    backgroundColor: colors.greenFlat,
    marginRight: 0,
  },
  defaultButton: {
    backgroundColor: colors.white,
    marginRight: 0,
  },
  itemProductContainer: itemProductContainer,
  editButtonShopingCart: {
    borderWidth: 1,
    borderColor: colors.blueRSales,
    backgroundColor: colors.white,
    width: 50,
    borderRadius: 5,
  },
  editButtonContainer: {
    marginLeft: 0,
    marginRight: 10,
  },
  buttonContainer: {
    padding: 0,
    alignContent: 'stretch',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
  },
  deleteButtonShopingCart: {
    borderWidth: 1,
    borderColor: colors.primary,
    backgroundColor: colors.white,
    width: 50,
    borderRadius: 5,
  },
  deleteButtonContainer: {
    marginLeft: 0,
    marginRight: 10,
  },
  addItemButton: {
    marginLeft: 10,
    marginRight: 10,
  },
  stateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    borderRadius: 20,
  },
  state: {
    borderColor: 'red',
    padding: 4,
    color: colors.white,
    fontSize: 10,
  },
  stateSuccess: {
    backgroundColor: colors.stateSuccess,
  },
  statePending: {
    backgroundColor: colors.stateSuccess,
  },
  stateFailure: {
    backgroundColor: colors.primary,
  },
});

/** Export component styles **/
export default styles;
