/** Define object to static labels in view **/
const strings = {
  mainLabel: 'MI CUENTA',
  personalInfo: 'INFORMACIÓN PERSONAL',
  fullname: 'Nombre completo:',
  mobile_number: 'Número móvil:',
  email: 'Email:',
  account: 'CUENTA',
  changePassword: 'Cambiar contraseña',
  passwordInfo: 'Contraseña actualizada',
  passwordUpdated: 'Su nueva contraseña ha sido modificada.',
  placeholderTextEmail: 'Ingrese su número móvil',
  searchCountryPlaceholder: 'Buscar...',
  searchCountryNotFound: 'Sin resultados',
  textWhatsapp:
    'Ingresa el código de confirmación que recibiste en WhatsApp para aplicar los cambios, el cual caducará en los próximos 60 segundos.',
  actionButton: 'REENVIAR CÓDIGO',
};

/** Export strings **/
export default strings;
