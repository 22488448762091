/** Import React **/
import React, { Component } from 'react';
import {
  ImageBackground,
  StatusBar,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  View,
  Dimensions,
  Text,
} from 'react-native';
import PropTypes from 'prop-types';

/** google reCAPTCHA **/
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reCaptchaSiteKey } from '../../api/constants';

/** Phone Number Input **/
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';

/** Config **/
import Config from 'react-native-config';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { apiGet, apiPost } from '../../api';
import { urlLogin, urlShoppingcartList, urlHome, urlOpenWhatsapp, urlGenerateSigninCode } from '../../api/urls';

/** Import components **/
import BackHandlerCustom from '../../components/backHandlerCustom';
import Button from '../../components/button';
import InputTextForm from '../../components/inputTextForm';
import Loader from '../../components/loader';
import Logo from '../../components/logo';
import Overlay from '../../components/overlay';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from './../../constants/styles/colors';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, Route } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Slices **/
import { store, cleanAndLogout } from '../../store';
import { setAuth, setRShop, setHome } from '../../slices/auth/authSlice';
import { setShoppingCart } from '../../slices/shoppingCart/shoppingCartSlice';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

/** Import Universal Cookie **/
import Cookies from 'universal-cookie';

import backgroundLogo from '../../web_assets/images/background_login4.jpg';

/** Import Images **/
let BACKGROUND_LOGIN = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  BACKGROUND_LOGIN = backgroundLogo;
} else if (Platform.OS === 'ios') {
  BACKGROUND_LOGIN = { uri: `${assets.backgroundLogin}` };
} else {
  BACKGROUND_LOGIN = { uri: `@mipmap/${assets.backgroundLogin}` };
}

/**
 * Create Cookies
 */
const cookies = new Cookies();

const COMPANY_CODE = Platform.OS !== 'web' ? Config.COMPANY_CODE : process.env.REACT_APP_COMPANY_CODE;

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  route: Route;
}

interface State {
  username: string;
  password: string;
  isValidationCodeSent: boolean;
  recaptchaToken: string;
  executeReCaptcha: boolean;
  codeSentBySMS: boolean;
}

const { width } = Dimensions.get('window');
const phoneInputStyle = {
  borderColor: colors.softGray,
  height: 44,
  width: width - 55,
  borderWidth: 1,
  color: colors.black,
  backgroundColor: colors.white,
  borderRadius: 20,
  marginBottom: 10,
};

/**
 * Class to export LoginScreen
 *
 * @class LoginScreen
 * @extends {Component<Props, State>}
 */
class LoginScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   * Creates an instance of LoginScreen.
   * @param {*} props
   * @memberof LoginScreen
   */
  constructor(props: Props) {
    super(props);
    const phone = this.props.route.params !== undefined ? this.props.route.params.phone : '';
    let username = this.props.auth.username && this.props.auth.username.length > 0 ? this.props.auth.username : '';
    if (!username) username = phone;

    this.state = {
      username: username,
      password: '',
      isValidationCodeSent: false,
      recaptchaToken: '',
      executeReCaptcha: false,
      codeSentBySMS: false,
    };
  }

  /**
   * Validate user and app state
   *
   * @memberof LoginScreen
   */
  componentDidMount() {
    let userToken = null;
    const auth = this.props.auth;
    const current_rshop = auth && auth.current_rshop;
    if (auth && auth.csrftoken && auth.csrftoken.length > 0) {
      userToken = auth.csrftoken;
    }
    /** This will switch to the App screen or Auth screen and this loading screen will be unmounted and thrown away **/
    if ((userToken && current_rshop === null) || (userToken && current_rshop && current_rshop.rshop_id === null)) {
      this.props.navigation.navigate(routes.companiesList, { backRoute: false });
    }
  }

  /**
   * Function to handle next view
   *
   * @memberof LoginScreen
   */
  _handleNextRoute = async () => {
    const auth = this.props.auth;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const company = auth.companies && auth.companies[COMPANY_CODE];
    const arrayCompanies = auth && auth.array_companies ? auth.array_companies : [];
    const branchOffices = arrayCompanies[0] && arrayCompanies[0].branch_offices ? arrayCompanies[0].branch_offices : {};
    const branch_offices = [];
    if (company) {
      colors.setPrimaryColor(company.ecommerce_primary_color);
      for (const branch_office in company.branch_offices) {
        branch_offices.push({
          ecommerce_image: company.ecommerce_image,
          company_name: company.company_name,
          company_code: company.company_code,
          ecommerce_sac_contact: company.ecommerce_sac_contact,
          client_id: company.branch_offices[branch_office].client_id,
          client_name: company.branch_offices[branch_office].client_name,
          rshop_id: company.branch_offices[branch_office].rshop_id,
          min_order_value: company.branch_offices[branch_office].min_order_value,
          client_address: company.branch_offices[branch_office].client_address,
          zone_settings: company.branch_offices[branch_office].zone_settings,
          apply_taxes: company.apply_taxes,
          has_rsales_operation: company.has_rsales_operation === 'Y',
          apply_charge_values_to: company.apply_charge_values_to,
          ecommerce_orders: company.ecommerce_orders,
          ecommerce_wallet: company.ecommerce_wallet,
          ecommerce_contact_supplier: company.ecommerce_contact_supplier,
          ecommerce_contact_seller: company.ecommerce_contact_seller,
          ecommerce_phone_sellers_contact: company.ecommerce_phone_sellers_contact,
          has_activate_logistics_zone: company.has_activate_logistics_zone,
          zone_default: company.zone_default,
          ecommerce_deliveries: company.ecommerce_deliveries,
          disabled_sales: company.branch_offices[branch_office].disabled_sales,
          ecommerce_cellar_select: company.ecommerce_cellar_select,
        });
      }
    } else {
      colors.setPrimaryColor(arrayCompanies[0].ecommerce_primary_color);
      for (const branch_office in branchOffices) {
        branch_offices.push({
          ecommerce_image: arrayCompanies[0].ecommerce_image,
          company_name: arrayCompanies[0].company_name,
          company_code: arrayCompanies[0].company_code,
          ecommerce_sac_contact: arrayCompanies[0].ecommerce_sac_contact,
          client_id: branchOffices[branch_office].client_id,
          client_name: branchOffices[branch_office].client_name,
          rshop_id: branchOffices[branch_office].rshop_id,
          min_order_value: branchOffices[branch_office].min_order_value,
          client_address: branchOffices[branch_office].client_address,
          zone_settings: branchOffices[branch_office].zone_settings,
          apply_taxes: arrayCompanies[0].apply_taxes,
          has_rsales_operation: arrayCompanies[0].has_rsales_operation === 'Y',
          apply_charge_values_to: arrayCompanies[0].apply_charge_values_to,
          ecommerce_orders: arrayCompanies[0].ecommerce_orders,
          ecommerce_wallet: arrayCompanies[0].ecommerce_wallet,
          ecommerce_contact_supplier: arrayCompanies[0].ecommerce_contact_supplier,
          ecommerce_contact_seller: arrayCompanies[0].ecommerce_contact_seller,
          has_activate_logistics_zone: arrayCompanies[0].has_activate_logistics_zone,
          zone_default: arrayCompanies[0].zone_default,
          ecommerce_deliveries: arrayCompanies[0].ecommerce_deliveries,
          disabled_sales: branchOffices[branch_office].disabled_sales,
          ecommerce_cellar_select: arrayCompanies[0].ecommerce_cellar_select,
        });
      }
    }

    if (COMPANY_CODE && COMPANY_CODE.length > 0 && !company) {
      cleanAndLogout();
      this.Loader.closeLoader(() =>
        showAlert({ error: `${strings.login}: ${strings.notAllowToAppSupremo}` }, 'danger'),
      );
    } else if (
      (company && branch_offices && branch_offices.length === 1) ||
      (auth && arrayCompanies && arrayCompanies.length === 1 && branch_offices && branch_offices.length === 1)
    ) {
      const rShop = {
        company: branch_offices[0],
        branch_offices: branch_offices,
      };
      const rshop_id = rShop.company && rShop.company && rShop.company.rshop_id ? rShop.company.rshop_id : '';
      const urlShoppingcartListReplaced = urlShoppingcartList.replace(/\[rshop_id\]/g, String(rshop_id));
      const urlHomeReplaced = urlHome.replace(/\[rshop_id\]/g, String(rshop_id));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
      };
      try {
        const responseShoppinCart = await apiGet(urlShoppingcartListReplaced, data)();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (responseShoppinCart.success) {
          const responseHome = await apiGet(urlHomeReplaced, data)();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (responseHome.success) {
            store.dispatch(setRShop(rShop));
            store.dispatch(setShoppingCart(responseShoppinCart));
            store.dispatch(setHome(responseHome));
          } else {
            this.Loader.closeLoader(() => showAlert(responseHome, 'danger'));
          }
        } else {
          this.Loader.closeLoader(() => showAlert(responseShoppinCart, 'danger'));
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (002).';
        }
        this.Loader.closeLoader(() => showAlert(error, 'danger'));
      }
    } else if (
      (company && branch_offices && branch_offices.length > 1) ||
      (auth && arrayCompanies && arrayCompanies.length === 1 && branch_offices && branch_offices.length > 1)
    ) {
      const data = {
        company: {
          ecommerce_image: company ? company.ecommerce_image : arrayCompanies[0].ecommerce_image,
          company_name: company ? company.company_name : arrayCompanies[0].company_name,
          company_code: company ? company.company_code : arrayCompanies[0].company_code,
          ecommerce_sac_contact: company ? company.ecommerce_sac_contact : arrayCompanies[0].ecommerce_sac_contact,
          client_id: null,
          client_name: null,
          rshop_id: null,
          min_order_value: null,
          client_address: null,
          zone_settings: null,
          apply_taxes: company ? company.apply_taxes : arrayCompanies[0].apply_taxes,
          has_rsales_operation: company
            ? company.has_rsales_operation === 'Y'
            : arrayCompanies[0].has_rsales_operation === 'Y',
          apply_charge_values_to: company ? company.apply_charge_values_to : arrayCompanies[0].apply_charge_values_to,
          ecommerce_orders: company ? company.ecommerce_orders : arrayCompanies[0].ecommerce_orders,
          ecommerce_wallet: company ? company.ecommerce_wallet : arrayCompanies[0].ecommerce_wallet,
          ecommerce_contact_supplier: company
            ? company.ecommerce_contact_supplier
            : arrayCompanies[0].ecommerce_contact_supplier,
          ecommerce_contact_seller: company
            ? company.ecommerce_contact_seller
            : arrayCompanies[0].ecommerce_contact_seller,
          has_activate_logistics_zone: company
            ? company.has_activate_logistics_zone
            : arrayCompanies[0].has_activate_logistics_zone,
          zone_default: company ? company.zone_default : arrayCompanies[0].zone_default,
          ecommerce_deliveries: company ? company.ecommerce_deliveries : arrayCompanies[0].ecommerce_deliveries,
          ecommerce_cellar_select: company
            ? company.ecommerce_cellar_select
            : arrayCompanies[0].ecommerce_cellar_select,
        },
        branch_offices: branch_offices,
      };
      store.dispatch(setRShop(data));
      this.Loader.closeLoader(() => this.props.navigation.navigate(routes.branchOfficesList, { backRoute: false }));
    } else {
      this.Loader.closeLoader(() => this.props.navigation.navigate(routes.companiesList, { backRoute: false }));
    }
  };

  /**
   * Validate if form login is valid
   *
   * @memberof LoginScreen
   */
  _completeFormLogin = (): boolean => {
    return this.state.username.length > 5 && this.state.password.length > 3;
  };
  /**
   * Validate if username is valid
   *
   * @memberof LoginScreen
   */
  _completeFormUsername = (): boolean => {
    return this.state.username.length > 5;
  };

  /**
   * Handle username validation on  backend
   *
   * @memberof LoginScreen
   */
  _generateCode = () => {
    if (this._completeFormUsername()) {
      this.Loader.openLoader(async () => {
        const { username, recaptchaToken } = this.state;
        const data = {
          username: '+' + username,
          recaptcha_token: recaptchaToken,
        };
        try {
          const response = await apiPost(urlGenerateSigninCode, data)();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (response.success) {
            let successMessage = strings.newValidationCodeSent;
            if (response.message_sent_by_sms) successMessage = strings.newValidationCodeSentBySMS;

            this.setState(
              {
                isValidationCodeSent: true,
                codeSentBySMS: response.message_sent_by_sms,
              },
              () => {
                this.Loader.closeLoader(() => showAlert({ error: `${strings.login}: ${successMessage}` }, 'info'));
              },
            );
          } else {
            this.Loader.closeLoader(() => showAlert(response, 'danger'));
          }
        } catch (error) {
          if (error.message) {
            error.message += ' (001).';
          }
          this.Loader.closeLoader(() => showAlert(error, 'danger'));
        }
      });
    } else {
      showAlert({ error: `${strings.login}: ${strings.usernameRequired}` }, 'danger');
    }
  };

  /**
   * Handle recaptcha validation
   *
   * @memberof LoginScreen
   */
  _validateReCaptcha = () => {
    this.Loader.openLoader(async () => {
      this.setState(
        {
          executeReCaptcha: true,
          recaptchaToken: '',
          password: '',
        },
        () => {
          setTimeout(() => {
            this.Loader.closeLoader();
            if (this.state.recaptchaToken.length > 0) {
              this._generateCode();
            } else {
              showAlert({ error: `${strings.login}: ${strings.botDetected}` }, 'danger');
            }
          }, 1000);
        },
      );
    });
  };

  /**
   * Handle username validation on  backend
   *
   * @memberof LoginScreen
   */
  _changeUsername = () => {
    this.setState({
      isValidationCodeSent: false,
    });
  };

  onReCaptchaVerify = (token: string) => {
    if (this.state.recaptchaToken.length === 0) {
      this.setState({
        recaptchaToken: token,
        executeReCaptcha: false,
      });
    }
  };

  /**
   * Handle login and call action
   *
   * @memberof LoginScreen
   */
  _login = () => {
    if (this._completeFormLogin()) {
      this.Loader.openLoader(async () => {
        const { username, password } = this.state;
        const data = {
          username: '+' + username,
          session_code: password,
        };
        try {
          const response = await apiPost(urlLogin, data)();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (response.success) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental,@typescript-eslint/ban-ts-comment
            // @ts-ignore
            const user = response.user;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            response.email = user.user_email;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            response.username = username;
            store.dispatch(setAuth(response));
            cookies.set('sessionid', response.sessionid, { path: '/' });
            cookies.set('csrftoken', response.csrftoken, { path: '/' });
            this._handleNextRoute();
          } else {
            this.Loader.closeLoader(() => showAlert(response, 'danger'));
          }
        } catch (error) {
          if (error.message) {
            error.message += ' (001).';
          }
          this.Loader.closeLoader(() => showAlert(error, 'danger'));
        }
      });
    } else {
      showAlert({ error: `${strings.login}: ${strings.emailPasswordRequired}` }, 'danger');
    }
  };

  _loginFromWa = () => {
    window.open(urlOpenWhatsapp + '&text=volver a iniciar', '_blank');
  };

  /**
   * Render sign in view
   *
   * @returns
   * @memberof LoginScreen
   */
  render() {
    return (
      <>
        <BackHandlerCustom exitApp={true} />
        <Loader onRef={(ref) => (this.Loader = ref)} />
        <StatusBar backgroundColor={colors.statusBarBackgroundColor} translucent={true} barStyle="light-content" />
        <ImageBackground source={BACKGROUND_LOGIN} style={styles.loginImageBackground}>
          <Overlay />
          <KeyboardAvoidingView style={styles.wrapper} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
            <ScrollView style={styles.scrollView} bounces={false}>
              <Logo />
              <View style={styles.containerInputs}>
                <View style={[styles.containerInput, { zIndex: 1 }]}>
                  <Text style={[styles.labelTextRegular, styles.labelText]}>{strings.labelTextEmail}</Text>
                  <PhoneInput
                    country={'co'}
                    localization={es}
                    value={this.state.username}
                    placeholder={strings.placeholderTextEmail}
                    onChange={(val) => this.setState({ username: val })}
                    inputProps={{
                      required: true,
                    }}
                    enableSearch={true}
                    searchPlaceholder={strings.searchCountryPlaceholder}
                    searchNotFound={strings.searchCountryNotFound}
                    priority={{ co: 0 }}
                    inputStyle={phoneInputStyle}
                    buttonStyle={{
                      height: 45,
                    }}
                    disabled={this.state.isValidationCodeSent}
                  />
                </View>
                <InputTextForm
                  label={strings.labelTextPassword}
                  secureTextEntry={true}
                  autoCapitalize="none"
                  placeholder={
                    this.state.codeSentBySMS ? strings.placeholderTextPasswordSMS : strings.placeholderTextPassword
                  }
                  onChangeText={(val) => this.setState({ password: val })}
                  value={this.state.password}
                  isHidden={!this.state.isValidationCodeSent}
                />
                {this.state.isValidationCodeSent ? (
                  <Button
                    onPress={this._validateReCaptcha}
                    buttonClass={styles.reGenerateCode}
                    buttonLabelText={strings.reGenerateCode}
                    buttonLabelTextClass={styles.reGenerateCodeLabelButtonTextClass}
                  />
                ) : null}
              </View>
            </ScrollView>
            <GoogleReCaptchaProvider
              language="es"
              reCaptchaKey={reCaptchaSiteKey}
              scriptProps={{
                appendTo: 'body',
              }}
            >
              {this.state.executeReCaptcha ? <GoogleReCaptcha onVerify={this.onReCaptchaVerify} /> : null}
            </GoogleReCaptchaProvider>
            <Button
              onPress={this.state.isValidationCodeSent ? this._login : this._validateReCaptcha}
              buttonClass={this.state.isValidationCodeSent ? styles.loginFromWhatsappButton : styles.loginButton}
              buttonLabelText={
                this.state.isValidationCodeSent ? strings.labelValidateCodeButtonText : strings.labelLoginButtonText
              }
              buttonLabelTextClass={styles.loginLabelButtonTextClass}
            />
            {this.state.isValidationCodeSent ? (
              <Button
                onPress={this._changeUsername}
                buttonClass={styles.loginButton}
                buttonLabelText={strings.changeUsernameButtonText}
                buttonLabelTextClass={styles.loginLabelButtonTextClass}
              />
            ) : null}
          </KeyboardAvoidingView>
        </ImageBackground>
      </>
    );
  }
}

LoginScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

LoginScreen.defaultProps = {};

/** Export component LoginScreen **/
export default connect((state) => ({ ...state }))(LoginScreen);
