/** Styles to Button **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  shopinghistoyContainer: {
    flexDirection: 'row',
    padding: 5,
    flex: 1,
    width: '100%',
    height: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  orderItemSoldDesc: {
    marginLeft: 5,
    display: 'flex',
    flex: 1,
  },
  orderSoldCode: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  orderSoldDateLabel: {
    fontWeight: 'bold',
  },
  orderSoldDate: {
    color: colors.grayTextDescription,
  },
  stateAndPriceContainer: {
    flexDirection: 'row',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  stateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    marginRight: 10,
    width: 100,
  },
  priceContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  priceText: {
    fontWeight: 'bold',
  },
  state: {
    borderColor: 'red',
    padding: 4,
    color: colors.white,
    fontSize: 10,
  },
  stateSuccess: {
    backgroundColor: colors.stateSuccess,
  },
  statePending: {
    backgroundColor: colors.statePending,
  },
  stateFailure: {
    backgroundColor: colors.primary,
  },
  stateDelivered: {
    backgroundColor: colors.delivered,
  },
  dateContainer: {
    marginTop: 5,
  },
  imageIconOrderSold: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  image: {
    width: 66,
    height: 56,
  },
  orderSourceLabel: {
    fontWeight: 'bold',
  },
});

/** Export component styles **/
export default styles;
