/** Import React **/
import React, { Component } from 'react';
import { View, Text } from 'react-native';
import 'react-datepicker/dist/react-datepicker.css';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Components **/
import Button from '../button';
// import Loader from '../loader';

/** Import constants **/
import Stringsconstants from '../../constants/strings/strings';
import styles from './style';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

import { SafeAreaProvider } from 'react-native-safe-area-context';

import './react-datepicker-input.css';

interface Props {
  onRef: (ref: any) => void;
  title: string;
  action: (props: any) => void;
  actionText: string;
  auth?: Auth;
  stateParent: any;
}

interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  callBackClose?: () => void | null;
  documentNumber: any;
  dateContainer: any;
  currentDebt: number;
  balance: number;
  expirationDate: any;
  expiredDays: number;
}

/**
 * Create Modal with password
 *
 * @param {*} props
 * @returns
 */
class OpenModalWalletDetail extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   *Creates an instance of OpenModalWalletDetail.
   * @param {*} props
   * @memberof OpenModalWalletDetail
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
      documentNumber: '',
      dateContainer: '',
      currentDebt: 0,
      balance: 0,
      expirationDate: '',
      expiredDays: 0,
    };
  }

  /**
   * Pass ref to View
   *
   * @memberof OpenModalWalletDetail
   */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   * Pass ref to View
   *
   * @memberof OpenModalWalletDetail
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  /**
   * Open openModalConfirmItem
   *
   * @memberof OpenModalWalletDetail
   */
  openModalWalletDetail = (props: any) => {
    console.log(props);
    const data = props.item;
    this.setState({
      isVisible: true,
      documentNumber: data.document_number,
      dateContainer: data.document_date,
      currentDebt: data.current,
      balance: data.balance,
      expirationDate: data.expiration_date,
      expiredDays: data.expired_days,
    });
  };

  /**
   * Close closeModal
   *
   * @memberof OpenModalWalletDetail
   */
  closeModal = () => {
    this.setState({
      isVisible: false,
    });
  };

  /**
   * Function _callAction
   *
   * @memberof OpenModalWalletDetail
   */
  _callAction = () => {
    this.setState({
      isVisible: false,
    });
  };

  /**
   * Render ModalConfirmItem in modal
   *
   * @returns
   * @memberof OpenModalWalletDetail
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        avoidKeyboard={true}
        style={styles.modal}
        onModalHide={() => this.state.callBackClose && this.state.callBackClose()}
      >
        <SafeAreaProvider>
          <View style={styles.modalBackground}>
            <View style={styles.modalHeader}>
              <Text style={styles.modalTitle}>{this.props.title}</Text>
            </View>
            <View style={[styles.portafolioItemContainer, styles.container]}>
              <View style={styles.itemPortafoliotDetailContainer}>
                <View style={styles.containerInfoDocument}>
                  <View>
                    <Text style={styles.orderSoldCode}>{Stringsconstants.documentNumber}</Text>
                    <Text style={[styles.orderSoldDate]}>{this.state.documentNumber}</Text>
                    <Text style={styles.documentDate}>{Stringsconstants.dateContainer}</Text>
                    <Text style={styles.orderSoldDate}>{this.state.dateContainer}</Text>
                    <Text style={styles.documentDate}>{Stringsconstants.currentDebt}</Text>
                    <Text style={styles.orderSoldDate}>$ {this.state.currentDebt}</Text>
                  </View>
                  <View style={styles.dateContainerSaldo}>
                    <Text style={styles.orderSoldCode}>{Stringsconstants.saldo}</Text>
                    <Text style={styles.orderSoldDate}>$ {this.state.balance}</Text>
                    <Text style={styles.documentDate}>{Stringsconstants.expires}</Text>
                    <Text style={styles.orderSoldDate}>{this.state.expirationDate}</Text>
                    <Text style={styles.documentDate}>{Stringsconstants.expiredDays}</Text>
                    <Text style={styles.orderSoldDate}>{this.state.expiredDays}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.containerButtons}>
              <Button
                onPress={this.closeModal}
                buttonClass={styles.buttonConfirm}
                buttonLabelText={this.props.actionText}
                buttonLabelTextClass={styles.buttonTextConfirm}
              />
            </View>
          </View>
        </SafeAreaProvider>
      </Modal>
    );
  }
}

OpenModalWalletDetail.propTypes = {
  auth: AuthPropTypes,
};

OpenModalWalletDetail.defaultProps = {};

/** Export component OpenModalWalletDetail **/
export default connect((state) => ({ ...state }))(OpenModalWalletDetail);
