/** Styles to view **/
import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../constants/styles/colors';

const { width, height } = Dimensions.get('window');
const BACK_TO_WA_CONTAINER_WIDTH = width * (1 - 0.1);
const BACK_TO_WA_CONTAINER_MARGIN = width * 0.05;

/** Create an object style **/
const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  scrollView: {
    flex: 1,
  },
  containerInputs: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginImageBackground: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    width: '100%',
    height: height,
  },
  containerDescription: {
    width: '95%',
    minHeight: 100,
    marginTop: 50,
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionText: {
    color: colors.primary,
    fontSize: 30,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  containerError: {
    width: BACK_TO_WA_CONTAINER_WIDTH,
    minHeight: 100,
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: BACK_TO_WA_CONTAINER_MARGIN,
    marginRight: BACK_TO_WA_CONTAINER_MARGIN,
  },
  errorText: {
    color: '#1c1481',
    fontSize: 18,
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  backToWhatsappContainer: {
    display: 'flex',
    borderRadius: 25,
    width: BACK_TO_WA_CONTAINER_WIDTH,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
    marginTop: 15,
    marginLeft: BACK_TO_WA_CONTAINER_MARGIN,
    marginRight: BACK_TO_WA_CONTAINER_MARGIN,
  },
  rowDirection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  textBackToWhatsapp: {
    flex: 1,
    fontSize: 17,
    fontWeight: 'bold',
    color: colors.white,
  },
});

/** Export component styles **/
export default styles;
