/** Import modules **/
import React from 'react';
import { View, Image, Platform, ImageSourcePropType } from 'react-native';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import styles from './style';

/** Import Images **/
//import easyPedidoLogo from '../../web_assets/images/background_login.png';
import easyPedidoLogo from '../../web_assets/images/logo.png';
import Config from 'react-native-config';

const API_URL = Platform.OS !== 'web' ? Config.API_URL : process.env.REACT_APP_API_URL;

const getLogoImage = (logoImage: string): ImageSourcePropType => {
  if (Platform.OS === 'web') {
    if (logoImage && logoImage.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return { uri: API_URL.replace('/appsupremo', logoImage.replace(/\*/g, '/')) };
    } else {
      return { uri: easyPedidoLogo };
    }
  } else if (Platform.OS === 'ios') {
    return { uri: `${assets.logo}` };
  } else {
    return { uri: `@mipmap/${assets.logo}` };
  }
};

interface Props {
  logoImage?: string;
}

/**
 * Create container with logo
 *
 * @param {Props}
 * @returns
 */
const Logo = ({ logoImage = '' }: Props) => {
  /**
   * Render component with Icon
   *
   * @returns
   * @memberof Logo
   */
  return (
    <View style={styles.container}>
      <Image style={styles.logo} source={getLogoImage(logoImage)} />
    </View>
  );
};

/** Export component Logo **/
export default Logo;
