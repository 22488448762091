/** Styles to view **/
import { StyleSheet, Dimensions } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Size Screen **/
const { width } = Dimensions.get('window');
const DEVICE_WIDTH = width;

/** Create an object style **/
const styles = StyleSheet.create({
  containerInput: {},
  containerInputError: {},
  labelTextRegular: {
    color: colors.black,
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: 10,
  },
  labelText: {
    color: colors.black,
  },
  labelTextError: {
    color: colors.danger,
  },
  inputText: {
    borderColor: colors.softGray,
    height: 44,
    width: DEVICE_WIDTH - 55,
    borderWidth: 1,
    color: colors.black,
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 10,
    marginBottom: 10,
  },
  icShowPassword: {
    position: 'absolute',
    bottom: 5,
    right: 0,
    marginRight: 14,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

/** Export component style **/
export default styles;
