/** Import React **/
import React, { Component } from 'react';
import { View, Text, ScrollView, TouchableOpacity, TextInput, Platform, Image } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import components **/
import HeaderSideDrawer from '../../components/headerSideDrawer';
import ModalPassword from '../../components/modalPassword';
import ButtonBranchBriefcase from '../../components/buttonBranchBriefcase';
import ModalOpenDate from '../../components/modalOpenDate';
import WalletReportFlastLyst from '../../components/walletReportFlastLyst';
import InputSelectForm from '../../components/inputSelectForm';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from '../../constants/styles/colors';
import strings from './strings';
import styles from './style';
import { routes } from '../../constants/routes/routes';

/** Import Interfaces **/
import { Auth, FilterData, WalletType, Portfolio } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import SearchBar **/
import { SearchBar } from 'react-native-elements';

/** Import Images **/
import notFoundImage from '../../web_assets/images/not_found.png';

import { store } from '../../store';
import { resetNextRouteName } from '../../slices/auth/authSlice';
import * as RootNavigation from '../../navigators/RootNavigation';

let NOT_FOUND = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NOT_FOUND = notFoundImage;
} else if (Platform.OS === 'ios') {
  NOT_FOUND = { uri: `${assets.notFound}` };
} else {
  NOT_FOUND = { uri: `@mipmap/${assets.notFound}` };
}

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {
  loading: boolean;
  data: Array<Portfolio>;
  filter_data: FilterData;
  fromDetail: boolean;
  statusFilter: string;
  orderSource: Array<WalletType>;
  dataPortafolioDetail: any;
  dataPortafolioAll: any;
  dateModal: any;
}

/**
 * Class to export PortfolioScreen
 *
 * @class PortfolioScreen
 * @extends {Component<Props, State>}
 */
class PortfolioScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};
  public ModalOpenDate!: any;
  public ModalPassword!: any;
  public timer!: any;
  public _unsubscribe!: any;

  /**
   * Creates an instance of PortfolioScreen.
   * @param {*} props
   * @memberof PortfolioScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      filter_data: {
        filter_value: '',
        filter_source: '',
        page: {
          has_next: true,
          next_page_number: 0,
        },
      },
      fromDetail: false,
      statusFilter: '',
      orderSource: [
        {
          label: strings.orderSources.defeated,
          value: 'S',
        },
        {
          label: strings.orderSources.active,
          value: 'N',
        },
      ],
      dataPortafolioDetail: [],
      dataPortafolioAll: [],
      dateModal: 'Fecha',
    };
    this.setDateModal = this.setDateModal.bind(this);
  }
  /**
   * Function to get rshop_id
   *
   * @memberof ShoppingHistoryScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };
  /**
   * Handle getPortfolio and return portfolio
   *
   * @memberof PortfolioScreen
   */
  filter_source: any = 'Todos';
  filterSearch: any = '';
  dateStart: any = '';
  dateFinish: any = '';
  getPortfolio = (filter_source: string, filterSearch: string, dateStart: string, dateFinish: string) => {
    const value = filterSearch.toLowerCase();
    const newDateStart = dateStart;
    const newDateFinish = dateFinish;
    let finallyData = [];
    if (this.state.dataPortafolioAll.length > 0) {
      if (
        value == '' &&
        (filter_source == 'Todos' || filter_source == null) &&
        newDateStart == '' &&
        newDateFinish == ''
      ) {
        this.setState({
          ...this.state,
          dataPortafolioDetail: this.state.dataPortafolioAll,
          dateModal: 'Fecha',
        });
      } else {
        finallyData = this.state.dataPortafolioAll.filter((obj: Portfolio) => {
          if (
            value == '' &&
            filter_source != 'Todos' &&
            filter_source != null &&
            newDateStart == '' &&
            newDateFinish == ''
          ) {
            return obj.state == filter_source;
          } else if (
            value != '' &&
            filter_source != 'Todos' &&
            filter_source != null &&
            newDateStart == '' &&
            newDateFinish == ''
          ) {
            return obj.document_number.toLowerCase().includes(value) && filter_source == obj.state;
          } else if (
            value != '' &&
            (filter_source == 'Todos' || filter_source == null) &&
            newDateStart == '' &&
            newDateFinish == ''
          ) {
            return obj.document_number.toLowerCase().includes(value);
          } else if (
            value == '' &&
            filter_source != 'Todos' &&
            filter_source != null &&
            newDateStart != '' &&
            newDateFinish != ''
          ) {
            return (
              obj.state == filter_source &&
              moment(obj.document_date, 'DD/MM/YYYY') >= moment(newDateStart, 'DD/MM/YYYY') &&
              moment(obj.document_date, 'DD/MM/YYYY') <= moment(newDateFinish, 'DD/MM/YYYY')
            );
          } else if (
            value != '' &&
            filter_source != 'Todos' &&
            filter_source != null &&
            newDateStart != '' &&
            newDateFinish != ''
          ) {
            return (
              obj.document_number.toLowerCase().includes(value) &&
              filter_source == obj.state &&
              moment(obj.document_date, 'DD/MM/YYYY') >= moment(newDateStart, 'DD/MM/YYYY') &&
              moment(obj.document_date, 'DD/MM/YYYY') <= moment(newDateFinish, 'DD/MM/YYYY')
            );
          } else if (
            value != '' &&
            (filter_source == 'Todos' || filter_source == null) &&
            newDateStart != '' &&
            newDateFinish != ''
          ) {
            return (
              obj.document_number.toLowerCase().includes(value) &&
              moment(obj.document_date, 'DD/MM/YYYY') >= moment(newDateStart, 'DD/MM/YYYY') &&
              moment(obj.document_date, 'DD/MM/YYYY') <= moment(newDateFinish, 'DD/MM/YYYY')
            );
          } else if (
            value == '' &&
            (filter_source == 'Todos' || filter_source == null) &&
            newDateStart != '' &&
            newDateFinish != ''
          ) {
            return (
              moment(obj.document_date, 'DD/MM/YYYY') >= moment(newDateStart, 'DD/MM/YYYY') &&
              moment(obj.document_date, 'DD/MM/YYYY') <= moment(newDateFinish, 'DD/MM/YYYY')
            );
          }
        });
        this.setState({
          ...this.state,
          dataPortafolioDetail: finallyData,
          dateModal: newDateStart != '' && newDateFinish != '' ? newDateStart + '-' + newDateFinish : 'Fecha',
        });
      }
    }
    return this.props.auth.user?.portfolio;
  };
  /**
   * Handle getCurrentDebt and return current debt
   *
   * @memberof PortfolioScreen
   */
  getCurrentDebt = () => {
    const currentDebt = Math.trunc(this.props.auth.user?.result_totals[0].total_current);
    return accounting.formatMoney(currentDebt);
  };

  /**
   * Handle getOverdueDebt and return overdue debt
   *
   * @memberof PortfolioScreen
   */
  getOverdueDebt = () => {
    const overdueDebt = Math.trunc(this.props.auth.user?.result_totals[0].expired_total);
    return accounting.formatMoney(overdueDebt);
  };

  /**
   * Handle getTotalDebt and return total debt
   *
   * @memberof PortfolioScreen
   */
  getTotalDebt = () => {
    const totalDebt = Math.trunc(this.props.auth.user?.result_totals[0].total_balance);
    return accounting.formatMoney(totalDebt);
  };
  /**
   * Function to open modal
   *
   * @memberof PortfolioScreen
   */
  _openDate = () => {
    this.setState({ loading: true });
    this.ModalOpenDate.openModalConfirmItem();
  };
  _rightComponent = () => <ButtonBranchBriefcase rshop={this.props.auth?.current_rshop} />;

  /**
   * Return an id to each view
   *
   * @memberof PortfolioScreen
   */
  _keyExtractor = (item: Portfolio) => {
    return `${item.document_number}`;
  };
  /**
   * Render emtpy component
   *
   * @memberof PortfolioScreen
   */
  _renderEmpty = () => {
    return (this.state.dataPortafolioDetail && this.state.dataPortafolioDetail.length > 0) ||
      this.state.loading ? null : (
      <View style={styles.imageNoFoundContainer}>
        <Image source={NOT_FOUND} style={styles.imageNoFound} />
        <View style={styles.imageNoFoundDescriptionContainer}>
          <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
        </View>
      </View>
    );
  };
  /**
   * Functin to get PortfolioScreen when moduled is opened
   *
   * @memberof PortfolioScreen
   */
  componentDidMount() {
    if (this.props.auth && this.props.auth.user && this.props.auth.user.portfolio) {
      this.setState({
        dataPortafolioDetail: this.props.auth.user.portfolio,
        dataPortafolioAll: this.props.auth.user.portfolio,
      });
    }
    this.timer = null;
    this._navigateToNextRoute();
  }

  _navigateToNextRoute = () => {
    const nextRouteName = this.props.auth.next_route_name;
    switch (nextRouteName) {
      case routes.deliveryHistory:
        setTimeout(() => {
          store.dispatch(resetNextRouteName());
          RootNavigation.navigate(nextRouteName);
        }, 100);
        break;
      case routes.profile:
        setTimeout(() => {
          store.dispatch(resetNextRouteName());
          RootNavigation.navigate(nextRouteName);
        }, 100);
        break;
      default:
        break;
    }
  };

  /**
   * Function to set false mounted
   *
   * @memberof PortfolioScreen
   */
  componentWillUnmount() {
    this.timer = null;
    //this._unsubscribe();
  }
  /**
   * Function to clean state and then load orders
   *
   * @memberof PortfolioScreen
   */
  _cleanStateToLoadOrders = () => {
    this.setState(
      {
        data: [],
      },
      () => {
        //this.getPortfolio();
      },
    );
  };
  setDateModal(dateStart: any, dateEnd: any, stateParent: any) {
    let datModal = 'Fecha';
    this.dateStart = '';
    this.dateFinish = '';
    if (dateStart != '' || dateEnd != '') {
      this.dateStart = moment(dateStart).format('DD/MM/YYYY');
      this.dateFinish = moment(dateEnd).format('DD/MM/YYYY');
      datModal = this.dateStart + '-' + this.dateFinish;
    }
    this.setState({
      ...stateParent,
      dateModal: datModal,
    });
    this.getPortfolio(this.filter_source, this.filterSearch, this.dateStart, this.dateFinish);
  }
  /**
   * Render _available_quota
   *
   * @returns
   * @memberof PortfolioScreen
   */
  available_quotaTotal = 0;
  function_available_quota() {
    this.available_quotaTotal = Math.trunc(this.props.auth.user?.result_totals[0].available_quota);
    const available_quota = Math.trunc(this.props.auth.user?.result_totals[0].available_quota);
    return accounting.formatMoney(available_quota);
  }
  /**
   * Render expiredDays
   *
   * @returns
   * @memberof PortfolioScreen
   */
  expiredDays = 0;
  functionexpiredDays() {
    this.expiredDays = Math.trunc(this.props.auth.user?.result_totals[0].expired_days);
    return this.expiredDays;
  }
  /**
   * Render client_quota
   *
   * @returns
   * @memberof PortfolioScreen
   */
  client_quota() {
    const client_quota = Math.trunc(this.props.auth.user?.result_totals[0].client_quota);
    return accounting.formatMoney(client_quota);
  }
  selectState(value: string) {
    this.filter_source = value;
    this.getPortfolio(this.filter_source, this.filterSearch, this.dateStart, this.dateFinish);
  }
  selectSearchBar(value: any) {
    this.filterSearch = value;
    this.getPortfolio(this.filter_source, this.filterSearch, this.dateStart, this.dateFinish);
  }
  /**
   * Render sign in view
   *
   * @returns
   * @memberof PortfolioScreen
   */
  render() {
    return (
      <>
        <ModalPassword onRef={(ref) => (this.ModalPassword = ref)} />
        <ModalOpenDate
          onRef={(ref) => (this.ModalOpenDate = ref)}
          title={strings.tituloCartera}
          subtitle="Fecha de Facturación"
          action={this._openDate}
          actionDate={this.setDateModal}
          actionText="Aplicar"
          stateParent={this.state}
        />
        <View style={styles.container}>
          <View style={styles.Allcontainer}>
            <View style={styles.headerSide}>
              <HeaderSideDrawer
                navigation={this.props.navigation}
                mainLabel={strings.mainLabel}
                color={colors.black}
                backgroundColor={colors.white}
                rightComponent={this._rightComponent}
              />
            </View>
            <View style={styles.containerHeader}>
              <View style={styles.containeTitle}>
                <Text style={styles.titleHistory}>{strings.totalDebt}</Text>
              </View>
              <View style={styles.containerOverdueDebt}>
                <Text style={styles.quotaAvailableValue}> {this.getTotalDebt()} </Text>
              </View>
            </View>
            <View style={styles.containerInfo}>
              <View style={styles.containerData}>
                <Text style={styles.label}>{strings.currentDebtLabel}</Text>
                <Text style={styles.value}>{this.getCurrentDebt()}</Text>
              </View>
              <View style={styles.containerOverdueDebt}>
                <Text style={styles.label}>{strings.overdueDebt}</Text>
                <Text style={styles.value}>{this.getOverdueDebt()}</Text>
              </View>
            </View>
            <SearchBar
              containerStyle={styles.searchBarContainer}
              inputContainerStyle={styles.inputContainerStyle}
              inputStyle={styles.searchBarInputStyle}
              onChangeText={(term) => this.selectSearchBar(term)}
              placeholder={strings.searchPlaceholder}
              placeholderTextColor={colors.grayPlaceholder}
              round
              value={this.filterSearch}
            />
            <View style={styles.containerDateType}>
              <View style={styles.containerButtons}>
                <View style={styles.containerDate}>
                  <TouchableOpacity onPress={this._openDate}>
                    <TextInput
                      style={styles.TextInputDate}
                      editable={false}
                      placeholder={this.state.dateModal}
                      placeholderTextColor={colors.black}
                    />
                  </TouchableOpacity>
                </View>
                <View style={styles.TextInputType}>
                  <InputSelectForm
                    placeholder={strings.placeholderSelect}
                    label={strings.tituloCartera}
                    items={this.state.orderSource}
                    value={this.filter_source}
                    onChangeText={(term: any) => this.selectState(term)}
                    colorText={colors.white}
                  />
                </View>
              </View>
            </View>
            <View style={styles.containerDateType}>
              <View style={styles.containerButtons}>
                <Text style={styles.TextTotaLimit}>
                  {strings.totaLimit}
                  {'\n'}
                  {this.client_quota()}
                </Text>
                <Text
                  style={[
                    styles.TextTotaLavailable,
                    this.function_available_quota() && this.available_quotaTotal >= 0
                      ? styles.colorTotaLavailable
                      : styles.colorRedTotaLavailable,
                  ]}
                >
                  {strings.totaLavailable}
                  {'\n'}
                  {this.function_available_quota()}
                </Text>
              </View>
            </View>
            <View style={styles.containerDaysPastDue}>
              <View style={styles.containerButtons}>
                <Text style={[styles.daysPastDue, this.expiredDays == 0 ? styles.colorblue : styles.colorRed]}>
                  {strings.expiredDays}
                  {this.functionexpiredDays()}
                </Text>
              </View>
            </View>
          </View>
          <ScrollView style={styles.containerItems}>
            {this.state.dataPortafolioDetail.length > 0 ? (
              this.state.dataPortafolioDetail.map((element: any, index: any) => {
                return (
                  <WalletReportFlastLyst
                    key={index}
                    backgroundColor={colors.stripedColors[index % colors.stripedColors.length]}
                    item={element}
                  />
                );
              })
            ) : (
              <View style={styles.imageNoFoundContainer}>
                <Image source={NOT_FOUND} style={styles.imageNoFound} />
                <View style={styles.imageNoFoundDescriptionContainer}>
                  <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
                </View>
              </View>
            )}
          </ScrollView>
        </View>
      </>
    );
  }
}

PortfolioScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

PortfolioScreen.defaultProps = {};

/** Export component PortfolioScreen **/
export default connect((state) => ({ ...state }))(PortfolioScreen);
