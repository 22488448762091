/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Carrito',
  observations: 'Observaciones',
  maxObservations: 'Máximo 500 Caracteres:',
  placeHolderObservations: 'Añada sus observaciones...',
  detailLabel: 'Detalle',
  confirmOrder: 'Confirmar pedido',
  messageOrder: 'Presione el botón "Solicitar" para confirmar que su pedido se encuentra completo.',
  errorSendOrder: 'Solicitar Pedido',
  sentOrder: 'Solicitud realizada',
  errorRemoveItem: 'Eliminar producto',
  total: 'Total',
  errorLoadShoppingCart: 'Carga de Carrito',
  errorToogleFavorite: 'Favorito',
  priceWithoutTaxes: 'Subtotal',
  taxes: 'Impuestos',
  priceChargesValues: 'Otros impuestos',
  sendOrder: 'Solicitar Pedido',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
  request: 'Solicitar',
  continue: 'EDITAR',
  informacionCompra: 'Producto(s) no disponible:',
  informacionSinItem: 'Importante',
  messageCompra: 'Por favor eliminarlo(s) del carrito para poder continuar con la compra.',
  messageSinItem:
    'Todos los ítems anteriormente seleccionados ya no se encuentran disponibles o no se encuentran actualizados, por favor Seleccione nuevamente ítems para continuar comprando.',
  messageSinActualizar: 'Por favor eliminarlo(s) del carrito e intente agregarlo nuevamente.',
  compraSinActualizar: 'Orden de compra no actualizada:',
  messageMininumAmount: 'No cumple con la cantidad mínima los siguientes productos:',
  messageDisabledSales:
    'Presentas saldo pendiente por pago y no estás autorizado para solicitar pedido en este momento. Haz clic en el botón para consultar tu estado de cartera:',
  labelSelectCellar: 'Seleccionar Distribuidor',
};

/** Export strings **/
export default strings;
