/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Favoritos',
  detailLabel: 'Detalle',
  searchPlaceholder: 'Buscar...',
  total: 'PEDIR ',
  errorLoadFavorites: 'Carga de Favoritos',
  errorToogleFavorite: 'Favorito',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
};

/** Export strings **/
export default strings;
