/** Import React **/
import React, { Component } from 'react';
import { Text, View, Image, Platform } from 'react-native';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Moment **/
import Moment from 'moment';
require('moment/locale/es');
Moment.locale('es');

/** Import Constants **/
import assets from '../../constants/assets/assets';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, DeliveryHistoryItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import shoppingImage from '../../web_assets/images/entregas.svg';

let SHOPPING = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  SHOPPING = shoppingImage;
} else if (Platform.OS === 'ios') {
  SHOPPING = { uri: `${assets.productWithoutImage}` };
} else {
  SHOPPING = { uri: `@mipmap/${assets.productWithoutImage}` };
}

interface Props {
  item: DeliveryHistoryItem;
  backgroundColor: string;
  auth?: Auth;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

/**
 * Class to export DeliveryHistoryFlatList view
 *
 * @class DeliveryHistoryFlatList
 * @extends {Component}
 */
class DeliveryHistoryFlatList extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   * Handle _getPrommedDate and return price
   *
   * @memberof DeliveryHistoryFlatList
   */
  _getPrommedDate = () => {
    if (this.props.item && this.props.item.programmed_date) {
      return Moment(this.props.item.programmed_date).format('LL HH:mm:ss');
    } else {
      return 'Sin fecha';
    }
  };

  /**
   * Handle delivery_date and return price
   *
   * @memberof DeliveryHistoryFlatList
   */
  _getDeliveryDate = () => {
    if (this.props.item && this.props.item.delivery_date) {
      return Moment(this.props.item.delivery_date).format('LL HH:mm:ss');
    } else {
      return 'Sin fecha';
    }
  };

  /**
   * Handle _getObservation and return price
   *
   * @memberof DeliveryHistoryFlatList
   */
  _getObservation = () => {
    if (this.props.item && this.props.item.observations) {
      return this.props.item.observations;
    } else {
      return '-';
    }
  };

  /**
   * Get color by state
   *
   * @memberof DeliveryHistoryFlatList
   */
  _getStyleOrder = () => {
    let stateOrder;
    switch (this.props.item.state) {
      case '1':
        stateOrder = styles.stateAssigned;
        break;
      case '2':
        stateOrder = styles.stateUndelivered;
        break;
      case '3':
        stateOrder = styles.stateDelivered;
        break;
      case 'RS_PA':
        stateOrder = styles.pendingAssignment;
        break;
      case 'RS_PARCIAL':
        stateOrder = styles.statePending;
        break;
      case 'RS_DEFEAT':
        stateOrder = styles.overdue;
        break;
      case 'RS_CANCEL':
        stateOrder = styles.cancelled;
        break;
      default:
        stateOrder = styles.pendingAssignment;
        break;
    }
    return stateOrder;
  };

  /**
   * Get label by state
   *
   * @memberof DeliveryHistoryFlatList
   */
  _getLabelOrder = () => {
    if (this.props.item && this.props.item.state_description) {
      return this.props.item.state_description;
    } else {
      return '-';
    }
  };

  /**
   * Render view with product data
   *
   * @returns
   * @memberof DeliveryHistoryFlatList
   */
  render() {
    return (
      <View style={[styles.shopinghistoyContainer, { backgroundColor: this.props.backgroundColor }]}>
        <View style={styles.imageIconOrderSold}>
          <Image source={SHOPPING} style={styles.image} />
        </View>
        <View style={styles.orderItemSoldDesc}>
          <Text style={styles.orderSoldCode}>#{this.props.item.delivery_code}</Text>
          <View style={styles.dateContainer}>
            <Text style={styles.orderSoldDateLabel}>{strings.programmedDate}</Text>
            <Text style={styles.orderSoldDate}>{this._getPrommedDate()}</Text>
          </View>
          <View style={styles.dateContainer}>
            <Text style={styles.orderSourceLabel}>{strings.deliveryDate}</Text>
            <Text style={styles.orderSoldDate}>{this._getDeliveryDate()}</Text>
          </View>
          <View style={styles.stateAndObservationsContainer}>
            <View style={styles.observationContainer}>
              <Text style={styles.observationLabel}>{strings.observations}</Text>
              <Text style={styles.orderSoldDate}>{this._getObservation()}</Text>
            </View>
            <View style={[styles.stateContainer, this._getStyleOrder()]}>
              <Text style={[styles.state]}>{this._getLabelOrder()}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

DeliveryHistoryFlatList.propTypes = {
  auth: AuthPropTypes,
};

DeliveryHistoryFlatList.defaultProps = {};

/** Export component DeliveryHistoryFlatList **/
export default connect((state) => ({ ...state }))(DeliveryHistoryFlatList);
