/** Import Redux Toolkit **/
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

/** Import Redux **/
import { createMigrate, persistStore, persistReducer } from 'redux-persist';

/** Import Storage **/
import AsyncStorage from '@react-native-async-storage/async-storage';

/** Import Reducers **/
import reducers from './../reducers';

/** Import Slices **/
import { logout } from './../slices/auth/authSlice';
import { cleanShoppingCart } from './../slices/shoppingCart/shoppingCartSlice';

/** Create config to persist data **/
const migrations = {
  1: ({ _persist }: any) => {
    return { _persist }; // reset all state, except version
  },
  2: ({ _persist }: any) => {
    return { _persist }; // reset all state, except version
  },
};

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: ['auth', 'favorites', 'products', 'shoppingCart', 'shoppingHistory'],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

const cleanAndLogout = () => {
  store.dispatch(cleanShoppingCart());
  store.dispatch(logout());
};

export { store, persistor, cleanAndLogout };
