/** Import Redux Toolkit **/
import { createSlice } from '@reduxjs/toolkit';

/** Import Initial State **/
import { INITIAL_STATE } from './initialState';

/** Import parseProductData **/
import parseProductData from '../../utils/parseProductData';

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: INITIAL_STATE,
  reducers: {
    setFavorites(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    toggleFavoriteItem(state, action) {
      let payloadItem: any;
      if (action.payload.state === 1) {
        payloadItem = { ...action.payload.item, is_favorite: 'Y' };
      } else {
        payloadItem = { ...action.payload.item, is_favorite: 'N' };
      }

      if (state.data && state.data.length > 0) {
        const indexFound = state.data.findIndex(
          (itemState) =>
            (itemState.code === payloadItem.code &&
              parseFloat(String(itemState.factors[0].factor)) === parseFloat(payloadItem.factors[0].factor)) ||
            (itemState.code === payloadItem.product_code &&
              parseFloat(String(itemState.factors[0].factor)) === parseFloat(payloadItem.factor)),
        );

        if (indexFound === -1 && action.payload.state === 1) {
          let newItem: any;
          if (payloadItem.product_code) {
            newItem = parseProductData(payloadItem, undefined);
          } else {
            newItem = parseProductData(undefined, payloadItem);
          }
          return {
            ...state,
            data: [...[newItem], ...state.data],
          };
        } else if (indexFound > -1 && action.payload.state === 0) {
          const data = [...state.data.slice(0, indexFound), ...state.data.slice(indexFound + 1)];
          return {
            ...state,
            data,
          };
        }
      } else if (state.data && state.data.length === 0 && action.payload.state === 1) {
        let newItem: any;
        if (payloadItem.product_code) {
          newItem = parseProductData(payloadItem, undefined);
        } else {
          newItem = parseProductData(undefined, payloadItem);
        }
        return {
          ...state,
          data: [...[newItem], ...state.data],
        };
      } else {
        return {
          ...state,
        };
      }
    },
    removeFavoriteFromShoppingCart(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          (itemState.code === action.payload.item.code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factors[0].factor)) ||
          (itemState.code === action.payload.item.product_code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factor)),
      );

      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...state.data[indexFound],
            inShoppingCart: false,
            quantity: '0',
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    updateFavoriteAsInShoppingCart(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          (itemState.code === action.payload.item.code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factors[0].factor)) ||
          (itemState.code === action.payload.item.product_code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factor)),
      );

      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...action.payload.item,
            inShoppingCart: true,
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    cleanShoppingCartFavorites(state) {
      const data: any = [];
      state.data.forEach((item) => {
        data.push({ ...item, inShoppingCart: false, quantity: '0' });
      });
      return {
        ...state,
        data,
      };
    },
    cleanFavorites() {
      return INITIAL_STATE;
    },
  },
});

export const {
  setFavorites,
  toggleFavoriteItem,
  cleanFavorites,
  updateFavoriteAsInShoppingCart,
  removeFavoriteFromShoppingCart,
  cleanShoppingCartFavorites,
} = favoritesSlice.actions;

export default favoritesSlice.reducer;
