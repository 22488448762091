/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Carrito',
  observations: 'Observaciones',
  maxObservations: 'Máximo 500 Caracteres:',
  placeHolderObservations: 'Añada sus observaciones...',
  detailLabel: 'Detalle',
  confirmOrder: 'Confirmar pedido',
  messageOrder: 'Presione el botón "Solicitar" para confirmar que su pedido se encuentra completo.',
  errorSendOrder: 'Solicitar Pedido',
  sentOrder: 'Solicitud realizada',
  errorRemoveItem: 'Eliminar producto',
  total: 'Total',
  errorLoadShoppingCart: 'Carga de Carrito',
  errorToogleFavorite: 'Favorito',
  priceWithoutTaxes: 'Subtotal',
  taxes: 'Impuestos',
  priceChargesValues: 'Otros impuestos',
  sendOrder: 'PEDIR',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
  request: 'Solicitar',
};

/** Export strings **/
export default strings;
