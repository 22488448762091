/** icons to App **/
const icons = {
  icNextBlack: 'ic_next_black',
  icShowPassword: 'ic_show_password',
  icHidePassword: 'ic_hide_password',
  icCheck: 'ic-check',
  icHome: 'ic_home',
  icProfile: 'ic_profile',
  icShoppingHistory: 'ic_shopping_history',
  icPortfolio: 'ic_portfolio',
  icCallClient: 'ic_call_client',
  icCallSeller: 'ic_call_seller',
  icWriteSeller: 'ic_write_seller',
  icInfo: 'ic_info',
  icNext: 'ic_next',
  icShopping: 'ic_shopping',
  icHeart: 'ic_heart',
  icCubes: 'ic_cubes',
  icClose: 'ic_close',
  icEdit: 'ic_edit',
  icTrash: 'ic_trash',
  icPlus: 'ic_plus',
  icMinus: 'ic_minus',
  icCar: 'camion',
  icCloseWhite: 'cerrar-blanco',
  icTrashWhite: 'basura-blanca',
};

/** Export icons **/
export default icons;
