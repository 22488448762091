/** Import Redux Toolkit **/
import { createSlice } from '@reduxjs/toolkit';

/** Import Initial State **/
import { INITIAL_STATE } from './initialState';

const productsSlice = createSlice({
  name: 'products',
  initialState: INITIAL_STATE,
  reducers: {
    setProducts(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    toggleProductItem(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          (itemState.code === action.payload.item.code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factors[0].factor)) ||
          (itemState.code === action.payload.item.product_code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factor)),
      );

      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...state.data[indexFound],
            is_favorite: action.payload.state === 1 ? 'Y' : 'N',
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    removeItemFromShoppingCart(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          (itemState.code === action.payload.item.code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factors[0].factor)) ||
          (itemState.code === action.payload.item.product_code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factor)),
      );

      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...state.data[indexFound],
            inShoppingCart: false,
            quantity: '0',
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    updateItemAsInShoppingCart(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          (itemState.code === action.payload.item.code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factors[0].factor)) ||
          (itemState.code === action.payload.item.product_code &&
            parseFloat(String(itemState.factors[0].factor)) === parseFloat(action.payload.item.factor)),
      );

      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...action.payload.item,
            inShoppingCart: true,
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    cleanShoppingCartProducts(state) {
      const data: any = [];
      state.data.forEach((item) => {
        data.push({ ...item, inShoppingCart: false, quantity: '0' });
      });
      return {
        ...state,
        data,
      };
    },
    cleanProducts() {
      return INITIAL_STATE;
    },
  },
});

export const {
  setProducts,
  toggleProductItem,
  cleanProducts,
  removeItemFromShoppingCart,
  updateItemAsInShoppingCart,
  cleanShoppingCartProducts,
} = productsSlice.actions;

export default productsSlice.reducer;
