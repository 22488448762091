/** Import Interfaces **/
import { ProductItem, ShoppingCartItem } from '../interfaces';

/** Function to return product data **/
export default (itemFromShopping?: ShoppingCartItem, itemDefault?: ProductItem) => {
  return {
    code: itemFromShopping?.product_code ? itemFromShopping.product_code : itemDefault?.code,
    description: itemFromShopping?.product_name ? itemFromShopping.product_name : itemDefault?.description,
    quantity: itemFromShopping?.quantity ? itemFromShopping.quantity : null,
    factors:
      itemFromShopping?.factor && itemDefault?.factors
        ? itemDefault.factors.map((factor) => {
            if (parseFloat(itemFromShopping.factor) === parseFloat(String(factor.factor))) {
              return {
                factor: parseFloat(itemFromShopping.factor),
                description: itemFromShopping.factor_description,
                minimum_amount: itemFromShopping.minimum_amount,
                state: 'Y',
                inShoppingCart: true,
              };
            } else {
              return factor;
            }
          })
        : itemDefault?.factors
        ? itemDefault?.factors
        : [
            {
              factor: itemFromShopping?.factor,
              description: itemFromShopping?.factor_description,
              minimum_amount: itemFromShopping?.minimum_amount,
              state: 'Y',
            },
          ],
    price: itemFromShopping?.price ? itemFromShopping.price : itemDefault?.price,
    tax: itemFromShopping && !isNaN(parseFloat(itemFromShopping.tax)) ? itemFromShopping.tax : itemDefault?.tax,
    location: itemFromShopping?.location ? itemFromShopping.location : itemDefault?.location,
    code_list: itemFromShopping?.code_list ? itemFromShopping.code_list : itemDefault?.code_list,
    stock: itemFromShopping && !isNaN(parseFloat(itemFromShopping.stock)) ? itemFromShopping.stock : itemDefault?.stock,
    charge_value:
      itemFromShopping && !isNaN(parseFloat(itemFromShopping.charge_value))
        ? itemFromShopping.charge_value
        : itemDefault?.charge_value,
    discount: 0,
    family: itemFromShopping?.family ? itemFromShopping.family : itemDefault?.family,
    group: itemFromShopping?.group ? itemFromShopping.group : itemDefault?.group,
    is_favorite: itemFromShopping?.is_favorite ? itemFromShopping.is_favorite : itemDefault?.is_favorite,
    line: itemFromShopping?.line ? itemFromShopping.line : itemDefault?.line,
    max_discount_1: itemFromShopping?.max_discount_1 ? itemFromShopping.max_discount_1 : itemDefault?.max_discount_1,
    max_discount_2: itemFromShopping?.max_discount_2 ? itemFromShopping.max_discount_2 : itemDefault?.max_discount_2,
    max_discount_3: itemFromShopping?.max_discount_3 ? itemFromShopping.max_discount_3 : itemDefault?.max_discount_3,
    price_charges_values:
      itemFromShopping && !isNaN(parseFloat(itemFromShopping.price_charges_values))
        ? itemFromShopping.price_charges_values
        : itemDefault?.price_charges_values,
    price_max: itemFromShopping?.price_max ? itemFromShopping.price_max : itemDefault?.price_max,
    price_min: itemFromShopping?.price_min ? itemFromShopping.price_min : itemDefault?.price_min,
    total_amount: itemFromShopping?.total_amount ? itemFromShopping.total_amount : itemDefault?.total_amount,
    inShoppingCart: !!itemFromShopping,
    observations: itemFromShopping?.observations ? itemFromShopping.observations : itemDefault?.observations,
    to_delete: itemFromShopping?.to_delete ? itemFromShopping.to_delete : false,
    has_image: itemFromShopping?.has_image ? itemFromShopping.has_image : itemDefault?.has_image,
  };
};
