/** Define object to static labels in view **/
const strings = {
  title: '¡Excelente!',
  subtitle: 'Su solicitud ha sido enviada correctamente',
  state: 'Estado: Pendiente',
  description: 'Para conocer el estado de su pedido puede hacerlo ingresando al historial de compras',
  home: 'Otro Pedido',
  returnToWa: 'Volver a Whatsapp',
};

/** Export strings **/
export default strings;
