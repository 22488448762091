/** Import Interfaces **/
import { Products } from '../../interfaces';

const INITIAL_STATE: Products = {
  filter_value: '',
  page: {
    has_next: true,
    next_page_number: 1,
  },
  data: [],
};

export { INITIAL_STATE };
