/** Define dictionary **/
const dict: any = {
  __all__: '',
  username: 'Email',
  password: 'Contraseña',
  password2: 'Contraseña',
  old_password: 'Contraseña antigua',
  filter_value: 'Valor a filtrar',
  group: 'Grupo',
  family: 'Familia',
  line: 'Linea',
  type_search: 'Tipo de busqueda',
  page: 'Página',
  total_amount: 'Cantidad Total',
  product_id: 'Código del producto',
  factor_id: 'Código del factor',
  product_code: 'Código del producto',
  product_name: 'Nombre del producto',
  quantity: 'Cantidad',
  factor: 'Código del factor',
  factor_description: 'Descripción del factor',
  price: 'Precio',
  tax: 'Impuesto',
  discount: 'Descuento',
  location: 'Ubicación',
  code_list: 'Lista código',
  stock: 'Inventario',
  charge_value: 'Otros cargos',
  price_charges_values: 'Otros cargos',
  token: 'Código de confirmación',
};

/**
 * Parse the form errors in string
 *
 * @export
 * @param {*} form_errors
 * @returns
 */
export default function parseFormErrors(form_errors: any) {
  let form_errors_parse = '';
  if (form_errors.__all__ && form_errors.__all__[0] && form_errors.__all__[0].length > 0) {
    form_errors_parse += form_errors.__all__[0];
  } else {
    for (const key in form_errors) {
      for (const form_errors_key in form_errors[key]) {
        form_errors_parse += dict[key] + ': ' + form_errors[key][form_errors_key] + '\n';
      }
    }
  }
  form_errors_parse = form_errors_parse.slice(0, form_errors_parse.lastIndexOf('\n'));
  return form_errors_parse;
}
