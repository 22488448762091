/** Import React **/
import React from 'react';

/** Import React Navigation **/
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { TransitionPresets } from '@react-navigation/stack';

/** Import components **/
import IconWithBackground from '../components/iconWithBackground';
import TopHeader from '../components/topHeader';

/** Import Constants **/
import colors from '../constants/styles/colors';
import icons from '../constants/icons/icons';
import { routes } from '../constants/routes/routes';

/** Import views products **/
import ProductsScreen from '../views/products';

/** Import views favorites **/
import FavoritesScreen from '../views/favorites';

/** Import views shopping cart **/
import ShoppingCartScreen from '../views/shoppingCart';

const Stack = createStackNavigator();

function TabProductsStack() {
  return (
    <Stack.Navigator
      headerMode={'screen'}
      screenOptions={() => ({
        ...TransitionPresets.ScaleFromCenterAndroid,
      })}
    >
      <Stack.Screen
        name={routes.products}
        component={ProductsScreen}
        options={{ header: (props) => <TopHeader {...props} backgroundColor={colors.white} /> }}
      />
    </Stack.Navigator>
  );
}

function TabFavoritesStack() {
  return (
    <Stack.Navigator
      headerMode={'screen'}
      screenOptions={() => ({
        ...TransitionPresets.ScaleFromCenterAndroid,
      })}
    >
      <Stack.Screen
        name={routes.favorites}
        component={FavoritesScreen}
        options={{ header: (props) => <TopHeader {...props} backgroundColor={colors.white} /> }}
      />
    </Stack.Navigator>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
function TabShoppingCartStack() {
  return (
    <Stack.Navigator
      headerMode={'screen'}
      screenOptions={() => ({
        ...TransitionPresets.ScaleFromCenterAndroid,
      })}
    >
      <Stack.Screen
        name={routes.shoppingCart}
        component={ShoppingCartScreen}
        options={{ header: (props) => <TopHeader {...props} backgroundColor={colors.white} /> }}
      />
    </Stack.Navigator>
  );
}

const Tab = createBottomTabNavigator();

export default function Order() {
  return (
    <Tab.Navigator
      screenOptions={({ route }: any) => {
        let tabBarVisible = false;
        if (
          !route.state ||
          (route.state && route.state.routeNames[route.state.index] === routes.products) ||
          (route.state && route.state.routeNames[route.state.index] === routes.favorites) ||
          (route.state && route.state.routeNames[route.state.index] === routes.shoppingCart)
        ) {
          tabBarVisible = true;
        }
        return {
          tabBarIcon: ({ focused }) => {
            let iconName = '';
            let color = '';
            let showBadge = false;
            let text = '';
            if (route.name === routes.tabProducts) {
              iconName = icons.icCubes;
              color = colors.primary;
              text = 'Productos';
            } else if (route.name === routes.tabFavorites) {
              iconName = icons.icHeart;
              color = colors.primary;
              text = 'Favoritos';
            } else if (route.name === routes.tabShoppingCart) {
              iconName = icons.icShopping;
              color = colors.primary;
              showBadge = true;
            }
            return (
              <IconWithBackground
                name={iconName}
                color={color}
                size={20}
                focused={focused}
                showBadge={showBadge}
                text={text}
              />
            );
          },
          tabBarVisible,
        };
      }}
      tabBarOptions={{
        activeTintColor: colors.gray,
        inactiveTintColor: colors.gray,
        showLabel: false,
      }}
    >
      <Tab.Screen name={routes.tabProducts} component={TabProductsStack} />
      <Tab.Screen name={routes.tabFavorites} component={TabFavoritesStack} />
      {/*<Tab.Screen name={routes.tabShoppingCart} component={TabShoppingCartStack} />*/}
    </Tab.Navigator>
  );
}
