/** Import Interfaces **/
import { Auth } from '../../interfaces';

// eslint-disable-next-line
let INITIAL_STATE: Auth = {
  app_version: undefined,
  companies: undefined,
  csrftoken: undefined,
  sessionid: undefined,
  user: undefined,
  array_companies: undefined,
  array_branchoffices: undefined,
  current_rshop: undefined,
  is_logged: undefined,
  email: undefined,
};

export { INITIAL_STATE };
