/** Import modules **/
import React from 'react';
import { View, ActivityIndicator } from 'react-native';

/** Components **/
import colors from './../../constants/styles/colors';
import styles from './style';

/**
 * Create loader with ActivityIndicator
 *
 * @param {Props}
 * @returns
 */
const LoaderWithoutModal = () => {
  /**
   * Render component with Icon
   *
   * @returns
   * @memberof LoaderWithoutModal
   */
  return (
    <View style={styles.container}>
      <ActivityIndicator animating size={'large'} color={colors.primary} />
    </View>
  );
};

/** Export component LoaderWithoutModal **/
export default LoaderWithoutModal;
