/** Styles to view **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    justifyContent: 'center',
  },
});

/** Export component style **/
export default styles;
