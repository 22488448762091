/** Import Redux **/
import { combineReducers } from 'redux';

/** Import Slices **/
import authReducer from '../slices/auth/authSlice';
import favoritesReducer from '../slices/favorites/favoritesSlice';
import productsReducer from '../slices/products/productsSlice';
import shoppingCartReducer from '../slices/shoppingCart/shoppingCartSlice';
import shoppingHistoryReducer from '../slices/shoppingHistory/shoppingHistorySlice';
import snackBarReducer from '../slices/snackBar/snackBarSlice';
import deliveryHistoryReducer from '../slices/deliveryHistory/deliveryHistorySlice';

export default combineReducers({
  auth: authReducer,
  favorites: favoritesReducer,
  products: productsReducer,
  shoppingCart: shoppingCartReducer,
  shoppingHistory: shoppingHistoryReducer,
  snackBar: snackBarReducer,
  deliveryHistory: deliveryHistoryReducer,
});
