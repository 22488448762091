import {
  urlShoppingcartAddItem,
  urlShoppingcartRemoveItem,
  urlToogleFavorite,
  urlShoppingMassivecartRemoveItem,
} from '../../api/urls';
import { apiPost } from '../../api';
import { store } from '../../store';
import {
  addShoppingCartItem,
  removeShoppingCartItem,
  toggleShoppingCartItem,
} from '../../slices/shoppingCart/shoppingCartSlice';
import strings from './strings';
import { Auth } from '../../interfaces';
import {
  toggleProductItem,
  removeItemFromShoppingCart,
  updateItemAsInShoppingCart,
} from '../../slices/products/productsSlice';
import {
  toggleFavoriteItem,
  removeFavoriteFromShoppingCart,
  updateFavoriteAsInShoppingCart,
} from '../../slices/favorites/favoritesSlice';

/**
 * Function to add item to shopping cart
 */
export const addToShoppingCart = async (
  auth: Auth | undefined,
  productItem: any,
  quantity: string,
  selectedFactor: any,
) => {
  if (quantity && parseFloat(quantity) > 0) {
    const item = { ...productItem, factorSelected: selectedFactor, quantity: quantity };

    const rshopId =
      auth && auth.current_rshop && auth.current_rshop && auth.current_rshop.rshop_id
        ? auth.current_rshop.rshop_id
        : '';
    const csrftoken = auth?.csrftoken;

    const urlShoppingcartAddItemReplaced = urlShoppingcartAddItem.replace(/\[rshop_id\]/g, String(rshopId));
    const data = {
      csrfmiddlewaretoken: csrftoken,
      product_code: item.product_code ? item.product_code : item.code,
      product_name: item.description,
      quantity: item.quantity,
      factor: item.factorSelected.factor,
      factor_description: item.factorSelected.description,
      minimum_amount: item.factorSelected.minimum_amount,
      price: item.price,
      tax: item.tax,
      discount: 0,
      location: item.location,
      code_list: item.code_list,
      stock: item.stock,
      //Se duplica el valor para el campo price_charges_values y charge_values ya que la aplicación Backend no almacena los
      //charge_values. (Mientras el producto del carrito siga vivo, los otros cargos siempre será el mismo valor sea los de la
      //lista de precio o del producto, según estaba configurado el proveedor cuando añadió el item)
      price_charges_values:
        auth?.current_rshop?.apply_charge_values_to === 'pri' ? item.price_charges_values : item.charge_value,
      charge_value:
        auth?.current_rshop?.apply_charge_values_to === 'pri' ? item.price_charges_values : item.charge_value,
      //----------
      family: item.family,
      group: item.group,
      is_favorite: item.is_favorite,
      line: item.line,
      max_discount_1: item.max_discount_1,
      max_discount_2: item.max_discount_2,
      max_discount_3: item.max_discount_3,
      price_max: item.price_max,
      price_min: item.price_min,
      total_amount: item.total_amount,
      observations: item.observations,
      to_delete: item.to_delete,
      has_image: item.has_image,
    };
    try {
      const response = await apiPost(urlShoppingcartAddItemReplaced, data)();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (response.success) {
        delete data.csrfmiddlewaretoken;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        response.item = data;
        store.dispatch(addShoppingCartItem(response));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        response.item = item;
        store.dispatch(updateItemAsInShoppingCart(response));
        store.dispatch(updateFavoriteAsInShoppingCart(response));
        return new Promise((resolve) => {
          resolve(response);
        });
      } else {
        return new Promise((reject) => {
          reject(response);
        });
      }
    } catch (error) {
      if (error.message) {
        error.message += ' (012).';
      }
      return new Promise((reject) => {
        reject(error);
      });
    }
  } else {
    return new Promise((reject) => {
      reject({ error: `${strings.quantityRequired}: ${strings.quantityRequiredDetail}` });
    });
  }
};

/**
 * Function to remove favorite item
 */
export const removeFromShoppingCart = async (auth: Auth | undefined, productItem: any, selectedFactor: any) => {
  const item = { ...productItem, factorSelected: selectedFactor };
  const rshopId =
    auth && auth.current_rshop && auth.current_rshop && auth.current_rshop.rshop_id ? auth.current_rshop.rshop_id : '';

  const csrftoken = auth?.csrftoken;
  const urlShoppingcartRemoveItemReplaced = urlShoppingcartRemoveItem.replace(/\[rshop_id\]/g, String(rshopId));
  const data = {
    csrfmiddlewaretoken: csrftoken,
    product_id: item.product_code ? item.product_code : item.code,
    factor: item.factorSelected.factor,
  };
  try {
    const response = await apiPost(urlShoppingcartRemoveItemReplaced, data)();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (response.success) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      response.item = { ...item, inShoppingCart: false };
      store.dispatch(removeShoppingCartItem(response));
      store.dispatch(removeItemFromShoppingCart(response));
      store.dispatch(removeFavoriteFromShoppingCart(response));
      return new Promise((resolve) => {
        resolve(response);
      });
    } else {
      return new Promise((reject) => {
        reject(response);
      });
    }
  } catch (error) {
    if (error.message) {
      error.message += ' (011).';
    }
    return new Promise((reject) => {
      reject(error);
    });
  }
};

/**
 * Function to toggle favorite item
 */
export const toogleFavorite = async (auth: Auth | undefined, productItem: any, selectedFactor: any) => {
  const rshopId =
    auth && auth.current_rshop && auth.current_rshop && auth.current_rshop.rshop_id ? auth.current_rshop.rshop_id : '';
  const item = { ...productItem, factorSelected: selectedFactor };
  const urlToogleFavoriteReplaced = urlToogleFavorite.replace(/\[rshop_id\]/g, String(rshopId));

  const data = {
    csrfmiddlewaretoken: auth?.csrftoken,
    product_id: item.product_code ? item.product_code : item.code,
    factor_id: item.factor ? item.factor : item.factors[0].factor,
  };

  try {
    const response = await apiPost(urlToogleFavoriteReplaced, data)();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (response.success) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      response.item = item;

      store.dispatch(toggleProductItem(response));
      store.dispatch(toggleFavoriteItem(response));
      store.dispatch(toggleShoppingCartItem(response));
      return new Promise((resolve) => {
        resolve(response);
      });
    } else {
      return new Promise((reject) => {
        reject(response);
      });
    }
  } catch (error) {
    if (error.message) {
      error.message += ' (007).';
    }
    return new Promise((reject) => {
      reject(error);
    });
  }
};

/**
 * Function to remove massive item
 */
export const removeMassiveFromShoppingCart = async (auth: Auth | undefined, productItems: any) => {
  let productsFactors = '';
  for (let i = 0; i < productItems.length; i++) {
    productsFactors += productItems[i].product_code + ';' + productItems[i].factor + '|';
  }
  const rshopId =
    auth && auth.current_rshop && auth.current_rshop && auth.current_rshop.rshop_id ? auth.current_rshop.rshop_id : '';

  const csrftoken = auth?.csrftoken;
  const urlShoppingcartRemoveItemReplaced = urlShoppingMassivecartRemoveItem.replace(/\[rshop_id\]/g, String(rshopId));
  const data = {
    csrfmiddlewaretoken: csrftoken,
    products_factors: productsFactors,
  };
  try {
    const response = await apiPost(urlShoppingcartRemoveItemReplaced, data)();

    if (response.success) {
      let selectedFactor = {};

      for (let i = 0; i < productItems.length; i++) {
        selectedFactor = {
          factor: productItems[i].factor,
          description: productItems[i].factor_description,
          state: 'Y',
        };
        const item = { ...productItems[i], factorSelected: selectedFactor };
        response.item = { ...item, inShoppingCart: false };
        store.dispatch(removeShoppingCartItem(response));
        store.dispatch(removeItemFromShoppingCart(response));
        store.dispatch(removeFavoriteFromShoppingCart(response));
      }
      return new Promise((resolve) => {
        resolve(response);
      });
    } else {
      return new Promise((reject) => {
        reject(response);
      });
    }
  } catch (error) {
    if (error.message) {
      error.message += ' (011).';
    }
    return new Promise((reject) => {
      reject(error);
    });
  }
};
