/** Styles to view **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderWidth: 0,
    height: 48,
    justifyContent: 'center',
  },
  buttonRounded: {
    borderRadius: 10,
  },
  buttonRed: {
    backgroundColor: colors.primary,
  },
  buttonText: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  buttonWhiteTextColor: {
    color: colors.white,
  },
  buttonBlackTextColor: {
    color: colors.black,
  },
});

/** Export component styles **/
export default styles;
