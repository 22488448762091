/** Import React **/
import React, { Component } from 'react';
import { View, Text, TextInput, Image, ScrollView, Platform, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { urlRootGalleryUrl, urlImageGalleryMaster } from '../../api/urls';

/** Import components **/
import HeaderSideDrawer from '../../components/headerSideDrawer';
import { IconMoon } from './../../components/icon/IconMoon';
import InputSelectForm from '../../components/inputSelectForm';
import Loader from '../../components/loader';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from './../../constants/styles/colors';
import icons from './../../constants/icons/icons';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, Factor, FactorSelect, ProductItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Utils **/
import showAlert from '../../utils/showAlert';
import totalizeValues from '../../utils/totalizeValues';
import ModalMininumAmount from '../../components/modalMininumAmount';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import productWithoutImage from '../../web_assets/images/product_without_image.png';
import { addToShoppingCart, removeFromShoppingCart, toogleFavorite } from './api';
import { routes } from '../../constants/routes/routes';

let PRODUCT_WITHOUT_IMAGE = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  PRODUCT_WITHOUT_IMAGE = productWithoutImage;
} else if (Platform.OS === 'ios') {
  PRODUCT_WITHOUT_IMAGE = { uri: `${assets.productWithoutImage}` };
} else {
  PRODUCT_WITHOUT_IMAGE = { uri: `@mipmap/${assets.productWithoutImage}` };
}

/** Const to define size in icons **/
const ICON_HEART_SIZE = 25;
const ICON_SHOPPING_SIZE = 18;

interface Props {
  navigation: NavigationProp<any>;
  route: any;
  auth: Auth;
}

interface State {
  item: any;
  originalItem: ProductItem;
  quantity: string;
  updateCart: boolean;
  factors: FactorSelect;
}

/**
 * Class to export ProductDetailScreen
 *
 * @class ProductDetailScreen
 * @extends {Component<Props, State>}
 */
class ProductDetailScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;
  public ModalMininumAmount!: any;

  /**
   * Creates an instance of ProductDetailScreen.
   * @param {*} props
   * @memberof ProductDetailScreen
   */
  constructor(props: Props) {
    super(props);
    const item = this.props.route.params?.item ?? undefined,
      originalItem = this.props.route.params?.originalItem ?? undefined;

    let factors: Array<Factor> = [
      {
        description: 'UNIDAD',
        factor: 1,
        label: 'UNIDAD (1)',
        value: 1,
        state: 'Y',
        inShoppingCart: false,
        minimum_amount: 0,
      },
    ];
    if (item && item.factors && item.factors.length > 0) {
      factors = item.factors.map((factor: Factor) => ({
        ...factor,
        label: `${factor.description} (${factor.factor})`,
        value: parseFloat(String(factor.factor)),
      }));
    }

    const factorSelected = factors.filter((factor) => factor.inShoppingCart);

    this.state = {
      item: item,
      originalItem: originalItem,
      quantity:
        item && item.quantity && item.quantity.length > 0 && parseFloat(item.quantity) > 0
          ? `${parseFloat(item.quantity)}`
          : '1',
      updateCart: item.inShoppingCart,
      factors: {
        selectedValue: factorSelected.length > 0 ? factorSelected[0].value : factors[0].value,
        selectedObject: factorSelected.length > 0 ? factorSelected[0] : factors[0],
        options: factors,
      },
    };
  }

  /**
   * Function to get rshop_id
   *
   * @memberof ProductDetail
   */
  _getRshop_id = (): number | string => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Function to increment quantity
   *
   * @memberof ProductDetail
   */
  _incrementQuantity = () => {
    const quantity = parseFloat(this.state.quantity);
    this.setState({
      quantity: `${quantity + 1}`,
    });
  };

  /**
   * Function to set new quantity
   *
   * @memberof ProductDetail
   */
  _onChangeQuantity = (quantity: string) => {
    if (quantity.length > 0 && quantity !== '0' && !isNaN(parseFloat(quantity))) {
      this.setState({ quantity: `${parseFloat(quantity)}` });
    } else {
      this.setState({ quantity: '1' });
    }
  };

  /**
   * Function to decrement quantity
   *
   * @memberof ProductDetail
   */
  _decrementQuantity = () => {
    const quantity = parseFloat(this.state.quantity);
    if (quantity <= 1) {
      return;
    }
    this.setState({ quantity: `${parseFloat(String(quantity)) - 1}` });
  };

  /**
   * Handle getImage and return object with image route
   *
   * @memberof ProductDetail
   */
  _getImage = () => {
    if (
      this.state.item &&
      this.state.item.code &&
      this.state.item.code.length > 0 &&
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.company_code &&
      this.state.item.has_image === 'Y'
    ) {
      return {
        uri:
          urlRootGalleryUrl +
          urlImageGalleryMaster
            .replace(/\[company_code\]/g, this.props.auth.current_rshop.company_code)
            .replace(/\[product_code\]/g, this.state.item.code),
      };
    } else {
      return PRODUCT_WITHOUT_IMAGE;
    }
  };

  /**
   * Return code of product
   *
   * @memberof ProductDetail
   */
  _getProductCode = () => {
    return this.state.item.code;
  };

  /**
   * Handle event onPress in view
   *
   * @memberof ProductDetail
   */
  _onPressFavorite = async () => {
    this.Loader.openLoader(async () => {
      const item = { ...this.state.originalItem };
      if (item.is_favorite === 'Y') {
        item.is_favorite = 'N';
      } else {
        item.is_favorite = 'Y';
      }

      const response = await toogleFavorite(this.props.auth, item, this.state.factors.selectedObject);
      this.Loader.closeLoader(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.item = item;

          this.setState({ originalItem: item });
        } else {
          showAlert(response, 'danger');
        }
      });
    });
  };

  /**
   * Return description of product
   *
   * @memberof ProductDetail
   */
  _getProductDescription = () => {
    return this.state.item.description;
  };

  /**
   * Hadle getTotalValues and return total price
   *
   * @memberof ProductDetail
   */
  _getTotalValues = () => {
    const factor = this.state.factors.selectedValue;
    const itemPriceValues = {
      price: this.state.item.price,
      quantity: this.state.quantity ? String(this.state.quantity) : '0',
      factor: factor,
      tax: this.state.item.tax,
      charge_value: this.state.item.charge_value,
      price_charges_values: this.state.item.price_charges_values,
    };

    return totalizeValues(itemPriceValues);
  };

  /**
   * Price by factor
   *
   * @memberof ProductDetail
   */
  _getPriceByFactor = () => {
    return `${accounting.formatMoney(this._getTotalValues().priceByFactor)}`;
  };

  /**
   * Price without taxes
   *
   * @memberof ProductDetail
   */
  _getPriceWithoutTaxes = () => {
    return `${accounting.formatMoney(this._getTotalValues().priceWithoutTaxes)}`;
  };

  /**
   * Taxes
   *
   * @memberof ProductDetail
   */
  _getTaxes = () => {
    return `${accounting.formatMoney(this._getTotalValues().taxes)}`;
  };

  /**
   * Price Charges Values
   *
   * @memberof ProductDetail
   */
  _getChargeValue = () => {
    return `${accounting.formatMoney(this._getTotalValues().chargeValue)}`;
  };

  /**
   * Total Neto
   *
   * @memberof ProductDetail
   */
  _getTotalNeto = () => {
    return `${accounting.formatMoney(this._getTotalValues().totalNeto)}`;
  };

  /**
   * Function to toggle item favorite
   *
   * @memberof ProductDetail
   */
  _removeItem = () => {
    this.Loader.openLoader(async () => {
      const response = await removeFromShoppingCart(
        this.props.auth,
        this.state.item,
        this.state.factors.selectedObject,
      );
      this.Loader.closeLoader(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          this.props.navigation.goBack();
        } else {
          showAlert(response, 'danger');
        }
      });
    });
  };

  /**
   * Function to add item to shopping cart
   *
   * @memberof ProductDetail
   */
  _addToCart = () => {
    if (
      this.state.quantity &&
      parseFloat(this.state.quantity) > 0 &&
      parseFloat(this.state.quantity) >= this.state.factors.selectedObject.minimum_amount
    ) {
      this.Loader.openLoader(async () => {
        const response = await addToShoppingCart(
          this.props.auth,
          this.state.item,
          this.state.quantity,
          this.state.factors.selectedObject,
        );
        this.Loader.closeLoader(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (response.success) {
            if (this.props.route.params?.backRoute) {
              this.props.navigation.navigate(this.props.route.params?.backRoute);
            } else {
              this.props.navigation.goBack();
            }
          } else {
            showAlert(response, 'danger');
          }
        });
      });
    } else {
      this.ModalMininumAmount.openModalMininumAmount();
    }
  };

  /**
   * Function to open product image gallery
   *
   * @memberof ProductDetailScreen
   */
  _onPressImage = () => {
    this.props.navigation.navigate(routes.productImageGallery, {
      backRoute: routes.productsDetail,
      item: this.state.item,
      originalItem: this.state.originalItem,
      backRouteParams: {
        backRoute: this.props.route.params?.backRoute,
        item: this.state.item,
        originalItem: this.state.originalItem,
      },
    });
  };

  _rightComponent = () =>
    this.state.item && this.state.item.inShoppingCart ? (
      <TouchableOpacity style={styles.iconTrash} onPress={() => this._removeItem()}>
        <IconMoon name={icons.icTrash} size={ICON_HEART_SIZE} color={colors.primary} />
      </TouchableOpacity>
    ) : undefined;

  _continueshopping = () => {
    this.props.navigation.navigate(routes.productsDetail, {
      backRoute: routes.shoppingCart,
    });
  };

  /**
   * Render sign in view
   *
   * @returns
   * @memberof ProductDetailScreen
   */
  render() {
    return (
      <>
        <ModalMininumAmount
          onRef={(ref) => (this.ModalMininumAmount = ref)}
          title={
            strings.messageMininumAmount +
            this.state.factors.selectedObject.minimum_amount +
            ' ' +
            this.state.factors.selectedObject.description +
            ', ' +
            strings.messageMininumAmount2
          }
          subtitle={''}
          action={this._continueshopping}
          actionText={'EDITAR'}
        />
        <HeaderSideDrawer
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
          showBack={true}
          navigation={this.props.navigation}
          route={this.props.route}
          rightComponent={this._rightComponent}
          paddingTop={13}
        />
        <SafeAreaView style={styles.container}>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <ScrollView bounces={false}>
            <View style={styles.containerScrollView}>
              <TouchableOpacity onPress={this._onPressImage}>
                <View style={styles.containerImage}>
                  <Image style={styles.image} source={this._getImage()} resizeMode="contain" />
                  <View style={styles.containerFavorite}>
                    <TouchableOpacity onPress={this._onPressFavorite}>
                      <IconMoon
                        name={icons.icHeart}
                        size={ICON_HEART_SIZE}
                        color={this.state.originalItem.is_favorite === 'Y' ? colors.primary : colors.gray}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </TouchableOpacity>
              <View style={styles.containerData}>
                <View style={styles.containerItemData}>
                  <Text style={styles.productDescription}>{this._getProductDescription()}</Text>
                  <Text style={styles.productCode}>
                    {strings.productCode}
                    {this._getProductCode()}
                  </Text>
                </View>
                <View style={styles.containerPrice}>
                  <View style={styles.containerBorderPrice}>
                    <Text style={styles.newPrice}>{this._getPriceByFactor()}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.containerBottom}>
                <View style={styles.containerDropdown}>
                  <InputSelectForm
                    placeholder={strings.placeholderSelect}
                    label={strings.labelSelect}
                    items={this.state.factors.options}
                    onChangeText={(value: any, index: number) => {
                      if (value) {
                        const { factors } = this.state;
                        this.setState({
                          factors: {
                            ...factors,
                            selectedValue: value,
                            selectedObject: factors.options[index - 1],
                          },
                        });
                      }
                    }}
                    value={this.state.factors.selectedValue}
                  />
                </View>
                <View style={styles.containerButtons}>
                  <TouchableOpacity style={styles.buttonsDec} onPress={this._decrementQuantity}>
                    <IconMoon name={icons.icMinus} size={ICON_SHOPPING_SIZE} color={colors.white} />
                  </TouchableOpacity>
                  <TextInput
                    style={styles.textInputQuantity}
                    keyboardType="numeric"
                    placeholder="Cantidad"
                    onChangeText={(val) => this._onChangeQuantity(val)}
                    value={this.state.quantity}
                  />
                  <TouchableOpacity style={styles.buttonsInc} onPress={this._incrementQuantity}>
                    <IconMoon name={icons.icPlus} size={ICON_SHOPPING_SIZE} color={colors.white} />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.containerObservation}>
                <Text style={styles.labelObservation}>{strings.labelObservation}</Text>
              </View>
              <View style={styles.containerDescription}>
                <TextInput
                  editable={false}
                  style={styles.textInputDescription}
                  value={this.state.item.observations}
                  multiline={true}
                  maxLength={500}
                />
              </View>
              <View style={styles.containerButtonAdd}>
                <TouchableOpacity style={styles.buttonAdd} onPress={this._addToCart}>
                  <IconMoon name={icons.icShopping} size={ICON_SHOPPING_SIZE} color={colors.white} />
                  <Text style={styles.buttonAddText}>
                    {this.state.updateCart ? strings.updateItem : strings.addItem}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.titleCartSection}>
                <Text style={styles.titleSection}>{strings.total}</Text>
              </View>
              <View style={styles.containerAllTotals}>
                <View style={styles.containerTotals}>
                  <View style={styles.containerTotalsLeft}>
                    <Text style={styles.totalsLabel}>{strings.priceWithoutTaxes}</Text>
                  </View>
                  <View style={styles.containerTotalsRight}>
                    <Text style={styles.totalsValue}>{this._getPriceWithoutTaxes()}</Text>
                  </View>
                </View>
                <View style={styles.containerTotals}>
                  <View style={styles.containerTotalsLeft}>
                    <Text style={styles.totalsLabel}>
                      {strings.taxes} {`(${this.state.item.tax}%)`}
                    </Text>
                  </View>
                  <View style={styles.containerTotalsRight}>
                    <Text style={styles.totalsValue}>{this._getTaxes()}</Text>
                  </View>
                </View>
                <View style={styles.containerTotals}>
                  <View style={styles.containerTotalsLeft}>
                    <Text style={styles.totalsLabel}>{strings.priceChargeValue}</Text>
                  </View>
                  <View style={styles.containerTotalsRight}>
                    <Text style={styles.totalsValue}>{this._getChargeValue()}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.containerTotalNeto}>
                <View style={styles.containerBorder}>
                  <View style={styles.containerValues}>
                    <Text style={styles.totalNetoLabel}>{strings.total}:</Text>
                    <Text style={styles.totalNetoValue}>{this._getTotalNeto()}</Text>
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      </>
    );
  }
}

ProductDetailScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

ProductDetailScreen.defaultProps = {};

/** Export component ProductDetailScreen **/
export default connect((state) => ({ ...state }))(ProductDetailScreen);
