/** Styles to Logo **/
import { Dimensions, StyleSheet } from 'react-native';

/** Size Screen **/
const { height } = Dimensions.get('window');
const DEVICE_HEIGHT = height;

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    marginTop: 58,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  logo: {
    marginTop: (DEVICE_HEIGHT * 0.1) / 2,
    maxWidth: 200,
    width: 200,
    height: 210,
  },
});

/** Export component styles **/
export default styles;
