/** Routes to App  **/
export const routes = {
  login: 'LoginScreen',
  loginWhatsapp: 'LoginScreenWhatsapp',
  companiesList: 'CompaniesListScreen',
  branchOfficesList: 'BranchOfficesListScreen',
  drawerCompaniesList: 'DrawerCompaniesListScreen',
  drawerBranchOfficesList: 'DrawerBranchOfficesListScreen',
  order: 'OrderScreen',
  tabProducts: 'TabProductsScreen',
  products: 'ProductsScreen',
  tabFavorites: 'TabFavoritesScreen',
  favorites: 'FavoritesScreen',
  tabShoppingCart: 'TabShoppingCartScreen',
  shoppingCart: 'ShoppingCartScreen',
  checkout: 'CheckoutScreen',
  productsDetail: 'ProductsDetailScreen',
  productImageGallery: 'ProductImageGalleryScreen',
  shoppingCartDetail: 'ShoppingCartDetailScreen',
  profile: 'ProfileScreen',
  shoppingHistory: 'ShoppingHistoryScreen',
  shoppingHistoryDetail: 'ShoppingHistoryDetailScreen',
  portfolio: 'PortfolioScreen',
  logout: 'LogoutScreen',
  deliveryHistory: 'DeliveryHistoryScreen',
};

/** Export strings **/
export const linking = {
  prefixes: [],
  config: {
    screens: {
      LoginScreen: '/:phone?',
      LoginScreenWhatsapp: 'IniciarWhatsapp/:user_id/:session_code/:route_name/:primary_color?/:logo_image?',
      ProfileScreen: 'Perfil',
      OrderScreen: 'ListaProductos',
      ShoppingCartScreen: 'ResumenDeCompra',
      CheckoutScreen: 'ConfirmacionDePedido',
      productImageGallery: 'ImagenesDeProducto',
    },
  },
};
