/** Import modules **/
import React, { Component } from 'react';
import { Text, View, Image, FlatList, TouchableOpacity, TextInput } from 'react-native';
import PropTypes from 'prop-types';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Components **/
import { IconMoon } from './../icon/IconMoon';

/** Import constants **/
import colors from './../../constants/styles/colors';
import icons from './../../constants/icons/icons';
import styles from './style';

import { SafeAreaProvider } from 'react-native-safe-area-context';

/** Const to define size in icons **/
const ICON_BACK_SIZE = 20;

interface Props {
  selectedValue: (item: any, index: number) => void;
  placeHolderLabel?: any;
  selectedLabel?: any;
  pickerTitle?: any;
  dataSource?: any;
  dummyDataSource?: any;
  dropDownImage?: number;
  defaultSelected?: any;
  defaultValue?: boolean;
  showSearchBar?: boolean;
  showPickerTitle?: boolean;
  disablePicker?: boolean;
  changeAnimation?: string;
  dropDownImageStyle?: any;
  selectLabelTextStyle?: any;
  placeHolderTextStyle?: any;
  textStyle?: any;
  pickerStyle?: any;
}

interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  selectedFlag?: boolean;
  dataSource?: Array<any>;
}

/**
 * Class to override and create a new TextInput with style
 *
 * @export
 * @class ModalPicker
 *
 * @extends {Component<Props, State>}
 */
class ModalPicker extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public iteration = 0;

  /**
   *Creates an instance of ModalPicker.
   * @param {Props} props
   * @memberof ModalPicker
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
      selectedFlag: this.props.defaultValue,
      dataSource: [],
    };
  }

  /**
   *
   *
   * @memberof ModalPicker
   */
  componentDidMount() {
    this._initState();
  }

  /**
   *
   *
   * @memberof ModalPicker
   */
  _initState = () => {
    this.setState({ dataSource: this.props.dataSource });
  };

  /**
   *
   *
   * @param {*} defaultText
   * @param {*} pickerStyle
   * @param {*} textStyle
   * @param {*} dropDownImageStyle
   * @param {number} dropDownImage
   * @returns
   * @memberof ModalPicker
   */
  _setDefaultValue(
    defaultText: any,
    pickerStyle: any,
    textStyle: any,
    dropDownImageStyle: any,
    dropDownImage?: number,
  ) {
    return (
      <View style={pickerStyle}>
        <Text style={textStyle}>{defaultText}</Text>
        {dropDownImage ? <Image style={dropDownImageStyle} resizeMode="contain" source={dropDownImage} /> : null}
      </View>
    );
  }

  /**
   *
   *
   * @param {*} defaultText
   * @param {*} pickerStyle
   * @param {*} textStyle
   * @param {*} dropDownImageStyle
   * @param {number} dropDownImage
   * @returns
   * @memberof ModalPicker
   */
  _setSelectedValue(
    defaultText: any,
    pickerStyle: any,
    textStyle: any,
    dropDownImageStyle: any,
    dropDownImage?: number,
  ) {
    return (
      <View style={pickerStyle}>
        <Text style={textStyle}>{defaultText}</Text>
        {dropDownImage ? <Image style={dropDownImageStyle} resizeMode="contain" source={dropDownImage} /> : null}
      </View>
    );
  }

  /**
   *
   *
   * @param {string} searchText
   * @param {*} data
   * @memberof ModalPicker
   */
  _searchFilterFunction(searchText: string, data: any) {
    this.iteration = 1;
    let newData = [];
    if (searchText) {
      newData = data.filter(function (item: any) {
        const itemData = item.value.toUpperCase();
        const textData = searchText.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      this.setState({
        dataSource: [...newData],
      });
    } else {
      this.setState({ dataSource: this.props.dataSource });
    }
  }

  /**
   *
   *
   * @param {*} item
   * @param {number} index
   * @returns
   * @memberof ModalPicker
   */
  _renderItemListValues(item: any, index: number) {
    return (
      <TouchableOpacity
        key={index}
        activeOpacity={1}
        style={styles.listRowClickTouchStyle}
        onPress={() => this._setSelectedIndex(item.id, item.value)}
      >
        <View style={styles.listRowContainerStyle}>{item.name}</View>
      </TouchableOpacity>
    );
  }

  /**
   *
   *
   * @param {number} id
   * @param {string} name
   * @memberof ModalPicker
   */
  _setSelectedIndex(id: number, name: string) {
    if (this.iteration === 0) {
      this.iteration = 0;
      this.props.selectedValue(name, 0);

      this.setState({ selectedFlag: true });
      this.setState({ isVisible: false });
    } else {
      this.iteration = 0;
      this.props.selectedValue(name, id);

      this.setState({ selectedFlag: true });
      this.setState({ isVisible: false });
    }
  }

  /**
   * Render component with Text and RNPickerSelect
   *
   * @returns
   * @memberof ModalPicker
   */
  render() {
    return (
      <View style={styles.mainContainer}>
        {this.state.selectedFlag ? (
          <TouchableOpacity disabled={this.props.disablePicker} onPress={() => this.setState({ isVisible: true })}>
            <View>
              {this._setSelectedValue(
                this.props.selectedLabel,
                this.props.pickerStyle,
                this.props.selectLabelTextStyle,
                this.props.dropDownImageStyle,
                this.props.dropDownImage,
              )}
            </View>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity disabled={this.props.disablePicker} onPress={() => this.setState({ isVisible: true })}>
            <View>
              {this._setDefaultValue(
                this.props.placeHolderLabel,
                this.props.pickerStyle,
                this.props.placeHolderTextStyle,
                this.props.dropDownImageStyle,
                this.props.dropDownImage,
              )}
            </View>
          </TouchableOpacity>
        )}
        <Modal
          backdropOpacity={this.state.backdropOpacity}
          animationIn={this.state.animationIn}
          isVisible={this.state.isVisible}
          avoidKeyboard={true}
          style={styles.modal}
          onModalHide={() => this.setState({ isVisible: false })}
        >
          <SafeAreaProvider>
            <View style={styles.modalBackground}>
              <View style={styles.listDataContainerStyle}>
                <TouchableOpacity style={styles.iconTimes} onPress={() => this.setState({ isVisible: false })}>
                  <IconMoon name={icons.icClose} size={ICON_BACK_SIZE} color={colors.black} />
                </TouchableOpacity>
                <View style={styles.pickerTitleContainerStyle}>
                  {this.props.showPickerTitle ? (
                    <Text style={styles.pickerTitleTextStyle}>{this.props.pickerTitle}</Text>
                  ) : null}
                </View>
                {this.props.showSearchBar ? (
                  <View style={styles.searchBarContainerStyle}>
                    <TextInput
                      onChangeText={(text) => this._searchFilterFunction(text, this.props.dummyDataSource)}
                      placeholder={'Buscar...'}
                      style={styles.textInputStyle}
                      placeholderTextColor={colors.grayPlaceholder}
                      underlineColorAndroid="transparent"
                      keyboardType="default"
                      returnKeyType={'default'}
                      blurOnSubmit={true}
                    />
                  </View>
                ) : null}
                <FlatList
                  style={styles.flatListStyle}
                  keyExtractor={(item) => item.id}
                  showsVerticalScrollIndicator={false}
                  extraData={this.state}
                  overScrollMode="never"
                  keyboardShouldPersistTaps="always"
                  numColumns={1}
                  data={this.state.dataSource}
                  renderItem={({ item, index }) => this._renderItemListValues(item, index)}
                  ItemSeparatorComponent={() => <View style={styles.itemSeparatorStyle} />}
                  bounces={false}
                />
              </View>
            </View>
          </SafeAreaProvider>
        </Modal>
      </View>
    );
  }
}

ModalPicker.propTypes = {
  placeHolderLabel: PropTypes.any,
  selectedLabel: PropTypes.any,
  pickerTitle: PropTypes.any,
  dataSource: PropTypes.any,
  dummyDataSource: PropTypes.any,
  dropDownImage: PropTypes.number,
  defaultSelected: PropTypes.any,
  defaultValue: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  showPickerTitle: PropTypes.bool,
  disablePicker: PropTypes.bool,
  changeAnimation: PropTypes.string,
  dropDownImageStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  selectLabelTextStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  placeHolderTextStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  textStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  pickerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  selectedValue: PropTypes.func,
};

ModalPicker.defaultProps = {
  defaultValue: false,
  showSearchBar: false,
  showPickerTitle: false,
  disablePicker: false,
  changeAnimation: 'slide',
  dropDownImage: require('./res/ic_drop_down.png'),
  placeHolderLabel: 'Please select value from picker',
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectLabelTextStyle: {
    color: colors.black,
    textAlign: 'left',
    width: '100%',
    paddingTop: 5,
    paddingRight: 14,
    paddingBottom: 5,
    paddingLeft: 14,
    flexDirection: 'row',
  },
  placeHolderTextStyle: {
    color: colors.grayPlaceholder,
    paddingTop: 5,
    paddingRight: 14,
    paddingBottom: 5,
    paddingLeft: 14,
    textAlign: 'left',
    width: '100%',
    flexDirection: 'row',
  },
  dropDownImageStyle: {
    marginLeft: 10,
    width: 10,
    height: 10,
    alignSelf: 'center',
  },
  pickerStyle: {
    paddingRight: 25,
    marginRight: 10,
    marginBottom: 2,
    borderColor: '#303030',
    borderRadius: 5,
    flexDirection: 'row',
  },
};

/** Export component ModalPicker **/
export default ModalPicker;
