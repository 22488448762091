/** Import modules **/
import React, { Component } from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';

/** Import RNPickerSelect **/
import RNPickerSelect from 'react-native-picker-select';

/** Import RNPicker **/
import ModalPicker from './../modalPicker';

/** Import Constants **/
import colors from './../../constants/styles/colors';
import stringsConstants from './../../constants/strings/strings';
import styles from './style';

interface Props {
  label: string;
  items: Array<any>;
  onChangeText: any;
  placeholder?: string;
  value?: number | string | undefined;
  activeError?: boolean | undefined;
  disabled?: boolean;
  showSearchBar?: boolean;
  pickerTitle?: string;
  searchBarPlaceHolder?: string;
  colorText?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

/**
 * Class to override and create a new TextInput with style
 *
 * @export
 * @class InputSelectForm
 *
 * @extends {Component<Props, State>}
 */
class InputSelectForm extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   * Function to call onChangeText from imported view
   *
   * @memberof InputSelectForm
   */
  _onChangeTextModal = (val: string, index: number) => {
    if (this.props.onChangeText) {
      this.props.onChangeText(val, index);
    }
  };

  /**
   * Function to call onChangeText from imported view
   *
   * @memberof InputSelectForm
   */
  _onChangeTextPicker = (val: string, index: number) => {
    if (this.props.onChangeText) {
      this.props.onChangeText(val, index);
    }
  };

  /**
   * Render component with Text and RNPickerSelect
   *
   * @returns
   * @memberof InputSelectForm
   */
  render() {
    return (
      <View
        style={[
          styles.containerInput,
          this.props.activeError ? styles.containerInputError : {},
          { borderBottomColor: this.props.colorText ? this.props.colorText : colors.grayPlaceholder },
        ]}
      >
        <Text
          style={[
            styles.labelTextRegular,
            this.props.activeError
              ? styles.labelTextError
              : this.props.colorText
              ? { color: this.props.colorText }
              : styles.labelText,
          ]}
        >
          {this.props.label}
        </Text>
        {this.props.showSearchBar ? (
          <ModalPicker
            dataSource={this.props.items}
            dummyDataSource={this.props.items}
            defaultValue={false}
            pickerTitle={this.props.pickerTitle ? this.props.pickerTitle : ''}
            showSearchBar={true}
            disablePicker={false}
            changeAnimation={'none'}
            showPickerTitle={true}
            selectedLabel={this.props.value}
            placeHolderLabel={this.props.placeholder}
            selectedValue={(val: any, index: number) => this._onChangeTextModal(val, index)}
          />
        ) : (
          <RNPickerSelect
            placeholder={{
              label: this.props.placeholder,
              value: null,
              color: colors.black,
            }}
            items={this.props.items}
            onValueChange={(val: any, index: number) => this._onChangeTextPicker(val, index)}
            value={this.props.value}
            useNativeAndroidPickerStyle={false}
            textInputProps={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              style: [styles.inputText, this.props.colorText ? { color: this.props.colorText } : styles.inputTextColor],
            }}
            disabled={this.props.disabled}
            doneText={stringsConstants.done}
          />
        )}
      </View>
    );
  }
}

InputSelectForm.propTypes = {
  label: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  activeError: PropTypes.bool,
  disabled: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  pickerTitle: PropTypes.string,
  searchBarPlaceHolder: PropTypes.string,
  colorText: PropTypes.string,
};

InputSelectForm.defaultProps = {
  disabled: false,
  showSearchBar: false,
};

/** Export component InputSelectForm **/
export default InputSelectForm;
