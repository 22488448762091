/** Styles to Button **/
import { StyleSheet, Dimensions } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

const { height } = Dimensions.get('window');
const LIST_HEIGHT = height - 45 - 60;

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 5,
  },
  portafolioItemContainer: {
    padding: 5,
    width: '99%',
    height: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    marginLeft: 2,
    marginBottom: 10,
    border: '1px solid #E3E0E0',
    display: 'flex',
  },
  itemPortafoliotDetailContainer: {
    flexDirection: 'column',
    width: '90%',
    borderWidth: 0,
  },
  containerInfoDate: {
    marginLeft: 5,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  containerInfoDocument: {
    marginLeft: 5,
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    justifyContent: 'flex-start',
  },
  orderSoldCode: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#1C1564',
  },
  documentDate: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: 5,
    color: '#1C1564',
  },
  orderSoldDateLabel: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  orderSoldDate: {
    color: colors.grayTextDescription,
  },
  stateAndPriceContainer: {
    flexDirection: 'row',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  stateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    marginRight: 10,
    width: 100,
  },
  priceContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  priceText: {
    fontWeight: 'bold',
  },
  state: {
    borderColor: 'red',
    padding: 4,
    color: colors.white,
    fontSize: 10,
  },
  stateSuccess: {
    backgroundColor: colors.stateSuccess,
  },
  statePending: {
    backgroundColor: colors.statePending,
  },
  stateFailure: {
    backgroundColor: colors.primary,
  },
  stateDelivered: {
    backgroundColor: colors.delivered,
  },
  dateContainer: {
    marginTop: 10,
  },
  dateContainerSaldo: {
    marginLeft: 5,
  },
  datecurrentDebt: {
    marginLeft: 8,
  },
  dateContainerinvoice: {
    marginLeft: 0,
  },
  dateContainerExpires: {
    marginTop: 10,
    marginLeft: 28,
  },
  expiredDays: {
    marginTop: 10,
    marginLeft: 25,
  },
  date: {
    marginTop: 5,
    marginLeft: 5,
    flexDirection: 'row',
  },
  imageIconOrderSold: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    marginRight: 5,
  },
  image: {
    width: 26,
    height: 36,
  },
  orderSourceLabel: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  containerDetayl: {
    borderRadius: 14,
  },
  flexOne: {
    height: LIST_HEIGHT,
  },
  text_truncate: {
    width: 90,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  expireColordDays: {
    color: '#2ECC71',
  },
  expirationDateRed: {
    color: '#ED1C24',
  },
});

/** Export component styles **/
export default styles;
