/** Import React **/
import React, { Component } from 'react';
import { View, Text } from 'react-native';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Components **/
import Button from '../button';
import Loader from '../loader';

/** Import constants **/
import stringsConstants from '../../constants/strings/strings';
import styles from './style';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

import { SafeAreaProvider } from 'react-native-safe-area-context';

interface Props {
  onRef: (ref: any) => void;
  title: string;
  subtitle: string;
  action: () => void;
  actionText: string;
  auth?: Auth;
}

interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  callBackClose?: () => void | null;
}

/**
 * Create Modal with password
 *
 * @param {*} props
 * @returns
 */
class ModalConfirm extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   *Creates an instance of ModalConfirm.
   * @param {*} props
   * @memberof ModalConfirm
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
    };
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalConfirm
   */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalConfirm
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  /**
   * Open alert
   *
   * @memberof ModalConfirm
   */
  openModalConfirm = () => {
    this.setState({
      isVisible: true,
    });
  };

  /**
   * Close alert
   *
   * @memberof ModalConfirm
   */
  closeModalConfirm = () => {
    this.setState({
      isVisible: false,
    });
  };

  /**
   * Function to log out
   *
   * @memberof ModalConfirm
   */
  _callAction = () => {
    this.setState({ callBackClose: this.props.action }, () => this.closeModalConfirm());
  };

  /**
   * Render ModalConfirm in modal
   *
   * @returns
   * @memberof ModalConfirm
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        avoidKeyboard={true}
        style={styles.modal}
        onModalHide={() => this.state.callBackClose && this.state.callBackClose()}
      >
        <SafeAreaProvider>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <View style={styles.modalBackground}>
            <View style={styles.modalView}>
              <Text style={styles.modalTitle}>{this.props.title}</Text>
              <Text style={styles.modalSubTitle}>{this.props.subtitle}</Text>
              <View style={styles.containerButtons}>
                <Button
                  onPress={this.closeModalConfirm}
                  buttonClass={styles.buttonCancel}
                  buttonLabelText={stringsConstants.cancel}
                  buttonLabelTextClass={styles.buttonTextCancel}
                />
                <Button
                  onPress={this._callAction}
                  buttonClass={styles.buttonConfirm}
                  buttonLabelText={this.props.actionText}
                  buttonLabelTextClass={styles.buttonTextConfirm}
                />
              </View>
            </View>
          </View>
        </SafeAreaProvider>
      </Modal>
    );
  }
}

ModalConfirm.propTypes = {
  auth: AuthPropTypes,
};

ModalConfirm.defaultProps = {};

/** Export component ModalConfirm **/
export default connect((state) => ({ ...state }))(ModalConfirm);
