/** Import React **/
import { Platform } from 'react-native';

/** Styles to InputTextForm **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  containerInput: {
    backgroundColor: colors.transparent,
    //borderBottomWidth: 1,
    width: '100%',
    paddingBottom: 5,
  },
  containerInputError: {
    borderBottomColor: colors.danger,
    borderBottomWidth: 1,
  },
  labelTextRegular: {
    fontSize: 12,
  },
  labelText: {
    color: colors.grayPlaceholder,
  },
  labelTextError: {
    color: colors.danger,
  },
  inputText: {
    marginTop: Platform.OS === 'ios' ? 10 : 0,
    padding: 0,
    fontSize: 13,
  },
  inputTextColor: {
    color: colors.black,
  },
});

/** Export component style **/
export default styles;
