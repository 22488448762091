/** Styles to view **/
import { StyleSheet, Dimensions } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Create const to dimension **/
const { width, height } = Dimensions.get('window');
const DEVICE_WIDTH = width;

/** Create an object style **/
const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  scrollView: {
    flex: 1,
  },
  containerInputs: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginImageBackground: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    width: '100vw',
    height: height,
  },
  loginButton: {
    backgroundColor: colors.primary,
    width: DEVICE_WIDTH - 55,
    marginBottom: 20,
  },
  loginFromWhatsappButton: {
    backgroundColor: 'rgb(20, 100, 44)',
    width: DEVICE_WIDTH - 55,
    marginBottom: 20,
  },
  loginForgetPasswordText: {
    color: colors.white,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  loginLabelButtonTextClass: {
    color: colors.white,
  },
  reGenerateCode: {
    backgroundColor: 'transparent',
    width: DEVICE_WIDTH - 55,
    marginBottom: 20,
  },
  reGenerateCodeLabelButtonTextClass: {
    color: colors.primary,
    borderBottomWidth: 2,
    borderColor: colors.primary,
  },
  containerInput: {},
  containerInputError: {},
  labelTextRegular: {
    color: colors.black,
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: 10,
  },
  labelText: {
    color: colors.black,
  },
  labelTextError: {
    color: colors.danger,
  },
});

/** Export component styles **/
export default styles;
