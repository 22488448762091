/** Import React **/
import React, { Component } from 'react';
import { View, Text, Image, ScrollView, Platform, TouchableOpacity, TextInput, Dimensions } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import components **/
import HeaderSideDrawer from '../../components/headerSideDrawer';
import Loader from '../../components/loader';
// import { IconMoon } from './../../components/icon/IconMoon';
import ModalPassword from '../../components/modalPassword';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from '../../constants/styles/colors';
// import icons from './../../constants/icons/icons';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

/** Const to define size in icons **/
// const ICON_SIZE = 24;

/** Import Images **/
import userImage from '../../web_assets/images/user.png';

import { IconMoon } from '../../components/icon/IconMoon';
import icons from './../../constants/icons/icons';

/** Phone Number Input **/
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';
import ModalConfirmWhatsapp from '../../components/modalConfirmWhatsapp';

/** Import Api **/
import { apiPost } from '../../api';
import { urlEditProfile, urlEditProfilePhone, urlResendTokenProfile } from '../../api/urls';

/** Import Slices **/
import { store } from '../../store';
import { updateSesionProfile } from '../../slices/auth/authSlice';

let USER = { uri: `` };
const ICON_SHOPPING_SIZE = 20;

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  USER = userImage;
} else if (Platform.OS === 'ios') {
  USER = { uri: `${assets.user}` };
} else {
  USER = { uri: `@mipmap/${assets.user}` };
}

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {
  name: string;
  editFields: boolean;
  username: string;
  userEmail: string;
  messageALert: string;
}

const { width } = Dimensions.get('window');

/**
 * Class to export ProfileScreen
 *
 * @class ProfileScreen
 * @extends {Component<Props, State>}
 */
class ProfileScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public ModalPassword!: any;
  public ModalConfirmWhatsapp!: any;
  public Loader!: any;

  /**
   * Creates an instance of ProfileScreen.
   * @param {*} props
   * @memberof ProfileScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      name: `${this._getCurrentUser().user_name}`,
      editFields: false,
      username: `${this._getCurrentUser().username}`,
      userEmail: `${this._getCurrentUser().user_email}`,
      messageALert: '',
    };
  }

  /**
   * Handle getCurrentUser and return currentUser
   *
   * @memberof ProfileScreen
   */
  _getCurrentUser = () => {
    if (this.props.auth && this.props.auth.user) {
      return this.props.auth.user;
    } else {
      return { user_name: '', user_email: '', username: '' };
    }
  };

  /**
   * Function to change password
   *
   * @memberof ProfileScreen
   */
  _changePassword = () => {
    this.ModalPassword.openModalPassword(() =>
      showAlert({ error: `${strings.passwordInfo}: ${strings.passwordUpdated}` }, 'success'),
    );
  };

  _updateEditFields(value: boolean) {
    this.setState({
      editFields: value,
      name: `${this._getCurrentUser().user_name}`,
      username: `${this._getCurrentUser().username}`,
      userEmail: `${this._getCurrentUser().user_email}`,
    });
  }

  /**
   * Function to get rshop_id
   *
   * @memberof ProductsScreen
   */
  _getRshop_id = () => {
    return this.props.auth && this.props.auth.current_rshop && this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  _updateFields = () => {
    let showModal = false;
    if (this.props.auth && this.props.auth.user?.username == this.state.username) {
      showModal = false;
    } else {
      showModal = true;
      if (this.state.username.charAt(0) != '+') {
        this.setState({
          username: '+' + this.state.username,
        });
      }
    }
    if (this.state.username.length == 0 || this.state.userEmail.length == 0 || this.state.name.length == 0) {
      showAlert(
        {
          message: 'Todos los campos son obligatorios',
        },
        'danger',
      );
    } else {
      this.Loader.openLoader(async () => {
        const urlEditProfileReplaced = urlEditProfile.replace(/\[rshop_id\]/g, String(this._getRshop_id()));
        const data = {
          csrfmiddlewaretoken: this.props.auth?.csrftoken,
          name: this.state.name,
          email: this.state.userEmail,
          username: this.state.username,
        };
        try {
          const response = await apiPost(urlEditProfileReplaced, data)();
          if (response.success) {
            this.Loader.closeLoader();
            if (showModal) {
              this.setState({
                messageALert: '',
              });
              this.ModalConfirmWhatsapp.openModalConfirmWhatsapp();
            } else {
              store.dispatch(
                updateSesionProfile({
                  user_email: this.state.userEmail,
                  user_name: this.state.name,
                  username: this.state.username,
                }),
              );
              this._updateEditFields(false);
              showAlert(response, 'success');
            }
          } else {
            this.Loader.closeLoader(() =>
              showAlert(response, 'danger', () => {
                console.log('');
              }),
            );
          }
        } catch (error) {
          if (error.message) {
            error.message += ' (006).';
          }
          this.Loader.closeLoader(() => showAlert(error, 'danger'));
        }
      });
    }
  };

  _continueEditProfile = () => {
    this.Loader.openLoader(async () => {
      const urlEditProfilePhoneReplaced = urlEditProfilePhone.replace(/\[rshop_id\]/g, String(this._getRshop_id()));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
        token: this.ModalConfirmWhatsapp.state.verificationCode,
      };
      try {
        const response = await apiPost(urlEditProfilePhoneReplaced, data)();
        if (response.success) {
          this.Loader.closeLoader();
          store.dispatch(
            updateSesionProfile({
              user_email: this.state.userEmail,
              user_name: this.state.name,
              username: this.state.username,
            }),
          );
          this._updateEditFields(false);
          showAlert(response, 'success');
        } else {
          if (response.form_errors.token == 'codigo_caduco') {
            this.setState({
              messageALert:
                'El código anterior ya no es válido, por favor reenvía el código para confirmar los cambios.',
            });
            this.Loader.closeLoader(() => this.ModalConfirmWhatsapp.openModalConfirmWhatsapp());
          } else {
            this.Loader.closeLoader(() =>
              showAlert(response, 'danger', () => {
                console.log('');
              }),
            );
          }
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (006).';
        }
        this.Loader.closeLoader(() => showAlert(error, 'danger'));
      }
    });
  };

  _resendTokenProfile = () => {
    this.Loader.openLoader(async () => {
      const urlResendTokenProfileReplaced = urlResendTokenProfile.replace(/\[rshop_id\]/g, String(this._getRshop_id()));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
      };
      try {
        const response = await apiPost(urlResendTokenProfileReplaced, data)();
        if (response.success) {
          this.Loader.closeLoader();
          this.setState({
            messageALert: '',
          });
          this.ModalConfirmWhatsapp.openModalConfirmWhatsapp();
        } else {
          this.Loader.closeLoader(() =>
            showAlert(response, 'danger', () => {
              console.log('');
            }),
          );
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (006).';
        }
        this.Loader.closeLoader(() => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Render sign in view
   *
   * @returns
   * @memberof ProfileScreen
   */
  render() {
    return (
      <>
        <Loader onRef={(ref) => (this.Loader = ref)} />
        <ModalPassword onRef={(ref) => (this.ModalPassword = ref)} />
        <ModalConfirmWhatsapp
          onRef={(ref) => (this.ModalConfirmWhatsapp = ref)}
          title={strings.textWhatsapp}
          action={this._continueEditProfile}
          actionResend={this._resendTokenProfile}
          actionText={strings.actionButton}
          messageAlert={this.state.messageALert}
        />
        <HeaderSideDrawer
          navigation={this.props.navigation}
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
        />
        <SafeAreaView style={styles.container}>
          <View style={styles.imageContainer}>
            <Image style={styles.profileImage} source={USER} />
          </View>
          <ScrollView bounces={false}>
            <View style={styles.containerData}>
              <Text style={styles.title}>{strings.personalInfo}</Text>
            </View>
            {!this.state.editFields ? (
              <View style={styles.containerEditButtons}>
                <TouchableOpacity style={styles.buttonsEdit} onPress={() => this._updateEditFields(true)}>
                  <IconMoon
                    name={icons.icEdit}
                    style={styles.iconMoon}
                    size={ICON_SHOPPING_SIZE}
                    color={colors.white}
                  />
                  <Text style={styles.textEdit}>Editar</Text>
                </TouchableOpacity>
              </View>
            ) : null}
            <View
              style={[
                styles.containerInfo,
                this.state.editFields ? { borderBottomWidth: 0 } : { borderBottomWidth: 1 },
              ]}
            >
              <Text style={styles.label}>{strings.fullname}</Text>
              <TextInput
                style={[
                  styles.value,
                  this.state.editFields ? { borderWidth: 1, backgroundColor: colors.white } : { borderWidth: 0 },
                ]}
                value={this.state.name}
                onChangeText={(val) => this.setState({ name: val })}
                editable={this.state.editFields}
              />
            </View>
            <Text
              style={[
                styles.containerInfo,
                this.state.editFields ? { borderBottomWidth: 0 } : { borderBottomWidth: 1 },
              ]}
            >
              <Text style={styles.label}>{strings.mobile_number}</Text>
              <PhoneInput
                country={'co'}
                localization={es}
                value={this.state.username}
                placeholder={strings.placeholderTextEmail}
                onChange={(val) => this.setState({ username: val })}
                inputProps={{
                  required: true,
                }}
                enableSearch={true}
                searchPlaceholder={strings.searchCountryPlaceholder}
                searchNotFound={strings.searchCountryNotFound}
                priority={{ co: 0 }}
                buttonStyle={{
                  height: 45,
                }}
                inputStyle={{
                  borderColor: this.state.editFields ? colors.black : colors.softGray,
                  height: 44,
                  width: width - 120,
                  borderWidth: 1,
                  color: colors.black,
                  backgroundColor: this.state.editFields ? colors.white : 'rgba(0, 0, 0, 0)',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
                disabled={!this.state.editFields}
              />
            </Text>
            <View
              style={[
                styles.containerInfo,
                this.state.editFields ? { borderBottomWidth: 0 } : { borderBottomWidth: 1 },
              ]}
            >
              <Text style={styles.label}>{strings.email}</Text>
              <TextInput
                style={[
                  styles.value,
                  this.state.editFields ? { borderWidth: 1, backgroundColor: colors.white } : { borderWidth: 0 },
                ]}
                value={this.state.userEmail}
                onChangeText={(val) => this.setState({ userEmail: val })}
                editable={this.state.editFields}
              />
            </View>
          </ScrollView>
          {this.state.editFields ? (
            <View style={[styles.containerButton, { marginTop: '50px' }]}>
              <TouchableOpacity style={styles.containerCancel} onPress={() => this._updateEditFields(false)}>
                <View style={styles.rowDirection}>
                  <Text style={styles.buttonTextCancel}>Cancelar</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={styles.containerSave} onPress={this._updateFields}>
                <View style={styles.rowDirection}>
                  <Text style={styles.buttonTextCancel}>Guardar</Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : null}
        </SafeAreaView>
      </>
    );
  }
}

ProfileScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

ProfileScreen.defaultProps = {};

/** Export component ProfileScreen **/
export default connect((state) => ({ ...state }))(ProfileScreen);
