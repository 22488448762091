/** Import React **/
import React, { Component } from 'react';
import { Text, View, TouchableOpacity, Image, Platform, TextInput, ActivityIndicator } from 'react-native';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Api **/
import { urlRootGalleryUrl, urlImageGalleryThumbs } from '../../api/urls';

/** Import Components **/
import { IconMoon } from '../icon/IconMoon';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from './../../constants/styles/colors';
import icons from './../../constants/icons/icons';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, Factor, ProductItem, ShoppingCart, ShoppingCartItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Utils **/
import totalizeValues from '../../utils/totalizeValues';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Const to define size in icons **/
const ICON_CHECK_SIZE = 20;
const ICON_SHOPPING_SIZE = 18;

/** Const to define time to update **/
const TIME_TO_UPDATE = 800;

/** Import Images **/
import productWithoutImage from '../../web_assets/images/product_without_image.png';
import { addToShoppingCart, removeFromShoppingCart, toogleFavorite } from '../../views/productDetail/api';

let PRODUCT_WITHOUT_IMAGE = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  PRODUCT_WITHOUT_IMAGE = productWithoutImage;
} else if (Platform.OS === 'ios') {
  PRODUCT_WITHOUT_IMAGE = { uri: `${assets.productWithoutImage}` };
} else {
  PRODUCT_WITHOUT_IMAGE = { uri: `@mipmap/${assets.productWithoutImage}` };
}

interface Props {
  onPressItem: (ref?: ProductItem | ShoppingCartItem) => void;
  onPressImage: (ref?: ProductItem | ShoppingCartItem) => void;
  item: ProductItem;
  fromView: string;
  backgroundColor: string;
  onPressFavorite?: (ref?: ProductItem | ShoppingCartItem) => void;
  removeItem?: (ref?: ProductItem | ShoppingCartItem) => void;
  auth?: Auth;
  shoppingCart?: ShoppingCart;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {
  quantity: string;
  isLoading: boolean;
  isCantMintRed: boolean;
}

/**
 * Class to export ProductsFlatList view
 *
 * @class ProductsFlatList
 * @extends {Component}
 */
class ProductsFlatList extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};
  private lastUpdateAtMs = -1;

  /**
   * Creates an instance of ProductsFlatList.
   * @param {*} props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      quantity: `${this.getQuantity()}`,
      isLoading: false,
      isCantMintRed: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
    if (
      nextProps.item.quantity &&
      nextProps.item.quantity !== this.props.item.quantity &&
      this.state.quantity !== nextProps.item.quantity
    ) {
      this.setState({
        quantity: nextProps.item.quantity ?? 0,
      });
    }
  }

  // static getDerivedStateFromProps(props: Props, current_state: State) {
  //   if (props.item.quantity && current_state.quantity !== props.item.quantity) {
  //     debugger;
  //     return {
  //       quantity: props.item.quantity ?? 0,
  //     };
  //   }
  //   return null;
  // }

  /**
   * Handle event onPress in view
   *
   * @memberof ProductsFlatList
   */
  onPressItem = () => {
    if (this.lastUpdateAtMs !== -1) {
      // Avoid conflict with other update process
      return;
    }
    this.props.onPressItem(this.props.item);
  };

  /**
   * Handle event onPress in image view
   *
   * @memberof ProductsFlatList
   */
  onPressImage = () => {
    if (this.lastUpdateAtMs !== -1) {
      // Avoid conflict with other update process
      return;
    }
    this.props.onPressImage(this.props.item);
  };

  /**
   * Handle event onPressRemoveItem
   *
   * @memberof ProductsFlatList
   */
  onPressRemoveItem = () => {
    this._updateQuantity(0, 1);
  };

  /**
   * Update Favorite state
   *
   * @memberof ProductsFlatList
   */
  toggleFavorite = async () => {
    const response = await toogleFavorite(this.props.auth, this.props.item, this.getFactor());

    return response;
  };

  /**
   * Handle event remove item in view
   *
   * @memberof ProductsFlatList
   */
  removeItem = async () => {
    const response = await removeFromShoppingCart(this.props.auth, this.props.item, this.getFactor());

    return response;
  };

  /**
   * Validate if item is added in shopping cart
   *
   * @memberof ProductsFlatList
   */
  isItemAdded = () => {
    return this.props.item.inShoppingCart;
  };

  /**
   * Handle getImage and return object with image route
   *
   * @memberof ProductsFlatList
   */
  getImage = () => {
    if (
      this.props.item &&
      this.props.item.code &&
      this.props.item.code.length > 0 &&
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.company_code &&
      this.props.item.has_image === 'Y'
    ) {
      return {
        uri:
          urlRootGalleryUrl +
          urlImageGalleryThumbs
            .replace(/\[company_code\]/g, this.props.auth.current_rshop.company_code)
            .replace(/\[product_code\]/g, this.props.item.code),
      };
    } else {
      return PRODUCT_WITHOUT_IMAGE;
    }
  };

  /**
   * Return description of product
   *
   * @memberof ProductsFlatList
   */
  getProductDescription = () => {
    return this.props.item.description;
  };

  /**
   * Handle getFactor and return first factor to products and favorites
   *
   * @memberof ProductsFlatList
   */
  getFactor = (): Factor | null => {
    if (this.props.item && this.props.item.factors && this.props.item.factors.length > 0) {
      return this.props.item.factors[0];
    } else {
      return null;
    }
  };

  getFactorDescription = () => {
    const factor = this.getFactor();
    return factor ? factor.description : strings.witoutFactor;
  };

  /**
   * Handle getPrice and return price
   *
   * @memberof ProductsFlatList
   */
  getPrice = () => {
    const calcAsUnit = ['Products', 'Favorites'].indexOf(this.props.fromView) > -1;
    const itemTotals = totalizeValues(this.props.item, calcAsUnit);

    if (this.props.item && this.props.item.price) {
      return accounting.formatMoney(itemTotals.totalNeto);
    } else {
      return accounting.formatMoney(0);
    }
  };

  /**
   * Handle getQuantity and return quantity
   *
   * @memberof ProductsFlatList
   */
  getQuantity = () => {
    if (this.props.item && this.props.item.quantity) {
      if (parseFloat(this.props.item.quantity) % 1 !== 0) {
        return this.props.item.quantity;
      } else {
        return parseFloat(this.props.item.quantity);
      }
    } else {
      return 0;
    }
  };

  /**
   * Function to add item to shopping cart
   *
   * @memberof ProductDetail
   */
  _addToCart = async (quantity: string) => {
    const response = await addToShoppingCart(this.props.auth, this.props.item, quantity, this.getFactor());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return response.success;
  };

  /**
   * Function to increment quantity
   *
   * @memberof ProductDetail
   */
  _incrementQuantity = () => {
    const factor = this.getFactor();
    if ((factor ? factor.minimum_amount : 0) <= parseFloat(this.state.quantity) + 1) {
      this._updateQuantity(parseFloat(this.state.quantity) + 1);
    } else {
      this.setState({
        quantity: (parseFloat(this.state.quantity) + 1).toString(),
        isCantMintRed: true,
      });
    }
  };

  /**
   * Function to set new quantity
   *
   * @memberof ProductDetail
   */
  _onChangeQuantity = (quantity: string) => {
    const factor = this.getFactor();
    if (quantity.length > 0 && quantity !== '0' && !isNaN(parseFloat(quantity))) {
      if ((factor ? factor.minimum_amount : 0) <= parseFloat(quantity)) {
        this._updateQuantity(parseFloat(quantity));
      } else {
        if (this.isItemAdded()) {
          this._updateQuantity(parseFloat(quantity) || 0, undefined, true);
        } else {
          this.setState({
            quantity: (parseFloat(quantity) || 0).toString(),
            isCantMintRed: true,
          });
        }
      }
    } else {
      this._updateQuantity(0);
    }
  };

  /**
   * Function to decrement quantity
   *
   * @memberof ProductDetail
   */
  _decrementQuantity = () => {
    const factor = this.getFactor();
    if (parseFloat(this.state.quantity) - 1 >= (factor ? factor.minimum_amount : 0)) {
      this._updateQuantity(parseFloat(this.state.quantity) - 1);
    }
  };

  /**
   * Function to update quantity on database
   *
   * @memberof ProductDetail
   */
  _updateQuantity = (newQuantity: number, timeToUpdate?: number, isCantMintRed?: boolean) => {
    this.lastUpdateAtMs = new Date().getTime();
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    this.setState({ quantity: `${newQuantity}`, isCantMintRed: isCantMintRed || false }, () => {
      ((currentIncrementAtMs: number) => {
        setTimeout(async () => {
          if (currentIncrementAtMs === this.lastUpdateAtMs) {
            this.setState({
              isLoading: true,
            });
            if (this.isItemAdded() && newQuantity <= 0) {
              await this.removeItem();
            } else if (newQuantity > 0) {
              await this._addToCart(`${newQuantity}`);
            }
            this.setState(
              {
                isLoading: false,
              },
              () => {
                this.lastUpdateAtMs = -1;
              },
            );
          }
        }, timeToUpdate ?? TIME_TO_UPDATE);
      })(this.lastUpdateAtMs);
    });
  };

  /**
   * Function to update quantity on database
   *
   * @memberof ProductDetail
   */
  _updateFavorite = async () => {
    if (this.lastUpdateAtMs !== -1) {
      // Avoid conflict with other update process
      return;
    }
    this.setState({
      isLoading: true,
    });
    await this.toggleFavorite();
    this.setState({
      isLoading: false,
    });
  };

  getFactorMinimumAmount = () => {
    const factor = this.getFactor();
    return factor && factor.minimum_amount > 0 ? `Cant. Min ${factor.minimum_amount}` : null;
  };

  isInvalidMinimumAmount = () => {
    const factor = this.getFactor();
    return (
      parseFloat(this.props.item.quantity) > 0 &&
      parseFloat(this.props.item.quantity) < (factor ? factor.minimum_amount : 0)
    );
  };

  render() {
    return (
      <View style={{ backgroundColor: this.props.backgroundColor }}>
        {this.state.isLoading ? (
          <View style={styles.isLoadingContainer}>
            <View style={styles.isLoadingIndicator}>
              <ActivityIndicator animating={true} size={'large'} color={colors.primary} />
            </View>
          </View>
        ) : null}
        <View style={[styles.itemProductContainer, styles.container]}>
          <View style={styles.itemProductImageContainer}>
            {this.props.item.inShoppingCart ? (
              <View style={styles.itemAddedContainer}>
                <View style={styles.itemAddedIcon}>
                  <IconMoon name={icons.icCheck} size={ICON_CHECK_SIZE} color={colors.white} />
                </View>
              </View>
            ) : null}
            <TouchableOpacity onPress={this.onPressImage}>
              <Image style={styles.image} source={this.getImage()} resizeMode="contain" />
            </TouchableOpacity>
          </View>
          <View style={styles.itemProductDetailContainer}>
            <TouchableOpacity onPress={this.onPressItem}>
              <View style={styles.itemProductDescriptionContainer}>
                <Text
                  style={
                    this.props.item.to_delete && this.props.fromView == 'ShoppingCart'
                      ? styles.itemProductDescriptionRed
                      : styles.itemProductDescription
                  }
                >
                  {this.getProductDescription()}
                </Text>
                <TouchableOpacity style={styles.itemProductFavoriteButton} onPress={this._updateFavorite}>
                  <IconMoon
                    name={icons.icHeart}
                    size={23}
                    color={
                      this.props.fromView === 'Favorites'
                        ? colors.primary
                        : this.props.item.is_favorite === 'Y'
                        ? colors.primary
                        : colors.gray
                    }
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.itemProductPriceButtonContainer}>
                <View style={styles.itemProductPriceContainer}>
                  <View style={styles.itemProductFactorContainer}>
                    <Text style={styles.itemProductFactor}>{this.getFactorDescription()}</Text>
                  </View>
                  <View style={styles.itemProductTotalContainer}>
                    <Text style={styles.itemProductPrice}>
                      {this.props.item.inShoppingCart ? this.getPrice() : this.getPrice()}
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
            <View style={styles.itemProductButtonsContainer}>
              <View style={styles.containerProductCartButton}>
                <TouchableOpacity style={[styles.itemProductCartButton]} onPress={this.onPressItem}>
                  <IconMoon name={icons.icEdit} size={ICON_CHECK_SIZE} color={colors.grayTextDescription} />
                </TouchableOpacity>
                {this.props.item.inShoppingCart ? (
                  <TouchableOpacity
                    style={
                      this.props.item.to_delete && this.props.fromView == 'ShoppingCart'
                        ? styles.itemProductTrashButtonRed
                        : styles.itemProductTrashButton
                    }
                    onPress={this.onPressRemoveItem}
                  >
                    <IconMoon
                      name={
                        this.props.item.to_delete && this.props.fromView == 'ShoppingCart'
                          ? icons.icTrashWhite
                          : icons.icTrash
                      }
                      size={ICON_CHECK_SIZE}
                      color={
                        this.props.item.to_delete && this.props.fromView == 'ShoppingCart'
                          ? colors.white
                          : colors.grayTextDescription
                      }
                    />
                  </TouchableOpacity>
                ) : null}
              </View>
              <View style={styles.containerAddingButtons}>
                <TouchableOpacity style={styles.buttonsDec} onPress={this._decrementQuantity}>
                  <IconMoon name={icons.icMinus} size={ICON_CHECK_SIZE} color={colors.white} />
                </TouchableOpacity>
                <TextInput
                  style={
                    this.state.isCantMintRed || this.isInvalidMinimumAmount()
                      ? styles.textInputQuantityRed
                      : styles.textInputQuantity
                  }
                  keyboardType="numeric"
                  placeholder="Cantidad"
                  onChangeText={(val) => this._onChangeQuantity(val)}
                  value={this.state.quantity}
                />
                <TouchableOpacity style={styles.buttonsInc} onPress={this._incrementQuantity}>
                  <IconMoon name={icons.icPlus} size={ICON_SHOPPING_SIZE} color={colors.white} />
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.containerMinimumAmount}>
              <Text style={styles.itemProductFactor}>{this.getFactorMinimumAmount()}</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

ProductsFlatList.propTypes = {
  auth: AuthPropTypes,
};

ProductsFlatList.defaultProps = {};

/** Export component ProductsFlatList **/
export default connect((state) => ({ ...state }))(ProductsFlatList);
