/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Detalle',
  errorAddItem: 'Producto',
  addItem: 'Añadir',
  updateItem: 'Actualizar',
  total: 'Total',
  errorToogleFavorite: 'Favorito',
  quantityRequired: 'Cantidad obligatoria',
  quantityRequiredDetail: 'La cantidad debe ser mayor a 0',
  errorRemoveItem: 'Eliminar producto',
  priceWithoutTaxes: 'Precio (Antes de impuesto)',
  labelObservation: 'Descripción',
  taxes: 'Impuesto',
  priceChargeValue: 'Otros impuestos',
  productCode: 'Código: ',
  labelSelect: 'Presentación: ',
  placeholderSelect: 'Seleccione...',
  messageMininumAmount: 'La cantidad mínima de venta son ',
  messageMininumAmount2: 'corregir para adicionar el ítem. ',
};

/** Export strings **/
export default strings;
