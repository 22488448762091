/** Styles to Button **/
import { StyleSheet } from 'react-native';
import colors from '../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  headerCenterComponent: {
    fontSize: 18,
    fontWeight: 'bold',
    height: 30,
    justifyContent: 'center',
    marginTop: 15,
    backgroundColor: 'red',
  },
  iconBack: {
    width: 48,
    height: 45,
    justifyContent: 'center',
  },
  iconStyle: {
    color: colors.white,
    backgroundColor: colors.grayIconBack,
    width: 25,
    height: 25,
    borderRadius: 20,
    paddingTop: 5,
    paddingLeft: 5,
    transform: [{ rotate: '180deg' }],
  },
});

/** Export component styles **/
export default styles;
