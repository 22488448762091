/** Styles to view **/
import { Platform, StyleSheet } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

let modalViewMarginBottom;
if (Platform.OS === 'web') {
  modalViewMarginBottom = '100%';
} else {
  modalViewMarginBottom = 0;
}

/** Create an object style **/
const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  modalBackground: {
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: 10,
    paddingBottom: 20,
    top: '30%',
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: colors.primary,
    paddingTop: 20,
    paddingRigth: 20,
    paddingBottom: 5,
    paddingLeft: 20,
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderTopEndRadius: 10,
  },
  modalView: {
    width: '100%',
    backgroundColor: colors.white,
    borderRadius: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 3,
    paddingTop: 20,
    paddingRight: 15,
    paddingBottom: 20,
    paddingLeft: 15,
    marginBottom: modalViewMarginBottom,
  },
  modalTitle: {
    marginBottom: 15,
    fontSize: 14,
    color: colors.white,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  modalSubTitle: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 15,
    color: colors.black,
    textAlign: 'left',
    fontWeight: 'bold',
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  buttonCancel: {
    marginTop: 10,
    marginBottom: 10,
    width: '80%',
  },
  buttonTextCancel: {
    color: colors.danger,
  },
  buttonConfirm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70%',
    borderWidth: 0,
    borderRadius: 8,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    backgroundColor: colors.primary,
  },
  buttonTextConfirm: {
    color: colors.white,
  },
  containerDetail: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 20,
    paddingRight: 20,
    paddingLeft: 20,
  },
  date_picker_modal: {
    width: '100% !important',
  },
  itemPortafoliotDetailContainer: {
    flexDirection: 'column',
    width: '90%',
    borderWidth: 0,
  },
  containerInfoDocument: {
    marginLeft: 5,
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    justifyContent: 'flex-start',
  },
  orderSoldCode: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#1C1564',
  },
  orderSoldDateLabel: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  orderSoldDate: {
    color: colors.grayTextDescription,
  },
  documentDate: {
    fontWeight: 'bold',
    fontSize: 12,
    marginTop: 10,
    color: '#1C1564',
  },
  portafolioItemContainer: {
    padding: 5,
    width: '95%',
    height: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    marginLeft: 2,
    marginBottom: 10,
    border: '1px solid #E3E0E0',
    display: 'flex',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  dateContainerSaldo: {
    left: '25%',
  },
});

/** Export component style **/
export default styles;
