/** Import React **/
import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
/** Import Redux **/
import { connect } from 'react-redux';
/** Import Modal **/
import Modal from 'react-native-modal';
/** Components **/
import Button from '../button';
import Loader from '../loader';
/** Import constants **/
import styles from './style';
/** Import Interfaces **/
import { Auth } from '../../interfaces';
/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { IconMoon } from '../icon/IconMoon';
import icons from '../../constants/icons/icons';
import colors from '../../constants/styles/colors';
/** Const to define size in icons **/
const ICON_SIZE = 24;
interface Props {
  onRef: (ref: any) => void;
  title: string;
  action: () => void;
  actionText: string;
  auth?: Auth;
}
interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  callBackClose?: () => void | null;
}
/**
 * Create Modal with password
 *
 * @param {*} props
 * @returns
 */
class ModalDisabledSales extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};
  public Loader!: any;
  /**
   *Creates an instance of ModalDisabledSales.
   * @param {*} props
   * @memberof ModalDisabledSales
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
    };
  }
  /**
   * Pass ref to View
   *
   * @memberof ModalDisabledSales
   */
  componentDidMount() {
    this.props.onRef(this);
  }
  /**
   * Pass ref to View
   *
   * @memberof ModalDisabledSales
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  /**
   * Open alert
   *
   * @memberof ModalDisabledSales
   */
  openModalDisabledSales = () => {
    this.setState({
      isVisible: true,
    });
  };
  /**
   * Close alert
   *
   * @memberof ModalDisabledSales
   */
  closeModalDisabledSales = () => {
    this.setState({
      isVisible: false,
    });
  };
  /**
   * Function to log out
   *
   * @memberof ModalDisabledSales
   */
  _callAction = () => {
    this.setState({ callBackClose: this.props.action }, () => this.closeModalDisabledSales());
  };
  /**
   * Render ModalDisabledSales in modal
   *
   * @returns
   * @memberof ModalDisabledSales
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        avoidKeyboard={true}
        style={styles.modal}
        onModalHide={() => this.state.callBackClose && this.state.callBackClose()}
      >
        <SafeAreaProvider>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <View style={styles.modalBackground}>
            <TouchableOpacity style={styles.button}>
              <IconMoon
                onPress={this.closeModalDisabledSales}
                style={styles.icon}
                name={icons.icCloseWhite}
                size={ICON_SIZE}
                color={colors.white}
              />
            </TouchableOpacity>
            <View style={styles.modalView}>
              {this.props.title != '' ? <Text style={styles.modalTitle}>{this.props.title}</Text> : null}
              <View style={styles.containerButtons}>
                <Button
                  onPress={this._callAction}
                  buttonClass={styles.buttonConfirm}
                  buttonLabelText={this.props.actionText}
                  buttonLabelTextClass={styles.buttonTextConfirm}
                />
              </View>
            </View>
          </View>
        </SafeAreaProvider>
      </Modal>
    );
  }
}
ModalDisabledSales.propTypes = {
  auth: AuthPropTypes,
};
ModalDisabledSales.defaultProps = {};
/** Export component ModalDisabledSales **/
export default connect((state) => ({ ...state }))(ModalDisabledSales);
