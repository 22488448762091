/** Import React **/
import React, { Component } from 'react';
import { Text, View, TextInput, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

/** Import IconMoon **/
import { IconMoon } from '../icon/IconMoon';

/** Import Constants **/
import colors from '../../constants/styles/colors';
import icons from '../../constants/icons/icons';
import styles from './style';

/** Const to define size in icons **/
const ICON_SHOW_PASSWORD = 24;

interface Props {
  label: string;
  autoCapitalize: 'none' | 'sentences' | 'words' | 'characters';
  onChangeText: (val: string) => void;
  value?: string | undefined;
  placeholder?: string;
  activeError?: boolean | undefined;
  secureTextEntry?: boolean;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
  keyboardType?:
    | 'default'
    | 'email-address'
    | 'numeric'
    | 'phone-pad'
    | 'ascii-capable'
    | 'numbers-and-punctuation'
    | 'url'
    | 'number-pad'
    | 'name-phone-pad'
    | 'decimal-pad'
    | 'twitter'
    | 'web-search'
    | 'visible-password';
  disabled?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  isHidden?: boolean;
}

interface State {
  hidePassword: boolean;
}

/**
 * Class to override and create a new TextInput with style
 *
 * @export
 * @class InputTextForm
 *
 * @extends {Component<Props, State>}
 */
class InputTextForm extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   * Creates an instance of SignInScreen.
   * @param {*} props
   * @memberof InputTextForm
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      hidePassword: false,
    };
  }

  /**
   * Function to call onChangeText from imported view
   *
   * @memberof InputTextForm
   */
  _onChangeText = (val: string) => {
    if (this.props.onChangeText) {
      this.props.onChangeText(val);
    }
  };

  /**
   * Render component with Text and TextInput
   *
   * @returns
   * @memberof InputTextForm
   */
  render() {
    if (this.props.isHidden) {
      return <></>;
    } else {
      return (
        <View style={[styles.containerInput, this.props.activeError ? styles.containerInputError : {}]}>
          <Text style={[styles.labelTextRegular, this.props.activeError ? styles.labelTextError : styles.labelText]}>
            {this.props.label}
          </Text>
          <TextInput
            style={[styles.inputText, { color: this.props.disabled ? colors.gray : colors.black }]}
            placeholder={this.props.placeholder}
            secureTextEntry={
              this.props.secureTextEntry
                ? this.state.hidePassword
                  ? !this.state.hidePassword
                  : this.props.secureTextEntry
                : false
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            textAlign={this.props.textAlign}
            autoCapitalize={this.props.autoCapitalize}
            keyboardType={this.props.keyboardType}
            onChangeText={(val: string) => this._onChangeText(val)}
            value={this.props.value}
            editable={!this.props.disabled}
            maxLength={this.props.maxLength && this.props.maxLength}
            autoFocus={this.props.autoFocus}
          />
          {this.props.secureTextEntry && (
            <TouchableOpacity
              style={styles.icShowPassword}
              onPress={() => this.setState({ hidePassword: !this.state.hidePassword })}
            >
              <IconMoon
                name={this.state.hidePassword ? icons.icHidePassword : icons.icShowPassword}
                size={ICON_SHOW_PASSWORD}
                color={colors.primary}
              />
            </TouchableOpacity>
          )}
        </View>
      );
    }
  }
}

InputTextForm.propTypes = {
  label: PropTypes.string.isRequired,
  autoCapitalize: PropTypes.oneOf(['none', 'sentences', 'words', 'characters']).isRequired,
  onChangeText: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  activeError: PropTypes.bool,
  secureTextEntry: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  keyboardType: PropTypes.oneOf([
    'default',
    'email-address',
    'numeric',
    'phone-pad',
    'ascii-capable',
    'numbers-and-punctuation',
    'url',
    'number-pad',
    'name-phone-pad',
    'decimal-pad',
    'twitter',
    'web-search',
    'visible-password',
  ]).isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
};

InputTextForm.defaultProps = {
  secureTextEntry: false,
  textAlign: 'left',
  keyboardType: 'default',
  disabled: false,
  autoFocus: false,
};

/** Export component InputTextForm **/
export default InputTextForm;
