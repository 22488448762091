/** Import modules **/
import React from 'react';
import { TouchableOpacity, Text } from 'react-native';

/** Import Constants **/
import styles from './style';

interface Props {
  onPress: () => void;
  disabled?: boolean;
  buttonClass?: any;
  buttonLabelTextClass?: any;
  buttonLabelText?: any;
}

/**
 * Create button
 *
 * @param {Props}
 * @returns
 */
const Button = ({ onPress, disabled, buttonClass, buttonLabelTextClass, buttonLabelText }: Props) => {
  /**
   * Render component with Icon
   *
   * @returns
   * @memberof Button
   */
  return (
    <TouchableOpacity onPress={onPress} style={[styles.button, styles.buttonRounded, buttonClass]} disabled={disabled}>
      <Text style={[styles.buttonText, buttonLabelTextClass]}>{buttonLabelText}</Text>
    </TouchableOpacity>
  );
};

/** Export component Button **/
export default Button;
