/** Import modules **/
import React from 'react';
import { TouchableOpacity, Text } from 'react-native';

/** Import Components **/
import { IconMoon } from './../icon/IconMoon';

/** Import Constants **/
import colors from './../../constants/styles/colors';
import styles from './style';

/** Const to define size in icons **/
const ICON_SIZE = 24;

interface Props {
  onPress: () => void;
  icon: string;
  buttonLabelText: any;
  active?: boolean;
  disabled?: boolean;
  buttonClass?: any;
  buttonLabelTextClass?: any;
}

/**
 * Create button
 *
 * @param {Props}
 * @returns
 */
const ButtonWithIcon = ({
  onPress,
  icon,
  buttonLabelText,
  active,
  disabled,
  buttonClass,
  buttonLabelTextClass,
}: Props) => {
  /**
   * Render component with Icon
   *
   * @returns
   * @memberof ButtonWithIcon
   */
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.button,
        buttonClass,
        disabled ? styles.opacity4 : {},
        active ? { backgroundColor: colors.whiteOpacity20 } : {},
      ]}
      disabled={disabled}
    >
      <IconMoon name={icon} size={ICON_SIZE} color={active ? colors.white : colors.buttonSideDrawer} />
      <Text
        style={[styles.buttonText, buttonLabelTextClass, { color: active ? colors.white : colors.buttonSideDrawer }]}
      >
        {buttonLabelText}
      </Text>
    </TouchableOpacity>
  );
};

/** Export component ButtonWithIcon **/
export default ButtonWithIcon;
