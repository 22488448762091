/** Import React **/
import React, { Component } from 'react';
import { View, ActivityIndicator } from 'react-native';
import PropTypes from 'prop-types';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Import constants **/
import colors from '../../constants/styles/colors';
import styles from './style';

import { SafeAreaProvider } from 'react-native-safe-area-context';

interface Props {
  onRef: (ref: any) => void;
}

interface State {
  backdropOpacity: number;
  animationIn: any;
  isVisible: boolean;
  size: 'small' | 'large';
  callBackOpen?: () => void | null;
  callBackClose?: () => void | null;
}

/**
 * Create Modal with ActivityIndicator
 *
 * @param {*} props
 * @returns
 */
class Loader extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   *Creates an instance of Loader.
   * @param {*} props
   * @memberof Loader
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
    };
  }

  /**
   * Pass ref to View
   *
   * @memberof Loader
   */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   * Pass ref to View
   *
   * @memberof Loader
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  /**
   * Open alert
   *
   * @memberof Loader
   */
  openLoader = (callBackOpen?: () => void) => {
    this.setState({
      isVisible: true,
      callBackOpen: callBackOpen,
    });
  };

  /**
   * Close alert
   *
   * @memberof Loader
   */
  closeLoader = (callBackClose?: () => void) => {
    this.setState({
      isVisible: false,
      callBackClose: callBackClose,
    });
  };

  /**
   * Render loader in modal
   *
   * @returns
   * @memberof Loader
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        onModalShow={() => this.state.callBackOpen && this.state.callBackOpen()}
        onModalHide={() => this.state.callBackClose && this.state.callBackClose()}
      >
        <SafeAreaProvider>
          <View style={styles.modalBackground}>
            <View style={styles.activityIndicatorWrapper}>
              <ActivityIndicator animating={this.state.isVisible} size={this.state.size} color={colors.white} />
            </View>
          </View>
        </SafeAreaProvider>
      </Modal>
    );
  }
}

Loader.propTypes = {
  onRef: PropTypes.func.isRequired,
};

Loader.defaultProps = {};

/** Export component Loader **/
export default Loader;
