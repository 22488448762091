/** Styles to view **/
import { Dimensions, ImageStyle, Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

const { height } = Dimensions.get('window');
const LIST_HEIGHT = height - 45 - 52 - 64 - 48 - 50;

let imageNotFoundContainer: ViewStyle | TextStyle | ImageStyle;
if (Platform.OS === 'web') {
  imageNotFoundContainer = {
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: '25%',
    marginLeft: '5%',
    marginRight: '5%',
  };
} else {
  imageNotFoundContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  searchInput: {
    width: '98%',
    height: 40,
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 5,
    backgroundColor: 'rgba(142,142,147,0.12)',
    color: '#8E8E93',
    position: 'relative',
  },
  clearICon: {
    position: 'absolute',
    color: '#8E8E93',
    bottom: -22,
    right: 0,
  },
  total: {
    backgroundColor: colors.white,
    width: '100%',
    paddingTop: 15,
    height: 70,
  },
  totalContainer: {
    display: 'flex',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    left: '2.5%',
    width: '95%',
    height: 55,
  },
  totalContainerEnabled: {
    backgroundColor: colors.primary,
  },
  totalContainerDisabled: {
    backgroundColor: colors.grayPlaceholder,
  },
  rowDirection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  viewRequestOrderItemsAmount: {
    paddingLeft: 20,
    width: '33%',
  },
  textRequestOrderItemsAmount: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.white,
    width: 40,
    height: 30,
    textAlign: 'center',
    paddingTop: 5,
    borderRadius: 10,
    backgroundColor: 'rgba(89, 88, 88, 68)',
  },
  textRequestOrder: {
    width: '27%',
    fontSize: 17,
    fontWeight: 'bold',
    color: colors.white,
    paddingLeft: 20,
    textAlign: 'center',
  },
  textTotalOrder: {
    width: '40%',
    fontSize: 17,
    textAlign: 'right',
    color: colors.white,
    paddingRight: 20,
  },
  flexOne: {
    height: LIST_HEIGHT - 65,
  },
  activityIndicatorWrapper: {
    paddingVertical: 20,
    borderTopWidth: 1,
    borderColor: '#CED0CE',
  },
  searchBarContainer: {
    backgroundColor: colors.white,
    borderWidth: 0,
    borderTopWidth: 0,
    borderTopColor: colors.white,
    borderBottomColor: colors.white,
    borderBottomWidth: 0,
  },
  inputContainerStyle: {
    backgroundColor: colors.searchBarBackground,
  },
  searchBarInputStyle: {
    backgroundColor: colors.searchBarBackground,
    color: colors.black,
  },
  appliedFilters: {
    height: 45,
    textAlignVertical: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: colors.white,
    fontStyle: 'italic',
    fontSize: 18,
    textDecorationLine: 'underline',
  },
  imageNoFoundContainer: imageNotFoundContainer,
  imageNoFound: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    width: 128,
    height: 128,
  },
  imageNoFoundDescriptionContainer: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescription: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: colors.grayPlaceholder,
  },
});

/** Export component style **/
export default styles;
