/** Import React **/
import React, { Component } from 'react';
import { Text, View, Image, Platform, TouchableOpacity } from 'react-native';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/**Import component */
import OpenModalWalletDetail from '../../components/openModalWalletDetail';

/** Import Moment **/
import Moment from 'moment';
require('moment/locale/es');
Moment.locale('es');

/** Import Constants **/
import assets from './../../constants/assets/assets';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, Portfolio } from './../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import shoppingImage from '../../web_assets/images/cartera.svg';
let SHOPPING = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  SHOPPING = shoppingImage;
} else if (Platform.OS === 'ios') {
  SHOPPING = { uri: `${assets.productWithoutImage}` };
} else {
  SHOPPING = { uri: `@mipmap/${assets.productWithoutImage}` };
}
interface Props {
  item: Portfolio;
  backgroundColor: string;
  auth?: Auth;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}
/**
 * Class to export walletReportFlastLyst view
 *
 * @class walletReportFlastLyst
 * @extends {Component}
 */
class walletReportFlastLyst extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};
  public OpenModalWalletDetail!: any;

  /**
   * Handle _getDocumentDate and return price
   *
   * @memberof walletReportFlastLyst
   */
  _getDocumentDate = () => {
    if (this.props.item && this.props.item.document_date) {
      return this.props.item.document_date;
    } else {
      return 'Sin fecha';
    }
  };
  /**
   * Handle _getExpirationDate and return price
   *
   * @memberof walletReportFlastLyst
   */
  _getExpirationDate = () => {
    if (this.props.item && this.props.item.expiration_date) {
      return this.props.item.expiration_date;
    } else {
      return 'Sin fecha';
    }
  };
  /**
   * Function to open modal
   *
   * @memberof PortfolioScreen
   */
  _openDetail = (props: any) => {
    this.setState({ loading: true });
    this.OpenModalWalletDetail.openModalWalletDetail(props);
  };
  /**
   * Render view with product data
   *
   * @returns
   * @memberof walletReportFlastLyst
   */
  render() {
    return (
      <>
        <OpenModalWalletDetail
          onRef={(ref) => (this.OpenModalWalletDetail = ref)}
          title="Detalle De Cartera"
          action={(props) => (this._openDetail = props)}
          actionText="Listo"
          stateParent={this.state}
        />
        <View style={{ backgroundColor: this.props.backgroundColor }}>
          <TouchableOpacity onPress={() => this._openDetail(this.props)}>
            <View style={[styles.portafolioItemContainer, styles.container, styles.flexOne]}>
              <View style={styles.imageIconOrderSold}>
                <Image source={SHOPPING} style={styles.image} />
              </View>
              <View style={styles.itemPortafoliotDetailContainer}>
                <View style={styles.containerInfoDocument}>
                  <View style={styles.dateContainerinvoice}>
                    <Text style={styles.orderSoldCode}>{strings.documentNumber}</Text>
                    <Text style={[styles.orderSoldDate, styles.text_truncate]}>{this.props.item.document_number}</Text>
                    <Text style={styles.documentDate}>{strings.dateContainer}</Text>
                    <Text style={styles.orderSoldDate}>{this._getDocumentDate()}</Text>
                  </View>
                  <View style={styles.dateContainerSaldo}>
                    <Text style={styles.orderSoldCode}>{strings.saldo}</Text>
                    <Text style={[styles.orderSoldDate, styles.text_truncate]}>$ {this.props.item.balance}</Text>
                    <Text style={styles.documentDate}>{strings.expires}</Text>
                    <Text
                      style={[this.props.item.expired_days == 0 ? styles.expireColordDays : styles.expirationDateRed]}
                    >
                      {this._getExpirationDate()}
                    </Text>
                  </View>
                  <View style={styles.datecurrentDebt}>
                    <Text style={styles.orderSoldCode}>{strings.currentDebt}</Text>
                    <Text style={[styles.orderSoldDate, styles.text_truncate]}>$ {this.props.item.current}</Text>
                    <Text style={styles.documentDate}>{strings.expiredDays}</Text>
                    <Text style={styles.orderSoldDate}>{this.props.item.expired_days}</Text>
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </>
    );
  }
}

walletReportFlastLyst.propTypes = {
  auth: AuthPropTypes,
};

walletReportFlastLyst.defaultProps = {};

/** Export component walletReportFlastLyst **/
export default connect((state) => ({ ...state }))(walletReportFlastLyst);
