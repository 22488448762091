/** Define object to static labels in view **/
const strings = {
  googleChrome: 'Google Chrome',
  mozillaFirefox: 'Mozilla Firefox',
  opera: 'Opera',
  notAllowedDeviceMessage: 'Este navegador no es compatible, toca el siguiente enlace para continuar:',
  notAllowedDeviceLinkMessage: 'click aquí',
};

/** Export strings **/
export default strings;
