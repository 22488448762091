/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Historial de Entregas',
  dropdownListLabel: 'Estado de la entrega:',
  orderSourceDropdownLabel: 'Origen Pedido',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
  placeholderSelect: 'Todos',
};

/** Export strings **/
export default strings;
