/** Styles to view **/
import { Dimensions, ImageStyle, Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

const { height } = Dimensions.get('window');
const LIST_HEIGHT = height - 45 - 60;

let imageNotFoundContainer: ViewStyle | TextStyle | ImageStyle;
if (Platform.OS === 'web') {
  imageNotFoundContainer = {
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: '25%',
    marginLeft: '5%',
    marginRight: '5%',
  };
} else {
  imageNotFoundContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerDropdown: {
    backgroundColor: colors.primary,
    paddingTop: 10,
    paddingRight: 5,
    paddingBottom: 10,
    paddingLeft: 5,
  },
  imageNoFoundContainer: imageNotFoundContainer,
  imageNoFound: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    width: 128,
    height: 128,
  },
  imageNoFoundDescriptionContainer: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescription: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: colors.grayPlaceholder,
  },
  filterList: {
    width: '98%',
    height: 40,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderRadius: 5,
    backgroundColor: colors.filterListBackgroundColor,
    color: colors.grayPlaceholder,
    position: 'relative',
  },
  flexOne: {
    height: LIST_HEIGHT,
  },
});

/** Export component styles **/
export default styles;
