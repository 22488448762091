/** Define object to static labels in app **/
const assets = {
  notFound: 'not_found',
  backgroundLogin: 'background_login',
  factory: 'factory',
  logo: 'logo',
  orderSent: 'order_sent',
  productWithoutImage: 'product_without_image',
  shopping: 'shopping',
  user: 'user',
};

/** Export assets **/
export default assets;
