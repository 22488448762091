/** Import modules **/
import React, { Component } from 'react';
import { View, Text, Platform, FlatList, Image, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

import { SafeAreaView } from 'react-native-safe-area-context';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import SearchBar **/
import { SearchBar } from 'react-native-elements';

/** Import Api **/
import { apiGet } from '../../api';
import { urlProductList, urlProductCategoryFilters } from '../../api/urls';

/** Import components **/
import BackHandlerCustom from '../../components/backHandlerCustom';
import ButtonBranchOffice from '../../components/buttonBranchOffice';
import HeaderSideDrawer from '../../components/headerSideDrawer';
import LoaderWithoutModal from '../../components/loaderWithoutModal';
import ProductsFlatList from '../../components/productsFlatList';

/** Import Constants **/
import assets from '../../constants/assets/assets';
import colors from '../../constants/styles/colors';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, FilterData, ProductItem, Products, ShoppingCart, ShoppingCartItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes, ProductsPropTypes, ShoppingCartPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setProducts } from '../../slices/products/productsSlice';

/** Import Utils **/
import parseProductData from '../../utils/parseProductData';
import showAlert from '../../utils/showAlert';

import { resetNextRouteName } from '../../slices/auth/authSlice';

import * as RootNavigation from '../../navigators/RootNavigation';
import { updateMinimumAmountShoppingCartItem } from '../../slices/shoppingCart/shoppingCartSlice';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Global variables **/
const WAIT_INTERVAL = 800;

/** Import Images **/
import notFoundImage from '../../web_assets/images/not_found.png';
import ShoppingCartButton from '../../components/shoppingCartButton';

/** Multilevel Sidebar **/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MultilevelSidebar from 'react-multilevel-sidebar';
import 'react-multilevel-sidebar/src/Sidebar.css';
import './style.css';

let NOT_FOUND: { uri: string };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NOT_FOUND = notFoundImage;
} else if (Platform.OS === 'ios') {
  NOT_FOUND = { uri: `${assets.notFound}` };
} else {
  NOT_FOUND = { uri: `@mipmap/${assets.notFound}` };
}

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  products: Products;
  shoppingCart: ShoppingCart;
  route: any;
}

interface State {
  handleLoadMore: boolean;
  group: string;
  family: string;
  line: string;
  type_search: string;
  total_amount?: number;
  loading: boolean;
  loadingCategoryFilters: boolean;
  data: Array<ProductItem>;
  filter_data: FilterData;
  hasBeenLoadedAsFirstTime: boolean;
  isCategoryFilterOpenned: boolean;
  persistLastCategoryFilterSelection: boolean;
  categoryFilterOptions: Array<any>;
}

/**
 * Class to export ProductsScreen
 *
 * @class ProductsScreen
 * @extends {Component<Props, State>}
 */
class ProductsScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public timer!: any;

  private lastCategoryFilters = {
    group: '%',
    family: '%',
    line: '%',
  };

  /**
   * Creates an instance of ProductsScreen.
   * @param {*} props
   * @memberof ProductsScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      handleLoadMore: true,
      group: '%',
      family: '%',
      line: '%',
      type_search: 'LIKENAMEANDCODE',
      total_amount: undefined,
      loading: false,
      loadingCategoryFilters: false,
      data: [],
      filter_data: {
        filter_value: undefined,
        page: {
          has_next: true,
          next_page_number: 0,
        },
      },
      hasBeenLoadedAsFirstTime: false,
      isCategoryFilterOpenned: false,
      persistLastCategoryFilterSelection: false,
      categoryFilterOptions: [],
    };
  }

  /**
   * Functin to get ProductsScreen when moduled is opened
   *
   * @memberof ProductsScreen
   */
  componentDidMount() {
    this.timer = null;
    this._navigateToNextRoute();
  }

  _navigateToNextRoute = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const nextRouteName = this.props.auth.next_route_name;
    switch (nextRouteName) {
      case routes.shoppingHistory:
        setTimeout(() => {
          store.dispatch(resetNextRouteName());
          RootNavigation.navigate(nextRouteName);
        }, 100);
        break;
      case routes.portfolio:
        setTimeout(() => {
          store.dispatch(resetNextRouteName());
          RootNavigation.navigate(nextRouteName);
        }, 100);
        break;
      case routes.deliveryHistory:
        setTimeout(() => {
          store.dispatch(resetNextRouteName());
          RootNavigation.navigate(nextRouteName);
        }, 100);
        break;
      case routes.profile:
        setTimeout(() => {
          store.dispatch(resetNextRouteName());
          RootNavigation.navigate(nextRouteName);
        }, 100);
        break;
      default:
        this.setState({ hasBeenLoadedAsFirstTime: true });
        this._products(this.props.auth);
        break;
    }
  };

  /**
   * Function to set false mounted
   *
   * @memberof ProductsScreen
   */
  componentWillUnmount() {
    this.timer = null;
  }

  /**
   * Function to receive props
   *
   * @param {*} prevProps
   * @memberof ProductsScreen
   */
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id &&
      prevProps.auth &&
      prevProps.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id !== prevProps.auth.current_rshop.rshop_id
    ) {
      this._updateState();
    }

    if (this.props.products !== prevProps.products) {
      this._updateProducts();
    }
    if (!this.state.hasBeenLoadedAsFirstTime) {
      this.setState({ hasBeenLoadedAsFirstTime: true });
      this._products(this.props.auth);
    }
  }

  /**
   * Function to update state from component did update
   *
   * @memberof ProductsScreen
   */
  _updateState = () => {
    this.setState(
      {
        handleLoadMore: true,
        group: '%',
        family: '%',
        line: '%',
        type_search: 'LIKENAMEANDCODE',
        total_amount: undefined,
        loading: false,
        data: [],
        filter_data: {
          filter_value: undefined,
          page: {
            has_next: true,
            next_page_number: 0,
          },
        },
      },
      () => this._products(this.props.auth),
    );
  };

  /**
   * Functionto update state from component did update
   *
   * @memberof ProductsScreen
   */
  _updateProducts = () => {
    this.setState({ data: this.props.products.data });
  };

  /**
   * Function to get rshop_id
   *
   * @memberof ProductsScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Function to call service and get products
   *
   * @memberof ProductsScreen
   */
  _products = (user: Auth) => {
    if (
      this.state.loading ||
      (user.csrftoken && user.csrftoken.length === 0) ||
      !user.current_rshop ||
      (user.current_rshop && !user.current_rshop.rshop_id)
    ) {
      return;
    }
    // Try to load category filters
    this._loadCategoryFilters(user);
    this.setState({ loading: true }, async () => {
      const urlProductListReplaced = urlProductList.replace(/\[rshop_id\]/g, String(this._getRshop_id()));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
        filter_value: this.state.filter_data?.filter_value,
        group: this.state.group,
        family: this.state.family,
        line: this.state.line,
        type_search: this.state.type_search,
        page: this.state.filter_data?.page.next_page_number,
        total_amount: this.state.total_amount,
      };
      try {
        const response = await apiGet(urlProductListReplaced, data)();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.data = [...this.state.data, ...this.parsedProducts(response.products)];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.filter_value = this.state.filter_data?.filter_value;

          store.dispatch(setProducts(response));

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const products = response.products;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const data = response.data;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const filter_value = response.filter_value;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const page = response.page;

          this.setState({
            handleLoadMore: false,
            type_search: '',
            total_amount: response && products && products.length > 0 ? products[0].total_amount : 0,
            data: data,
            filter_data: {
              filter_value: filter_value,
              page: page,
            },
            loading: false,
          });
        } else {
          this.setState({ loading: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (006).';
        }
        this.setState({ loading: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Function to call service and get product category filters
   *
   * @memberof ProductsScreen
   */
  _loadCategoryFilters = (user: Auth) => {
    if (
      this.state.loadingCategoryFilters ||
      (user.csrftoken && user.csrftoken.length === 0) ||
      !user.current_rshop ||
      (user.current_rshop && !user.current_rshop.rshop_id) ||
      this.state.categoryFilterOptions.length > 0
    ) {
      return;
    }
    this.setState({ loadingCategoryFilters: true }, async () => {
      const urlProductCategoryFiltersReplaced = urlProductCategoryFilters.replace(
        /\[rshop_id\]/g,
        String(this._getRshop_id()),
      );
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
      };
      try {
        const response = await apiGet(urlProductCategoryFiltersReplaced, data)();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          const categoryFilterOptions = this.parsedProductCategoryFilters(response.data[0]);
          this.setState({
            categoryFilterOptions: categoryFilterOptions,
          });
        } else {
          this.setState({ loadingCategoryFilters: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (006).';
        }
        this.setState({ loadingCategoryFilters: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Function to merge ProductItems with the ShoppingCartItems
   */
  parsedProducts = (products: any) => {
    const mergedProducts: Array<any> = [];
    let key: any;
    for (key in products) {
      const item = products[key];
      const shoppingCartItem = this.getShoppingCartItem(item);
      if (shoppingCartItem) {
        mergedProducts.push(parseProductData(shoppingCartItem, undefined));
      } else {
        mergedProducts.push(parseProductData(undefined, item));
      }
    }
    return mergedProducts;
  };

  /**
   * Function to parse product category filters
   */
  parsedProductCategoryFilters = (categoryFilters: any) => {
    let id = 3;
    const options = [
      {
        title: 'Opciones',
        hideBorder: false,
        content: [
          {
            id: 1,
            name: 'Cerrar',
            filterLevel: 'close',
            isFinalItem: true,
          },
          {
            id: 2,
            name: 'Restablecer',
            filterLevel: 'restore',
            isFinalItem: true,
          },
        ],
      },
      {
        title: 'Grupo',
        hideBorder: false,
        content: [
          {
            id: id,
            name: 'Todos',
            filterLevel: 'group',
            value: '%',
            isFinalItem: true,
          },
        ],
      },
    ];
    id++;
    categoryFilters.groups.forEach((group: any) => {
      const groupToAdd = {
        id: id,
        name: group.group,
        value: group.group,
        filterLevel: 'group',
        isFinalItem: group.families.length === 0,
      };
      id++;
      if (!groupToAdd.isFinalItem) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        groupToAdd.children = [
          {
            title: 'Familia',
            hideBorder: false,
            content: [
              {
                id: id,
                name: 'Todos',
                filterLevel: 'family',
                value: '%',
                isFinalItem: true,
              },
            ],
          },
        ];
        id++;
        group.families.forEach((family: any) => {
          const familyToAdd = {
            id: id,
            name: family.family,
            filterLevel: 'family',
            value: family.family,
            isFinalItem: family.lines.length === 0,
          };
          id++;
          if (!familyToAdd.isFinalItem) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            familyToAdd.children = [
              {
                title: 'Linea',
                hideBorder: false,
                content: [
                  {
                    id: id,
                    name: 'Todos',
                    filterLevel: 'line',
                    value: '%',
                    isFinalItem: true,
                  },
                ],
              },
            ];
            id++;
            family.lines.forEach((line: any) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              familyToAdd.children[0].content.push({
                id: id,
                name: line,
                filterLevel: 'line',
                value: line,
                isFinalItem: true,
              });
              id++;
            });
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          groupToAdd.children[0].content.push(familyToAdd);
        });
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options[1].content.push(groupToAdd);
    });
    return options;
  };

  getShoppingCartItem = (productItem: ProductItem): ShoppingCartItem | undefined => {
    if (this.props.shoppingCart?.data && this.props.shoppingCart.data.length > 0) {
      let i: any;
      for (i in this.props.shoppingCart.data) {
        const shoppingFactor = parseFloat(this.props.shoppingCart.data[i].factor);
        const factorFound = productItem.factors.filter(
          (factor) => parseFloat(String(factor.factor)) === shoppingFactor,
        );
        if (this.props.shoppingCart.data[i].product_code === productItem.code && factorFound.length > 0) {
          store.dispatch(
            updateMinimumAmountShoppingCartItem({
              ...this.props.shoppingCart.data[i],
              minimum_amount: factorFound[0].minimum_amount || 0,
            }),
          );
          return this.props.shoppingCart.data[i];
        }
      }
    }
    return undefined;
  };

  /**
   * Get current items amount on shopping cart
   *
   * @memberof ShoppingCartScreen
   */
  _getCurrentShoppingCartItemsAmount = () => {
    return this.props.shoppingCart.data ? this.props.shoppingCart.data.length : 0;
  };

  /**
   * Function to clean state and then load products
   *
   * @memberof ProductsScreen
   */
  _cleanStateToLoadProducts = () => {
    this.setState(
      {
        total_amount: undefined,
        data: [],
      },
      () => {
        this._products(this.props.auth);
      },
    );
  };

  /**
   * Handle event to update filter_value
   *
   * @param {*} term
   * @memberof ProductsScreen
   */
  _searchUpdated(term: string) {
    clearTimeout(this.timer);
    this.setState(
      {
        filter_data: {
          filter_value: term,
          page: {
            has_next: false,
            next_page_number: 0,
          },
        },
      },
      () => {
        this.timer = setTimeout(this._cleanStateToLoadProducts, WAIT_INTERVAL);
      },
    );
  }

  /**
   * Return an id to each view
   *
   * @memberof ProductsScreen
   */
  _keyExtractor = (item: ProductItem) => {
    return `${item.code}`;
  };

  /**
   * Function to open detail
   *
   * @memberof ProductsScreen
   */
  _onPressItem = (item: ProductItem) => {
    this.props.navigation.navigate(routes.productsDetail, {
      backRoute: routes.products,
      item: item,
      originalItem: item,
    });
  };

  /**
   * Function to open product image gallery
   *
   * @memberof ProductsScreen
   */
  _onPressImage = (item: ProductItem) => {
    this.props.navigation.navigate(routes.productImageGallery, {
      backRoute: routes.products,
      item: item,
      originalItem: item,
    });
  };

  /**
   * Render item from ProductsFlatList view
   *
   * @memberof ProductsScreen
   */
  _renderItem = ({ item, index }: { item: ProductItem; index: number }) => (
    <ProductsFlatList
      backgroundColor={colors.stripedColors[index % colors.stripedColors.length]}
      item={item}
      onPressItem={(itemPressed: any) => {
        this._onPressItem(itemPressed);
      }}
      onPressImage={(itemPressed: any) => {
        this._onPressImage(itemPressed);
      }}
      fromView="Products"
    />
  );

  /**
   * Render emtpy component
   *
   * @memberof ProductsScreen
   */
  _renderEmpty = () => {
    return (this.state.data && this.state.data.length > 0) || this.state.loading ? null : (
      <View style={styles.imageNoFoundContainer}>
        <Image source={NOT_FOUND} style={styles.imageNoFound} />
        <View style={styles.imageNoFoundDescriptionContainer}>
          <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
        </View>
      </View>
    );
  };

  /**
   * Render loader in footer
   *
   * @memberof ProductsScreen
   */
  _renderFooter = () => {
    if (this.state.loading) {
      return <LoaderWithoutModal />;
    }
  };

  /**
   * Function to handle scroll bottom
   *
   * @memberof ProductsScreen
   */
  _handleLoadMore = () => {
    if (this.state.loading) {
      return;
    }
    if (this.state.filter_data?.page.has_next) {
      this._products(this.props.auth);
    } else {
      this.setState({
        data:
          this.props.products && this.props.products.data && this.props.products.data.length > 0
            ? this.props.products.data
            : [],
      });
    }
  };

  _rightComponent = () => <ShoppingCartButton navigation={this.props.navigation} backRoute={routes.products} />;

  _onPressTotal = () => {
    this.props.navigation.navigate(routes.checkout, {
      backRoute: routes.products,
    });
  };

  /**  ------ Category Filter ----------   **/
  _onShowCategoryFilter = () => {
    this.setState({
      isCategoryFilterOpenned: true,
    });
  };
  _onCategoryFilterItemClick = (itemOptions: any) => {
    let group = this.state.group;
    let family = this.state.family;
    let line = this.state.line;
    switch (itemOptions.filterLevel) {
      case 'group':
        group = itemOptions.value;
        family = '%';
        line = '%';
        break;
      case 'family':
        family = itemOptions.value;
        line = '%';
        break;
      case 'line':
        line = itemOptions.value;
        break;
      case 'restore':
        group = '%';
        family = '%';
        line = '%';
        break;
    }
    this.setState(
      {
        group: group,
        family: family,
        line: line,
        isCategoryFilterOpenned: !itemOptions.isFinalItem, // Close category filter when is final item
        persistLastCategoryFilterSelection: itemOptions.filterLevel === 'restore' ? false : itemOptions.isFinalItem,
      },
      () => {
        if (!this.state.isCategoryFilterOpenned) {
          this.lastCategoryFilters = {
            group: this.state.group,
            family: this.state.family,
            line: this.state.line,
          };
          if (itemOptions.filterLevel !== 'close') {
            this._cleanStateToLoadProducts();
          }
        }
      },
    );
  };
  _onCategoryFilterClose = () => {
    const haveChanges =
      this.state.group !== this.lastCategoryFilters.group ||
      this.state.family !== this.lastCategoryFilters.family ||
      this.state.line !== this.lastCategoryFilters.line;
    this.setState({
      group: this.lastCategoryFilters.group,
      family: this.lastCategoryFilters.family,
      line: this.lastCategoryFilters.line,
      isCategoryFilterOpenned: false,
      persistLastCategoryFilterSelection: !haveChanges,
    });
  };

  _onCategoryFilterBackClick = () => {
    console.log('backClick');
  };

  _getAppliedFilters = () => {
    if (this.state.group + this.state.family + this.state.line === '%%%') {
      return 'Todas las Categorías';
    } else {
      let appliedFilters = '';
      if (this.state.group !== '%') {
        appliedFilters += this.state.group;
      }
      if (this.state.family !== '%') {
        appliedFilters += ' > ' + this.state.family;
      }
      if (this.state.line !== '%') {
        appliedFilters += ' > ' + this.state.line;
      }
      return appliedFilters;
    }
  };
  /**    -------------------------------    **/

  /**
   * Render home view
   *
   * @returns
   * @memberof ProductsScreen
   */
  render() {
    return (
      <>
        <BackHandlerCustom exitApp={true} />
        <HeaderSideDrawer
          navigation={this.props.navigation}
          auth={this.props.auth}
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
          rightComponent={this._rightComponent}
        />
        <SafeAreaView style={styles.container}>
          <MultilevelSidebar
            open={this.state.isCategoryFilterOpenned}
            persist={this.state.persistLastCategoryFilterSelection}
            //wrapperClassName={'multilevel-sidebar'}
            headerClassName={'multilevel-sidebar-header'}
            options={this.state.categoryFilterOptions}
            header="Categorías"
            onToggle={() => {
              console.log('Hola');
            }}
            onItemClick={this._onCategoryFilterItemClick}
            onBackClick={this._onCategoryFilterBackClick}
            onClose={this._onCategoryFilterClose}
          />
          <ButtonBranchOffice rshop={this.props.auth?.current_rshop} />
          <SearchBar
            containerStyle={styles.searchBarContainer}
            inputContainerStyle={styles.inputContainerStyle}
            inputStyle={styles.searchBarInputStyle}
            onChangeText={(term) => this._searchUpdated(term)}
            placeholder={strings.searchPlaceholder}
            placeholderTextColor={colors.grayPlaceholder}
            lightTheme
            round
            value={this.state.filter_data?.filter_value}
          />
          <TouchableOpacity
            disabled={this.state.categoryFilterOptions.length === 0}
            style={styles.appliedFilters}
            onPress={this._onShowCategoryFilter}
          >
            <Text>{this._getAppliedFilters()}</Text>
          </TouchableOpacity>
          <FlatList
            style={styles.flexOne}
            data={this.state.data}
            extraData={this.state}
            keyExtractor={this._keyExtractor}
            renderItem={this._renderItem}
            ListHeaderComponent={this._renderEmpty()}
            ListFooterComponent={this._renderFooter()}
            onEndReached={this._handleLoadMore}
            onEndReachedThreshold={0.4}
            bounces={false}
          />
          <View style={styles.total}>
            <TouchableOpacity
              style={[
                styles.totalContainer,
                this._getCurrentShoppingCartItemsAmount() > 0
                  ? styles.totalContainerEnabled
                  : styles.totalContainerDisabled,
              ]}
              disabled={!(this._getCurrentShoppingCartItemsAmount() > 0)}
              onPress={this._onPressTotal}
            >
              <View style={styles.rowDirection}>
                <View style={styles.viewRequestOrderItemsAmount}>
                  <Text style={styles.textRequestOrderItemsAmount}>{this._getCurrentShoppingCartItemsAmount()}</Text>
                </View>
                <Text style={styles.textRequestOrder}>{strings.total}</Text>
                <Text style={styles.textTotalOrder}>{accounting.formatMoney(this.props.shoppingCart.total_neto)}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </>
    );
  }
}

ProductsScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
  products: ProductsPropTypes,
  shoppingCart: ShoppingCartPropTypes,
};

ProductsScreen.defaultProps = {};

/** Export component ProductsScreen **/
export default connect((state) => ({ ...state }))(ProductsScreen);
