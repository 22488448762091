/** Import React **/
import React, { Component } from 'react';
import { Text, View, TouchableOpacity, Image, Platform } from 'react-native';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Moment **/
import Moment from 'moment';
require('moment/locale/es');
Moment.locale('es');

/** Import Constants **/
import assets from './../../constants/assets/assets';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, ShoppingHistoryItem } from './../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import shoppingImage from '../../web_assets/images/shopping.png';

let SHOPPING = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  SHOPPING = shoppingImage;
} else if (Platform.OS === 'ios') {
  SHOPPING = { uri: `${assets.productWithoutImage}` };
} else {
  SHOPPING = { uri: `@mipmap/${assets.productWithoutImage}` };
}

interface Props {
  onPressItem: (ref: ShoppingHistoryItem) => void;
  item: ShoppingHistoryItem;
  backgroundColor: string;
  auth?: Auth;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

/**
 * Class to export ShoppingHistoryFlatList view
 *
 * @class ShoppingHistoryFlatList
 * @extends {Component}
 */
class ShoppingHistoryFlatList extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   * Handle event onPress in view
   *
   * @memberof ShoppingHistoryFlatList
   */
  _onPressItem = () => {
    this.props.onPressItem(this.props.item);
  };

  /**
   * Handle _getCreatedDate and return price
   *
   * @memberof ShoppingHistoryFlatList
   */
  _getCreatedDate = () => {
    if (this.props.item && this.props.item.created_date) {
      return Moment(this.props.item.created_date).format('LL');
    } else {
      return 'Sin fecha';
    }
  };

  /**
   * Handle _getNetTotal and return price
   *
   * @memberof ShoppingHistoryFlatList
   */
  _getNetTotal = () => {
    if (this.props.item && this.props.item.net_total) {
      return accounting.formatMoney(parseFloat(this.props.item.net_total));
    } else {
      return accounting.formatMoney(0);
    }
  };

  /**
   * Get color by state
   *
   * @memberof ShoppingHistoryFlatList
   */
  _getStyleOrder = () => {
    let stateOrder;
    switch (this.props.item.state) {
      case 'APROBADO':
        stateOrder = styles.stateSuccess;
        break;
      case 'RECHAZADO':
        stateOrder = styles.stateFailure;
        break;
      case 'DESPACHADO':
        stateOrder = styles.stateDelivered;
        break;
      default:
        stateOrder = styles.statePending;
        break;
    }
    return stateOrder;
  };

  /**
   * Get label by state
   *
   * @memberof ShoppingHistoryFlatList
   */
  _getLabelOrder = () => {
    let stateOrder;
    switch (this.props.item.state) {
      case 'APROBADO':
        stateOrder = strings.orderStates.approved;
        break;
      case 'RECHAZADO':
        stateOrder = strings.orderStates.rejected;
        break;
      case 'DESPACHADO':
        stateOrder = strings.orderStates.shipped;
        break;
      default:
        stateOrder = strings.orderStates.pending;
        break;
    }
    return stateOrder;
  };

  /**
   *
   * @returns Item source parsed to string
   * @memberof ShoppingHistoryFlatList
   */
  _getSource() {
    const source = this.props.item.source;
    return source === 2 ? strings.easyPedido : strings.mySeller;
  }

  /**
   * Render view with product data
   *
   * @returns
   * @memberof ShoppingHistoryFlatList
   */
  render() {
    return (
      <TouchableOpacity onPress={this._onPressItem}>
        <View style={[styles.shopinghistoyContainer, { backgroundColor: this.props.backgroundColor }]}>
          <View style={styles.imageIconOrderSold}>
            <Image source={SHOPPING} style={styles.image} />
          </View>
          <View style={styles.orderItemSoldDesc}>
            <Text style={styles.orderSoldCode}>#{this.props.item.order_code}</Text>
            <View style={styles.dateContainer}>
              <Text style={styles.orderSoldDateLabel}>{strings.createdDate}</Text>
              <Text style={styles.orderSoldDate}>{this._getCreatedDate()}</Text>
            </View>
            <View style={styles.dateContainer}>
              <Text style={styles.orderSourceLabel}>{strings.orderSource}</Text>
              <Text style={styles.orderSoldDate}>{this._getSource()}</Text>
            </View>
            <View style={styles.stateAndPriceContainer}>
              <View style={styles.priceContainer}>
                <Text style={styles.priceText}>{this._getNetTotal()}</Text>
              </View>
              <View style={[styles.stateContainer, this._getStyleOrder()]}>
                <Text style={[styles.state]}>{this._getLabelOrder()}</Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

ShoppingHistoryFlatList.propTypes = {
  auth: AuthPropTypes,
};

ShoppingHistoryFlatList.defaultProps = {};

/** Export component ShoppingHistoryFlatList **/
export default connect((state) => ({ ...state }))(ShoppingHistoryFlatList);
