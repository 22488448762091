/** Import React **/
import { Platform, I18nManager } from 'react-native';

/** Styles to InputTextForm **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    paddingTop: 120,
  },
  mainContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  iconTimes: {
    top: 0,
    width: 30,
    height: 30,
    marginLeft: 15,
    justifyContent: 'center',
    marginTop: 10,
  },
  pickerTitleContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 10,
  },
  searchBarContainerStyle: {
    marginBottom: 10,
    flexDirection: 'row',
    height: 40,
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  itemSeparatorStyle: {
    height: 1,
    width: '90%',
    alignSelf: 'center',
    backgroundColor: colors.grayPlaceholder,
    marginTop: 8,
    marginBottom: 8,
  },
  flatListStyle: {
    maxHeight: '85%',
    minHeight: '35%',
  },
  iconGPSStyle: {
    alignItems: 'center',
    alignSelf: 'center',
    height: 20,
    width: 20,
    margin: 5,
    transform: [
      {
        scaleX: I18nManager.isRTL ? -1 : 1,
      },
    ],
  },
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.3)',
    justifyContent: 'center',
  },
  listRowContainerStyle: {
    width: '100%',
    justifyContent: 'center',
  },
  textInputStyle: {
    color: 'black',
    paddingLeft: 15,
    marginTop: Platform.OS === 'ios' ? 10 : 0,
    marginBottom: Platform.OS === 'ios' ? 10 : 0,
    alignSelf: 'center',
    flex: 1,
    textAlign: I18nManager.isRTL ? 'right' : 'left',
  },
  crossImageStyle: {
    width: 40,
    height: 40,
    marginTop: -4,
    marginRight: -7,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end',
  },
  listDataContainerStyle: {
    alignSelf: 'center',
    borderRadius: 10,
    maxHeight: '70%',
    width: '100%',
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    paddingBottom: 20,
  },
  listTextViewStyle: {
    color: colors.black,
    marginVertical: 10,
    flex: 0.9,
    marginLeft: 20,
    marginHorizontal: 10,
    textAlign: 'left',
  },
  listRowClickTouchStyle: {
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  pickerTitleTextStyle: {
    fontSize: 18,
    flex: 1,
    paddingBottom: 10,
    color: colors.black,
    textAlign: 'center',
  },
});

/** Export component style **/
export default styles;
