/** Styles to view **/
import { StyleSheet, Dimensions, ViewStyle, TextStyle, ImageStyle, Platform } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Size Screen **/
const { width, height } = Dimensions.get('window');
const TOTAL_CONTAINER_WIDTH = width * (1 - 0.1);
const TOTAL_CONTAINER_MARGIN = width * 0.05;
const LIST_HEIGHT = height - 45 - 110 - 30;

let containerObservations: ViewStyle | TextStyle | ImageStyle;
let flexOne: ViewStyle | TextStyle | ImageStyle;
let totalContainer: ViewStyle | TextStyle | ImageStyle;
let containerTextObservations: ViewStyle | TextStyle | ImageStyle;
if (Platform.OS === 'web') {
  containerObservations = {
    height: 110,
  };
  containerTextObservations = {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    overflow: 'scroll',
    height: 80,
  };
  flexOne = {
    height: LIST_HEIGHT,
  };
  totalContainer = {
    marginLeft: TOTAL_CONTAINER_MARGIN,
    marginRight: TOTAL_CONTAINER_MARGIN,
    borderRadius: 25,
    width: TOTAL_CONTAINER_WIDTH,
    height: 50,
    backgroundColor: colors.primary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
} else {
  containerObservations = {
    minHeight: 70,
    height: 'auto',
  };
  containerTextObservations = {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  };
  flexOne = {
    minHeight: 250,
  };
  totalContainer = {
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 25,
    width: '90%',
    height: 50,
    backgroundColor: colors.primary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  containerObservations: containerObservations,
  titleCartSection: {
    backgroundColor: colors.primary,
    height: 30,
    flexDirection: 'row',
  },
  titleSection: {
    color: colors.white,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 'bold',
    width: '70%',
  },
  containerTextObservations: containerTextObservations,
  flexOne: flexOne,
  centerElements: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendOrderLabelButtonTextClass: {
    color: colors.white,
  },
  imageNoFoundContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  },
  imageNoFound: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    width: 128,
    height: 128,
  },
  imageNoFoundDescriptionContainer: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescription: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: colors.grayPlaceholder,
  },
  total: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'absolute',
    bottom: 20,
    flex: 1,
  },
  totalContainer: totalContainer,
  totalText: {
    flex: 1,
    fontSize: 17,
    fontWeight: 'bold',
    color: colors.white,
    paddingLeft: 20,
    textAlign: 'left',
  },
  totalValue: {
    flex: 1,
    fontSize: 17,
    fontWeight: 'bold',
    color: colors.white,
    textAlign: 'right',
    paddingRight: 20,
  },
  stateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    borderRadius: 20,
    height: 20,
    marginTop: 5,
    marginLeft: 20,
  },
  state: {
    borderColor: 'red',
    paddingTop: 4,
    paddingRight: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    color: colors.white,
    fontSize: 10,
  },
  stateSuccess: {
    backgroundColor: colors.stateSuccess,
  },
  statePending: {
    backgroundColor: colors.statePending,
  },
  stateFailure: {
    backgroundColor: colors.primary,
  },
  backButtonStyles: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  containerFlatlist: {
    paddingBottom: 85,
    backgroundColor: colors.white,
  },
});

/** Export component styles **/
export default styles;
