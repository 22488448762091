/** Import React **/
import React, { Component } from 'react';
import { View, FlatList, Text, TouchableOpacity, Image, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Config **/
import Config from 'react-native-config';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import SearchBar **/
import { SearchBar } from 'react-native-elements';

/** Import Api **/
import { apiGet } from '../../api';
import { urlShoppingcartList, urlHome } from '../../api/urls';

/** Import components **/
import BackHandlerCustom from './../../components/backHandlerCustom';
import HeaderSideDrawer from '../../components/headerSideDrawer';
import Loader from '../../components/loader';

/** Import Constants **/
import colors from './../../constants/styles/colors';
import { routes } from './../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, Company } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setRShop, setHome } from '../../slices/auth/authSlice';
import { setShoppingCart } from '../../slices/shoppingCart/shoppingCartSlice';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

const API_URL = Platform.OS !== 'web' ? Config.API_URL : process.env.REACT_APP_API_URL;

interface Props {
  navigation: NavigationProp<any>;
  route: any;
  auth: Auth;
}

interface State {
  data?: Array<Company>;
  filter_value?: string;
}

/**
 * Class to export CompaniesListScreen
 *
 * @class CompaniesListScreen
 * @extends {Component<Props, State>}
 */
class CompaniesListScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   * Creates an instance of CompaniesListScreen.
   * @param {*} props
   * @memberof CompaniesListScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      data: this.props.auth?.array_companies,
      filter_value: undefined,
    };
  }

  /**
   * Validate user and app state
   *
   * @memberof AppNavigator
   */
  componentDidMount() {
    if (this.props.route.name === routes.companiesList) {
      let userToken = null;
      const auth = this.props.auth;
      const current_rshop = auth && auth.current_rshop;
      if (auth && auth.csrftoken && auth.csrftoken.length > 0) {
        userToken = auth.csrftoken;
      }
      /** This will switch to the App screen or Auth screen and this loading screen will be unmounted and thrown away **/
      if (
        userToken &&
        current_rshop &&
        current_rshop.rshop_id === null &&
        auth &&
        auth.array_branchoffices &&
        auth.array_branchoffices.length > 0
      ) {
        this.props.navigation.navigate(routes.branchOfficesList, { backRoute: true });
      }
    }
  }

  /**
   * componentDidUpdate
   *
   * @param {*} nextProps
   * @memberof SingleDatepicker
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.auth?.csrftoken !== prevProps.auth?.csrftoken && this.props.auth?.csrftoken === undefined) {
      this.props.navigation.navigate(routes.login);
    }
  }

  /**
   * Return an id to each view
   *
   * @memberof CompaniesListScreen
   */
  keyExtractor = (item: Company) => {
    return `${item.company_code}`;
  };

  /**
   * Function to togle item favorite
   *
   * @memberof CompaniesListScreen
   */
  onPressItem = (item: Company) => () => {
    colors.setPrimaryColor(item.ecommerce_primary_color);
    this.Loader.openLoader(async () => {
      const branch_offices = [];
      for (const branch_office in item.branch_offices) {
        branch_offices.push({
          ecommerce_image: item.ecommerce_image,
          company_name: item.company_name,
          company_code: item.company_code,
          ecommerce_sac_contact: item.ecommerce_sac_contact,
          client_id: item.branch_offices[branch_office].client_id,
          client_name: item.branch_offices[branch_office].client_name,
          rshop_id: item.branch_offices[branch_office].rshop_id,
          min_order_value: item.branch_offices[branch_office].min_order_value,
          client_address: item.branch_offices[branch_office].client_address,
          zone_settings: item.branch_offices[branch_office].zone_settings,
          apply_taxes: item.apply_taxes,
          apply_charge_values_to: item.apply_charge_values_to,
          ecommerce_orders: item.ecommerce_orders,
          ecommerce_wallet: item.ecommerce_wallet,
          ecommerce_contact_supplier: item.ecommerce_contact_supplier,
          ecommerce_contact_seller: item.ecommerce_contact_seller,
          has_activate_logistics_zone: item.has_activate_logistics_zone,
          zone_default: item.zone_default,
          ecommerce_deliveries: item.ecommerce_deliveries,
          has_rsales_operation: item.has_rsales_operation === 'Y',
          disabled_sales: item.branch_offices[branch_office].disabled_sales,
          ecommerce_cellar_select: item.ecommerce_cellar_select,
        });
      }

      if (branch_offices && branch_offices.length === 1) {
        const rShop = {
          company: branch_offices[0],
          branch_offices: branch_offices,
        };
        const rshop_id = rShop.company && rShop.company && rShop.company.rshop_id ? rShop.company.rshop_id : '';
        const urlShoppingcartListReplaced = urlShoppingcartList.replace(/\[rshop_id\]/g, String(rshop_id));
        const urlHomeReplaced = urlHome.replace(/\[rshop_id\]/g, String(rshop_id));
        const data = {
          csrfmiddlewaretoken: this.props.auth?.csrftoken,
        };
        try {
          const responseShoppinCart = await apiGet(urlShoppingcartListReplaced, data)();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (responseShoppinCart.success) {
            const responseHome = await apiGet(urlHomeReplaced, data)();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (responseHome.success) {
              store.dispatch(setRShop(rShop));
              store.dispatch(setShoppingCart(responseShoppinCart));
              store.dispatch(setHome(responseHome));
              if (this.props.auth.is_logged) {
                const drawerPropsNavigation = this.props.route.params?.drawerPropsNavigation;
                if (drawerPropsNavigation && drawerPropsNavigation.closeDrawer) {
                  drawerPropsNavigation.closeDrawer();
                }
                this.props.navigation.navigate(routes.order, { backRoute: false });
              }
            } else {
              this.Loader.closeLoader(() => showAlert(responseHome, 'danger'));
            }
          } else {
            this.Loader.closeLoader(() => showAlert(responseShoppinCart, 'danger'));
          }
        } catch (error) {
          if (error.message) {
            error.message += ' (003).';
          }
          this.Loader.closeLoader(() => showAlert(error, 'danger'));
        }
      } else {
        const data = {
          company: {
            ecommerce_image: item.ecommerce_image,
            company_name: item.company_name,
            company_code: item.company_code,
            ecommerce_sac_contact: item.ecommerce_sac_contact,
            client_id: null,
            client_name: null,
            rshop_id: null,
            min_order_value: null,
            client_address: null,
            zone_settings: null,
            apply_taxes: item.apply_taxes,
            apply_charge_values_to: item.apply_charge_values_to,
            ecommerce_orders: item.ecommerce_orders,
            ecommerce_wallet: item.ecommerce_wallet,
            ecommerce_contact_supplier: item.ecommerce_contact_supplier,
            ecommerce_contact_seller: item.ecommerce_contact_seller,
            has_activate_logistics_zone: item.has_activate_logistics_zone,
            zone_default: item.zone_default,
            ecommerce_deliveries: item.ecommerce_deliveries,
            has_rsales_operation: item.has_rsales_operation === 'Y',
            disabled_sales: null,
            ecommerce_cellar_select: item.ecommerce_cellar_select,
          },
          branch_offices: branch_offices,
        };
        const route =
          this.props.route.name === routes.companiesList ? routes.branchOfficesList : routes.drawerBranchOfficesList;
        this.Loader.closeLoader(() => {
          store.dispatch(setRShop(data));
          const drawerPropsNavigation = this.props.route.params?.drawerPropsNavigation;
          this.props.navigation.navigate(route, { backRoute: true, drawerPropsNavigation });
        });
      }
    });
  };

  /**
   * Handle event to update filter_value
   *
   * @param {*} term
   * @memberof CompaniesListScreen
   */
  searchUpdated(term: string) {
    const data = this.props.auth?.array_companies?.filter(function (company) {
      return (
        company.company_code.indexOf(term) > -1 || company.company_name.toLowerCase().indexOf(term.toLowerCase()) > -1
      );
    });
    this.setState({
      filter_value: term,
      data,
    });
  }

  /**
   * Render item
   *
   * @memberof CompaniesListScreen
   */
  renderItem = ({ item }: { item: Company }) => (
    <TouchableOpacity onPress={this.onPressItem(item)}>
      <View style={styles.itemContainer}>
        <View style={styles.companyImageContainer}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <Image source={{ uri: API_URL.replace('/appsupremo', item.ecommerce_image) }} style={styles.image} />
        </View>
        <View style={styles.companyContainer}>
          <Text numberOfLines={1} ellipsizeMode={'tail'} style={styles.companyTitle}>
            {item.company_name}
          </Text>
          <Text style={styles.orderMinPrice}>
            {strings.nit}
            {item.company_code}
          </Text>
          <Text numberOfLines={1} ellipsizeMode={'tail'} style={styles.orderMinPrice}>
            {strings.address}
            {item.company_address}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  /**
   * Render sign in view
   *
   * @returns
   * @memberof CompaniesListScreen
   */
  render() {
    return (
      <>
        <BackHandlerCustom exitApp={this.props.auth.current_rshop?.rshop_id === undefined} />
        <HeaderSideDrawer
          mainLabel={strings.headerTitleShop}
          color={colors.white}
          backgroundColor={colors.primary}
          showBack={true}
          navigation={this.props.navigation}
          route={this.props.auth.current_rshop?.rshop_id ? this.props.route : {}}
          paddingTop={13}
        />
        <SafeAreaView style={styles.container}>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <SearchBar
            containerStyle={styles.searchBarContainer}
            inputContainerStyle={styles.inputContainerStyle}
            inputStyle={styles.searchBarInputStyle}
            onChangeText={(term) => this.searchUpdated(term)}
            placeholder={strings.searchPlaceholder}
            placeholderTextColor={colors.grayPlaceholder}
            lightTheme
            round
            value={this.state.filter_value}
          />
          <FlatList
            style={styles.flexOne}
            data={this.state.data}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItem}
            bounces={false}
          />
        </SafeAreaView>
      </>
    );
  }
}

CompaniesListScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

CompaniesListScreen.defaultProps = {};

/** Export component CompaniesListScreen **/
export default connect((state) => ({ ...state }))(CompaniesListScreen);
