/** Import Redux Toolkit **/
import { createSlice } from '@reduxjs/toolkit';

/** Import Initial State **/
import { INITIAL_STATE } from './initialState';

/** Import Interfaces **/
import { ShoppingCartItem } from './../../interfaces';

/** Import Utils **/
import totalizeOrder from './../../utils/totalizeOrder';

const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState: INITIAL_STATE,
  reducers: {
    setShoppingCart(state, action) {
      const data = action.payload.shopping_cart.map((item: ShoppingCartItem) => ({
        ...item,
        charge_value: item.price_charges_values,
      }));
      const totals = totalizeOrder(data);
      return {
        ...state,
        data,
        total_neto: totals.totalNeto,
        totalPriceWithoutTaxes: totals.totalPriceWithoutTaxes,
        totalRealTaxes: totals.totalRealTaxes,
        totalTaxes: totals.totalTaxes,
        totalChargeValue: totals.totalChargeValue,
      };
    },
    addShoppingCartItem(state, action) {
      let data = [...state.data];

      if (data && data.length > 0) {
        const indexFound = data.findIndex(
          (itemState) =>
            (itemState.product_code === action.payload.item.code &&
              parseFloat(itemState.factor) === parseFloat(action.payload.item.factors[0].factor)) ||
            (itemState.product_code === action.payload.item.product_code &&
              parseFloat(itemState.factor) === parseFloat(action.payload.item.factor)),
        );

        if (indexFound > -1) {
          data = [...data.slice(0, indexFound), action.payload.item, ...data.slice(indexFound + 1)];
        } else {
          data = [...data, action.payload.item];
        }
      } else if (data && data.length === 0) {
        data = [...data, action.payload.item];
      }

      const totals = totalizeOrder(data);
      return {
        ...state,
        data,
        total_neto: totals.totalNeto,
        totalPriceWithoutTaxes: totals.totalPriceWithoutTaxes,
        totalRealTaxes: totals.totalRealTaxes,
        totalTaxes: totals.totalTaxes,
        totalChargeValue: totals.totalChargeValue,
        observations: state.data && state.data.length > 0 ? state.observations : '',
      };
    },
    removeShoppingCartItem(state, action) {
      if (state.data && state.data.length > 0) {
        const indexFound = state.data.findIndex(
          (itemState) =>
            (itemState.product_code === action.payload.item.code &&
              parseFloat(itemState.factor) === parseFloat(action.payload.item.factors[0].factor)) ||
            (itemState.product_code === action.payload.item.product_code &&
              parseFloat(itemState.factor) === parseFloat(action.payload.item.factor)),
        );

        const data = [...state.data.slice(0, indexFound), ...state.data.slice(indexFound + 1)];
        const totals = totalizeOrder(data);
        return {
          ...state,
          data,
          total_neto: totals.totalNeto,
          totalPriceWithoutTaxes: totals.totalPriceWithoutTaxes,
          totalRealTaxes: totals.totalRealTaxes,
          totalTaxes: totals.totalTaxes,
          totalChargeValue: totals.totalChargeValue,
          observations: state.data && state.data.length > 0 ? state.observations : '',
        };
      } else {
        return { ...state };
      }
    },
    saveObservations(state, action) {
      return {
        ...state,
        observations: action.payload.observations,
      };
    },
    toggleShoppingCartItem(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          (itemState.product_code === action.payload.item.code &&
            parseFloat(itemState.factor) === parseFloat(action.payload.item.factors[0].factor)) ||
          (itemState.product_code === action.payload.item.product_code &&
            parseFloat(itemState.factor) === parseFloat(action.payload.item.factor)),
      );

      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...state.data[indexFound],
            is_favorite: action.payload.state === 1 ? 'Y' : 'N',
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    cleanShoppingCart() {
      return INITIAL_STATE;
    },
    addToDeleteShoppingCartItem(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          itemState.product_code === action.payload.product_code &&
          parseFloat(itemState.factor) === parseFloat(action.payload.factor),
      );
      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...state.data[indexFound],
            to_delete: action.payload.to_delete,
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
    updateMinimumAmountShoppingCartItem(state, action) {
      const indexFound = state.data.findIndex(
        (itemState) =>
          itemState.product_code === action.payload.product_code &&
          parseFloat(itemState.factor) === parseFloat(action.payload.factor),
      );
      if (indexFound > -1) {
        const data = [
          ...state.data.slice(0, indexFound),
          {
            ...state.data[indexFound],
            minimum_amount: action.payload.minimum_amount,
          },
          ...state.data.slice(indexFound + 1),
        ];
        return {
          ...state,
          data,
        };
      } else {
        return {
          ...state,
        };
      }
    },
  },
});

export const {
  setShoppingCart,
  addShoppingCartItem,
  removeShoppingCartItem,
  toggleShoppingCartItem,
  saveObservations,
  cleanShoppingCart,
  addToDeleteShoppingCartItem,
  updateMinimumAmountShoppingCartItem,
} = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
