/** Styles to Button **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    borderTopWidth: 1,
    borderColor: colors.loaderContainer,
  },
});

/** Export component style **/
export default styles;
