/** Import Redux Toolkit **/
import { createSlice } from '@reduxjs/toolkit';

/** Import Initial State **/
import { INITIAL_STATE } from './initialState';
import colors from '../../constants/styles/colors';

declare let global: any;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setAuth(state, action) {
      const companies = [];
      for (const key in action.payload.companies) {
        companies.push(action.payload.companies[key]);
      }
      return {
        ...state,
        ...action.payload,
        array_companies: companies,
        array_branchoffices: null,
        current_rshop: null,
      };
    },
    updateSesionPassword(state) {
      let action;
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...action.payload,
        user: { ...state.user, password_status: { message: '', password_expired: false, password_updated: true } },
      };
    },
    resetNextRouteName(state) {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        next_route_name: '',
      };
    },
    setRShop(state, action) {
      // Setiamos en la variable global el apply_taxes de la compania.
      global.apply_taxes = action.payload.company.apply_taxes;
      global.apply_charge_values_to = action.payload.company.apply_charge_values_to;
      return {
        ...state,
        array_branchoffices: action.payload.branch_offices,
        current_rshop: action.payload.company,
      };
    },
    setHome(state, action) {
      return {
        ...state,
        user: state.user
          ? {
              ...state.user,
              portfolio: action.payload.data,
              result_totals: action.payload.result_totals,
              array_cellars: action.payload.cellars,
            }
          : undefined,
        current_rshop: state.current_rshop
          ? {
              ...state.current_rshop,
              ecommerce: {
                ecommerce_phone_sellers_contact: action.payload.ecommerce_phone_sellers_contact,
                ecommerce_sac_contact: state.current_rshop && state.current_rshop.ecommerce_sac_contact,
              },
            }
          : undefined,
        is_logged: true,
      };
    },
    logout(state) {
      delete global.apply_taxes;
      delete global.apply_charge_values_to;
      try {
        INITIAL_STATE.email = state.email;
        colors.setPrimaryColor('');
      } catch (e) {}
      return INITIAL_STATE;
    },
    updateSesionProfile(state, action) {
      return {
        ...state,
        email: action.payload.user_email,
        username: action.payload.username.replace('+', ''),
        user: state.user
          ? {
              ...state.user,
              user_email: action.payload.user_email,
              user_name: action.payload.user_name,
              username: action.payload.username,
            }
          : undefined,
      };
    },
  },
});

export const {
  setAuth,
  updateSesionPassword,
  setRShop,
  setHome,
  logout,
  resetNextRouteName,
  updateSesionProfile,
} = authSlice.actions;

export default authSlice.reducer;
