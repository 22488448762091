/** Import React **/
import React, { Component } from 'react';
import { View, Text, Image, Platform, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { apiGet } from '../../api';
import { urlShoppinghistoryDetail } from '../../api/urls';

/** Import components **/
import HeaderSideDrawer from '../../components/headerSideDrawer';
import LoaderWithoutModal from '../../components/loaderWithoutModal';
import ShoppingHistoryDetailFlatList from '../../components/shoppingHistoryDetailFlatList';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from '../../constants/styles/colors';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, ShoppingHistory, ShoppingHistoryDetailItem, ShoppingHistoryItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes, ShoppingHistoryPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setShoppingHistoryDetail } from '../../slices/shoppingHistory/shoppingHistorySlice';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
const NOT_FOUND = Platform.OS === 'ios' ? { uri: `${assets.notFound}` } : { uri: `@mipmap/${assets.notFound}` };

interface Props {
  navigation: NavigationProp<any>;
  route: any;
  auth: Auth;
  shoppingHistory: ShoppingHistory;
}

interface State {
  loading: boolean;
  data: Array<ShoppingHistoryDetailItem>;
  currentOrder: ShoppingHistoryItem;
}

/**
 * Class to export ShoppingHistoryDetailScreen
 *
 * @class ShoppingHistoryDetailScreen
 * @extends {Component<Props, State>}
 */
class ShoppingHistoryDetailScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   * Creates an instance of ShoppingHistoryDetailScreen.
   * @param {*} props
   * @memberof ShoppingHistoryDetailScreen
   */
  constructor(props: Props) {
    super(props);
    const currentOrder = this.props.route.params?.currentOrder ?? undefined;
    this.state = {
      loading: false,
      data: [],
      currentOrder,
    };
  }

  /**
   * Functin to get ShoppingHistoryDetailScreen when moduled is opened
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  componentDidMount() {
    this._shoppingHistoryDetail();
  }

  /**
   * Function to get rshop_id
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Function to call service and get products
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _shoppingHistoryDetail = () => {
    if (this.state.loading) {
      return;
    }
    if (this.props.shoppingHistory.currentOrder?.order_code === this.state.currentOrder?.order_code) {
      this.setState({
        data: this.props.shoppingHistory.detail,
        loading: false,
      });
    } else {
      this.setState({ loading: true }, async () => {
        const urlShoppinghistoryDetailReplaced = urlShoppinghistoryDetail.replace(
          /\[rshop_id\]/g,
          String(this._getRshop_id()),
        );
        const data = {
          csrfmiddlewaretoken: this.props.auth?.csrftoken,
          order_code: this.state.currentOrder.order_code,
        };
        try {
          const response = await apiGet(urlShoppinghistoryDetailReplaced, data)();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (response.success) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            response.data_loaded = this.state.data;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            response.currentOrder = this.state.currentOrder;
            store.dispatch(setShoppingHistoryDetail(response));
            this.setState({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              data: response.data,
              loading: false,
            });
          } else {
            this.setState({ loading: false }, () => showAlert(response, 'danger'));
          }
        } catch (error) {
          if (error.message) error.message += ' (017).';
          this.setState({ loading: false }, () => showAlert(error, 'danger'));
        }
      });
    }
  };

  /**
   * Return an id to each view
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _keyExtractor = (item: ShoppingHistoryDetailItem) => {
    return `${item.product_code}-${parseFloat(item.factor)}`;
  };

  /**
   * Render item from ShoppingHistoryDetailScreen view
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _renderItem = ({ item, index }: { item: ShoppingHistoryDetailItem; index: number }) => (
    <ShoppingHistoryDetailFlatList
      backgroundColor={colors.stripedColors[index % colors.stripedColors.length]}
      item={item}
    />
  );

  /**
   * Render emtpy component
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _renderEmpty = () => {
    return (this.state.data && this.state.data.length > 0) || this.state.loading ? null : (
      <View style={styles.imageNoFoundContainer}>
        <Image source={NOT_FOUND} style={styles.imageNoFound} />
        <View style={styles.imageNoFoundDescriptionContainer}>
          <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
        </View>
      </View>
    );
  };

  /**
   * Render loader in footer
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _renderFooter = () => {
    if (this.state.loading) {
      return <LoaderWithoutModal />;
    }
  };

  /**
   * Return state order
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _getStateOrder = () => {
    if (this.props.shoppingHistory && this.props.shoppingHistory.currentOrder) {
      return this.props.shoppingHistory.currentOrder.state;
    } else {
      return 'Sin estado';
    }
  };

  /**
   * Get total of item in shopping cart
   *
   * @memberof ShoppingHistoryDetailScreen
   */
  _getTotalAmount = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingHistory && this.props.shoppingHistory.detail
      ? this.props.shoppingHistory.detail.length
      : 0;
  };

  /**
   * Get total order
   *
   * @memberof ShoppingCart
   */
  _getTotalHistoryDetail = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingHistory &&
      this.props.shoppingHistory.detail &&
      this.props.shoppingHistory.detail.length > 0 &&
      this.props.shoppingHistory.currentOrder
      ? accounting.formatMoney(parseFloat(this.props.shoppingHistory.currentOrder.net_total))
      : 0;
  };

  /**
   * Get total of item in history cart
   *
   * @memberof ShoppingCart
   */
  getTotalAmount = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingHistory && this.props.shoppingHistory.detail
      ? this.props.shoppingHistory.detail.length
      : 0;
  };

  /**
   * Render sign in view
   *
   * @returns
   * @memberof ShoppingHistoryDetailScreen
   */
  render() {
    return (
      <>
        <HeaderSideDrawer
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
          showBack={true}
          navigation={this.props.navigation}
          route={this.props.route}
          paddingTop={13}
        />
        <SafeAreaView style={styles.container}>
          <View style={styles.containerObservations}>
            <View style={styles.titleCartSection}>
              <Text style={styles.titleSection}>{strings.observations}</Text>
            </View>
            <View style={styles.containerTextObservations}>
              <Text>
                {!this.state.loading &&
                this.props.shoppingHistory &&
                this.props.shoppingHistory.currentOrder &&
                this.props.shoppingHistory.currentOrder.observations &&
                this.props.shoppingHistory.currentOrder.observations.length > 0
                  ? this.props.shoppingHistory.currentOrder.observations
                  : ''}
              </Text>
            </View>
          </View>
          <View style={styles.titleCartSection}>
            <Text style={styles.titleSection}>{`Resumen de compra (${this._getTotalAmount()})`}</Text>
            <View style={[styles.stateContainer, styles.stateSuccess]}>
              <Text style={styles.state}>{this._getStateOrder()}</Text>
            </View>
          </View>
          <FlatList
            style={styles.flexOne}
            data={this.state.data}
            keyExtractor={this._keyExtractor}
            renderItem={this._renderItem}
            ListHeaderComponent={this._renderEmpty()}
            ListFooterComponent={this._renderFooter()}
            contentContainerStyle={styles.containerFlatlist}
            bounces={false}
          />
          <View style={styles.total}>
            <View style={styles.totalContainer}>
              <Text style={styles.totalText}>{strings.total}</Text>
              <Text style={styles.totalValue}>{this._getTotalHistoryDetail()}</Text>
            </View>
          </View>
        </SafeAreaView>
      </>
    );
  }
}

ShoppingHistoryDetailScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
  shoppingHistory: ShoppingHistoryPropTypes,
};

ShoppingHistoryDetailScreen.defaultProps = {};

/** Export component ShoppingHistoryDetailScreen **/
export default connect((state) => ({ ...state }))(ShoppingHistoryDetailScreen);
