/** Import Constants **/
import stringsConstants from '../constants/strings/strings';

/** Import parseFormErrors **/
import parseFormErrors from './parseFormErrors';

/** Import Slices **/
import { store, cleanAndLogout } from '../store';
import { showSnackBar, hideSnackBar } from '../slices/snackBar/snackBarSlice';
import { cleanShoppingCart } from '../slices/shoppingCart/shoppingCartSlice';
import { Platform } from 'react-native';

const showAlert = (
  err: any,
  theme: string,
  callBack?: (value?: string | undefined) => void,
  visibleCustom?: boolean,
) => {
  const errorParsed = {
    internal_code: -1,
    form_errors: '',
  };

  if (Platform.OS === 'web' && err && err.data) {
    err = err.data;
  }

  if (!err.success && err.internal_code === stringsConstants.internalError1111) {
    errorParsed.internal_code = stringsConstants.internalError1111;
    errorParsed.form_errors = err.msg && err.msg.length > 0 ? `${err.msg}` : `${stringsConstants.menssageError}`;
  } else if (!err.success && err.internal_code === stringsConstants.internalError1112) {
    errorParsed.internal_code = stringsConstants.internalError1112;
    errorParsed.form_errors =
      err.message && err.message.length > 0 ? `${err.message}` : `${stringsConstants.menssageError}`;
  } else if (!err.success && err.data && err.data.internal_code === stringsConstants.internalError1111) {
    errorParsed.internal_code = stringsConstants.internalError1111;
    errorParsed.form_errors =
      err.data.messages && err.data.messages.length > 0
        ? `${err.data.messages[0]}`
        : `${stringsConstants.menssageError}`;
  } else if (!err.success && err.data && err.data.internal_code === stringsConstants.internalError4003) {
    errorParsed.internal_code = stringsConstants.internalError4003;
    errorParsed.form_errors =
      err.data.messages && err.data.messages.length > 0
        ? `${err.data.messages[0]}`
        : `${stringsConstants.menssageError}`;
  } else if (!err.success && err.data && err.data.status === stringsConstants.internalError500) {
    errorParsed.internal_code = stringsConstants.internalError500;
    errorParsed.form_errors =
      err.data.messages && err.data.messages.length > 0
        ? `${err.data.messages[0]}`
        : `${stringsConstants.menssageError}`;
  } else if (!err.success && err.data && err.data.internal_code === stringsConstants.internalError4444) {
    errorParsed.internal_code = stringsConstants.internalError4444;
    errorParsed.form_errors =
      err.data.messages && err.data.messages.length > 0
        ? `${err.data.messages[0]}`
        : `${stringsConstants.menssageError}`;
  } else if (!err.form_errors || Object.keys(err.form_errors).length === 0) {
    errorParsed.form_errors = err.error ? err.error : err.message ? err.message : stringsConstants.menssageError;
  } else if (err.form_errors) {
    errorParsed.form_errors = parseFormErrors(err.form_errors);
  } else {
    errorParsed.form_errors = stringsConstants.menssageError;
  }

  // Si requiere autenticación o expiró la contraseña o se encuentra inactivo el proveedor/comercion limpiar y redirigir a login
  if (
    errorParsed.internal_code === stringsConstants.internalError1111 ||
    errorParsed.internal_code === stringsConstants.internalError4003 ||
    errorParsed.internal_code === stringsConstants.internalError4444
  ) {
    callBack = cleanAndLogout;
  }

  if (errorParsed.internal_code === stringsConstants.internalError1112) {
    store.dispatch(cleanShoppingCart());
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callBack = () => {};
  }

  const timer = 2000;
  store.dispatch(
    showSnackBar({
      textMessage: errorParsed.form_errors,
      actionHandler: callBack
        ? () => {
            store.dispatch(hideSnackBar());
            callBack && callBack();
          }
        : null,
      autoHidingTime: callBack ? 0 : timer,
      theme,
      visibleCustom,
    }),
  );
  if (!callBack) {
    setTimeout(() => store.dispatch(hideSnackBar()), timer);
  }
};

/** Export component showAlert **/
export default showAlert;
