/** Styles to view **/
import { Dimensions, Platform, StyleSheet } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

const { width } = Dimensions.get('window');
const MODAL_WIDTH = width;

let modalViewMarginBottom;
if (Platform.OS === 'web') {
  modalViewMarginBottom = -20;
} else {
  modalViewMarginBottom = 0;
}

/** Create an object style **/
const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    paddingTop: 120,
  },
  modalView: {
    width: MODAL_WIDTH,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    paddingTop: 20,
    paddingRight: 15,
    paddingBottom: 20,
    paddingLeft: 15,
    marginBottom: modalViewMarginBottom,
  },
  iconBack: {
    top: 0,
    width: 48,
    height: 56,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
    transform: [{ rotate: '180deg' }],
  },
  modalTitle: {
    marginBottom: 15,
    fontSize: 18,
    color: colors.black,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  modalSubTitle: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 15,
    color: colors.gray,
    textAlign: 'center',
  },
  containerInput: {
    marginTop: 5,
  },
  inputText: {
    borderColor: colors.softGray,
    height: 44,
    borderWidth: 1,
    color: colors.black,
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 10,
    marginBottom: 10,
  },
  icCheck: {
    position: 'absolute',
    bottom: 5,
    right: 0,
    marginRight: 40,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: colors.primary,
    marginTop: 10,
    marginBottom: 10,
  },
  buttonDisabled: {
    backgroundColor: colors.buttoDisabled,
    marginTop: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: colors.white,
  },
  icShowPassword: {
    position: 'absolute',
    bottom: 5,
    right: 0,
    marginRight: 14,
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

/** Export component style **/
export default styles;
