/** Import modules **/
import React, { Component } from 'react';
import { SafeAreaView, Text, View, FlatList, Image, ScrollView, TouchableOpacity, Platform } from 'react-native';
import PropTypes from 'prop-types';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { apiGet } from '../../api';
import { urlShoppingcartList } from '../../api/urls';

/** Import components **/
import ButtonBranchOffice from '../../components/buttonBranchOffice';
import HeaderSideDrawer from '../../components/headerSideDrawer';
import Loader from '../../components/loader';
import LoaderWithoutModal from '../../components/loaderWithoutModal';
import ProductsFlatList from '../../components/productsFlatList';

/** Import Constants **/
import assets from '../../constants/assets/assets';
import colors from '../../constants/styles/colors';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, ProductItem, ShoppingCartItem, ShoppingCart } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes, ProductsPropTypes, ShoppingCartPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setShoppingCart } from '../../slices/shoppingCart/shoppingCartSlice';

/** Import Utils **/
import parseProductData from '../../utils/parseProductData';
import showAlert from '../../utils/showAlert';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import notFoundImage from '../../web_assets/images/not_found.png';

let NOT_FOUND = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NOT_FOUND = notFoundImage;
} else if (Platform.OS === 'ios') {
  NOT_FOUND = { uri: `${assets.notFound}` };
} else {
  NOT_FOUND = { uri: `@mipmap/${assets.notFound}` };
}

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  shoppingCart: ShoppingCart;
  route: any;
}

interface State {
  loading: boolean;
  data: Array<any>;
  load_data: boolean;
  handleLoadMore: boolean;
}

/**
 * Class to export ShoppingCartScreen
 *
 * @class ShoppingCartScreen
 * @extends {Component<Props, State>}
 */
class ShoppingCartScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public timer!: any;

  public Loader!: any;
  public ModalConfirm!: any;

  /**
   * Creates an instance of ShoppingCartScreen.
   * @param {*} props
   * @memberof ShoppingCartScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      load_data: true,
      handleLoadMore: true,
    };
  }

  /**
   * Functin to get ShoppingCartScreen when moduled is opened
   *
   * @memberof ShoppingCartScreen
   */
  componentDidMount() {
    this.timer = null;
    if (this._getTotalAmount() > 0) {
      this.setState({
        data: this.parsedProducts(this.props.shoppingCart.data),
      });
    } else {
      this._shoppingCart();
    }
  }

  /**
   * Function to set false mounted
   *
   * @memberof ShoppingCartScreen
   */
  componentWillUnmount() {
    this.timer = null;
  }

  /**
   * Function to receive props
   *
   * @param {*} prevProps
   * @memberof ShoppingCartScreen
   */
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id &&
      prevProps.auth &&
      prevProps.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id !== prevProps.auth.current_rshop.rshop_id
    ) {
      this._updateState();
    }

    if (this.props.shoppingCart !== prevProps.shoppingCart) {
      this._updateShoppingCart();
    }
  }

  /**
   * Function to update state from component did mount
   *
   * @memberof ShoppingCartScreen
   */
  _updateState = () => {
    this.setState(
      {
        loading: false,
        data: [],
        load_data: true,
        handleLoadMore: true,
      },
      () => this._shoppingCart(),
    );
  };

  /**
   * Functionto update state from component did update
   *
   * @memberof ShoppingCartScreen
   */
  _updateShoppingCart = () => {
    this.setState({ data: this.parsedProducts(this.props.shoppingCart.data) });
  };

  /**
   * Function to get rshop_id
   *
   * @memberof ShoppingCartScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Get total of item in shopping carg
   *
   * @memberof ShoppingCartScreen
   */
  _getTotalAmount = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data ? this.props.shoppingCart.data.length : 0;
  };

  /**
   * Function to call service and get products
   *
   * @memberof ShoppingCartScreen
   */
  _shoppingCart = () => {
    if (
      this.state.loading ||
      (this.props.auth.csrftoken && this.props.auth.csrftoken.length === 0) ||
      !this.props.auth.current_rshop ||
      (this.props.auth.current_rshop && !this.props.auth.current_rshop.rshop_id)
    ) {
      return;
    }
    this.setState({ loading: true }, async () => {
      const urlShoppingcartListReplaced = urlShoppingcartList.replace(/\[rshop_id\]/g, String(this._getRshop_id()));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
      };
      try {
        const response = await apiGet(urlShoppingcartListReplaced, data)();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.data_loaded = this.state.data;

          store.dispatch(setShoppingCart(response));

          this.setState({
            loading: false,
            data: this.parsedProducts(this.props.shoppingCart.data),
            load_data: false,
          });
        } else {
          this.setState({ loading: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (013).';
        }
        this.setState({ loading: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Function to merge ProductItems with the ShoppingCartItems
   */
  parsedProducts = (products: any) => {
    const mergedProducts: Array<any> = [];
    let key: any;
    for (key in products) {
      mergedProducts.push(parseProductData(products[key], undefined));
    }
    return mergedProducts;
  };

  /**
   * Return an id to each view
   *
   * @memberof ShoppingCartScreen
   */
  _keyExtractor = (item: ShoppingCartItem) => {
    return `${item.product_code}-${parseFloat(item.factor)}`;
  };

  /**
   * Function to open detail
   *
   * @memberof ShoppingCartScreen
   */
  _onPressItem = (item: ShoppingCartItem) => {
    this.props.navigation.navigate(routes.productsDetail, {
      backRoute: routes.shoppingCart,
      item: item,
      originalItem: item,
    });
  };

  /**
   * Function to open product image gallery
   *
   * @memberof ShoppingCartScreen
   */
  _onPressImage = (item: ProductItem) => {
    this.props.navigation.navigate(routes.productImageGallery, {
      backRoute: routes.shoppingCart,
      item: item,
      originalItem: item,
    });
  };

  /**
   * Render item from ProductsFlatList view
   *
   * @memberof ShoppingCartScreen
   */
  _renderItem = ({ item, index }: { item: ProductItem; index: number }) => (
    <ProductsFlatList
      backgroundColor={colors.stripedColors[index % colors.stripedColors.length]}
      item={item}
      onPressItem={(itemPressed: any) => this._onPressItem(itemPressed)}
      onPressImage={(itemPressed: any) => {
        this._onPressImage(itemPressed);
      }}
      fromView="ShoppingCart"
    />
  );

  /**
   * Render emtpy component
   *
   * @memberof ShoppingCartScreen
   */
  _renderEmpty = () => {
    return (this.state.data && this.state.data.length > 0) || this.state.loading ? null : (
      <View style={styles.imageNoFoundContainer}>
        <Image source={NOT_FOUND} style={styles.imageNoFound} />
        <View style={styles.imageNoFoundDescriptionContainer}>
          <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
        </View>
      </View>
    );
  };

  /**
   * Render loader in footer
   *
   * @memberof ShoppingCartScreen
   */
  _renderFooter = () => {
    if (this.state.loading) {
      return <LoaderWithoutModal />;
    }
  };

  /**
   * Function to handle scroll bottom
   *
   * @memberof ShoppingCartScreen
   */
  _handleLoadMore = () => {
    if (this.state.loading) {
      return;
    }
    if (this.state.load_data) {
      this._shoppingCart();
    } else {
      this.setState({
        data:
          this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
            ? this.props.shoppingCart.data
            : [],
      });
    }
  };

  _onPressTotal = () => {
    this.props.navigation.navigate(routes.checkout, {
      backRoute: routes.products,
    });
  };

  /**
   * Get total price without taxes
   *
   * @memberof ShoppingCartScreen
   */
  _getTotalPriceWithoutTaxes = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.totalPriceWithoutTaxes)
      : 0;
  };

  /**
   * Get real tax
   *
   * @memberof ShoppingCartScreen
   */
  _getTotalRealTax = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.totalRealTaxes)
      : 0;
  };

  /**
   * Get total taxes
   *
   * @memberof ShoppingCartScreen
   */
  _getTotalTaxes = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.totalTaxes)
      : 0;
  };

  /**
   * Get total order
   *
   * @memberof ShoppingCartScreen
   */
  _getTotalChargeValue = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.totalChargeValue)
      : 0;
  };

  /**
   * Get total order
   *
   * @memberof ShoppingCartScreen
   */
  _getTotalOrder = () => {
    if (this.state.loading) {
      return accounting.formatMoney(0);
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.total_neto)
      : accounting.formatMoney(0);
  };

  /**
   * Get current items amount on shopping cart
   *
   * @memberof ShoppingCartScreen
   */
  _getCurrentItemsAmount = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.state.data ? this.state.data.length : 0;
  };

  _getNotAvailableItemsAmount = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.state.data.filter((obj) => obj.to_delete).length;
  };

  /**
   * Render home view
   *
   * @returns
   * @memberof ShoppingCartScreen
   */
  render() {
    return (
      <>
        <Loader onRef={(ref) => (this.Loader = ref)} />
        <HeaderSideDrawer
          navigation={this.props.navigation}
          auth={this.props.auth}
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
          showBack={true}
          route={this.props.route}
          paddingTop={13}
        />
        <SafeAreaView style={styles.container}>
          <ButtonBranchOffice rshop={this.props.auth?.current_rshop} />
          <View style={styles.containerWrapper}>
            <View style={styles.titleCartSection}>
              <Text style={styles.titleSection}>{`Resumen de compra (${this._getCurrentItemsAmount()})`}</Text>
            </View>
            {this._getNotAvailableItemsAmount() > 0 ? (
              <View>
                <Text style={styles.titleNotAvailable}>
                  {`Productos no disponibles (${this._getNotAvailableItemsAmount()})`}
                </Text>
              </View>
            ) : null}
            <ScrollView bounces={false}>
              <FlatList
                contentContainerStyle={styles.containerFlatList}
                data={this.state.data}
                extraData={this.state}
                keyExtractor={this._keyExtractor}
                renderItem={this._renderItem}
                ListHeaderComponent={this._renderEmpty()}
                ListFooterComponent={this._renderFooter()}
                bounces={false}
              />
            </ScrollView>
            <View style={styles.total}>
              <TouchableOpacity
                style={[
                  styles.totalContainer,
                  this.state.data && this.state.data.length > 0
                    ? styles.totalContainerEnabled
                    : styles.totalContainerDisabled,
                ]}
                disabled={!(this._getCurrentItemsAmount() > 0)}
                onPress={this._onPressTotal}
              >
                <View style={styles.rowDirection}>
                  <View style={styles.viewRequestOrderItemsAmount}>
                    <Text style={styles.textRequestOrderItemsAmount}>{this._getCurrentItemsAmount()}</Text>
                  </View>
                  <Text style={styles.textRequestOrder}>{strings.sendOrder}</Text>
                  <Text style={styles.textTotalOrder}>{this._getTotalOrder()}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
      </>
    );
  }
}

ShoppingCartScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
  products: ProductsPropTypes,
  shoppingCart: ShoppingCartPropTypes,
};

ShoppingCartScreen.defaultProps = {};

/** Export component ShoppingCartScreen **/
export default connect((state) => ({ ...state }))(ShoppingCartScreen);
