/** Import Interfaces **/
import { ProductTotalize } from '../interfaces';

declare let global: any;

/** Function to return total neto and subtotal of item **/
export default (item: ProductTotalize, calcAsUnit?: boolean) => {
  const price = parseFloat(item.price);
  const quantity = calcAsUnit ? 1 : parseFloat(item.quantity) || 1;
  let factor = parseFloat(String(item.factor));
  const tax = global.apply_taxes ? parseFloat(item.tax) : parseFloat('0');
  const charge_value =
    global.apply_charge_values_to === 'pri' ? parseFloat(item.price_charges_values) : parseFloat(item.charge_value);

  if (item.factors) {
    factor = parseFloat(String(item.factors[0].factor));
  }

  const totalNeto = (price * (1 + tax / 100) + charge_value) * (quantity * factor);

  return {
    totalNeto: totalNeto,
    subTotal: price * quantity * factor,
    priceByFactor: price * factor,
    priceWithoutTaxes: price * quantity * factor,
    totalRealTaxes: tax,
    taxes: price * (tax / 100) * quantity * factor,
    chargeValue: charge_value * quantity * factor,
  };
};
