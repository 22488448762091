/** Import React **/
import React from 'react';
import { View, Text } from 'react-native';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import components **/
import { IconMoon } from '../icon/IconMoon';

/** Import Constants **/
import colors from '../../constants/styles/colors';
import styles from './style';

/** Import Interfaces **/
import { ShoppingCart } from '../../interfaces';

/**
 * Get total of item in shopping carg
 *
 * @memberof ProductsScreen
 */
const getTotalAmount = (shoppingCart?: ShoppingCart) => {
  return shoppingCart && shoppingCart.data ? shoppingCart.data.length : 0;
};

interface Props {
  name: string;
  color: string;
  size: number;
  focused: boolean;
  showBadge: boolean;
  text?: string;
  shoppingCart?: ShoppingCart;
  width?: number;
  height?: number;
  textStyles?: any;
}

/**
 * Function to create a button rounded
 *
 * @param {Props} { name, focused }
 * @returns
 */
const IconWithBackground = ({
  name,
  color,
  size,
  focused,
  showBadge,
  shoppingCart,
  text,
  width,
  height,
  textStyles,
}: Props) => {
  const quantityBadge = getTotalAmount(shoppingCart);
  const elementWidth = width || 20;
  const elementHeight = height || 10;
  const elementTextStyles = textStyles || {};
  /**
   * Render component with Icon
   *
   * @returns
   * @memberof IconWithBackground
   */
  return (
    <View style={[styles.container, { width: size + elementWidth, height: size + elementHeight }]}>
      {/* {focused ? <View style={[styles.containerFocused, { width: size + 20, height: size + 10 }]}></View> : null} */}
      {showBadge && quantityBadge > 0 ? (
        <View style={styles.textContainer}>
          <View style={styles.badgeContainer}>
            <View style={styles.badge}>
              <Text style={styles.badgeText} numberOfLines={1}>
                {quantityBadge}
              </Text>
            </View>
            <IconMoon name={name} size={size} color={focused ? color : colors.gray} />
          </View>
          {text ? (
            <Text style={[styles.text, elementTextStyles]} numberOfLines={1}>
              {text}
            </Text>
          ) : null}
        </View>
      ) : (
        <View style={styles.textContainer}>
          <IconMoon name={name} size={size} color={focused ? color : colors.gray} />
          {text ? (
            <Text style={[styles.text, elementTextStyles]} numberOfLines={1}>
              {text}
            </Text>
          ) : null}
        </View>
      )}
    </View>
  );
};

/** Export component IconWithBackground **/
export default connect((state) => ({ ...state }))(IconWithBackground);
