/** Import Geolocation **/
import Geolocation from '@react-native-community/geolocation';

/** Import DeviceInfo **/
import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';

let lastPosition: any = {};

/**
 * Function to add info of device and return by promise
 *
 * @export
 * @param {*} obj
 * @returns
 */
export default function addDeviceInfo(obj: any) {
  const returnDeviceInfo = (latitude: number, longitude: number, accuracy: number, batteryLevel: number) => {
    lastPosition = { latitude, longitude, accuracy, lastCall: new Date().getTime() };

    obj.device_id = DeviceInfo.getUniqueId();
    obj.latitude = latitude;
    obj.longitude = longitude;
    obj.accuracy = accuracy;
    obj.battery = (batteryLevel * 100).toFixed(0);
    obj.version = DeviceInfo.getVersion();
    return obj;
  };

  return new Promise((resolve) => {
    DeviceInfo.getBatteryLevel().then((batteryLevel) => {
      let diffMinutes;
      if (lastPosition.lastCall) {
        diffMinutes = (new Date().getTime() - lastPosition.lastCall) / 1000 / 60;
      }

      if (Platform.OS === 'web') {
        resolve(returnDeviceInfo(0, 0, 0, batteryLevel));
      } else if (!diffMinutes || (diffMinutes && diffMinutes >= 1)) {
        Geolocation.getCurrentPosition(
          (position: any) =>
            resolve(
              returnDeviceInfo(
                position.coords.latitude,
                position.coords.longitude,
                position.coords.accuracy,
                batteryLevel,
              ),
            ),
          () => resolve(returnDeviceInfo(0, 0, 0, batteryLevel)),
        );
      } else {
        resolve(returnDeviceInfo(lastPosition.latitude, lastPosition.longitude, lastPosition.accuracy, batteryLevel));
      }
    });
  });
}
