/** Import React **/
import React, { Component } from 'react';
import { View, TouchableOpacity, Text, TextInput } from 'react-native';

/** Import SnackBar **/
import SnackBar from 'react-native-snackbar-component';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Create password and schemaPassword to validate **/
import passwordValidator from 'password-validator';
const schemaPassword = new passwordValidator();
schemaPassword
  .is()
  .min(6) // Minimum length 6
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .symbols(); // Must have symbols (!@$&) letters

/** Import Api **/
import { apiPost } from './../../api';
import { urlPasswordChange } from './../../api/urls';

/** Components **/
import Button from './../../components/button';
import { IconMoon } from './../../components/icon/IconMoon';
import Loader from './../../components/loader';

/** Import constants **/
import colors from './../../constants/styles/colors';
import icons from './../../constants/icons/icons';
import stringsConstants from './../../constants/strings/strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, Input, SnackBarData } from './../../interfaces';

/** Import Slices **/
import { store } from '../../store';
import { updateSesionPassword } from '../../slices/auth/authSlice';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Utils **/
import debounce from './../../utils/debounce';
import showAlert from './../../utils/showAlert';

import { SafeAreaProvider } from 'react-native-safe-area-context';

/** Const to define size in icons **/
const ICON_BACK_SIZE = 40;
const ICON_SHOW_PASSWORD = 20;

interface Props {
  onRef: (ref: any) => void;
  auth?: Auth;
  snackBar?: SnackBarData;
}

interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  invalidForm?: boolean;
  firstPassword?: Input;
  secondPassword?: Input;
  callBack?: () => void | null;
  hideFirstPassword?: boolean;
  hideSecondPassword?: boolean;
}

/**
 * Create Modal with password
 *
 * @param {*} props
 * @returns
 */
class ModalPassword extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   *Creates an instance of ModalPassword.
   * @param {*} props
   * @memberof ModalPassword
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
      invalidForm: true,
      firstPassword: {
        value: '',
        error: false,
      },
      secondPassword: {
        value: '',
        error: false,
      },
      hideFirstPassword: true,
      hideSecondPassword: true,
    };
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalPassword
   */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalPassword
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  /**
   * Open alert
   *
   * @memberof ModalPassword
   */
  openModalPassword = (callBack?: () => void) => {
    this.setState({
      isVisible: true,
      invalidForm: true,
      firstPassword: {
        value: '',
        error: false,
      },
      secondPassword: {
        value: '',
        error: false,
      },
      callBack: callBack,
    });
  };

  /**
   * Close alert
   *
   * @memberof ModalPassword
   */
  closeModalPassword = (callBack: boolean) => {
    if (callBack && this.state.callBack) {
      this.state.callBack();
    }
    this.setState({
      isVisible: false,
    });
  };

  /**
   * Function to update state by on change
   *
   * @memberof MobileOperatorScreen
   */
  _onChangeText = (newState: State) => {
    this.setState(newState);
    debounce(50, () => {
      this._isInvalidForm();
    });
  };

  /**
   * Function to handle if the form is valid or invalid
   *
   * @memberof ModalPassword
   */
  _isInvalidForm = () => {
    const { firstPassword, secondPassword } = this.state;
    if (
      !firstPassword?.value ||
      firstPassword.value.length === 0 ||
      !secondPassword?.value ||
      secondPassword.value.length === 0 ||
      !firstPassword.error ||
      !secondPassword.error
    ) {
      this.setState({ invalidForm: true });
    } else {
      this.setState({ invalidForm: false });
    }
  };

  /**
   * Function to validate email, password
   *
   * @memberof RegisterDataScreen
   */
  _validateForm = (callBack: (isValid: boolean, title?: string, message?: string) => void) => {
    const { firstPassword, secondPassword } = this.state;
    if (!firstPassword?.value || firstPassword.value.length === 0) {
      callBack(false, stringsConstants.newPassword, stringsConstants.required);
    } else if (!secondPassword?.value || secondPassword.value.length === 0) {
      callBack(false, stringsConstants.confirmPassword, stringsConstants.required);
    } else if (firstPassword?.value !== secondPassword?.value) {
      callBack(false, stringsConstants.password, stringsConstants.passwordMatched);
    } else {
      callBack(true);
    }
  };

  /**
   * Function to confirm code
   *
   * @memberof ModalPassword
   */
  _updatePassword = () => {
    this._validateForm((isValid, title, message) => {
      if (isValid) {
        this.Loader.openLoader(async () => {
          const pk = this.props.auth && this.props.auth.user ? this.props.auth.user.user_id : '';
          const url = urlPasswordChange.replace(/\[pk\]/g, String(pk));
          const { firstPassword, secondPassword } = this.state;
          const data = {
            csrfmiddlewaretoken: this.props.auth?.csrftoken,
            password: firstPassword?.value,
            password2: secondPassword?.value,
            old_password: '',
          };
          try {
            const response = await apiPost(url, data)();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (response.success) {
              this.Loader.closeLoader(() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                store.dispatch(updateSesionPassword(response.data));
                this.closeModalPassword(true);
              });
            } else {
              this.Loader.closeLoader(() => showAlert(response, 'danger', undefined, true));
            }
          } catch (error) {
            if (error.message) error.message += ' (005).';
            this.Loader.closeLoader(() => showAlert(error, 'danger', undefined, true));
          }
        });
      } else {
        showAlert({ error: `${title}: ${message}` }, 'danger', undefined, true);
      }
    });
  };

  /**
   * Render ModalPassword in modal
   *
   * @returns
   * @memberof ModalPassword
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        avoidKeyboard={true}
        style={styles.modal}
      >
        <SafeAreaProvider>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <View style={styles.modalBackground}>
            <View style={styles.modalView}>
              <TouchableOpacity style={styles.iconBack} onPress={() => this.closeModalPassword(false)}>
                <IconMoon name={icons.icNextBlack} size={ICON_BACK_SIZE} color={colors.black} />
              </TouchableOpacity>
              <Text style={styles.modalTitle}>{stringsConstants.requestNewPassword}</Text>
              <Text style={styles.modalSubTitle}>{stringsConstants.typeNewPassword}</Text>
              <View style={styles.containerInput}>
                <TextInput
                  style={styles.inputText}
                  placeholder={stringsConstants.newPassword}
                  secureTextEntry={this.state.hideFirstPassword}
                  onChangeText={(val) =>
                    this._onChangeText({ firstPassword: { value: val, error: schemaPassword.validate(val) } })
                  }
                />
                {this.state.firstPassword?.error ? (
                  <View style={styles.icCheck}>
                    <IconMoon name={icons.icCheck} size={ICON_SHOW_PASSWORD} color={colors.greenFlat} />
                  </View>
                ) : null}
                <TouchableOpacity
                  style={styles.icShowPassword}
                  onPress={() => this.setState({ hideFirstPassword: !this.state.hideFirstPassword })}
                >
                  <IconMoon
                    name={this.state.hideFirstPassword ? icons.icHidePassword : icons.icShowPassword}
                    size={ICON_SHOW_PASSWORD}
                    color={colors.gray}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.containerInput}>
                <TextInput
                  style={styles.inputText}
                  placeholder={stringsConstants.confirmPassword}
                  secureTextEntry={this.state.hideSecondPassword}
                  onChangeText={(val) =>
                    this._onChangeText({ secondPassword: { value: val, error: schemaPassword.validate(val) } })
                  }
                />
                {this.state.secondPassword?.error ? (
                  <View style={styles.icCheck}>
                    <IconMoon name={icons.icCheck} size={ICON_SHOW_PASSWORD} color={colors.greenFlat} />
                  </View>
                ) : null}
                <TouchableOpacity
                  style={styles.icShowPassword}
                  onPress={() => this.setState({ hideSecondPassword: !this.state.hideSecondPassword })}
                >
                  <IconMoon
                    name={this.state.hideSecondPassword ? icons.icHidePassword : icons.icShowPassword}
                    size={ICON_SHOW_PASSWORD}
                    color={colors.gray}
                  />
                </TouchableOpacity>
              </View>
              <Button
                onPress={this._updatePassword}
                buttonClass={this.state.invalidForm ? styles.buttonDisabled : styles.button}
                buttonLabelText={stringsConstants.confirm}
                buttonLabelTextClass={styles.buttonText}
              />
            </View>
          </View>
          <SnackBar
            visible={this.props.snackBar?.visibleCustom}
            textMessage={this.props.snackBar?.textMessage}
            actionHandler={this.props.snackBar?.actionHandler}
            actionText={this.props.snackBar?.actionText}
            position={this.props.snackBar?.position}
            top={this.props.snackBar?.top}
            bottom={this.props.snackBar?.bottom}
            autoHidingTime={this.props.snackBar?.autoHidingTime}
            backgroundColor={this.props.snackBar?.backgroundColor}
            accentColor={this.props.snackBar?.accentColor}
            messageColor={this.props.snackBar?.messageColor}
            containerStyle={this.props.snackBar?.containerStyle}
          />
        </SafeAreaProvider>
      </Modal>
    );
  }
}

ModalPassword.propTypes = {
  auth: AuthPropTypes,
};

ModalPassword.defaultProps = {};

/** Export component ModalPassword **/
export default connect((state) => ({ ...state }))(ModalPassword);
