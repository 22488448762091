/** Import modules **/
import React, { Component } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Import Components **/
import { IconMoon } from '../icon/IconMoon';

/** Import Constants **/
import icons from '../../constants/icons/icons';
import colors from '../../constants/styles/colors';
import StringsContants from '../../constants/strings/strings';
import styles from './style';

/** Import Interfaces **/
import { Company } from '../../interfaces';

/** Import PropTypes **/
import { CompanyPropTypes } from '../../propTypes';

import { SafeAreaProvider } from 'react-native-safe-area-context';

/** Const to define size in icons **/
const ICON_SIZE = 24;
//const ICON_SIZE_CLOSE = 14;

interface Props {
  rshop?: Company;
}

interface State {
  backdropOpacity: number;
  animationIn: any;
  loading: boolean;
}

/**
 * Class to override and create a new ButtonBranchBriefcase with style
 *
 * @export
 * @class ButtonBranchOffice
 * @extends {Component<Props, State>}
 */
class ButtonBranchBriefcase extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   *Creates an instance of Loader.
   * @param {*} props
   * @memberof Loader
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      loading: false,
    };
  }

  /**
   * Function to open modal
   *
   * @memberof ButtonBranchBriefcase
   */
  _openInfo = () => {
    this.setState({ loading: true });
  };

  /**
   * Function to open modal
   *
   * @memberof ButtonBranchBriefcase
   */
  _closeInfo = () => {
    this.setState({ loading: false });
  };

  /**
   * Render component with TouchableOpacity
   *
   * @returns
   * @memberof ButtonBranchBriefcase
   */
  render() {
    return this.props.rshop ? (
      <View>
        <TouchableOpacity onPress={this._openInfo} style={styles.button}>
          <View style={styles.containerText}>
            <Text style={styles.buttonTextTop}>{StringsContants.branchOffice.toUpperCase()}</Text>
          </View>
          <IconMoon name={icons.icInfo} size={ICON_SIZE} color={colors.black} />
        </TouchableOpacity>

        <Modal
          backdropOpacity={this.state.backdropOpacity}
          animationIn={this.state.animationIn}
          isVisible={this.state.loading}
        >
          <SafeAreaProvider>
            <View style={styles.modalBackground}>
              <View style={styles.modalHeader}>
                <Text style={styles.modalTitle}>{StringsContants.tituloCartera}</Text>
              </View>
              <View style={styles.containerDetail}>
                <Text style={styles.modalLabel}>{StringsContants.infoCartera}</Text>
              </View>
              <TouchableOpacity onPress={this._closeInfo} style={styles.modalButton}>
                <Text style={styles.buttonTextBottom}>{StringsContants.ready}</Text>
              </TouchableOpacity>
            </View>
          </SafeAreaProvider>
        </Modal>
      </View>
    ) : (
      <View />
    );
  }
}

ButtonBranchBriefcase.propTypes = {
  rshop: CompanyPropTypes,
};

ButtonBranchBriefcase.defaultProps = {};

/** Export component ButtonBranchOffice **/
export default ButtonBranchBriefcase;
