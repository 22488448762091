/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Historial de compras',
  dropdownListLabel: 'Estado del pedido:',
  orderSourceDropdownLabel: 'Origen Pedido',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
  placeholderSelect: 'Todos',
  orderStates: {
    approved: 'Solicitud Recibida',
    pending: 'Pendiente',
    rejected: 'Rechazado',
    shipped: 'Despachado',
  },
  orderSources: {
    seller: 'Mi Vendedor',
    app: 'EasyPedido',
  },
};

/** Export strings **/
export default strings;
