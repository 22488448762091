/** Import React **/
import React, { Component } from 'react';
import { View, Text, Image, ScrollView, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import components **/
import Button from './../../components/button';
import BackHandlerCustom from './../../components/backHandlerCustom';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Images **/
import orderSentImage from '../../web_assets/images/order_sent.png';
import { Auth } from '../../interfaces';
import { urlOpenWhatsapp } from '../../api/urls';

let ORDER_SENT = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ORDER_SENT = orderSentImage;
} else if (Platform.OS === 'ios') {
  ORDER_SENT = { uri: `${assets.orderSent}` };
} else {
  ORDER_SENT = { uri: `@mipmap/${assets.orderSent}` };
}

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

/**
 * Class to export ShoppingCartDetailScreen
 *
 * @class ShoppingCartDetailScreen
 * @extends {Component<Props, State>}
 */
class ShoppingCartDetailScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   * Creates an instance of ShoppingCartDetailScreen.
   * @param {*} props
   * @memberof ShoppingCartDetailScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Go the the Home and open whatsapp chat
   *
   * @returns Void
   */
  private _returnToWhatsapp(): void {
    this.props.navigation.navigate(routes.tabProducts);
    window.open(urlOpenWhatsapp, '_blank');
  }

  /**
   * Render sign in view
   *
   * @returns
   * @memberof ShoppingCartDetailScreen
   */
  render() {
    return (
      <>
        <BackHandlerCustom avoidBack={true} />
        <SafeAreaView style={styles.container}>
          <ScrollView bounces={false}>
            <View style={styles.containerItem}>
              <Image source={ORDER_SENT} style={styles.image} />
            </View>
            <View style={styles.containerText}>
              <Text style={styles.titleText}>{strings.title}</Text>
              <Text style={styles.subtitleText}>{strings.subtitle}</Text>
              <View style={styles.containerState}>
                <Text style={styles.stateText}>{strings.state}</Text>
              </View>
              <View style={styles.containerDescription}>
                <Text style={styles.descriptionText}>{strings.description}</Text>
              </View>
            </View>
            <View style={styles.containerButton}>
              <Button
                onPress={() => this.props.navigation.navigate(routes.tabProducts)}
                buttonClass={styles.registerButton}
                buttonLabelText={strings.home.toUpperCase()}
                buttonLabelTextClass={styles.preLoginLabelButtonTextClass}
              />
              {this.props.auth.is_from_wa ? (
                <Button
                  onPress={() => this._returnToWhatsapp()}
                  buttonClass={styles.returnToWaButton}
                  buttonLabelText={strings.returnToWa}
                  buttonLabelTextClass={styles.returnToWaButtonTextClass}
                />
              ) : null}
            </View>
          </ScrollView>
        </SafeAreaView>
      </>
    );
  }
}

ShoppingCartDetailScreen.propTypes = {
  navigation: PropTypes.any,
};

ShoppingCartDetailScreen.defaultProps = {};

/** Export component ShoppingCartDetailScreen **/
export default connect((state) => ({ ...state }))(ShoppingCartDetailScreen);
