/** Styles to view **/
import { StyleSheet, Dimensions, Platform } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Create const to dimension **/
const { width } = Dimensions.get('window');
const DEVICE_WIDTH = width;

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  imageNoFoundContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  },
  imageNoFound: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescriptionContainer: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescription: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: colors.grayPlaceholder,
  },
  filterList: {
    width: '98%',
    height: 40,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderRadius: 5,
    backgroundColor: 'rgba(142,142,147,0.12)',
    color: '#8E8E93',
    position: 'relative',
  },
  itemContainer: {
    backgroundColor: '#ffffff',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    height: 90,
  },
  companyImageContainer: {
    width: 80,
    height: 54,
    marginLeft: 5,
    alignItems: 'center',
    marginRight: 5,
  },
  image: {
    width: 80,
    height: 54,
    backgroundColor: '#ffffff',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  companyTitle: {
    fontWeight: 'bold',
    width: DEVICE_WIDTH - 120,
    fontSize: 14,
  },
  companyContainer: {
    width: DEVICE_WIDTH - 120,
  },
  orderMinPrice: {
    fontSize: 13,
  },
  quotaAvailable: {
    fontSize: 10,
  },
  searchInput: {
    width: '98%',
    height: 40,
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    borderRadius: 5,
    backgroundColor: 'rgba(142,142,147,0.12)',
    color: '#8E8E93',
    position: 'relative',
  },
  clearICon: {
    position: 'absolute',
    color: '#8E8E93',
    bottom: -22,
    right: 0,
  },
  searchBarContainer: {
    backgroundColor: colors.white,
    borderWidth: 0,
    borderTopWidth: 0,
    borderTopColor: colors.white,
    borderBottomColor: colors.white,
    borderBottomWidth: 0,
  },
  inputContainerStyle: {
    backgroundColor: colors.searchBarBackground,
  },
  searchBarInputStyle: {
    backgroundColor: colors.searchBarBackground,
    color: colors.black,
  },
  headerOuterContainerStyles: {
    height: Platform.OS === 'ios' ? 70 : 48,
    borderBottomWidth: 0,
  },
  headerCenterComponent: {
    color: colors.white,
    fontSize: 18,
    fontWeight: 'bold',
  },
  flexOne: {
    flex: 1,
  },
});

/** Export component styles **/
export default styles;
