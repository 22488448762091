/** Import modules **/
import React from 'react';
import { TouchableOpacity } from 'react-native';

/** Import Constants **/
// import strings from './strings';
import icons from '../../constants/icons/icons';
import colors from '../../constants/styles/colors';
import IconWithBackground from '../iconWithBackground';
import { NavigationProp } from '@react-navigation/core';
import { routes } from '../../constants/routes/routes';

interface Props {
  navigation: NavigationProp<any>;
  backRoute: string;
}

/**
 * Create shoppingCartButton
 *
 * @returns
 */
const ShoppingCartButton = ({ navigation, backRoute }: Props) => {
  function _onPress() {
    navigation.navigate(routes.shoppingCart, {
      backRoute: backRoute,
    });
  }

  /**
   * Render component with Icon
   *
   * @returns
   * @memberof Button
   */
  return (
    <TouchableOpacity onPress={_onPress}>
      <IconWithBackground
        name={icons.icShopping}
        color={colors.primary}
        size={20}
        focused={false}
        showBadge={true}
        width={40}
      />
    </TouchableOpacity>
  );
};

/** Export component Button **/
export default ShoppingCartButton;
