/** Styles to Button **/
import { Platform, StyleSheet } from 'react-native';

/** Import Constants **/
import colors from './../../constants/styles/colors';

let itemProductContainer;
if (Platform.OS === 'web') {
  itemProductContainer = {
    marginLeft: 2,
    marginRight: 8,
    marginTop: 10,
    marginBottom: 10,
  };
} else {
  itemProductContainer = {
    marginLeft: 2,
    marginRight: 2,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    margin: 2,
    padding: 5,
  },
  itemAddedContainer: {
    zIndex: 10000,
    backgroundColor: colors.white,
    position: 'absolute',
    right: 8,
    top: -5,
    borderRadius: 100,
  },
  itemAddedIcon: {
    borderRadius: 100,
    backgroundColor: colors.greenFlat,
  },
  image: {
    width: 60,
    height: 50,
    marginLeft: 7,
    marginRight: 5,
  },
  itemProductImageContainer: {
    marginRight: 5,
    marginLeft: 5,
    borderWidth: 0,
    borderColor: 'blue',
  },
  itemProductDetailContainer: {
    flexDirection: 'column',
    width: '80%',
    marginLeft: 2,
    borderWidth: 0,
  },
  itemProductDescriptionContainer: {
    flexDirection: 'row',
    width: '100%',
    borderWidth: 0,
    borderColor: 'blue',
    flex: 1,
    justifyContent: 'flex-start',
  },
  itemProductDescription: {
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    flex: 3,
    paddingRight: 5,
  },
  itemProductDescriptionRed: {
    color: colors.danger,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    flex: 3,
    paddingRight: 5,
  },
  itemProductFactor: {
    fontStyle: 'italic',
    color: colors.grayPlaceholder,
  },
  itemProductFactorRed: {
    fontStyle: 'italic',
    color: colors.danger,
  },
  itemProductPriceButtonContainer: {
    flexDirection: 'row',
  },
  itemProductPriceContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemProductFactorContainer: {
    width: '50%',
  },
  itemProductTotalContainer: {
    width: '50%',
    alignItems: 'flex-end',
    paddingRight: 15,
  },
  itemProductPriceContainerShoppingCart: {
    flexDirection: 'row',
    width: '40%',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  itemProductPrice: {
    fontWeight: 'bold',
    fontSize: 18,
    color: 'rgb(7, 149, 7)',
  },
  itemProductPriceShoppingCart: {
    fontSize: 15,
    color: colors.greenFlat,
  },
  itemProductButtonsContainer: {
    flexDirection: 'row',
    borderWidth: 0,
    width: '100%',
    paddingTop: 10,
  },
  itemProductFavoriteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
  shoppingItemContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    borderWidth: 0,
    borderColor: 'green',
    alignSelf: 'flex-end',
    width: '40%',
  },
  itemProductCartButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 27,
    borderRadius: 5,
    marginRight: 10,
    border: '1px solid ' + colors.grayTextDescription,
  },
  itemProductTrashButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 27,
    borderRadius: 5,
    marginRight: 10,
    border: '1px solid ' + colors.grayTextDescription,
  },
  itemProductTrashButtonRed: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 27,
    borderRadius: 5,
    marginRight: 10,
    backgroundColor: colors.danger,
  },
  containerProductCartButton: {
    width: '30%',
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
  successButon: {
    backgroundColor: colors.greenFlat,
  },
  defaultButton: {
    backgroundColor: colors.white,
    marginRight: 0,
  },
  itemProductContainer: itemProductContainer,
  editButtonShopingCart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 42,
    borderWidth: 1,
    borderColor: colors.blueRSales,
    backgroundColor: colors.white,
    borderRadius: 5,
    marginLeft: 0,
    marginRight: 10,
  },
  buttonContainer: {
    padding: 0,
    alignContent: 'stretch',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
  },
  deleteButtonShopingCart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 42,
    borderWidth: 1,
    borderColor: colors.primary,
    backgroundColor: colors.white,
    borderRadius: 5,
    marginLeft: 0,
    marginRight: 10,
  },
  containerAddingButtons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    display: 'flex',
    flex: 1,
    width: '70%',
    paddingRight: 10,
  },
  buttonsDec: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(49, 163, 190, 0.57)',
    borderRadius: 5,
    width: 30,
    height: 30,
    border: '1px solid ' + colors.grayTextDescription,
  },
  textInputQuantity: {
    borderWidth: 1,
    borderColor: colors.grayPlaceholder,
    borderRadius: 5,
    width: 40,
    marginRight: 15,
    marginLeft: 15,
    paddinTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    textAlign: 'center',
  },
  buttonsInc: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(49, 163, 190, 0.72)',
    borderRadius: 5,
    width: 30,
    height: 30,
    border: '1px solid ' + colors.grayTextDescription,
  },
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: 'transparent',
  },
  activityIndicatorWrapper: {
    position: 'absolute',
    top: 10,
    backgroundColor: 'transparent',
    height: 100,
    width: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  isLoadingContainer: {
    zIndex: 10000,
    backgroundColor: 'rgba(230, 228, 228, 0.38)',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 135,
    paddingTop: 50,
    paddingLeft: '46%',
  },
  isLoadingIndicator: {
    borderRadius: 100,
    backgroundColor: colors.transparent,
    width: 36,
    height: 36,
  },
  containerMinimumAmount: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginTop: 5,
    paddingLeft: '60%',
  },
  textInputQuantityRed: {
    borderWidth: 1,
    borderColor: colors.danger,
    borderRadius: 5,
    width: 40,
    marginRight: 15,
    marginLeft: 15,
    paddinTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    textAlign: 'center',
  },
});

/** Export component styles **/
export default styles;
