/** Styles to view **/
import { Dimensions, ImageStyle, Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

const { height } = Dimensions.get('window');
const LIST_HEIGHT = height - 45 - 52 - 35 - 70;

let imageNotFoundContainer: ViewStyle | TextStyle | ImageStyle;
if (Platform.OS === 'web') {
  imageNotFoundContainer = {
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: '25%',
    marginLeft: '5%',
    marginRight: '5%',
  };
} else {
  imageNotFoundContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  };
}

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  containerDescription: {
    height: 27,
    flexDirection: 'row',
  },
  titleDescription: {
    color: colors.primary,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 'bold',
  },
  titleCartSection: {
    backgroundColor: colors.white,
    width: '100hv',
    height: 35,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  titleSection: {
    color: colors.black,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 'bold',
    fontSize: 15,
  },
  titleNotAvailable: {
    color: colors.danger,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    fontWeight: 'bold',
    fontSize: 15,
  },
  flexOne: {
    height: LIST_HEIGHT,
  },
  containerFlatList: {
    backgroundColor: colors.white,
    height: LIST_HEIGHT,
  },
  centerElements: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendOrderButton: {
    backgroundColor: colors.primary,
  },
  sendOrderLabelButtonTextClass: {
    color: colors.white,
  },
  imageNoFoundContainer: imageNotFoundContainer,
  imageNoFound: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
    width: 128,
    height: 128,
  },
  imageNoFoundDescriptionContainer: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    marginLeft: 20,
  },
  imageNoFoundDescription: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: colors.grayPlaceholder,
  },
  total: {
    backgroundColor: colors.white,
    width: '100%',
    paddingTop: 15,
    height: 70,
  },
  totalContainer: {
    display: 'flex',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    left: '2.5%',
    width: '95%',
    height: 55,
  },
  totalContainerEnabled: {
    backgroundColor: colors.primary,
  },
  totalContainerDisabled: {
    backgroundColor: colors.grayPlaceholder,
  },
  containerInput: {
    flex: 4,
    marginBottom: 20,
  },
  containerObservations: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },
  flexRow: {
    flexDirection: 'row',
  },
  maxObservations: {
    color: colors.primary,
    marginBottom: 5,
  },
  textArea: {
    height: 65,
    borderColor: colors.primary,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderRadius: 5,
    textAlignVertical: 'top',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  totalOrderContainer: {
    backgroundColor: colors.primary,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalOrderPrice: {
    fontSize: 19,
    color: colors.white,
    fontWeight: 'bold',
  },
  rowDirection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  viewRequestOrderItemsAmount: {
    paddingLeft: 20,
    width: '33%',
  },
  textRequestOrderItemsAmount: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.white,
    width: 40,
    height: 30,
    textAlign: 'center',
    paddingTop: 5,
    borderRadius: 10,
    backgroundColor: 'rgba(89, 88, 88, 68)',
  },
  textRequestOrder: {
    width: '27%',
    fontSize: 17,
    fontWeight: 'bold',
    color: colors.white,
    paddingLeft: 20,
    textAlign: 'center',
  },
  textTotalOrder: {
    width: '40%',
    fontSize: 17,
    textAlign: 'right',
    color: colors.white,
    paddingRight: 20,
  },
  containerWrapper: {
    flex: 1,
    flexDirection: 'column',
  },
  containerTitleAndObservations: {
    height: 130,
  },
  titleTotalCartSection: {
    backgroundColor: colors.primary,
    flexDirection: 'row',
  },
  titleTotalSection: {
    color: colors.white,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    fontWeight: 'bold',
  },
  containerAllTotals: {
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    marginBottom: 90,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray,
    backgroundColor: colors.white,
  },
  containerTotals: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerTotalsLeft: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  totalsLabel: {
    color: colors.gray,
    fontSize: 12,
  },
  containerTotalsRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  totalsValue: {
    color: colors.black,
    fontSize: 16,
  },
});

/** Export component style **/
export default styles;
