/** Import React **/
import React, { Component } from 'react';
import { View, Text, Image, Platform, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { apiGet } from '../../api';
import { urlDeliveryhistoryList, urlDeliveryhistoryState } from '../../api/urls';

/** Import components **/
import HeaderSideDrawer from '../../components/headerSideDrawer';
import InputSelectForm from '../../components/inputSelectForm';
import LoaderWithoutModal from '../../components/loaderWithoutModal';
import DeliveryHistoryFlatList from '../../components/deliveryHistoryFlatList';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from '../../constants/styles/colors';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, FilterData, OrderState, DeliveryHistory, DeliveryHistoryItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes, DeliveryHistoryPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setDeliveryHistory } from '../../slices/deliveryHistory/deliveryHistorySlice';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

/** Global variables **/
const WAIT_INTERVAL = 800;

/** Import Images **/
import notFoundImage from '../../web_assets/images/not_found.png';

let NOT_FOUND = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NOT_FOUND = notFoundImage;
} else if (Platform.OS === 'ios') {
  NOT_FOUND = { uri: `${assets.notFound}` };
} else {
  NOT_FOUND = { uri: `@mipmap/${assets.notFound}` };
}

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  deliveryHistory: DeliveryHistory;
}

interface State {
  type_search: string;
  loading: boolean;
  order_state: Array<OrderState>;
  data: Array<DeliveryHistoryItem>;
  filter_data: FilterData;
  fromDetail: boolean;
  statusFilter: string;
}

/**
 * Class to export DeliveryHistoryScreen
 *
 * @class DeliveryHistoryScreen
 * @extends {Component<Props, State>}
 */
class DeliveryHistoryScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public timer!: any;
  public _unsubscribe!: any;

  /**
   * Creates an instance of DeliveryHistoryScreen.
   * @param {*} props
   * @memberof DeliveryHistoryScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      type_search: 'LIKENAMEANDCODE',
      loading: false,
      order_state: [],
      statusFilter: '',
      data: [],
      filter_data: {
        filter_value: '',
        page: {
          has_next: true,
          next_page_number: 1,
        },
      },
      fromDetail: false,
    };
  }

  /**
   * Functin to get DeliveryHistoryScreen when moduled is opened
   *
   * @memberof DeliveryHistoryScreen
   */
  componentDidMount() {
    this._deliveryHistoryState();
    this.timer = null;
    this._unsubscribe = this.props.navigation.addListener('focus', () => {
      if (!this.state.fromDetail) {
        this._cleanStateToLoadOrders();
      }
      this.setState({ fromDetail: false });
    });
  }

  /**
   * Function to set false mounted
   *
   * @memberof DeliveryHistoryScreen
   */
  componentWillUnmount() {
    this.timer = null;
    this._unsubscribe();
  }

  /**
   * Function to get rshop_id
   *
   * @memberof DeliveryHistoryScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Function to call service and get Deliveries
   *
   * @memberof DeliveryHistoryScreen
   */
  _deliveryHistory = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true }, async () => {
      const urlDeliveryhistoryListReplaced = urlDeliveryhistoryList.replace(
        /\[rshop_id\]/g,
        String(this._getRshop_id()),
      );
      let filterValue = null;
      if (this.state.filter_data?.filter_value && this.state.filter_data?.filter_value != 'Todos') {
        filterValue = this.state.filter_data?.filter_value;
      }
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
        page: this.state.filter_data.page.next_page_number,
        filter_value: filterValue,
      };
      try {
        const response = await apiGet(urlDeliveryhistoryListReplaced, data)();
        if (response.success) {
          response.data = [...this.state.data, ...response.data];
          response.data_loaded = this.state.data;
          const orderState = this.state.filter_data.filter_value;
          const page = response.page;
          const data = response.data;

          store.dispatch(setDeliveryHistory(response));
          this.setState({
            data: data,
            filter_data: {
              page: page,
              filter_value: orderState,
            },
            loading: false,
          });
        } else {
          this.setState({ loading: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) error.message += ' (016).';
        this.setState({ loading: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Function to call service and get state Deliveries
   *
   * @memberof DeliveryHistoryScreen
   */
  _deliveryHistoryState = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true }, async () => {
      let urlDeliveryhistoryStateReplaced = urlDeliveryhistoryState.replace('/appsupremo', '/companies');
      urlDeliveryhistoryStateReplaced = urlDeliveryhistoryStateReplaced.replace(
        /\[rshop_id\]/g,
        String(this._getRshop_id()),
      );
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
      };
      try {
        const response = await apiGet(urlDeliveryhistoryStateReplaced, data)();
        const state = [];
        if (response.success) {
          const states = response.states;
          for (let i = 0; i < states.length; i++) {
            state.push({ label: states[i].description, value: states[i].code });
          }
          this.setState({
            order_state: state,
            loading: false,
          });
        } else {
          this.setState({ loading: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) error.message += ' (016).';
        this.setState({ loading: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Return an id to each view
   *
   * @memberof DeliveryHistoryScreen
   */
  _keyExtractor = (item: DeliveryHistoryItem) => {
    const code = item.delivery_code + item.programmed_date;
    return `${code}`;
  };

  /**
   * Render item from DeliveryHistoryFlatList view
   *
   * @memberof DeliveryHistoryScreen
   */
  _renderItem = ({ item, index }: { item: DeliveryHistoryItem; index: number }) => (
    <DeliveryHistoryFlatList backgroundColor={colors.stripedColors[index % colors.stripedColors.length]} item={item} />
  );

  /**
   * Render emtpy component
   *
   * @memberof DeliveryHistoryScreen
   */
  _renderEmpty = () => {
    return (this.state.data && this.state.data.length > 0) || this.state.loading ? null : (
      <View style={styles.imageNoFoundContainer}>
        <Image source={NOT_FOUND} style={styles.imageNoFound} />
        <View style={styles.imageNoFoundDescriptionContainer}>
          <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
        </View>
      </View>
    );
  };

  /**
   * Render loader in footer
   *
   * @memberof DeliveryHistoryScreen
   */
  _renderFooter = () => {
    if (this.state.loading) {
      return <LoaderWithoutModal />;
    }
  };

  /**
   * Function to handle scroll bottom
   *
   * @memberof DeliveryHistoryScreen
   */
  _handleLoadMore = () => {
    if (this.state.loading) {
      return;
    }
    if (this.state.filter_data.page.has_next) {
      this._deliveryHistory();
    } else {
      this.setState({
        data:
          this.props.deliveryHistory &&
          this.props.deliveryHistory.delivery_history &&
          this.props.deliveryHistory.delivery_history.length > 0
            ? this.props.deliveryHistory.delivery_history
            : [],
      });
    }
  };

  /**
   * Function to clean state and then load orders
   *
   * @memberof DeliveryHistoryScreen
   */
  _cleanStateToLoadOrders = () => {
    this.setState(
      {
        data: [],
      },
      () => {
        this._deliveryHistory();
      },
    );
  };

  /**
   * Handle event to update filter_value
   *
   * @memberof DeliveryHistoryScreen
   */
  _searchUpdated() {
    clearTimeout(this.timer);
    this.setState(
      {
        filter_data: {
          filter_value: this.state.statusFilter,
          page: {
            has_next: false,
            next_page_number: 1,
          },
        },
      },
      () => {
        this.timer = setTimeout(this._cleanStateToLoadOrders, WAIT_INTERVAL);
      },
    );
  }

  _handleStatusFilterChange(value: string) {
    this.setState(
      {
        statusFilter: value,
      },
      () => {
        this._searchUpdated();
      },
    );
  }

  /**
   * Render sign in view
   *
   * @returns
   * @memberof DeliveryHistoryScreen
   */
  render() {
    return (
      <>
        <HeaderSideDrawer
          navigation={this.props.navigation}
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
        />
        <SafeAreaView style={styles.container}>
          <View style={styles.containerDropdown}>
            <InputSelectForm
              placeholder={strings.placeholderSelect}
              label={strings.dropdownListLabel}
              items={this.state.order_state}
              onChangeText={(value: string) => this._handleStatusFilterChange(value)}
              value={this.state.filter_data.filter_value}
              colorText={colors.white}
            />
          </View>
          <FlatList
            style={styles.flexOne}
            data={this.state.data}
            keyExtractor={this._keyExtractor}
            renderItem={this._renderItem}
            ListHeaderComponent={this._renderEmpty()}
            ListFooterComponent={this._renderFooter()}
            onEndReached={this._handleLoadMore}
            onEndReachedThreshold={0.4}
            bounces={false}
          />
        </SafeAreaView>
      </>
    );
  }
}

DeliveryHistoryScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
  deliveryHistory: DeliveryHistoryPropTypes,
};

DeliveryHistoryScreen.defaultProps = {};

/** Export component DeliveryHistoryScreen **/
export default connect((state) => ({ ...state }))(DeliveryHistoryScreen);
