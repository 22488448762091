/** Import Axios **/
import axios from 'axios';

/** Import addDeviceInfo **/
import addDeviceInfo from '../utils/addDeviceInfo';

/** Import Constants **/
import stringsConstants from '../constants/strings/strings';

/**
 * Create headers
 *
 * @static
 * @returns
 * @memberof API
 */
const getHeaders = () => {
  return new Headers({
    'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
  });
};

/**
 * Function to call service and send header with params
 *
 * @static
 * @param {*} url
 * @param {*} params
 * @param {*} method
 * @returns
 * @memberof API
 */
const xhr = (url: string, params: Record<string, unknown>, method: string, withCredentials: boolean) => {
  return addDeviceInfo(params)
    .then((data: any) => {
      const request: any = {
        method: method,
        url: url,
        transformRequest: function (transform: any, headers: any) {
          console.log('headers: ' + headers);
          const formData = new FormData();
          for (const key in transform) {
            formData.append(key, transform[key]);
          }
          return formData;
        },
        withCredentials: withCredentials,
        headers: getHeaders(),
        params: {},
        data: {},
      };
      if (method === 'get') {
        request.params = data;
      } else {
        request.data = data;
      }

      return axios(request)
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          if (err.message === stringsConstants.msgNetWorkError) {
            return new Promise((reject) => {
              reject(new Error(stringsConstants.msgConnectInternet));
            });
          } else {
            return err.response;
          }
        });
    })
    .then((json) => json);
};

/**
 * Function to method GET
 *
 * @static
 * @param {*} route
 * @param {*} params
 * @returns
 * @memberof API
 */
export const apiGet = (url: string, params: any) => () => xhr(url, params, 'get', true);

/**
 * Function to method GET
 *
 * @static
 * @param {*} route
 * @param {*} params
 * @returns
 * @memberof API
 */
export const apiGetWithouthCredentials = (url: string, params: any) => () => xhr(url, params, 'get', false);

/**
 * Function to method PUT
 *
 * @static
 * @param {*} route
 * @param {*} params
 * @returns
 * @memberof API
 */
export const apiPut = (url: string, params: any) => () => xhr(url, params, 'put', true);

/**
 * Function to method POST
 *
 * @static
 * @param {*} route
 * @param {*} params
 * @returns
 * @memberof API
 */
export const apiPost = (url: string, params: any) => () => xhr(url, params, 'post', true);

/**
 * Function to method DELETE
 *
 * @static
 * @param {*} route
 * @param {*} params
 * @returns
 * @memberof API
 */
export const apiDelete = (url: string, params: any) => () => xhr(url, params, 'delete', true);
