/** Styles to view **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';

/** Create an object style **/
const styles = StyleSheet.create({
  container: {
    display: 'flex',
  },
  containerItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    marginTop: 50,
  },
  containerText: {
    marginTop: 20,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 19,
    color: colors.black,
  },
  subtitleText: {
    color: colors.grayTextDescription,
    fontSize: 14,
  },
  containerState: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 5,
    borderColor: '#979797',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 1,
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    width: '95%',
  },
  stateText: {
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.black,
  },
  containerDescription: {
    width: '95%',
    minHeight: 100,
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionText: {
    color: colors.grayTextDescription,
    fontSize: 14,
    textAlign: 'center',
  },
  containerButton: {
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: 15,
    paddingRight: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    width: '90%',
    marginLeft: '5%',
    borderColor: 'black',
    borderWidth: 1,
  },
  registerButton: {
    backgroundColor: colors.white,
    width: '100%',
    borderWidth: 1,
    borderColor: colors.grayTextDescription,
    marginTop: 10,
  },
  returnToWaButton: {
    backgroundColor: 'rgb(65, 161, 106)',
    width: '100%',
    borderWidth: 1,
    borderColor: colors.white,
    marginTop: 10,
  },
  preLoginLabelButtonTextClass: {
    color: colors.black,
  },
  returnToWaButtonTextClass: {
    color: colors.white,
  },
  image: {
    width: 159,
    height: 159,
  },
});

/** Export component styles **/
export default styles;
