/** Import React **/
import React, { Component } from 'react';
import { View, Text } from 'react-native';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Components **/
import Button from '../button';
// import Loader from '../loader';

/** Import constants **/
import Stringsconstants from '../../constants/strings/strings';
import styles from './style';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

import { SafeAreaProvider } from 'react-native-safe-area-context';

import './react-datepicker-input.css';

interface Props {
  onRef: (ref: any) => void;
  title: string;
  subtitle: string;
  action: () => void;
  actionText: string;
  auth?: Auth;
  actionDate: (dateStart: any, dateEnd: any, stateParent: any) => void;
  stateParent: any;
}

interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  callBackClose?: () => void | null;
  startDate?: any;
  setStartDate?: Date;
  endtDate?: any;
  setEndDate?: Date;
}

/**
 * Create Modal with password
 *
 * @param {*} props
 * @returns
 */
class ModalOpenDate extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   *Creates an instance of ModalOpenDate.
   * @param {*} props
   * @memberof ModalOpenDate
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
      startDate: '',
      setStartDate: new Date(),
      endtDate: '',
      setEndDate: new Date(),
    };
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalOpenDate
   */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalOpenDate
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  /**
   * Open openModalConfirmItem
   *
   * @memberof ModalOpenDate
   */
  openModalConfirmItem = () => {
    this.setState({
      isVisible: true,
    });
  };

  /**
   * Close closeModal
   *
   * @memberof ModalOpenDate
   */
  closeModal = () => {
    this.setState({
      isVisible: false,
      endtDate: null,
      startDate: null,
    });
    this.props.actionDate('', '', this.props.stateParent);
  };

  /**
   * Function _callAction
   *
   * @memberof ModalOpenDate
   */
  _callAction = () => {
    this.setState({
      isVisible: false,
      endtDate: this.state.endtDate,
      startDate: this.state.startDate,
    });
    this.props.actionDate(this.state.startDate ?? '', this.state.endtDate ?? '', this.props.stateParent);
  };
  /**
   * Render ModalConfirmItem in modal
   *
   * @returns
   * @memberof ModalOpenDate
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        avoidKeyboard={true}
        style={styles.modal}
        onModalHide={() => this.state.callBackClose && this.state.callBackClose()}
      >
        <SafeAreaProvider>
          <View style={styles.modalBackground}>
            <View style={styles.modalHeader}>
              <Text style={styles.modalTitle}>{this.props.subtitle}</Text>
            </View>
            <View style={styles.containerDetail}>
              <Text style={styles.modalSubTitle}>{Stringsconstants.from}</Text>
              <DatePicker
                selected={this.state.startDate}
                onChange={(date: Date) => this.setState({ startDate: date })}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endtDate}
                withPortal
                portalId="root-portal"
                locale={es}
                dateFormat="MM/dd/yyyy"
                maxDate={this.state.endtDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={(e) => e.target.blur()}
              />
            </View>
            <View style={styles.containerDetail}>
              <Text style={styles.modalSubTitle}>{Stringsconstants.until}</Text>
              <DatePicker
                selected={this.state.endtDate}
                onChange={(date: Date) => this.setState({ endtDate: date })}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endtDate}
                minDate={this.state.startDate}
                withPortal
                portalId="root-portal"
                locale={es}
                dateFormat="MM/dd/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onFocus={(e) => e.target.blur()}
              />
            </View>
            <View style={styles.containerButtons}>
              <Button
                onPress={this.closeModal}
                buttonClass={styles.buttonCancel}
                buttonLabelText={Stringsconstants.restore}
                buttonLabelTextClass={styles.buttonTextCancel}
              />
              <Button
                onPress={this._callAction}
                buttonClass={styles.buttonConfirm}
                buttonLabelText={this.props.actionText}
                buttonLabelTextClass={styles.buttonTextConfirm}
              />
            </View>
          </View>
        </SafeAreaProvider>
      </Modal>
    );
  }
}

ModalOpenDate.propTypes = {
  auth: AuthPropTypes,
};

ModalOpenDate.defaultProps = {};

/** Export component ModalOpenDate **/
export default connect((state) => ({ ...state }))(ModalOpenDate);
