/** Import Interfaces **/
import { ShoppingCart } from '../../interfaces';

const INITIAL_STATE: ShoppingCart = {
  total_neto: 0,
  totalPriceWithoutTaxes: 0,
  totalRealTaxes: 0,
  totalTaxes: 0,
  totalChargeValue: 0,
  data: [],
  observations: '',
};

export { INITIAL_STATE };
