/** Import Config **/
import Config from 'react-native-config';
import { Platform } from 'react-native';
import { linking } from '../routes/routes';

const PRIMARY_COLOR_KEY = 'EP-PC';

const setPrimaryColor = function (primaryColor: string | undefined): void {
  if (Platform.OS === 'web') {
    if (!primaryColor || primaryColor.length === 0) {
      window.localStorage.removeItem(PRIMARY_COLOR_KEY);
    } else {
      window.localStorage.setItem(PRIMARY_COLOR_KEY, primaryColor);
    }
  }
};

/** Create an object Colors **/
const colors = {
  setPrimaryColor: setPrimaryColor,
  primary: '',
  danger: '#FF0800',
  info: '#3399FF',
  warning: '#FBCE1A',
  warningErrors: '#7b3915',
  success: '#28A745',
  whiteOpacity20: 'rgba(255, 255, 255, 0.2)',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#5E5E5E',
  grayPlaceholder: '#8E8E93',
  grayTextDescription: '#494949',
  grayIconBack: '#48484b',
  stripedColors: ['#FFFFFF', '#EBF6FF'],
  greenFlat: '#2ECC71',
  blueRSales: '#3498DB',
  softRed: '#EBF6FF',
  softGray: '#F5F5F5',
  delivered: '#34495E',
  branchOffices: '#9F40F5',
  buttonSideDrawer: '#dddddd',
  buttoDisabled: '#95A5A6',
  searchBarBackground: '#F2F2F2',
  transparent: 'transparent',
  loaderContainer: '#CED0CE',
  statusBarBackgroundColor: 'rgba(0, 0, 0, 0.7)',
  filterListBackgroundColor: 'rgba(142,142,147,0.12)',
  stateSuccess: '#053575',
  statePending: '#F5A623',
  statePendingAssignment: '#2BCCEC',
  pink: '#EC2B89',
};

Object.defineProperty(colors, 'primary', {
  get: function () {
    if (Platform.OS === 'web') {
      try {
        const path = location.pathname.split('/');
        if (path[1] === linking.config.screens.LoginScreenWhatsapp.split('/')[0]) {
          colors.setPrimaryColor(path[5].replace('*', '#'));
        }
      } catch (e) {}
    }
    const savedPrimaryColor = window.localStorage.getItem(PRIMARY_COLOR_KEY);
    if (Platform.OS !== 'web') {
      return Config.PRIMARY_COLOR;
    } else if (savedPrimaryColor && savedPrimaryColor.length > 0) {
      return savedPrimaryColor;
    } else {
      return process.env.REACT_APP_PRIMARY_COLOR || '';
    }
  },
});

/** Export object colors **/
export default colors;
