/** Import Redux Toolkit **/
import { createSlice } from '@reduxjs/toolkit';

/** Import Initial State **/
import { INITIAL_STATE } from './initialState';

const deliveryHistorySlice = createSlice({
  name: 'deliveryHistory',
  initialState: INITIAL_STATE,
  reducers: {
    setDeliveryHistory(state, action) {
      return {
        ...state,
        page: action.payload.page,
        delivery_history: action.payload.data,
        order_state: action.payload.order_state,
      };
    },
    cleanDeiveryHistory() {
      return INITIAL_STATE;
    },
  },
});

export const { setDeliveryHistory, cleanDeiveryHistory } = deliveryHistorySlice.actions;

export default deliveryHistorySlice.reducer;
