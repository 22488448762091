/** Import modules **/
import React from 'react';
import { View } from 'react-native';

/** Components **/
import styles from './style';

/**
 * Create overlay
 *
 * @param {Props}
 * @returns
 */
const Overlay = () => {
  /**
   * Render component with Icon
   *
   * @returns
   * @memberof Overlay
   */
  return <View style={styles.overlay} />;
};

/** Export component Overlay **/
export default Overlay;
