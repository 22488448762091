import Config from 'react-native-config';
import { Platform } from 'react-native';

export const urlBase = Platform.OS !== 'web' ? Config.API_URL : process.env.REACT_APP_API_URL;
export const waUrlBase = Platform.OS !== 'web' ? Config.API_URL_WA : process.env.REACT_APP_API_URL_WA;
export const waNumber = Platform.OS !== 'web' ? Config.WA_NUMBER : process.env.REACT_APP_WA_NUMBER;
export const reCaptchaSiteKey =
  Platform.OS !== 'web' ? Config.RECAPTCHA_SITE_KEY : process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const reCaptchaBaseUrl =
  Platform.OS !== 'web' ? Config.RECAPTCHA_BASE_URL : process.env.REACT_APP_RECAPTCHA_BASE_URL;
