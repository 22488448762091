/** Import React **/
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, TextInput } from 'react-native';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Modal **/
import Modal from 'react-native-modal';

/** Components **/
import Button from '../button';
import Loader from '../loader';

/** Import constants **/
import styles from './style';
import stringsConstants from '../../constants/strings/strings';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { IconMoon } from '../icon/IconMoon';
import icons from '../../constants/icons/icons';
import colors from '../../constants/styles/colors';

/** Const to define size in icons **/
const ICON_SIZE = 24;

interface Props {
  onRef: (ref: any) => void;
  title: string;
  action: () => void;
  actionResend: () => void;
  actionText: string;
  auth?: Auth;
  messageAlert: string;
}

interface State {
  backdropOpacity?: number;
  animationIn?: any;
  isVisible?: boolean;
  size?: 'small' | 'large';
  callBackClose?: () => void | null;
  verificationCode: string;
  showAlertModal: boolean;
}

/**
 * Create Modal with password
 *
 * @param {*} props
 * @returns
 */
class ModalConfirmWhatsapp extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   *Creates an instance of ModalConfirmWhatsapp.
   * @param {*} props
   * @memberof ModalConfirmWhatsapp
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      backdropOpacity: 0.7,
      animationIn: 'slideInUp',
      isVisible: false,
      size: 'large',
      verificationCode: '',
      showAlertModal: true,
    };
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalConfirmWhatsapp
   */
  componentDidMount() {
    this.props.onRef(this);
  }

  /**
   * Pass ref to View
   *
   * @memberof ModalConfirmWhatsapp
   */
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  /**
   * Open alert
   *
   * @memberof ModalConfirmWhatsapp
   */
  openModalConfirmWhatsapp = () => {
    this.setState({
      isVisible: true,
      verificationCode: '',
    });
  };

  /**
   * Close alert
   *
   * @memberof ModalConfirmWhatsapp
   */
  closeModalConfirmWhatsapp = () => {
    this.setState({
      isVisible: false,
    });
  };

  /**
   * Function to log out
   *
   * @memberof ModalConfirmWhatsapp
   */
  _callAction = () => {
    this.props.action();
    this.closeModalConfirmWhatsapp();
  };
  _callActionResend = () => {
    this.props.actionResend();
    this.closeModalConfirmWhatsapp();
  };

  closeAlert = () => {
    this.setState({
      showAlertModal: false,
    });
  };
  /**
   * Render ModalConfirmWhatsapp in modal
   *
   * @returns
   * @memberof ModalConfirmWhatsapp
   */
  render() {
    return (
      <Modal
        backdropOpacity={this.state.backdropOpacity}
        animationIn={this.state.animationIn}
        isVisible={this.state.isVisible}
        avoidKeyboard={true}
        style={styles.modal}
      >
        {this.props.messageAlert != '' && this.state.showAlertModal ? (
          <View style={styles.modalAlert}>
            <TouchableOpacity style={styles.modalDivAlert}>
              <IconMoon
                onPress={this.closeAlert}
                style={styles.iconAlert}
                name={icons.icCloseWhite}
                size={15}
                color={colors.white}
              />
              <Text style={styles.buttonTextAlert}>{this.props.messageAlert}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
        <SafeAreaProvider>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <View style={styles.modalBackground}>
            <TouchableOpacity style={styles.button}>
              <IconMoon
                onPress={this.closeModalConfirmWhatsapp}
                style={styles.icon}
                name={icons.icCloseWhite}
                size={ICON_SIZE}
                color={colors.white}
              />
            </TouchableOpacity>
            <View style={styles.modalView}>
              {this.props.title != '' ? <Text style={styles.modalTitle}>{this.props.title}</Text> : null}
              <View>
                <TextInput
                  style={styles.value}
                  value={this.state.verificationCode}
                  onChangeText={(val) => this.setState({ verificationCode: val })}
                />
              </View>
              <View style={styles.containerButtons}>
                <Button
                  onPress={this._callActionResend}
                  buttonClass={styles.buttonCancel}
                  buttonLabelText={this.props.actionText}
                  buttonLabelTextClass={styles.buttonTextConfirm}
                />
                <Button
                  onPress={this._callAction}
                  buttonClass={styles.buttonConfirm}
                  buttonLabelText={stringsConstants.accept}
                  buttonLabelTextClass={styles.buttonTextConfirm}
                />
              </View>
            </View>
          </View>
        </SafeAreaProvider>
      </Modal>
    );
  }
}

ModalConfirmWhatsapp.propTypes = {
  auth: AuthPropTypes,
};

ModalConfirmWhatsapp.defaultProps = {};

/** Export component ModalConfirmWhatsapp **/
export default connect((state) => ({ ...state }))(ModalConfirmWhatsapp);
