/** Styles to view **/
import { Platform, StyleSheet } from 'react-native';
/** Import Constants **/
import colors from '../../constants/styles/colors';
let modalViewMarginBottom;
if (Platform.OS === 'web') {
  modalViewMarginBottom = '100%';
} else {
  modalViewMarginBottom = 0;
}
/** Create an object style **/
const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    paddingTop: 120,
  },
  modalView: {
    width: '100%',
    backgroundColor: colors.white,
    borderRadius: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 3,
    paddingTop: 20,
    paddingRight: 30,
    paddingBottom: 20,
    paddingLeft: 30,
    marginBottom: modalViewMarginBottom,
  },
  modalSubTitle: {
    marginTop: 15,
    marginBottom: 15,
    fontSize: 15,
    color: colors.black,
    fontWeight: 'bold',
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  buttonConfirm: {
    backgroundColor: colors.primary,
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  buttonTextConfirm: {
    color: colors.white,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderWidth: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    backgroundColor: colors.primary,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  icon: {
    marginLeft: '93%',
  },
  modalTitle: {
    marginBottom: 15,
    fontSize: 18,
    color: colors.black,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
/** Export component style **/
export default styles;
