/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Lista de Productos',
  factor: 'Factor: ',
  quantity: 'Cant: ',
  witoutFactor: 'Sin factor',
};

/** Export strings **/
export default strings;
