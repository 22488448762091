/** Define object to static labels in view **/
const strings = {
  createdDate: 'Fecha Creación:',
  orderStates: {
    approved: 'Solicitud Recibida',
    pending: 'Pendiente',
    rejected: 'Rechazado',
    shipped: 'Despachado',
  },
  orderSource: 'Origen Pedido:',
  mySeller: 'Mi vendedor',
  easyPedido: 'EasyPedido',
  documentNumber: 'N° de Factura',
  saldo: 'Saldo',
  currentDebt: 'Deuda Corriente',
  dateContainer: 'Fecha',
  expires: 'Vence',
  expiredDays: 'Días vencido',
};

/** Export STR_PRODUCT_FLAT_LIST **/
export default strings;
