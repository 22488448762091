/** Define object to static labels in view **/
const strings = {
  mainLabel: 'Detalle',
  noData: '¡Vaya¡, no hemos encontrado información para mostrarte.',
  observations: 'Observaciones',
  total: 'Total:',
};

/** Export strings **/
export default strings;
