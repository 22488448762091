/** Import React **/
import React, { Component } from 'react';
import { Text, View, Image, Platform } from 'react-native';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Api **/
import { urlRootGalleryUrl, urlImageGalleryThumbs } from '../../api/urls';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, ShoppingHistoryDetailItem } from './../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import productWithoutImage from '../../web_assets/images/product_without_image.png';

let PRODUCT_WITHOUT_IMAGE = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  PRODUCT_WITHOUT_IMAGE = productWithoutImage;
} else if (Platform.OS === 'ios') {
  PRODUCT_WITHOUT_IMAGE = { uri: `${assets.productWithoutImage}` };
} else {
  PRODUCT_WITHOUT_IMAGE = { uri: `@mipmap/${assets.productWithoutImage}` };
}

interface Props {
  item: ShoppingHistoryDetailItem;
  backgroundColor: string;
  auth?: Auth;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

/**
 * Class to export ShoppingHistoryDetailFlatList view
 *
 * @class ShoppingHistoryDetailFlatList
 * @extends {Component}
 */
class ShoppingHistoryDetailFlatList extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   * Handle getImage and return object with image route
   *
   * @memberof ShoppingHistoryDetailFlatList
   */
  _getImage = () => {
    if (
      this.props.item &&
      this.props.item.product_code &&
      this.props.item.product_code.length > 0 &&
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.company_code &&
      this.props.item.has_image === 'Y'
    ) {
      return {
        uri:
          urlRootGalleryUrl +
          urlImageGalleryThumbs
            .replace(/\[company_code\]/g, this.props.auth.current_rshop.company_code)
            .replace(/\[product_code\]/g, this.props.item.product_code),
      };
    } else {
      return PRODUCT_WITHOUT_IMAGE;
    }
  };

  /**
   * Return description of product
   *
   * @memberof ShoppingHistoryDetailFlatList
   */
  _getProductDescription = () => {
    return this.props.item.product_name;
  };

  /**
   * Handle getFactor and return first factor to products and favorites
   *
   * @memberof ShoppingHistoryDetailFlatList
   */
  _getFactor = () => {
    if (this.props.item && this.props.item.factor) {
      return `${this.props.item.factor_description} - ${this.props.item.factor}`;
    } else {
      return 'Sin factor';
    }
  };

  /**
   * Handle getPrice and return price
   *
   * @memberof ShoppingHistoryDetailFlatList
   */
  _getPrice = () => {
    if (this.props.item && this.props.item.net_total) {
      return accounting.formatMoney(parseFloat(this.props.item.net_total));
    } else {
      return accounting.formatMoney(0);
    }
  };

  /**
   * Handle getQuantity and return quantity
   *
   * @memberof ShoppingHistoryDetailFlatList
   */
  _getQuantity = () => {
    if (this.props.item && this.props.item.quantity) {
      if (parseFloat(this.props.item.quantity) % 1 !== 0) {
        return this.props.item.quantity;
      } else {
        return parseFloat(this.props.item.quantity);
      }
    } else {
      return 0;
    }
  };

  /**
   * Render view with product data
   *
   * @returns
   * @memberof ShoppingHistoryDetailFlatList
   */
  render() {
    return (
      <View style={[styles.itemProductContainer, styles.container, { backgroundColor: this.props.backgroundColor }]}>
        <View style={styles.itemProductImageContainer}>
          <Image style={styles.image} source={this._getImage()} />
        </View>
        <View style={styles.itemProductDetailContainer}>
          <Text style={styles.itemProductDescription}>{this._getProductDescription()}</Text>
          <Text style={styles.itemProductFactor}>
            {strings.factor}
            {this._getFactor()}
          </Text>
          <Text style={styles.itemProductQuantity}>
            {strings.quantity}
            {this._getQuantity()}
          </Text>
          <Text style={styles.itemProductPrice}>{this._getPrice()}</Text>
        </View>
      </View>
    );
  }
}

ShoppingHistoryDetailFlatList.propTypes = {
  auth: AuthPropTypes,
};

ShoppingHistoryDetailFlatList.defaultProps = {};

/** Export component ShoppingHistoryDetailFlatList **/
export default connect((state) => ({ ...state }))(ShoppingHistoryDetailFlatList);
