/** Import modules **/
import React from 'react';
import { TouchableOpacity } from 'react-native';

// import { SafeAreaProvider } from 'react-native-safe-area-context';
// import { SafeAreaView } from 'react-native-safe-area-context';

/** Import Navigation **/
import { DrawerActions } from '@react-navigation/native';
import { NavigationProp } from '@react-navigation/core';

/** Import React Native Elements **/
import { Header } from 'react-native-elements';

/** Components **/
import { IconMoon } from './../icon/IconMoon';

/** Import Constants **/
import icons from './../../constants/icons/icons';
import styles from './style';

/** Import Interfaces **/
import { Auth } from './../../interfaces';
import { routes } from 'src/constants/routes/routes';

/** Toolbar Icons **/
const headerSidebarMenuIcon = 'menu';

/** Const to define size in icons **/
const ICON_BACK_SIZE = 15;

interface Props {
  mainLabel: string;
  color: string;
  backgroundColor: string;
  showBack?: boolean;
  navigation?: NavigationProp<any>;
  route?: any;
  auth?: Auth;
  rightComponent?: any;
  paddingTop?: number;
}

/**
 * Function to return Header
 *
 * @param {Props} {
 *   mainLabel,
 *   color,
 *   backgroundColor,
 *   showBack,
 *   navigation,
 *   route,
 *   auth,
 *   rightComponent,
 * }
 */
const HeaderSideDrawer = ({
  mainLabel,
  color,
  backgroundColor,
  showBack,
  navigation,
  route,
  auth,
  rightComponent,
  paddingTop = 5,
}: Props) => (
  <Header
    statusBarProps={{ translucent: true, barStyle: 'light-content' }}
    placement="center"
    leftComponent={
      showBack ? (
        route.params?.backRoute ?? null ? (
          <TouchableOpacity
            style={styles.iconBack}
            onPress={() => {
              const hasValidBackRoute =
                route.params?.backRoute && Object.values(routes).indexOf(route.params?.backRoute) >= 0;

              if (hasValidBackRoute) {
                navigation && navigation.navigate(route.params?.backRoute, route.params?.backRouteParams);
              } else {
                navigation && navigation.goBack();
              }
            }}
          >
            <IconMoon style={styles.iconStyle} name={icons.icNext} size={ICON_BACK_SIZE} color={color} />
          </TouchableOpacity>
        ) : undefined
      ) : (
        {
          icon: headerSidebarMenuIcon,
          color: color,
          onPress: () => navigation && navigation.dispatch(DrawerActions.openDrawer()),
        }
      )
    }
    rightComponent={rightComponent ? rightComponent : undefined}
    backgroundColor={backgroundColor}
    centerComponent={{
      text: auth && auth.current_rshop ? auth.current_rshop.company_name : mainLabel,
      style: { ...styles.headerCenterComponent, color: color, paddingTop: paddingTop, fontWeight: 'bold' },
    }}
    containerStyle={{
      height: 45,
    }}
  />
);

/** Export component HeaderSideDrawer **/
export default HeaderSideDrawer;
