/** Define object to static labels in view **/
const strings = {
  searchCountryPlaceholder: 'Buscar...',
  searchCountryNotFound: 'Sin resultados',
  labelTextEmail: 'Número Móvil:',
  placeholderTextEmail: 'Ingrese su número móvil',
  labelTextPassword: 'Código de Veificación:',
  placeholderTextPassword: 'Código recibido en WhatsApp',
  placeholderTextPasswordSMS: 'Código recibido por SMS',
  labelForgetPassword: '¿Olvidaste tu contraseña?',
  labelLoginButtonText: 'INICIAR SESIÓN',
  labelValidateCodeButtonText: 'VALIDAR CÓDIGO',
  login: 'Inicio de sesión',
  emailPasswordRequired: 'El número móvil y código de verificación son obligatorios',
  usernameRequired: 'El número móvil es obligatorio',
  tryAgain: 'Intentar de nuevo',
  msgErrorShoppingCar: 'Carro de Compras',
  msgErrorHome: 'Inicio',
  notAllowToAppSupremo: 'El usuario no pertenece a AppSupremo',
  sendNewPassword: 'Te enviaremos una nueva contraseña',
  messageNewPassword:
    'Sólo requerimos que nos brindes el número de teléfono móvil asociado a tu cuenta y te enviaremos a tu correo la nueva contraseña.',
  request: 'Solicitar',
  reGenerateCode: 'Volver a enviar código',
  loginFromWhatsapp: 'INICIAR DESE WHATSAPP',
  changeUsernameButtonText: 'CAMBIAR NÚMERO',
  newValidationCodeSent: 'Un nuevo código ha sido enviado a tu WhatsApp',
  newValidationCodeSentBySMS: 'Un nuevo código ha sido enviando por SMS',
  botDetected: 'Lo sentimos, no detectamos que seas humano',
};

/** Export strings **/
export default strings;
