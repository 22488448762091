/** Styles to Logo **/
import { StyleSheet } from 'react-native';

/** Create an object style **/
const styles = StyleSheet.create({
  overlay: {
    backgroundColor: '#fff',
    ...StyleSheet.absoluteFillObject,
    opacity: 0.5,
  },
});

/** Export component styles **/
export default styles;
