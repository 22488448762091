/** Import React **/
import React, { Component } from 'react';
import { View, Text, Image, Platform, FlatList } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { apiGet } from '../../api';
import { urlShoppinghistoryList } from '../../api/urls';

/** Import components **/
import HeaderSideDrawer from '../../components/headerSideDrawer';
import InputSelectForm from '../../components/inputSelectForm';
import LoaderWithoutModal from '../../components/loaderWithoutModal';
import ShoppingHistoryFlatList from '../../components/shoppingHistoryFlatList';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from '../../constants/styles/colors';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, FilterData, OrderState, ShoppingHistory, ShoppingHistoryItem } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes, ShoppingHistoryPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setShoppingHistory } from '../../slices/shoppingHistory/shoppingHistorySlice';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

/** Global variables **/
const WAIT_INTERVAL = 800;

/** Import Images **/
import notFoundImage from '../../web_assets/images/not_found.png';

let NOT_FOUND = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  NOT_FOUND = notFoundImage;
} else if (Platform.OS === 'ios') {
  NOT_FOUND = { uri: `${assets.notFound}` };
} else {
  NOT_FOUND = { uri: `@mipmap/${assets.notFound}` };
}

interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  shoppingHistory: ShoppingHistory;
}

interface State {
  type_search: string;
  loading: boolean;
  order_state: Array<OrderState>;
  data: Array<ShoppingHistoryItem>;
  filter_data: FilterData;
  fromDetail: boolean;
  orderSource: Array<OrderState>;
  statusFilter: string;
  sourceFilter: string;
}

/**
 * Class to export ShoppingHistoryScreen
 *
 * @class ShoppingHistoryScreen
 * @extends {Component<Props, State>}
 */
class ShoppingHistoryScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public timer!: any;
  public _unsubscribe!: any;

  /**
   * Creates an instance of ShoppingHistoryScreen.
   * @param {*} props
   * @memberof ShoppingHistoryScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      type_search: 'LIKENAMEANDCODE',
      loading: false,
      order_state: [
        {
          label: strings.orderStates.approved,
          value: 'APROBADO',
        },
        {
          label: strings.orderStates.pending,
          value: 'PENDIENTE',
        },
        {
          label: strings.orderStates.rejected,
          value: 'RECHAZADO',
        },
        {
          label: strings.orderStates.shipped,
          value: 'DESPACHADO',
        },
      ],
      orderSource: [
        {
          label: strings.orderSources.seller,
          value: 'SELLER',
        },
        {
          label: strings.orderSources.app,
          value: 'EASY_PEDIDO',
        },
      ],
      statusFilter: '',
      sourceFilter: '',
      data: [],
      filter_data: {
        filter_value: '',
        filter_source: '',
        page: {
          has_next: true,
          next_page_number: 1,
        },
      },
      fromDetail: false,
    };
  }

  /**
   * Functin to get ShoppingHistoryScreen when moduled is opened
   *
   * @memberof ShoppingHistoryScreen
   */
  componentDidMount() {
    this.timer = null;
    this._unsubscribe = this.props.navigation.addListener('focus', () => {
      if (!this.state.fromDetail) {
        this._cleanStateToLoadOrders();
      }
      this.setState({ fromDetail: false });
    });
  }

  /**
   * Function to set false mounted
   *
   * @memberof ShoppingHistoryScreen
   */
  componentWillUnmount() {
    this.timer = null;
    this._unsubscribe();
  }

  /**
   * Function to get rshop_id
   *
   * @memberof ShoppingHistoryScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Function to call service and get products
   *
   * @memberof ShoppingHistoryScreen
   */
  _shoppingHistory = () => {
    if (this.state.loading) {
      return;
    }
    this.setState({ loading: true }, async () => {
      const urlShoppinghistoryListReplaced = urlShoppinghistoryList.replace(
        /\[rshop_id\]/g,
        String(this._getRshop_id()),
      );
      let filterValue = null;
      if (this.state.filter_data?.filter_value && this.state.filter_data?.filter_value != 'Todos') {
        filterValue = this.state.filter_data?.filter_value;
      }
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
        page: this.state.filter_data.page.next_page_number,
        filter_value: filterValue,
        filter_source: this.state.filter_data?.filter_source,
      };
      try {
        const response = await apiGet(urlShoppinghistoryListReplaced, data)();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.data = [...this.state.data, ...response.data];
          response.data_loaded = this.state.data;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const orderState = this.state.filter_data.filter_value;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const page = response.page;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const data = response.data;
          store.dispatch(setShoppingHistory(response));
          this.setState({
            data: data,
            filter_data: {
              page: page,
              filter_value: orderState,
            },
            loading: false,
          });
        } else {
          this.setState({ loading: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) error.message += ' (016).';
        this.setState({ loading: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Return an id to each view
   *
   * @memberof ShoppingHistoryScreen
   */
  _keyExtractor = (item: ShoppingHistoryItem) => {
    return `${item.order_code}`;
  };

  /**
   * Function to togle item favorite
   *
   * @memberof ShoppingHistoryScreen
   */
  _onPressItem = (item: ShoppingHistoryItem) => {
    this.setState({ fromDetail: true }, () => {
      this.props.navigation.navigate(routes.shoppingHistoryDetail, {
        backRoute: routes.shoppingHistory,
        currentOrder: item,
      });
    });
  };

  /**
   * Render item from ShoppingHistoryFlatList view
   *
   * @memberof ShoppingHistoryScreen
   */
  _renderItem = ({ item, index }: { item: ShoppingHistoryItem; index: number }) => (
    <ShoppingHistoryFlatList
      backgroundColor={colors.stripedColors[index % colors.stripedColors.length]}
      item={item}
      onPressItem={(itemPressed: ShoppingHistoryItem) => this._onPressItem(itemPressed)}
    />
  );

  /**
   * Render emtpy component
   *
   * @memberof ShoppingHistoryScreen
   */
  _renderEmpty = () => {
    return (this.state.data && this.state.data.length > 0) || this.state.loading ? null : (
      <View style={styles.imageNoFoundContainer}>
        <Image source={NOT_FOUND} style={styles.imageNoFound} />
        <View style={styles.imageNoFoundDescriptionContainer}>
          <Text style={styles.imageNoFoundDescription}>{strings.noData}</Text>
        </View>
      </View>
    );
  };

  /**
   * Render loader in footer
   *
   * @memberof ShoppingHistoryScreen
   */
  _renderFooter = () => {
    if (this.state.loading) {
      return <LoaderWithoutModal />;
    }
  };

  /**
   * Function to handle scroll bottom
   *
   * @memberof ShoppingHistoryScreen
   */
  _handleLoadMore = () => {
    if (this.state.loading) {
      return;
    }
    if (this.state.filter_data.page.has_next) {
      this._shoppingHistory();
    } else {
      this.setState({
        data:
          this.props.shoppingHistory &&
          this.props.shoppingHistory.shopping_history &&
          this.props.shoppingHistory.shopping_history.length > 0
            ? this.props.shoppingHistory.shopping_history
            : [],
      });
    }
  };

  /**
   * Function to clean state and then load orders
   *
   * @memberof ShoppingHistoryScreen
   */
  _cleanStateToLoadOrders = () => {
    this.setState(
      {
        data: [],
      },
      () => {
        this._shoppingHistory();
      },
    );
  };

  /**
   * Handle event to update filter_value
   *
   * @memberof ShoppingHistoryScreen
   */
  _searchUpdated() {
    clearTimeout(this.timer);
    this.setState(
      {
        filter_data: {
          filter_value: this.state.statusFilter,
          filter_source: this.state.sourceFilter,
          page: {
            has_next: false,
            next_page_number: 1,
          },
        },
      },
      () => {
        this.timer = setTimeout(this._cleanStateToLoadOrders, WAIT_INTERVAL);
      },
    );
  }

  _handleStatusFilterChange(value: string) {
    this.setState(
      {
        statusFilter: value,
      },
      () => {
        this._searchUpdated();
      },
    );
  }

  _handleSourceFilterChange(value: string) {
    this.setState(
      {
        sourceFilter: value,
      },
      () => {
        this._searchUpdated();
      },
    );
  }

  /**
   * Render sign in view
   *
   * @returns
   * @memberof ShoppingHistoryScreen
   */
  render() {
    return (
      <>
        <HeaderSideDrawer
          navigation={this.props.navigation}
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
        />
        <SafeAreaView style={styles.container}>
          <View style={styles.containerDropdown}>
            <InputSelectForm
              placeholder={strings.placeholderSelect}
              label={strings.dropdownListLabel}
              items={this.state.order_state}
              onChangeText={(value: string) => this._handleStatusFilterChange(value)}
              value={this.state.filter_data.filter_value}
              colorText={colors.white}
            />
            {this.props.auth.current_rshop?.has_rsales_operation ? (
              <InputSelectForm
                placeholder={strings.placeholderSelect}
                label={strings.orderSourceDropdownLabel}
                items={this.state.orderSource}
                onChangeText={(value: string) => this._handleSourceFilterChange(value)}
                value={this.state.filter_data.filter_source}
                colorText={colors.white}
              />
            ) : null}
          </View>
          <FlatList
            style={styles.flexOne}
            data={this.state.data}
            keyExtractor={this._keyExtractor}
            renderItem={this._renderItem}
            ListHeaderComponent={this._renderEmpty()}
            ListFooterComponent={this._renderFooter()}
            onEndReached={this._handleLoadMore}
            onEndReachedThreshold={0.4}
            bounces={false}
          />
        </SafeAreaView>
      </>
    );
  }
}

ShoppingHistoryScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
  shoppingHistory: ShoppingHistoryPropTypes,
};

ShoppingHistoryScreen.defaultProps = {};

/** Export component ShoppingHistoryScreen **/
export default connect((state) => ({ ...state }))(ShoppingHistoryScreen);
