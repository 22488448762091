/** Import React **/
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, FlatList, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import SearchBar **/
import { SearchBar } from 'react-native-elements';

/** Import Api **/
import { apiGet } from '../../api';
import { urlShoppingcartList, urlHome } from '../../api/urls';

/** Import components **/
import BackHandlerCustom from './../../components/backHandlerCustom';
import HeaderSideDrawer from '../../components/headerSideDrawer';
import Loader from '../../components/loader';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import colors from './../../constants/styles/colors';
import { routes } from './../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, Company } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { setRShop, setHome } from '../../slices/auth/authSlice';
import { setShoppingCart } from '../../slices/shoppingCart/shoppingCartSlice';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import factoryLogo from '../../web_assets/images/factory.png';
import AppNavigator from 'src/navigators/AppNavigator';

let FACTORY = { uri: `` };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  FACTORY = factoryLogo;
} else if (Platform.OS === 'ios') {
  FACTORY = { uri: `${assets.factory}` };
} else {
  FACTORY = { uri: `@mipmap/${assets.factory}` };
}

interface Props {
  navigation: NavigationProp<any>;
  route: any;
  auth: Auth;
}

interface State {
  data?: Array<Company>;
  filter_value?: string;
}

/**
 * Class to export BranchOfficesListScreen
 *
 * @class BranchOfficesListScreen
 * @extends {Component<Props, State>}
 */
class BranchOfficesListScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public Loader!: any;

  /**
   * Creates an instance of BranchOfficesListScreen.
   * @param {*} props
   * @memberof BranchOfficesListScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      data: this.props.auth?.array_branchoffices,
      filter_value: undefined,
    };
  }

  /**
   * Return an id to each view
   *
   * @memberof BranchOfficesListScreen
   */
  keyExtractor = (item: Company) => {
    return `${item.client_id}`;
  };

  _goDrawer = () => {
    return <AppNavigator />;
  };

  /**
   * Function to togle item favorite
   *
   * @memberof BranchOfficesListScreen
   */
  onPressItem = (item: Company) => () => {
    this.Loader.openLoader(async () => {
      const rShop = {
        company: item,
        branch_offices: this.props.auth.array_branchoffices,
      };
      const rshop_id = rShop.company && rShop.company && rShop.company.rshop_id ? rShop.company.rshop_id : '';
      const urlShoppingcartListReplaced = urlShoppingcartList.replace(/\[rshop_id\]/g, String(rshop_id));
      const urlHomeReplaced = urlHome.replace(/\[rshop_id\]/g, String(rshop_id));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
      };
      try {
        const responseShoppinCart = await apiGet(urlShoppingcartListReplaced, data)();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (responseShoppinCart.success) {
          const responseHome = await apiGet(urlHomeReplaced, data)();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (responseHome.success) {
            store.dispatch(setRShop(rShop));
            store.dispatch(setShoppingCart(responseShoppinCart));
            store.dispatch(setHome(responseHome));
            if (this.props.auth.is_logged) {
              const drawerPropsNavigation = this.props.route.params?.drawerPropsNavigation;
              if (drawerPropsNavigation && drawerPropsNavigation.closeDrawer) {
                drawerPropsNavigation.closeDrawer();
              }
              if (
                this.props.auth.current_rshop?.ecommerce_orders === 'N' ||
                (this.props.auth.current_rshop?.ecommerce_wallet === 'Y' &&
                  this.props.auth.current_rshop?.ecommerce_orders === 'N' &&
                  !this.props.auth.current_rshop?.has_rsales_operation)
              ) {
                this.props.navigation.navigate(routes.portfolio, { backRoute: false });
              } else if (
                this.props.auth.current_rshop?.ecommerce_orders === 'Y' ||
                this.props.auth.current_rshop?.ecommerce_orders === null ||
                this.props.auth.current_rshop?.ecommerce_wallet === 'N' ||
                !this.props.auth.current_rshop?.has_rsales_operation
              ) {
                this.props.navigation.navigate(routes.products, { backRoute: false });
              } else {
                this.props.navigation.navigate(routes.products, { backRoute: false });
              }
            }
          } else {
            this.Loader.closeLoader(() => showAlert(responseHome, 'danger'));
          }
        } else {
          this.Loader.closeLoader(() => showAlert(responseShoppinCart, 'danger'));
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (004).';
        }
        this.Loader.closeLoader(() => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Handle event to update filter_value
   *
   * @param {*} term
   * @memberof BranchOfficesListScreen
   */
  searchUpdated(term: string) {
    const data = this.props.auth?.array_branchoffices?.filter(function (branch_office) {
      return (
        branch_office.client_id.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        branch_office.client_name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
        branch_office.client_address.toLowerCase().indexOf(term.toLowerCase()) > -1
      );
    });
    this.setState({
      filter_value: term,
      data,
    });
  }

  /**
   * Render item
   *
   * @memberof BranchOfficesListScreen
   */
  renderItem = ({ item }: { item: Company }) => (
    <TouchableOpacity onPress={this.onPressItem(item)}>
      <View style={styles.itemContainer}>
        <Image style={styles.image} source={FACTORY} />
        <View style={styles.branchOfficeContainer}>
          <Text numberOfLines={1} ellipsizeMode={'tail'} style={styles.companyTitle}>
            {item.client_name}
          </Text>
          <Text style={styles.branchCode}>
            {strings.nit}
            {item.client_id}
          </Text>
          <Text numberOfLines={1} ellipsizeMode={'tail'} style={styles.branchCode}>
            {strings.address}
            {item.client_address}
          </Text>
          <View style={styles.orderMinContainer}>
            <Text style={styles.orderMinText}>{strings.ordermin}</Text>
            <Text style={styles.orderMinPrice}>{accounting.formatMoney(item.min_order_value)}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );

  /**
   * Render sign in view
   *
   * @returns
   * @memberof BranchOfficesListScreen
   */
  render() {
    return (
      <>
        <BackHandlerCustom exitApp={!this.props.route.params?.backRoute} />
        <HeaderSideDrawer
          mainLabel={strings.headerTitleBranchOffices}
          color={colors.white}
          backgroundColor={colors.primary}
          showBack={true}
          navigation={this.props.navigation}
          route={this.props.route}
          paddingTop={13}
        />
        <SafeAreaView style={styles.container}>
          <Loader onRef={(ref) => (this.Loader = ref)} />
          <SearchBar
            containerStyle={styles.searchBarContainer}
            inputContainerStyle={styles.inputContainerStyle}
            inputStyle={styles.searchBarInputStyle}
            onChangeText={(term) => this.searchUpdated(term)}
            placeholder={strings.searchPlaceholder}
            placeholderTextColor={colors.grayPlaceholder}
            lightTheme
            round
            value={this.state.filter_value}
          />
          <FlatList
            style={styles.flexOne}
            data={this.state.data}
            keyExtractor={this.keyExtractor}
            renderItem={this.renderItem}
            bounces={false}
          />
        </SafeAreaView>
      </>
    );
  }
}

BranchOfficesListScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

BranchOfficesListScreen.defaultProps = {};

/** Export component BranchOfficesListScreen **/
export default connect((state) => ({ ...state }))(BranchOfficesListScreen);
