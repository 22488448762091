/** Import React **/
import React from 'react';
import { View, Text, Image, Linking, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

/** Config **/
import Config from 'react-native-config';

/** Import Redux **/
import { connect } from 'react-redux';

/** DeviceInfo **/
import DeviceInfo from 'react-native-device-info';

/** Import React Navigation **/
import { DrawerContentScrollView } from '@react-navigation/drawer';

/** Import Components **/
import Button from '../../components/button';
import ButtonWithIcon from '../../components/buttonWithIcon';
import ModalConfirm from '../../components/modalConfirm';

/** Import Constants **/
import assets from '../../constants/assets/assets';
import icons from '../../constants/icons/icons';
import { routes } from '../../constants/routes/routes';
import stringsConstants from '../../constants/strings/strings';
import styles from '../style';
import { apiPost } from '../../api';
import { urlLogout } from '../../api/urls';
import { navigationRef } from '../RootNavigation';

/** Import Interfaces **/
import { Auth } from '../../interfaces';

/** Import Slices **/
import { cleanAndLogout } from '../../store';

/** Import Utils **/
import showAlert from '../../utils/showAlert';

/** Import PackageJson **/
import packageJson from '../../../package.json';

/** Import Images **/
import userImage from '../../web_assets/images/user.png';

let USER = { uri: '' };

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  USER = userImage;
} else if (Platform.OS === 'ios') {
  USER = { uri: `${assets.user}` };
} else {
  USER = { uri: `@mipmap/${assets.user}` };
}

const COMPANY_CODE = Platform.OS !== 'web' ? Config.COMPANY_CODE : process.env.REACT_APP_COMPANY_CODE;

/**
 * Handle getUserName and return name
 *
 * @memberof CustomDrawerContent
 */
function _getUserName(auth?: Auth) {
  if (auth && auth.user && auth.user.user_name) {
    return auth.user.user_name;
  }
}

/**
 * Handle getUserEmail and return email
 *
 * @memberof CustomDrawerContent
 */
function _getUserMobilePhone(auth?: Auth) {
  if (auth && auth.user && auth.user.username) {
    return auth.user.username;
  }
}

/**
 * Get all Ecommerce data
 *
 * @memberof CustomDrawerContent
 */
function _getEcommerce(auth?: Auth) {
  if (auth && auth.current_rshop && auth.current_rshop.ecommerce) {
    return auth.current_rshop.ecommerce;
  }
}

/**
 * Get all Ecommerce data
 *
 * @memberof CustomDrawerContent
 */
function _getCurrentRshop(auth?: Auth) {
  if (auth && auth.current_rshop) {
    return auth.current_rshop;
  }
}

/**
 * Has ecommerce_phone_sellers_contact
 *
 * @memberof CustomDrawerContent
 */
function _hasEcommercePhoneSellersContact(auth?: Auth) {
  const ecommerce = _getEcommerce(auth);
  if (ecommerce) {
    if (typeof ecommerce.ecommerce_phone_sellers_contact[0] !== 'undefined') {
      const phoneNumber = ecommerce.ecommerce_phone_sellers_contact[0]
        .replace('.', '')
        .replace(' ', '')
        .replace('-', '');
      if (phoneNumber.length > 0 && !isNaN(Number(phoneNumber))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * Get ecommerce_phone_sellers_contact
 *
 * @memberof CustomDrawerContent
 */
function _getEcommercePhoneSellersContact(auth?: Auth) {
  const ecommerce = _getEcommerce(auth);
  const hasPhoneSellersContact = _hasEcommercePhoneSellersContact(auth);
  if (ecommerce && hasPhoneSellersContact) {
    return Number(ecommerce.ecommerce_phone_sellers_contact[0]);
  } else {
    return 0;
  }
}

/**
 * Get version
 *
 * @memberof CustomDrawerContent
 */
function _getVersion() {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return packageJson.webVersion;
  } else {
    return DeviceInfo.getVersion();
  }
}

/**
 * Has ecommerce_phone_sellers_contact
 *
 * @memberof CustomDrawerContent
 */
function _hasEcommerceSacContact(auth?: Auth) {
  const ecommerce = _getEcommerce(auth);
  if (ecommerce) {
    if (typeof ecommerce.ecommerce_sac_contact !== 'undefined') {
      const phoneNumber = ecommerce.ecommerce_sac_contact
        .replace('.', '')
        .replace(' ', '')
        .replace('-', '')
        .replace('#', '');
      if (phoneNumber.length > 0 && !isNaN(Number(phoneNumber))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * Get ecommerce_sac_contact
 *
 * @memberof CustomDrawerContent
 */
function _getEcommerceSacContact(auth?: Auth) {
  const ecommerce = _getEcommerce(auth);
  const hasEcommercePhoneSellersContact = _hasEcommercePhoneSellersContact(auth);
  if (ecommerce && hasEcommercePhoneSellersContact) {
    if (ecommerce.ecommerce_sac_contact.substr(0, 1) == '#') {
      return '%23' + Number(ecommerce.ecommerce_sac_contact.replace('#', ''));
    } else {
      return Number(ecommerce.ecommerce_sac_contact);
    }
  } else {
    return 0;
  }
}

/**
 * Get ecommerce_orders
 *
 * @memberof CustomDrawerContent
 */
function _getEcommerceModuleOrders(auth?: Auth) {
  const currentRshop = _getCurrentRshop(auth);
  return (
    currentRshop &&
    typeof currentRshop.ecommerce_orders !== 'undefined' &&
    currentRshop.ecommerce_orders === 'N' &&
    currentRshop.ecommerce_orders !== null
  );
}

/**
 * Get ecommerce_wallet
 *
 * @memberof CustomDrawerContent
 */
function _getEcommerceModuleWallet(auth?: Auth) {
  const currentRshop = _getCurrentRshop(auth);
  return (
    currentRshop &&
    typeof currentRshop.ecommerce_wallet !== 'undefined' &&
    currentRshop.ecommerce_wallet === 'N' &&
    currentRshop.ecommerce_wallet !== null
  );
}

/**
 * Get ecommerce_contact_supplier
 *
 * @memberof CustomDrawerContent
 */
function _getEcommerceContactSupplier(auth?: Auth) {
  const currentRshop = _getCurrentRshop(auth);
  return (
    currentRshop &&
    typeof currentRshop.ecommerce_contact_supplier !== 'undefined' &&
    currentRshop.ecommerce_contact_supplier === 'N' &&
    currentRshop.ecommerce_contact_supplier !== null
  );
}

/**
 * Get ecommerce_contact_seller
 *
 * @memberof CustomDrawerContent
 */
function _getEcommerceContactSeller(auth?: Auth) {
  const currentRshop = _getCurrentRshop(auth);
  return (
    currentRshop &&
    typeof currentRshop.ecommerce_contact_seller !== 'undefined' &&
    currentRshop.ecommerce_contact_seller === 'N' &&
    currentRshop.ecommerce_contact_seller !== null
  );
}

/**
 * Get has_rsales_operation
 *
 * @memberof CustomDrawerContent
 */
function _getHasRsalesOperation(auth?: Auth) {
  const currentRshop = _getCurrentRshop(auth);
  return (
    currentRshop &&
    typeof currentRshop.has_rsales_operation !== 'undefined' &&
    !currentRshop.has_rsales_operation &&
    currentRshop.has_rsales_operation !== null
  );
}

/**
 * Open phone cal with text and phonenumber
 *
 * @memberof CustomDrawerContent
 */
function _callLinking(phoneNumber: any) {
  const url = `tel:${phoneNumber}`;
  Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        showAlert({ error: `${stringsConstants.callLinking}: ${stringsConstants.callLinkingError}` }, 'danger');
      } else {
        return Linking.openURL(url);
      }
    })
    .catch(() => {
      showAlert({ error: `${stringsConstants.callLinking}: ${stringsConstants.callLinkingError}` }, 'danger');
    });
}

/**
 * Open whatsapp with text and phonenumber
 *
 * @memberof CustomDrawerContent
 */
function _whatsappLinking(text: string, phoneNumber: number) {
  const url = `whatsapp://send?text=${text}&phone=${phoneNumber}`;
  Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        showAlert({ error: `${stringsConstants.whatsappLinking}: ${stringsConstants.whatsappLinkingError}` }, 'danger');
      } else {
        return Linking.openURL(url);
      }
    })
    .catch(() => {
      showAlert({ error: `${stringsConstants.whatsappLinking}: ${stringsConstants.whatsappLinkingError}` }, 'danger');
    });
}

/**
 * Function to know if button is active
 *
 * @memberof CustomDrawerContent
 */
function _isButtonActive(state: any, routeName: string) {
  return state.routeNames[state.index] === routeName;
}

/**
 * Function to log out
 *
 * @memberof CustomDrawerContent
 */
async function _logout() {
  const response = await apiPost(urlLogout, {})();

  if (response.success) {
    cleanAndLogout();
  } else {
    showAlert(response, 'danger');
  }
}

/**
 * Get ecommerce_deliveries
 *
 * @memberof CustomDrawerContent
 */
function _getEcommerceModuleDelivery(auth?: Auth) {
  const currentRshop = _getCurrentRshop(auth);
  return (
    currentRshop &&
    (typeof currentRshop.ecommerce_deliveries == 'undefined' ||
      currentRshop.ecommerce_deliveries === 'N' ||
      currentRshop.ecommerce_deliveries == null)
  );
}

let ModalConfirmRef!: any;

interface Props {
  auth?: Auth;
  navigation?: any;
  state?: any;
}

function Index(props: Props) {
  const navigate = (pageName: string) => {
    const currentRoute = navigationRef.current?.getCurrentRoute();
    return props.navigation.navigate(pageName, {
      backRoute: currentRoute?.name,
      backRouteParams: currentRoute?.params,
      drawerPropsNavigation: props.navigation,
    });
  };

  return (
    <>
      <ModalConfirm
        onRef={(ref) => (ModalConfirmRef = ref)}
        title={stringsConstants.signOutTitle}
        subtitle={stringsConstants.signOutSubtitle}
        action={_logout}
        actionText={stringsConstants.signOutConfirm}
      />
      <SafeAreaView style={styles.container}>
        <View style={styles.profileContainer}>
          <View style={styles.imageContainer}>
            <Image style={styles.profileImage} source={USER} />
          </View>
          <View style={styles.nameContainer}>
            <Text style={styles.profileUserName}> {_getUserName(props.auth)} </Text>
            <Text style={styles.profileEmail}> {_getUserMobilePhone(props.auth)} </Text>
          </View>
        </View>
        <View style={styles.distributorsAndBranchesContainer}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          {props.auth && props.auth.companies && props.auth.companies[COMPANY_CODE] ? (
            <View style={styles.containerButtonTop}>
              <Button
                onPress={() => {
                  return navigate(routes.drawerBranchOfficesList);
                }}
                buttonClass={styles.branchOfficesButton100}
                buttonLabelText={stringsConstants.branchOfficesButton}
                buttonLabelTextClass={styles.buttonsTopTextClass100}
              />
            </View>
          ) : (
            <View style={styles.containerButtonsTop}>
              <Button
                onPress={() => {
                  return navigate(routes.drawerCompaniesList);
                }}
                buttonClass={styles.companiesButton}
                buttonLabelText={stringsConstants.companiesButton}
                buttonLabelTextClass={styles.buttonsTopTextClass}
              />
              <Button
                onPress={() => {
                  return navigate(routes.drawerBranchOfficesList);
                }}
                buttonClass={styles.branchOfficesButton}
                buttonLabelText={stringsConstants.branchOfficesButton}
                buttonLabelTextClass={styles.buttonsTopTextClass}
              />
            </View>
          )}
        </View>
        <DrawerContentScrollView {...props} contentContainerStyle={styles.menuOptionsContainer} bounces={false}>
          <ButtonWithIcon
            icon={icons.icHome}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.homeButton}
            onPress={() => props.navigation.navigate(routes.order)}
            active={_isButtonActive(props.state, routes.order)}
            disabled={_getEcommerceModuleOrders(props.auth)}
          />
          <ButtonWithIcon
            icon={icons.icProfile}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.profileButton}
            onPress={() => props.navigation.navigate(routes.profile)}
            active={_isButtonActive(props.state, routes.profile)}
          />
          <ButtonWithIcon
            icon={icons.icShoppingHistory}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.historialButton}
            onPress={() => props.navigation.navigate(routes.shoppingHistory)}
            active={_isButtonActive(props.state, routes.shoppingHistory)}
            disabled={_getEcommerceModuleOrders(props.auth)}
          />
          <ButtonWithIcon
            icon={icons.icPortfolio}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.portfolioButton}
            onPress={() => props.navigation.navigate(routes.portfolio)}
            active={_isButtonActive(props.state, routes.portfolio)}
            disabled={_getEcommerceModuleWallet(props.auth) || _getHasRsalesOperation(props.auth)}
          />
          <ButtonWithIcon
            icon={icons.icCallClient}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.callClientButton}
            onPress={() => _callLinking(_getEcommerceSacContact(props.auth))}
            disabled={!_hasEcommerceSacContact(props.auth) || _getEcommerceContactSupplier(props.auth)}
          />
          <ButtonWithIcon
            icon={icons.icCallSeller}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.callSellerButton}
            onPress={() => _callLinking(_getEcommercePhoneSellersContact(props.auth))}
            disabled={
              !_hasEcommercePhoneSellersContact(props.auth) ||
              _getEcommerceContactSeller(props.auth) ||
              _getHasRsalesOperation(props.auth)
            }
          />
          <ButtonWithIcon
            icon={icons.icWriteSeller}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.writeSellerButton}
            onPress={() =>
              _whatsappLinking(stringsConstants.helloSellerPrefix, _getEcommercePhoneSellersContact(props.auth))
            }
            disabled={
              !_hasEcommercePhoneSellersContact(props.auth) ||
              _getEcommerceContactSeller(props.auth) ||
              _getHasRsalesOperation(props.auth)
            }
          />
          <ButtonWithIcon
            icon={icons.icCar}
            buttonClass={styles.sidebarButton}
            buttonLabelTextClass={styles.sidebarLabelButtonTextClass}
            buttonLabelText={stringsConstants.deliveryHistoryButton}
            onPress={() => props.navigation.navigate(routes.deliveryHistory)}
            active={_isButtonActive(props.state, routes.deliveryHistory)}
            disabled={_getEcommerceModuleDelivery(props.auth) || _getHasRsalesOperation(props.auth)}
          />
        </DrawerContentScrollView>
        <Text style={styles.textVersion}>{stringsConstants.versionPrefix + ' ' + _getVersion()}</Text>
        <Button
          onPress={() => ModalConfirmRef.openModalConfirm()}
          buttonClass={styles.signoutButton}
          buttonLabelText={stringsConstants.logoutButton}
          buttonLabelTextClass={styles.signoutButtonTextClass}
        />
      </SafeAreaView>
    </>
  );
}

/** Export component CustomDrawerContent **/
export default connect((state) => ({ ...state }))(Index);
