/** Import Redux Toolkit **/
import { createSlice } from '@reduxjs/toolkit';

/** Import Initial State **/
import { INITIAL_STATE } from './initialState';

/** Import Constants **/
import colors from '../../constants/styles/colors';

const THEME: any = {
  danger: {
    backgroundColor: colors.danger,
    accentColor: colors.white,
    messageColor: colors.white,
  },
  info: {
    backgroundColor: colors.info,
    accentColor: colors.white,
    messageColor: colors.white,
  },
  warning: {
    backgroundColor: colors.warning,
    accentColor: colors.warningErrors,
    messageColor: colors.warningErrors,
  },
  success: {
    backgroundColor: colors.success,
    accentColor: colors.white,
    messageColor: colors.white,
  },
};

declare let global: any;

const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState: INITIAL_STATE,
  reducers: {
    showSnackBar(state, action) {
      const newPosition = action.payload.position ? action.payload.position : state.position;
      let newHeight = global && global.insets && global.insets[newPosition] ? global.insets[newPosition] : 0;
      if (newHeight === 'top') {
        newHeight -= 15;
      } else {
        newHeight += 15;
      }
      return {
        ...state,
        ...THEME[action.payload.theme ? action.payload.theme : state.theme],
        theme: action.payload.theme,
        visible: action.payload.visibleCustom ? false : true,
        visibleCustom: action.payload.visibleCustom,
        textMessage: action.payload.textMessage,
        actionHandler: action.payload.actionHandler ? action.payload.actionHandler : state.actionHandler,
        actionText: action.payload.actionText ? action.payload.actionText : state.actionText,
        position: newPosition,
        [newPosition]: newHeight,
        autoHidingTime: action.payload.autoHidingTime,
      };
    },
    hideSnackBar(state) {
      return {
        ...INITIAL_STATE,
        ...THEME[state.theme ? state.theme : 'danger'],
        theme: state.theme,
      };
    },
  },
});

export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;

export default snackBarSlice.reducer;
