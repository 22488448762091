/** Import modules **/
import React, { Component } from 'react';
import { SafeAreaView, Text, View, TextInput, TouchableOpacity, Modal, FlatList } from 'react-native';
import PropTypes from 'prop-types';

/** Import accounting **/
import accounting from 'accounting';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import { apiGet } from '../../api';
import { urlShoppingcartList, urlShoppingcartOrder } from '../../api/urls';

/** Import components **/
import ButtonBranchOffice from '../../components/buttonBranchOffice';
import HeaderSideDrawer from '../../components/headerSideDrawer';
import Loader from '../../components/loader';
import ModalConfirm from '../../components/modalConfirm';

/** Import Constants **/
import colors from '../../constants/styles/colors';
import { routes } from '../../constants/routes/routes';
import strings from './strings';
import styles from './style';

/** Import Interfaces **/
import { Auth, ShoppingCart } from '../../interfaces';

/** Import PropTypes **/
import { AuthPropTypes, ProductsPropTypes, ShoppingCartPropTypes } from '../../propTypes';

/** Import Slices **/
import { store } from '../../store';
import { cleanShoppingCartProducts } from '../../slices/products/productsSlice';
import { cleanShoppingCartFavorites } from '../../slices/favorites/favoritesSlice';
import {
  setShoppingCart,
  saveObservations,
  cleanShoppingCart,
  addToDeleteShoppingCartItem,
} from '../../slices/shoppingCart/shoppingCartSlice';

/** Import Utils **/
import parseProductData from '../../utils/parseProductData';
import showAlert from '../../utils/showAlert';
import { removeMassiveFromShoppingCart } from '../../views/productDetail/api';
import ModalConfirmItem from '../../components/modalConfirmItem';
import ModalMininumAmount from '../../components/modalMininumAmount';
import ModalDisabledSales from '../../components/modalDisabledSales';

accounting.settings = {
  currency: {
    symbol: '$ ',
    format: '%s%v',
    decimal: ',',
    thousand: '.',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: '.',
    decimal: ',',
  },
};

/** Import Images **/
import icons from '../../constants/icons/icons';
import IconWithBackground from '../../components/iconWithBackground';
import { IconMoon } from '../../components/icon/IconMoon';

/** Enables basic storage and retrieval of dates and times. */
interface Props {
  navigation: NavigationProp<any>;
  auth: Auth;
  shoppingCart: ShoppingCart;
  route: any;
}

interface State {
  loading: boolean;
  data: Array<any>;
  load_data: boolean;
  handleLoadMore: boolean;
  observations: string;
  deliveryDateLabel: string;
  deliveryDayLabel: string;
  activeDelivery: boolean;
  deliveryDate: string;
  message_item: string;
  name_products: string;
  name_products_minimum_amount: string;
  selectedOptionCode: string;
  selectedOptionDescription: string;
  modalVisible: boolean;
  searchQuery: string;
  filteredData: Array<any>;
}

interface StringByBoolean {
  [key: string]: boolean;
}
/**
 * Class to export CheckoutScreen
 *
 * @class CheckoutScreen
 * @extends {Component<Props, State>}
 */
class CheckoutScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  public timer!: any;

  public Loader!: any;
  public ModalConfirm!: any;
  public ModalConfirmItem!: any;
  public ModalMininumAmount!: any;
  public ModalDisabledSales!: any;

  /**
   * Creates an instance of CheckoutScreen.
   * @param {*} props
   * @memberof CheckoutScreen
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      load_data: true,
      handleLoadMore: true,
      observations:
        this.props &&
        this.props.shoppingCart &&
        this.props.shoppingCart.observations &&
        this.props.shoppingCart.observations.length > 0
          ? this.props.shoppingCart.observations
          : '',
      deliveryDateLabel: '',
      deliveryDayLabel: '',
      activeDelivery: false,
      deliveryDate: '',
      message_item: '',
      name_products: '',
      name_products_minimum_amount: '',
      selectedOptionCode: '',
      selectedOptionDescription: '',
      modalVisible: false,
      searchQuery: '',
      filteredData: props.auth.user?.array_cellars || [],
    };
  }

  setModalVisible = (visible: boolean) => {
    this.setState({ modalVisible: visible, searchQuery: '', filteredData: this.props.auth.user?.array_cellars || [] });
  };

  handleOptionSelect = (item: { code: string; description: string }) => {
    this.setState({
      selectedOptionCode: item.code,
      selectedOptionDescription: item.description,
      modalVisible: false,
    });
  };

  /**
   * Functin to get CheckoutScreen when moduled is opened
   *
   * @memberof CheckoutScreen
   */
  componentDidMount() {
    this.timer = null;
    if (this._getTotalAmount() > 0) {
      this.setState({
        data: this.parsedProducts(this.props.shoppingCart.data),
      });
    } else {
      this._shoppingCart();
    }
    this._activeSectionDelivery();
  }

  handleSearch = (text: string) => {
    this.setState({ searchQuery: text });

    const { array_cellars } = this.props.auth.user || {};
    const filteredData =
      array_cellars?.filter((item) => item.description.toLowerCase().includes(text.toLowerCase())) || [];
    this.setState({ filteredData });
  };

  /**
   * Function to set false mounted
   *
   * @memberof CheckoutScreen
   */
  componentWillUnmount() {
    this.timer = null;
  }

  /**
   * Function to receive props
   *
   * @param {*} prevProps
   * @memberof CheckoutScreen
   */
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id &&
      prevProps.auth &&
      prevProps.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id !== prevProps.auth.current_rshop.rshop_id
    ) {
      this._updateState();
    }

    if (this.props.shoppingCart !== prevProps.shoppingCart) {
      this._updateShoppingCart();
    }
  }

  /**
   * Function to update state from component did mount
   *
   * @memberof CheckoutScreen
   */
  _updateState = () => {
    this.setState(
      {
        loading: false,
        data: [],
        load_data: true,
        handleLoadMore: true,
        observations:
          this.props &&
          this.props.shoppingCart &&
          this.props.shoppingCart.observations &&
          this.props.shoppingCart.observations.length > 0
            ? this.props.shoppingCart.observations
            : '',
        deliveryDateLabel: '',
        deliveryDayLabel: '',
        activeDelivery: false,
        deliveryDate: '',
        message_item: '',
        name_products: '',
        name_products_minimum_amount: '',
      },
      () => this._shoppingCart(),
    );
  };

  /**
   * Functionto update state from component did update
   *
   * @memberof CheckoutScreen
   */
  _updateShoppingCart = () => {
    this.setState({ data: this.parsedProducts(this.props.shoppingCart.data) });
  };

  /**
   * Function to get rshop_id
   *
   * @memberof CheckoutScreen
   */
  _getRshop_id = () => {
    return this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.rshop_id
      ? this.props.auth.current_rshop.rshop_id
      : '';
  };

  /**
   * Get total of item in shopping carg
   *
   * @memberof CheckoutScreen
   */
  _getTotalAmount = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data ? this.props.shoppingCart.data.length : 0;
  };

  /**
   * Function to call service and get products
   *
   * @memberof CheckoutScreen
   */
  _shoppingCart = () => {
    if (
      this.state.loading ||
      (this.props.auth.csrftoken && this.props.auth.csrftoken.length === 0) ||
      !this.props.auth.current_rshop ||
      (this.props.auth.current_rshop && !this.props.auth.current_rshop.rshop_id)
    ) {
      return;
    }
    this.setState({ loading: true }, async () => {
      const urlShoppingcartListReplaced = urlShoppingcartList.replace(/\[rshop_id\]/g, String(this._getRshop_id()));
      const data = {
        csrfmiddlewaretoken: this.props.auth?.csrftoken,
      };
      try {
        const response = await apiGet(urlShoppingcartListReplaced, data)();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.data_loaded = this.state.data;

          store.dispatch(setShoppingCart(response));

          this.setState({
            loading: false,
            data: this.parsedProducts(this.props.shoppingCart.data),
            load_data: false,
          });
        } else {
          this.setState({ loading: false }, () => showAlert(response, 'danger'));
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (013).';
        }
        this.setState({ loading: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Function to merge ProductItems with the ShoppingCartItems
   */
  parsedProducts = (products: any) => {
    const mergedProducts: Array<any> = [];
    let key: any;
    for (key in products) {
      mergedProducts.push(parseProductData(products[key], undefined));
    }
    return mergedProducts;
  };

  /**
   * Get total price without taxes
   *
   * @memberof CheckoutScreen
   */
  _getTotalPriceWithoutTaxes = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.totalPriceWithoutTaxes)
      : 0;
  };

  /**
   * Get total taxes
   *
   * @memberof CheckoutScreen
   */
  _getTotalTaxes = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.totalTaxes)
      : 0;
  };

  /**
   * Get total order
   *
   * @memberof CheckoutScreen
   */
  _getTotalChargeValue = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.totalChargeValue)
      : 0;
  };

  /**
   * Get total order
   *
   * @memberof CheckoutScreen
   */
  _getTotalOrder = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.props.shoppingCart && this.props.shoppingCart.data && this.props.shoppingCart.data.length > 0
      ? accounting.formatMoney(this.props.shoppingCart.total_neto)
      : 0;
  };

  /**
   * Function to continue with send order
   *
   * @memberof CheckoutScreen
   */
  _continueSendOrder = () => {
    if (this.props.auth.current_rshop && this.props.auth.current_rshop.disabled_sales == 'Y') {
      this.ModalDisabledSales.openModalDisabledSales();
    } else {
      let name_product = '';
      let name_product_minimum_amount = '';

      for (let i = 0; i < this.props.shoppingCart.data.length; i++) {
        if (this.props.shoppingCart.data[i].to_delete) {
          name_product = name_product + '- ' + this.props.shoppingCart.data[i].product_name + '\n';
        }
        if (parseFloat(this.props.shoppingCart.data[i].quantity) < this.props.shoppingCart.data[i].minimum_amount) {
          name_product_minimum_amount =
            name_product_minimum_amount +
            '- ' +
            this.props.shoppingCart.data[i].product_name +
            ' Cant. Min: ' +
            this.props.shoppingCart.data[i].minimum_amount +
            '\n';
        }
      }
      if (name_product.length > 0) {
        this.setState({
          name_products: name_product,
          message_item: 'NA',
        });
        this.ModalConfirmItem.openModalConfirmItem();
      } else if (name_product_minimum_amount.length > 0) {
        this.ModalMininumAmount.openModalMininumAmount();
        this.setState({
          name_products_minimum_amount: name_product_minimum_amount,
        });
      } else {
        this.Loader.openLoader(async () => {
          const urlShoppingcartOrderReplaced = urlShoppingcartOrder.replace(
            /\[rshop_id\]/g,
            String(this._getRshop_id()),
          );
          const data = {
            csrfmiddlewaretoken: this.props.auth?.csrftoken,
            observations: this.state.observations || '',
            delivers_date: this.state.deliveryDate,
            cellar_code_checkout: this.state.selectedOptionCode,
            cellar_description_checkout: this.state.selectedOptionDescription,
          };
          try {
            const response = await apiGet(urlShoppingcartOrderReplaced, data)();

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (response.success) {
              this.setState({ observations: '' }, () => {
                store.dispatch(cleanShoppingCart());
                store.dispatch(cleanShoppingCartProducts());
                store.dispatch(cleanShoppingCartFavorites());
                this.props.navigation.navigate(routes.shoppingCartDetail);
                this.Loader.closeLoader();
              });
            } else if (response.errors.length > 0 && response.message === 'errorProducto') {
              const arrayAllProducts: any = this.props.shoppingCart.data;
              const arrayInactiveProducts = [];
              const errorsprod: any = response.errors;
              errorsprod.forEach(function (prod_error: any) {
                arrayAllProducts.forEach(function (prod: any) {
                  if (
                    prod.product_code == prod_error.product_code &&
                    parseFloat(String(prod_error.factor)) == parseFloat(String(prod.factor))
                  ) {
                    store.dispatch(addToDeleteShoppingCartItem({ ...prod, to_delete: true }));
                  } else if (
                    !errorsprod.some(function (prod_error_exist: any) {
                      return (
                        prod_error_exist.product_code == prod.product_code &&
                        parseFloat(String(prod_error_exist.factor)) == parseFloat(String(prod.factor))
                      );
                    })
                  ) {
                    store.dispatch(addToDeleteShoppingCartItem({ ...prod, to_delete: false }));
                  }
                });
              });
              for (let i = 0; i < this.props.shoppingCart.data.length; i++) {
                if (this.props.shoppingCart.data[i].to_delete) {
                  name_product = name_product + '- ' + this.props.shoppingCart.data[i].product_name + '\n';
                  arrayInactiveProducts.push(this.props.shoppingCart.data[i]);
                }
              }
              this.setState({
                name_products: name_product,
              });
              if (this.props.shoppingCart.data.length > 0 && arrayAllProducts.length != arrayInactiveProducts.length) {
                this.setState({
                  message_item: 'SI',
                });
              } else if (this.props.shoppingCart.data.length == arrayInactiveProducts.length) {
                await removeMassiveFromShoppingCart(this.props.auth, arrayInactiveProducts);
              }
              this.ModalConfirmItem.openModalConfirmItem();
              this.Loader.closeLoader();
            } else {
              this.Loader.closeLoader(() =>
                showAlert(response, 'danger', () => {
                  console.log('');
                }),
              );
            }
          } catch (error) {
            if (error.message) {
              error.message += ' (015).';
            }
            this.Loader.closeLoader(() => showAlert(error, 'danger'));
          }
        });
      }
    }
  };

  /**
   * Function continue buy
   *
   * @memberof CheckoutScreen
   */
  _continueshopping = () => {
    this.props.navigation.navigate(routes.shoppingCart, {
      backRoute: routes.products,
    });
  };
  _endShopping = () => {
    this.props.navigation.navigate(routes.products, { backRoute: false });
  };

  /**
   * Function to open detail and send orden
   *
   * @memberof CheckoutScreen
   */
  _sendOrder = () => {
    if (
      this.props.auth.current_rshop?.ecommerce_cellar_select === 'Y' &&
      this.props.auth.user &&
      this.props.auth.user.array_cellars &&
      this.props.auth.user.array_cellars?.length > 0 &&
      this.state.selectedOptionCode === '' &&
      this.state.selectedOptionDescription === ''
    ) {
      showAlert(
        { success: false, message: 'Es obligatorio seleccionar una bodega antes de enviar el pedido.' },
        'error',
      );
      return;
    }
    this.ModalConfirm.openModalConfirm();
  };

  /**
   *
   *
   * @memberof CheckoutScreen
   */
  _onChangeText = (text: string) => {
    this.setState({ observations: text });
    store.dispatch(saveObservations({ observations: text }));
  };

  /**
   * Get current items amount on shopping cart
   *
   * @memberof CheckoutScreen
   */
  _getCurrentItemsAmount = () => {
    if (this.state.loading) {
      return 0;
    }
    return this.state.data ? this.state.data.length : 0;
  };

  /**
   * Get label to current items amount on shopping cart
   *
   * @memberof CheckoutScreen
   */
  _getCurrentItemsAmountLabel = () => {
    const currentItemsAmount = this._getCurrentItemsAmount();
    return currentItemsAmount + ' producto' + (currentItemsAmount > 1 ? 's' : '');
  };

  _onPressShoppingCart = () => {
    this.props.navigation.navigate(routes.shoppingCart, {
      backRoute: routes.checkout,
    });
  };

  _continueDisabledSales = () => {
    this.props.navigation.navigate(routes.portfolio, {
      backRoute: false,
    });
  };

  /**
   * Zones Settings
   *
   * @memberof CheckoutScreen
   */

  _getCheckedDay = (firstDay: boolean) => {
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const date = new Date();
    let numberDay = date.getDay();
    let day: string = weekDays[numberDay];
    let checkedDay = `checked_${day}`;
    let flagCheckedDay = true;
    let count = 0;
    let countExtraDays = 0;
    if (this.props.auth?.current_rshop?.zone_settings && this.props.auth?.current_rshop?.zone_settings.length > 0) {
      const checkedWeekdays: StringByBoolean = {
        checked_sunday: this.props.auth?.current_rshop?.zone_settings[0].checked_sunday,
        checked_monday: this.props.auth?.current_rshop?.zone_settings[0].checked_monday,
        checked_tuesday: this.props.auth?.current_rshop?.zone_settings[0].checked_tuesday,
        checked_wednesday: this.props.auth?.current_rshop?.zone_settings[0].checked_wednesday,
        checked_thursday: this.props.auth?.current_rshop?.zone_settings[0].checked_thursday,
        checked_friday: this.props.auth?.current_rshop?.zone_settings[0].checked_friday,
        checked_saturday: this.props.auth?.current_rshop?.zone_settings[0].checked_saturday,
      };

      while (flagCheckedDay) {
        for (const data in checkedWeekdays) {
          if (data === checkedDay) {
            if (!firstDay) {
              if (countExtraDays === 0) {
                if (numberDay === 6) {
                  numberDay = 0;
                } else {
                  numberDay += 1;
                }
                day = weekDays[numberDay];
                checkedDay = `checked_${day}`;
                countExtraDays += 1;
                continue;
              }
            }
            if (checkedWeekdays[data]) {
              flagCheckedDay = false;
              return `${numberDay}${countExtraDays}`;
            } else {
              if (numberDay === 6) {
                numberDay = 0;
              } else {
                numberDay += 1;
              }
              day = weekDays[numberDay];
              checkedDay = `checked_${day}`;
            }
            countExtraDays += 1;
          }
          count += 1;
          if (count === 14) {
            flagCheckedDay = false;
            return false;
          }
        }
      }
    }
  };

  _checkedHour = (daySelect: number) => {
    if (this.props.auth?.current_rshop?.zone_settings && this.props.auth?.current_rshop?.zone_settings.length > 0) {
      const hourPerDay = [
        {
          from: this.props.auth?.current_rshop?.zone_settings[0].from_sunday,
          until: this.props.auth?.current_rshop?.zone_settings[0].until_sunday,
        },
        {
          from: this.props.auth?.current_rshop?.zone_settings[0].from_monday,
          until: this.props.auth?.current_rshop?.zone_settings[0].until_monday,
        },
        {
          from: this.props.auth?.current_rshop?.zone_settings[0].from_tuesday,
          until: this.props.auth?.current_rshop?.zone_settings[0].until_tuesday,
        },
        {
          from: this.props.auth?.current_rshop?.zone_settings[0].from_wednesday,
          until: this.props.auth?.current_rshop?.zone_settings[0].until_wednesday,
        },
        {
          from: this.props.auth?.current_rshop?.zone_settings[0].from_thursday,
          until: this.props.auth?.current_rshop?.zone_settings[0].until_thursday,
        },
        {
          from: this.props.auth?.current_rshop?.zone_settings[0].from_friday,
          until: this.props.auth?.current_rshop?.zone_settings[0].until_friday,
        },
        {
          from: this.props.auth?.current_rshop?.zone_settings[0].from_saturday,
          until: this.props.auth?.current_rshop?.zone_settings[0].until_saturday,
        },
      ];
      const from = hourPerDay[daySelect].from;
      const until = hourPerDay[daySelect].until;
      const date = new Date();
      const minutes = date.getMinutes();
      let hourDay;
      if (minutes <= 9) {
        hourDay = `${date.getHours()}:0${minutes}`;
      } else {
        hourDay = `${date.getHours()}:${minutes}`;
      }

      if (hourDay >= from && hourDay <= until) {
        return true;
      } else {
        return false;
      }
    }
  };

  _getDeliveryDay = (dayExactly: number, returnCountDays: number) => {
    if (this.props.auth?.current_rshop?.zone_settings && this.props.auth?.current_rshop?.zone_settings.length > 0) {
      const amountPerDay: (number | boolean)[] = [
        this.props.auth?.current_rshop?.zone_settings[0].number_days_sunday,
        this.props.auth?.current_rshop?.zone_settings[0].number_days_monday,
        this.props.auth?.current_rshop?.zone_settings[0].number_days_tuesday,
        this.props.auth?.current_rshop?.zone_settings[0].number_days_wednesday,
        this.props.auth?.current_rshop?.zone_settings[0].number_days_thursday,
        this.props.auth?.current_rshop?.zone_settings[0].number_days_friday,
        this.props.auth?.current_rshop?.zone_settings[0].number_days_saturday,
      ];
      const amountDay = amountPerDay[dayExactly];
      if (typeof amountDay === 'number' && returnCountDays > 0) {
        const moreDays = 1000 * 60 * 60 * 24 * returnCountDays;
        const diasEnMilisegundos = 1000 * 60 * 60 * 24 * amountDay;
        const suma = new Date().getTime() + diasEnMilisegundos + moreDays;
        const res = new Date(suma);
        return res;
      } else if (typeof amountDay === 'number' && returnCountDays === 0) {
        const diasEnMilisegundos = 1000 * 60 * 60 * 24 * amountDay;
        const suma = new Date().getTime() + diasEnMilisegundos;
        const res = new Date(suma);
        return res;
      } else {
        return false;
      }
    }
  };

  _activeShowDeliveryDay = () => {
    if (
      this.props.auth?.current_rshop?.has_activate_logistics_zone === 'Y' &&
      this.props.auth?.current_rshop?.zone_settings
    ) {
      const weekDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      const checkedDay: false | string | undefined = this._getCheckedDay(true);

      if (typeof checkedDay !== 'boolean' && checkedDay !== undefined) {
        const returnCheckedDay: number = +checkedDay.slice(0, 1);
        const returnCountDays: number = +checkedDay.slice(1, 2);
        if (this._checkedHour(returnCheckedDay)) {
          const newDayExactly: false | Date | undefined = this._getDeliveryDay(returnCheckedDay, returnCountDays);
          if (typeof newDayExactly !== 'boolean' && newDayExactly !== undefined) {
            const activeDelivery = `${newDayExactly.getDate()}/${
              newDayExactly.getMonth() + 1
            }/${newDayExactly.getFullYear()}`;
            const labelDayDelivery = weekDays[newDayExactly.getDay()];
            const newDayBD = `${newDayExactly.getFullYear()}-${
              newDayExactly.getMonth() + 1
            }-${newDayExactly.getDate()} ${newDayExactly.getHours()}:${newDayExactly.getMinutes()}:${newDayExactly.getSeconds()}`;
            this.setState({
              deliveryDate: newDayBD,
              deliveryDateLabel: activeDelivery,
              deliveryDayLabel: labelDayDelivery,
            });
          } else {
            return false;
          }
        } else {
          const checkedDayTwo: false | string | undefined = this._getCheckedDay(false);
          if (typeof checkedDayTwo !== 'boolean' && checkedDayTwo !== undefined) {
            const returnCheckedDayTwo: number = +checkedDayTwo.slice(0, 1);
            const returnCountDaysTwo: number = +checkedDayTwo.slice(1, 2);
            const newDayExactlyTwo: false | Date | undefined = this._getDeliveryDay(
              returnCheckedDayTwo,
              returnCountDaysTwo,
            );
            if (typeof newDayExactlyTwo !== 'boolean' && newDayExactlyTwo !== undefined) {
              const activeDeliveryTwo = `${newDayExactlyTwo.getDate()}/${
                newDayExactlyTwo.getMonth() + 1
              }/${newDayExactlyTwo.getFullYear()}`;
              const labelDayDeliveryTwo = weekDays[newDayExactlyTwo.getDay()];
              const newDayBD = `${newDayExactlyTwo.getFullYear()}-${
                newDayExactlyTwo.getMonth() + 1
              }-${newDayExactlyTwo.getDate()} ${newDayExactlyTwo.getHours()}:${newDayExactlyTwo.getMinutes()}:${newDayExactlyTwo.getSeconds()}`;
              this.setState({
                deliveryDate: newDayBD,
                deliveryDateLabel: activeDeliveryTwo,
                deliveryDayLabel: labelDayDeliveryTwo,
              });
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  _activeSectionDelivery() {
    const zoneDefault: boolean | undefined = this.props.auth?.current_rshop?.zone_default;
    let zoneDefaultValue: boolean;
    const renderDelivery: boolean = this._activeShowDeliveryDay();
    zoneDefaultValue = true;
    if (zoneDefault == undefined) {
      zoneDefaultValue = false;
    } else if (zoneDefault == true) {
      zoneDefaultValue = true;
    } else if (zoneDefault == false) {
      zoneDefaultValue = false;
    }
    this.setState({
      activeDelivery: renderDelivery && zoneDefaultValue,
    });
  }

  /**
   * Render home view
   *
   * @returns
   * @memberof CheckoutScreen
   */
  render() {
    const showCellars =
      this.props.auth.current_rshop?.ecommerce_cellar_select === 'Y' &&
      this.props.auth.user &&
      this.props.auth.user.array_cellars &&
      this.props.auth.user.array_cellars?.length > 0;
    return (
      <>
        <Loader onRef={(ref) => (this.Loader = ref)} />
        <ModalConfirm
          onRef={(ref) => (this.ModalConfirm = ref)}
          title={strings.confirmOrder}
          subtitle={strings.messageOrder}
          action={this._continueSendOrder}
          actionText={strings.request}
        />
        <ModalConfirmItem
          onRef={(ref) => (this.ModalConfirmItem = ref)}
          title={
            this.state.message_item == 'SI'
              ? strings.informacionCompra
              : this.state.message_item == 'NA'
              ? strings.compraSinActualizar
              : ''
          }
          text_products={
            this.state.message_item == 'SI' || this.state.message_item == 'NA' ? this.state.name_products : ''
          }
          subtitle={
            this.state.message_item == 'SI'
              ? strings.messageCompra
              : this.state.message_item == 'NA'
              ? strings.messageSinActualizar
              : strings.messageSinItem
          }
          action={
            this.state.message_item == 'SI' || this.state.message_item == 'NA'
              ? this._continueshopping
              : this._endShopping
          }
          actionText={strings.continue}
        />

        <ModalDisabledSales
          onRef={(ref) => (this.ModalDisabledSales = ref)}
          title={strings.messageDisabledSales}
          action={this._continueDisabledSales}
          actionText={'CONSULTAR CARTERA'}
        />
        <ModalMininumAmount
          onRef={(ref) => (this.ModalMininumAmount = ref)}
          title={strings.messageMininumAmount}
          subtitle={this.state.name_products_minimum_amount}
          action={this._continueshopping}
          actionText={'EDITAR'}
        />
        <HeaderSideDrawer
          navigation={this.props.navigation}
          auth={this.props.auth}
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
          showBack={true}
          route={this.props.route}
          paddingTop={13}
        />
        <SafeAreaView style={styles.container}>
          <ButtonBranchOffice rshop={this.props.auth?.current_rshop} />
          <View style={styles.containerWrapper}>
            {this.state.activeDelivery && (
              <View style={styles.containerPreviewDeliveryDay}>
                <Text style={styles.containerPreviewDeliveryDayText}>Fecha aproximada de entrega:</Text>
                <View style={styles.containerPreviewDeliveryDayContent}>
                  <Text style={styles.containerPreviewDeliveryDayContentText}>{this.state.deliveryDateLabel}</Text>
                  <Text style={styles.containerPreviewDeliveryDayContentText}>{this.state.deliveryDayLabel}</Text>
                </View>
              </View>
            )}
            <View>
              {showCellars && (
                <View style={styles.containerSelectCheckout}>
                  <Text style={styles.containerLabelCheckout}>{strings.labelSelectCellar}</Text>
                  <TouchableOpacity
                    style={{
                      borderWidth: 2,
                      borderColor: '#555',
                      borderStyle: 'solid',
                      marginLeft: 10,
                      marginTop: -10,
                      marginRight: 10,
                      height: 30,
                    }}
                    onPress={() => this.setModalVisible(true)}
                  >
                    {/* prettier-ignore */}
                    <Text style={styles.selectButtonText}>
                      {/* prettier-ignore */}
                      {this.state.selectedOptionDescription
                        ? `${this.state.selectedOptionDescription}`
                        : 'Seleccionar'}
                    </Text>
                  </TouchableOpacity>
                  <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    onRequestClose={() => this.setModalVisible(false)}
                  >
                    <View
                      style={{
                        flex: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        padding: 20,
                      }}
                    >
                      <View style={styles.modalContent}>
                        <TextInput
                          style={styles.searchInput}
                          placeholder="Buscar..."
                          value={this.state.searchQuery}
                          onChangeText={this.handleSearch}
                        />
                        <FlatList
                          data={this.state.filteredData}
                          keyExtractor={(item) => item.code.toString()}
                          renderItem={({ item }) => (
                            <TouchableOpacity onPress={() => this.handleOptionSelect(item)}>
                              <View style={styles.itemContainer}>
                                <Text style={styles.optionText}>{item.description}</Text>
                              </View>
                            </TouchableOpacity>
                          )}
                        />
                      </View>
                    </View>
                  </Modal>
                </View>
              )}
            </View>
            <TouchableOpacity onPress={this._onPressShoppingCart}>
              <View style={styles.containerCheckout}>
                <View style={styles.containerCheckoutIcon}>
                  <IconWithBackground
                    name={icons.icShopping}
                    color={colors.primary}
                    size={35}
                    focused={false}
                    showBadge={false}
                    width={40}
                  />
                </View>

                <View style={styles.containerCheckoutContent}>
                  <Text style={styles.containerCheckoutContentText1}>Resumen de Compra</Text>
                  <Text style={styles.containerCheckoutContentText2}>{`${this._getCurrentItemsAmountLabel()}`}</Text>
                </View>

                <View style={styles.containerCheckoutButton}>
                  <IconMoon
                    style={styles.containerCheckoutButtonIcon}
                    name={icons.icNext}
                    size={60}
                    color={colors.black}
                  />
                </View>
              </View>
            </TouchableOpacity>
            <View style={styles.containerTitleAndObservations}>
              <View style={styles.containerDescription}>
                <Text style={styles.titleDescription}>{strings.observations}</Text>
              </View>
              <View style={styles.containerInput}>
                <View style={styles.containerObservations}>
                  <View style={styles.flexRow}>
                    <Text style={styles.maxObservations}>{strings.maxObservations}</Text>
                    {/* <Text style={{marginLeft: 'auto'}}>{500 - this.state.observations.length}</Text> */}
                  </View>
                  <TextInput
                    style={styles.textArea}
                    onChangeText={(text) => this._onChangeText(text)}
                    value={this.state.observations}
                    placeholder={strings.placeHolderObservations}
                    multiline={true}
                    maxLength={500}
                  />
                </View>
              </View>
            </View>
            <View style={styles.containerTotal}>
              <View style={styles.containerDescription}>
                <Text style={styles.titleTotalSection}>{strings.total}</Text>
              </View>
              <View style={styles.containerAllTotals}>
                <View style={styles.containerTotals}>
                  <View style={styles.containerTotalsLeft}>
                    <Text style={styles.totalsLabel}>{strings.priceWithoutTaxes}</Text>
                  </View>
                  <View style={styles.containerTotalsRight}>
                    <Text style={styles.totalsValue}>{this._getTotalPriceWithoutTaxes()}</Text>
                  </View>
                </View>
                <View style={styles.containerTotals}>
                  <View style={styles.containerTotalsLeft}>
                    <Text style={styles.totalsLabel}>{strings.taxes}</Text>
                  </View>
                  <View style={styles.containerTotalsRight}>
                    <Text style={styles.totalsValue}>{this._getTotalTaxes()}</Text>
                  </View>
                </View>
                <View style={styles.containerTotals}>
                  <View style={styles.containerTotalsLeft}>
                    <Text style={styles.totalsLabel}>{strings.priceChargesValues}</Text>
                  </View>
                  <View style={styles.containerTotalsRight}>
                    <Text style={styles.totalsValue}>{this._getTotalChargeValue()}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.total}>
            <TouchableOpacity
              style={[
                styles.totalContainer,
                this.state.data && this.state.data.length > 0
                  ? styles.totalContainerEnabled
                  : styles.totalContainerDisabled,
              ]}
              disabled={!(this._getCurrentItemsAmount() > 0)}
              onPress={this._sendOrder}
            >
              <View style={styles.rowDirection}>
                <View style={styles.viewRequestOrderItemsAmount}>
                  <Text style={styles.textRequestOrderItemsAmount}>{this._getCurrentItemsAmount()}</Text>
                </View>
                <Text style={styles.textRequestOrder}>{strings.sendOrder}</Text>
                <Text style={styles.textTotalOrder}>{this._getTotalOrder()}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </>
    );
  }
}

CheckoutScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
  products: ProductsPropTypes,
  shoppingCart: ShoppingCartPropTypes,
};

CheckoutScreen.defaultProps = {};

/** Export component CheckoutScreen **/
export default connect((state) => ({ ...state }))(CheckoutScreen);
