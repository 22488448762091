/** Styles to view **/
import { StyleSheet } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';
/** Create an object style **/
const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderWidth: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    backgroundColor: colors.primary,
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonTextTop: {
    color: colors.white,
    fontSize: 12,
  },
  buttonTextBottom: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 14,
  },
  modalBackground: {
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: 10,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  modalTitle: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 14,
  },
  modalButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderWidth: 0,
    borderRadius: 10,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    backgroundColor: colors.primary,
  },
  containerDetail: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 10,
    marginBottom: 20,
  },
  modalLabel: {
    color: colors.gray,
    fontSize: 14,
    marginTop: 10,
  },
  modalLabelValue: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 14,
  },
});

/** Export component styles **/
export default styles;
