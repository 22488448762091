/** Import Interfaces **/
import { DeliveryHistory } from '../../interfaces';

const INITIAL_STATE: DeliveryHistory = {
  page: {
    has_next: true,
    next_page_number: 1,
  },
  delivery_history: [],
  order_state: '',
};

export { INITIAL_STATE };
