/** Import React **/
import React, { Component } from 'react';
import { ActivityIndicator, Dimensions, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import { SafeAreaView } from 'react-native-safe-area-context';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
//import PhotoBrowser from 'react-native-photo-browser';
//import ImageLayout from 'react-native-image-layout';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import { CustomGallery, DefaultLayout, Item } from 'react-photoswipe-gallery';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import Navigation **/
import { NavigationProp } from '@react-navigation/core';

/** Import Api **/
import {
  urlRootGalleryUrl,
  urlImageGalleryMaster,
  urlImageGalleryThumbs,
  urlImageGalleryProducts,
} from '../../api/urls';

/** Import Constants **/
import assets from './../../constants/assets/assets';
import styles from './style';

/** Import Interfaces **/

/** Import PropTypes **/
import { AuthPropTypes } from '../../propTypes';

/** Import Images **/
import productWithoutImage from '../../web_assets/images/product_without_image.png';
import { ProductImage } from '../../interfaces/ProductImage';
import { Auth, ProductItem } from '../../interfaces';
import strings from './strings';
import colors from '../../constants/styles/colors';
import HeaderSideDrawer from '../../components/headerSideDrawer';
import { IconMoon } from '../../components/icon/IconMoon';
import icons from '../../constants/icons/icons';

/** Import Routes **/
import { routes } from '../../constants/routes/routes';
import { apiGet } from '../../api';
import showAlert from '../../utils/showAlert';

let PRODUCT_WITHOUT_IMAGE = '';

if (Platform.OS === 'web') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  PRODUCT_WITHOUT_IMAGE = productWithoutImage;
} else if (Platform.OS === 'ios') {
  PRODUCT_WITHOUT_IMAGE = `${assets.productWithoutImage}`;
} else {
  PRODUCT_WITHOUT_IMAGE = `@mipmap/${assets.productWithoutImage}`;
}

const { width } = Dimensions.get('window');
const IMAGE_WIDTH = 177;

interface Props {
  navigation: NavigationProp<any>;
  route: any;
  auth: Auth;
}

interface State {
  item: any;
  imageList: Array<ProductImage>;
  loadingProductPhotos: boolean;
}

/**
 * Class to export ProductImageGalleryScreen
 *
 * @class ProductImageGalleryScreen
 * @extends {Component<Props, State>}
 */
class ProductImageGalleryScreen extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  private layoutRef: any;

  /**
   * Creates an instance of ProductImageGalleryScreen.
   * @param {*} props
   * @memberof ProductImageGalleryScreen
   */
  constructor(props: Props) {
    super(props);
    this.layoutRef = React.createRef();
    const item = this.props.route.params?.item ?? undefined;

    this.state = {
      item: item,
      imageList: [],
      loadingProductPhotos: false,
    };
  }

  /**
   * Functin to get ProductsScreen when moduled is opened
   *
   * @memberof ProductsScreen
   */
  componentDidMount() {
    this._loadProductPhotos(this.props.auth, this.props.route.params.item);
  }

  /**
   * Handle getImage and return object with image route
   *
   * @memberof ProductImageGallery
   */
  _getPhoto = (item: ProductItem) => {
    if (
      item &&
      item.code &&
      item.code.length > 0 &&
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.company_code &&
      item.has_image === 'Y'
    ) {
      return (
        urlRootGalleryUrl +
        urlImageGalleryMaster
          .replace(/\[company_code\]/g, this.props.auth.current_rshop.company_code)
          .replace(/\[product_code\]/g, item.code)
      );
    } else {
      return PRODUCT_WITHOUT_IMAGE;
    }
  };

  /**
   * Handle _getThumb and return object with image route
   *
   * @memberof ProductImageGallery
   */
  _getThumb = (item: ProductItem) => {
    if (
      item &&
      item.code &&
      item.code.length > 0 &&
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.company_code &&
      item.has_image === 'Y'
    ) {
      return (
        urlRootGalleryUrl +
        urlImageGalleryThumbs
          .replace(/\[company_code\]/g, this.props.auth.current_rshop.company_code)
          .replace(/\[product_code\]/g, item.code)
      );
    } else {
      return PRODUCT_WITHOUT_IMAGE;
    }
  };

  /**
   * Handle get product photos
   *
   * @memberof ProductImageGallery
   */
  _getPhotos = (item: ProductItem) => {
    if (
      item &&
      item.code &&
      item.code.length > 0 &&
      this.props.auth &&
      this.props.auth.current_rshop &&
      this.props.auth.current_rshop.company_code &&
      item.has_image === 'Y'
    ) {
      return (
        urlRootGalleryUrl +
        urlImageGalleryMaster
          .replace(/\[company_code\]/g, this.props.auth.current_rshop.company_code)
          .replace(/\[product_code\]/g, item.code)
      );
    } else {
      return PRODUCT_WITHOUT_IMAGE;
    }
  };

  /**
   * Function to call service and get product photos url
   *
   * @memberof ProductsScreen
   */
  _loadProductPhotos = (user: Auth, item: ProductItem) => {
    if (this.state.loadingProductPhotos || !user.current_rshop?.company_code) {
      return;
    }
    this.setState({ loadingProductPhotos: true }, async () => {
      const url = urlImageGalleryProducts.replace(/\[rshop_id\]/g, String(user.current_rshop?.rshop_id));
      const data = {
        csrfmiddlewaretoken: user.csrftoken,
        product_id: item.code,
      };
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
        const response = await apiGet(url, data)();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (response && typeof response === 'object' && response.hasOwnProperty && response.hasOwnProperty('length')) {
          if (response.length > 0) {
            this.setState({
              loadingProductPhotos: false,
              imageList: this.parsedProductPhotos(response),
            });
          } else {
            this.setState({ loadingProductPhotos: false }, () =>
              showAlert(
                {
                  message: strings.notProductImagesAssociated,
                },
                'warning',
              ),
            );
          }
        } else {
          this.setState({ loadingProductPhotos: false }, () =>
            showAlert(
              {
                message: strings.notProductImagesAssociated,
              },
              'warning',
            ),
          );
        }
      } catch (error) {
        if (error.message) {
          error.message += ' (006).';
        }
        this.setState({ loadingProductPhotos: false }, () => showAlert(error, 'danger'));
      }
    });
  };

  /**
   * Function to parse product category filters
   */
  parsedProductPhotos = (photos: Array<any>) => {
    const imageList: Array<ProductImage> = [];
    photos.forEach((photoObj: any, index: number) => {
      imageList.push({
        thumb: urlRootGalleryUrl + photoObj.thumb, // thumbnail version of the photo to be displayed in grid view. actual photo is used if thumb is not provided
        photo: urlRootGalleryUrl + photoObj.master, // a remote photo or local media url
        id: index + 1, // unique identifer for the photo; can be omitted if the `thumb`/`photo` will always be unique
        caption: '', // photo caption to be displayed
        selected: index === 0, // set the photo selected initially(default is false)
      });
    });

    return imageList;
  };

  _printItems = () => {
    // Render Items
    return this.state.imageList.map((productImage: ProductImage) => {
      return (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Item original={productImage.photo} thumbnail={productImage.thumb} width={1024} height={1024}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {({ ref, open }) => <img width={IMAGE_WIDTH} ref={ref} onClick={open} src={productImage.photo} />}
          </Item>
        </>
      );
    });
  };

  /**
   * _getGalleryGridTemplateColumns calculates the amount of image columns
   *
   * @memberof ProductImageGallery
   */
  _getGalleryGridTemplateColumns = () => {
    const amountOfColumns = Math.floor(width / IMAGE_WIDTH);
    if (amountOfColumns > 1) {
      let gridTemplateColumns = `${IMAGE_WIDTH}px`;
      for (let i = 1; i < amountOfColumns; i++) {
        gridTemplateColumns += ` ${IMAGE_WIDTH}px`;
      }
      return gridTemplateColumns;
    } else {
      return `${width}px`;
    }
  };

  /**
   * _onOpenGallery is call when the gallery is open and adjust the photo dimensions
   *
   * @memberof ProductImageGallery
   */
  _onOpenGallery = (photoswipe: any) => {
    const fitRatio = photoswipe.currItem.fitRatio;
    photoswipe.items.forEach((item: any) => {
      if (item.el.clientWidth > item.el.clientHeight) {
        item.h = (item.h * fitRatio - (item.el.clientWidth - item.el.clientHeight)) / fitRatio;
      } else if (item.el.clientWidth < item.el.clientHeight) {
        item.w = (item.w * fitRatio - (item.el.clientHeight - item.el.clientWidth)) / fitRatio;
      }
    });
  };

  _rightComponent = () =>
    this.props.route.params.backRoute !== routes.productsDetail ? (
      <TouchableOpacity
        style={styles.icShopping}
        onPress={() => {
          this.props.navigation.navigate(routes.productsDetail, {
            backRoute: this.props.route.params?.backRoute,
            item: this.props.route.params.item,
            originalItem: this.props.route.params.originalItem,
            backRouteParams: {
              backRoute: this.props.route.params?.backRoute,
              item: this.props.route.params.item,
              originalItem: this.props.route.params.originalItem,
            },
          });
        }}
      >
        <IconMoon name={icons.icShopping} size={20} color={colors.primary} />
      </TouchableOpacity>
    ) : undefined;

  /**
   * Render sign in view
   *
   * @returns
   * @memberof ProductImageGalleryScreen
   */
  render() {
    return (
      <>
        <HeaderSideDrawer
          mainLabel={strings.mainLabel}
          color={colors.black}
          backgroundColor={colors.white}
          showBack={true}
          navigation={this.props.navigation}
          route={this.props.route}
          rightComponent={this._rightComponent}
          paddingTop={13}
        />
        <SafeAreaView style={styles.container}>
          {this.state.loadingProductPhotos ? (
            <View style={styles.isLoadingContainer}>
              <View style={styles.isLoadingIndicator}>
                <ActivityIndicator animating={true} size={'large'} color={colors.primary} />
              </View>
            </View>
          ) : null}
          <ScrollView bounces={false}>
            <View style={styles.containerScrollView}>
              <CustomGallery
                onOpen={this._onOpenGallery}
                layoutRef={this.layoutRef}
                ui={PhotoswipeUIDefault}
                options={{ modal: false }}
              >
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: this._getGalleryGridTemplateColumns(),
                    gridGap: 12,
                  }}
                >
                  {this._printItems()}
                </div>
              </CustomGallery>
              <DefaultLayout shareButton={false} ref={this.layoutRef} />
            </View>
          </ScrollView>
        </SafeAreaView>
      </>
    );
  }
}

ProductImageGalleryScreen.propTypes = {
  navigation: PropTypes.any,
  auth: AuthPropTypes,
};

ProductImageGalleryScreen.defaultProps = {};

/** Export component ProductImageGalleryScreen **/
export default connect((state) => ({ ...state }))(ProductImageGalleryScreen);
