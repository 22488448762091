/** Import modules **/
import React from 'react';
import { View, StyleSheet } from 'react-native';

declare let global: any;

interface Props {
  insets: any;
  layout: any;
  backgroundColor: string;
}

/**
 * Create button
 *
 * @param {Props}
 * @returns
 */
const TopHeader = ({ insets, layout, backgroundColor }: Props) => {
  global.insets = insets;
  global.layout = layout;
  /**
   * Render component with Icon
   *
   * @returns
   * @memberof TopHeader
   */
  return <View style={[StyleSheet.absoluteFill, { backgroundColor, height: insets.top }]} />;
};

/** Export component TopHeader **/
export default TopHeader;
