/** Import Interfaces **/
import { ShoppingHistory } from '../../interfaces';

const INITIAL_STATE: ShoppingHistory = {
  page: {
    has_next: true,
    next_page_number: 1,
  },
  shopping_history: [],
  detail: [],
  currentOrder: undefined,
  order_state: '',
};

export { INITIAL_STATE };
